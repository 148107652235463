import React from "react";
import { HStack, Image, Stack, Text, useBreakpointValue, VStack } from "native-base";
import { SkButton } from "../../../common/components/sk_button";
import { GlassBackground } from "../../../common/components/glass_background";
import SecureCredentialsIcon from "../../../common/assets/icons/secure-creds-icon.svg";
import FriendsIcon from "../../../common/assets/icons/friends.svg";
import MySelfkeyIdIcon from "../../../common/assets/icons/my-selfkey-id.svg";
import VoteIcon from "../../../common/assets/icons/vote.svg";
import SelfIcon from "../../../common/assets/icons/self-icon.svg";
import selfToken from "../../../common/assets/icons/self-token.svg";
import arrowFwd from "../../../common/assets/icons/arrow-fwd.svg";

interface ActionCardProps {
    title?: JSX.Element,
    icon?: string,
    actionButton?: JSX.Element,
    disabled?: boolean,
    halfSize?: boolean,
    height?: string,
    width?: string,
    cardPy?: string
}
export const ActionCard = ({ title, icon, actionButton, disabled, halfSize, height, width, cardPy }: ActionCardProps) => {
    const responsiveSize = useBreakpointValue({
        base: {
          width: width ? width : '320px',
          height: height ? height : '248px',
          direction: 'column',
          spaceButton: '24px',
          iconSize: '64px'},
        md: {
          width: width ? width : halfSize ? '336px' : '696px',
          height: height ? height : halfSize ? '176px' : '112px',
          direction: halfSize ? 'column' : 'row',
          spaceButton: '16px',
          iconSize: halfSize ? '56px' : '64px'
        },
    });
    return (
        <GlassBackground w={responsiveSize.width} h={responsiveSize.height} px={'32px'} py={cardPy ? cardPy : '24px'} disabled={disabled}>
            <Stack direction={responsiveSize.direction} justifyContent={'space-between'} alignItems={'center'} space={responsiveSize.spaceButton}>
                <Stack direction={{base: 'column', md: 'row'}} alignItems={'center'} space={'16px'}>
                  {icon && (
                    <Image
                      source={{
                        uri: icon
                      }}
                      w={responsiveSize.iconSize}
                      h={responsiveSize.iconSize}
                      alt={'title'}
                      resizeMode="contain"
                    />
                  )}
                    <HStack alignItems={'center'}>{title}</HStack>
                </Stack>
                {actionButton}
            </Stack>
        </GlassBackground>
    );
};

export const TextLine = ({ children }) => {
    return (
        <Text
            display={'flex'}
            alignItems={'center'}
            fontWeight={400}
            fontSize={{ base: '14px', md: '16px' }}
            lineHeight={{ base: '20px', md: '24px' }}
            textAlign={{ base: 'center', md: 'left' }}
        >
            {children}
        </Text>
    );
};

interface ActionButtonProps {
    handleClick: () => void,
    handleDisabledClick: () => void,
    disabled: boolean,
    eventName: string,
    halfSize?: boolean,
    variant?: string,
    children: React.ReactNode
}

export const ActionButton = ({ handleClick, handleDisabledClick, disabled, eventName, halfSize, variant, children }: ActionButtonProps) => {
    return (
        <SkButton
            eventName={eventName}
            fixedSize={false}
            w={{ base: '160px', md: halfSize ? '252px' : '180px' }}
            onPress={() => handleClick()}
            isDisabled={disabled}
            onDisabledClick={() => handleDisabledClick()}
            variant={variant}
        >
            {children}
        </SkButton>
    );
};

export interface ButtonProps {
    handleClick: () => void,
    handleDisabledClick: () => void,
    disabled: boolean,
    visible: boolean
    eventName?: string
    variant?: string
}

export interface ActionCardsProps {
    airdrop: ButtonProps,
    lock: ButtonProps,
    invite: ButtonProps,
    details: ButtonProps,
    vote: ButtonProps,
}

const ActionCards = ({airdrop, lock, invite, details, vote}: ActionCardsProps) => {
    const halfSizeLockInvite = lock.visible === invite.visible
    const lockInviteLayout = useBreakpointValue({
        base: 'column',
        md: halfSizeLockInvite ? 'row' : 'column',
    });
    const lockInviteSingleLine = useBreakpointValue({
        base: false,
        md: !halfSizeLockInvite,
    });

    const halfSizeDetailsVote = details.visible === vote.visible
    const detailsVoteLayout = useBreakpointValue({
        base: 'column',
        md: halfSizeDetailsVote ? 'row' : 'column',
    });

    return (
        <VStack space={'24px'}>
            {airdrop.visible && (
                // TODO: customize border as Figma layout (like button, maybe refactor button to use same border approach)
                <ActionCard
                    halfSize={false}
                    title={(
                        <VStack alignItems={{base: 'center', md: 'left'}}>
                            <TextLine>
                                Claim your SELF
                                <Image alt="self token" source={{uri: selfToken}} w={'20px'} h={'20px'} mx={'4px'}/>
                                Tokens
                            </TextLine>
                        </VStack>
                    )}
                    icon={SelfIcon}
                    disabled={airdrop.disabled}
                    actionButton={
                        <ActionButton
                            halfSize={false}
                            eventName={`Claim Now`}
                            handleClick={() => airdrop.handleClick()}
                            handleDisabledClick={() => airdrop.handleDisabledClick()}
                            disabled={airdrop.disabled}
                        >
                            Claim Now
                        </ActionButton>
                    }
                ></ActionCard>
            )}
            <Stack direction={lockInviteLayout} space={'24px'}>
                {invite.visible && (
                    <ActionCard
                        halfSize={halfSizeLockInvite}
                        title={(
                            <VStack alignItems={{base: 'center', md: 'left'}}>
                                {!lockInviteSingleLine && (<TextLine>Invite eligible members</TextLine>)}
                                <TextLine>
                                    {lockInviteSingleLine && 'Invite eligible members '}and Mint SELF
                                    <Image alt="self token" source={{uri: selfToken}} w={'20px'} h={'20px'} ml={'4px'}/>
                                </TextLine>
                            </VStack>
                        )}
                        icon={FriendsIcon}
                        disabled={invite.disabled}
                        actionButton={
                            <ActionButton
                                eventName={`Invite Now`}
                                handleClick={() => invite.handleClick()}
                                handleDisabledClick={() => invite.handleDisabledClick()}
                                disabled={invite.disabled}
                                halfSize={halfSizeLockInvite}
                            >
                                Invite / Redeem Now
                            </ActionButton>
                        }
                    ></ActionCard>
                )}
                {lock.visible && (
                    <ActionCard
                        halfSize={halfSizeLockInvite}
                        title={
                            <VStack alignItems={{ base: 'center', md: 'left' }}>
                                {!lockInviteSingleLine && (<TextLine>Lock your POI Credential</TextLine>)}
                                <TextLine>
                                    {lockInviteSingleLine && 'Lock your POI Credential '}and Mint SELF
                                    <Image alt="self token" source={{ uri: selfToken }} w={'20px'} h={'20px'} ml={'4px'} />
                                </TextLine>
                            </VStack>
                        }
                        icon={SecureCredentialsIcon}
                        disabled={lock.disabled}
                        actionButton={
                            <ActionButton
                                eventName={`Go`}
                                handleClick={() => lock.handleClick()}
                                handleDisabledClick={() => lock.handleDisabledClick()}
                                disabled={lock.disabled}
                                halfSize={halfSizeLockInvite}
                            >
                                Go
                            </ActionButton>
                        }
                    ></ActionCard>
                )}
            </Stack>
            <Stack direction={detailsVoteLayout} space={'24px'}>
                {details.visible && (
                    <ActionCard
                        halfSize={halfSizeDetailsVote}
                        title={
                            <Text
                                maxW={'294px'}
                                fontWeight={400}
                                fontSize={{ base: '14px', md: '16px' }}
                                lineHeight={{ base: '20px', md: '24px' }}
                                textAlign={{ base: 'center', md: 'left' }}
                            >
                                My SelfKey iD Details
                            </Text>
                        }
                        icon={MySelfkeyIdIcon}
                        disabled={details.disabled}
                        actionButton={
                            <ActionButton
                                eventName={`My Selfkey iD Details`}
                                handleClick={() => details.handleClick()}
                                handleDisabledClick={() => details.handleDisabledClick()}
                                disabled={details.disabled}
                                halfSize={halfSizeDetailsVote}
                                variant={'secondary'}
                            >
                                <Image
                                    source={{
                                        uri: arrowFwd
                                    }}
                                    alt="My Selfkey iD Details"
                                    w="28px"
                                    h="16px"
                                    resizeMode="contain"
                                />
                            </ActionButton>
                        }
                    ></ActionCard>
                )}
                {vote.visible && (
                    <ActionCard
                        halfSize={halfSizeDetailsVote}
                        title={
                            <TextLine>
                                Vote to unlock SELF
                                <Image alt="self token" source={{ uri: selfToken }} w={'20px'} h={'20px'} ml={'4px'} />
                            </TextLine>
                        }
                        icon={VoteIcon}
                        disabled={vote.disabled}
                        actionButton={
                            <ActionButton
                                eventName={`Vote`}
                                handleClick={() => vote.handleClick()}
                                handleDisabledClick={() => vote.handleDisabledClick()}
                                disabled={vote.disabled}
                                halfSize={halfSizeDetailsVote}
                                variant={'secondary'}
                            >
                                <Image
                                    source={{
                                        uri: arrowFwd
                                    }}
                                    alt="Vote"
                                    w="28px"
                                    h="16px"
                                    resizeMode="contain"
                                />
                            </ActionButton>
                        }
                    ></ActionCard>
                )}
            </Stack>
        </VStack>
    );
}

export default ActionCards;
