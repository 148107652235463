import { useState, useContext } from 'react';
import { LoadingContext, NftContext, Web3WagmiContext } from '../context/app_context';
import useSkrProcess from '../hooks/use_skr_flow';
import { getDID } from '../lib/helpers';
import { current as envConfigs } from 'env-configs/selfkey-org';

const useEligibility = () => {
  const { activeAddress: address } = useContext(Web3WagmiContext);
  const { mintNft, mintingTransactionHash, mintError } = useContext(NftContext);
  const { isWaitingForWallet, setIsWaitingForWallet, isLoadingNftMinting } = useContext(LoadingContext);

  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [termsWarning, setTermsWarning] = useState(false);

  const {
    getSkr,
    skr,
    loadingSkr,
    getVka,
    loadingVka,
    showTakeSelfieModal,
    setShowTakeSelfieModal,
    showConsentModal,
    setShowConsentModal,
    consentView,
    loadingSignConsent,
    handleAgreeConsent,
    errorMessage,
    error,
    eligibleError,
    poiCheckFailedError,
    consentError,
    resetErrors
  } = useSkrProcess(
    address,
    data => {
      mintNft(data.to, data.param, data.timestamp, data.signer, data.signature);
      setIsWaitingForWallet(true);
    },
    (success, error) => {
      if (success) {
        handleClaim();
      }
    }
  );

  const acceptTerms = () => {
    if (!acceptedTerms) {
      setTermsWarning(false);
    }
    setAcceptedTerms(!acceptedTerms);
  };

  const handleClaim = async () => {
    if (acceptedTerms) {
      getSkr(`${envConfigs.serverUrl}/user/mint-request/${getDID(address)}`);
    } else {
      setTermsWarning(true);
    }
  };

  const handlePOIContinue = async base64Selfie => {
    setShowTakeSelfieModal(false);
    resetErrors();
    await getVka(skr.SKR.url, { SKR: skr.SKR, selfie: base64Selfie });
  };

  return {
    address,
    acceptedTerms,
    termsWarning,
    acceptTerms,
    handleClaim,
    handlePOIContinue,
    handleAgreeConsent,
    mintingTransactionHash,
    mintError,
    isWaitingForWallet,
    isLoadingNftMinting,
    loadingSkr,
    loadingVka,
    showTakeSelfieModal,
    setShowTakeSelfieModal,
    showConsentModal,
    setShowConsentModal,
    consentView,
    loadingSignConsent,
    errorMessage,
    error,
    eligibleError,
    poiCheckFailedError,
    consentError,
    resetErrors
  };
};

export default useEligibility;
