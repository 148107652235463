import styled from 'styled-components';

export const TextGradient = styled.b`
  font-weight: 800;
  font-family: 'Plus Jakarta Sans';
  background-image: linear-gradient(93.17deg, #2da1f8 0%, #cd22c6 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
`;
