import React from 'react';
import { PagesBackground } from '../../common/layout/main_layout.styled';
import { RtfContentPage } from '../../common/components/rtf_content';
import useDocumentTitle from '../../common/hooks/use_document_title';
import { APP_NAME, PAGES } from '../../common/lib/constants';

function GovernanceAgreementPageContainer() {
  const { setDocumentTitle } = useDocumentTitle();

  React.useEffect(() => {
    setDocumentTitle(`${APP_NAME} - ${PAGES.GOVERNANCE_AGREEMENT}`);
  }, []);
  return (
    <PagesBackground>
      <RtfContentPage isGovAgreement={true} />
    </PagesBackground>
  );
}

export { GovernanceAgreementPageContainer };
