import { SkLink } from '../../../../common/components/sk_link.jsx';
import { SkBackButton } from '../sk_back_button.jsx';
import { SkButton } from '../../../../common/components/sk_button.jsx';
import { VotingSection } from './VotingSection.js';
import styled from 'styled-components';
import { SkCheckbox } from '../../../../common/components/sk_checkbox.jsx';
import { HStack, Text, useBreakpointValue, View, VStack } from 'native-base';
import React from 'react';

const BackArrowIcon = styled.img`
  position: relative;
  width: 28px;
  height: 16px;
`;
const Button = styled(SkBackButton)`
  //position: absolute;
  //top: 0px;
  //left: calc(50% - 452px);
  //border-radius: 40px;
  //background-color: rgba(0, 0, 0, 0.2);
  //width: 72px;
  //height: 48px;
  //display: flex;
  //flex-direction: row;
  //align-items: center;
  //justify-content: center;
  //padding: 20px 24px;
  //box-sizing: border-box;
`;
const Icon = styled.img`
  position: absolute;
  top: 0px;
  left: calc(50% - 296px);
  width: 592px;
  height: 436px;
`;
const VoteToUnlock = styled.b`
  position: relative;
  line-height: 36px;
`;
const B = styled.b`
  position: relative;
  line-height: 60px;
`;
const AwesomePeopleAlready = styled.div`
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-family: Inter;
`;
const Parent1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
`;
const SelfkeyIdHolders = styled.div`
  position: relative;
  line-height: 24px;
  display: inline-block;
  width: 430px;
`;
const MoreInfo1 = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  line-height: 24px;
`;
const ArrowSmallerIcon = styled.img`
  position: absolute;
  top: 7px;
  left: 80px;
  width: 15px;
  height: 12px;
`;
const MoreInfo = styled(SkLink)`
  position: relative;
  width: 95px;
  height: 24px;
  color: #fff;
`;
const SelfkeyIdHoldersMustVoteTParent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
`;
const ThanksForVoting = styled.div`
  position: absolute;
  top: 0%;
  left: 0%;
  line-height: 24px;
`;
const ThanksForVotingYouAreAwe = styled.section`
  position: relative;
  width: 280px;
  height: 24px;
  text-align: center;
  font-size: 16px;
  color: #48c281;
  font-family: Inter;
`;
const Button2 = styled.b`
  position: relative;
  line-height: 16px;
`;
const Button1 = styled(SkButton)`
  border-radius: 40px;
  background: linear-gradient(93.17deg, #2da1f8, #cd22c6);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px 40px;
`;
const Actionfeedback = styled(VotingSection)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  color: #fff;
`;
const FrameGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 40px;
  font-size: 16px;
  color: #ffedd2;
  font-family: Inter;
`;
const FrameParent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  text-align: center;
  font-size: 48px;
`;
const VoteToUnlockSelfMintingParent = styled.div`
  position: absolute;
  top: 40px;
  left: 81px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 32px;
`;
const Votecard = styled.div`
  position: relative;
  top: -55px;
  left: 312px;
  width: 592px;
  height: 436px;
`;
const VotecontainerRoot = styled.div`
  position: relative;
  //top: 139px;
  //left: 352px;
  width: 904px;
  //height: 446px;
  text-align: left;
  font-size: 28px;
  color: #fff;
  font-family: 'Plus Jakarta Sans';
`;

const VoteContainer = ({ votes, handleBack, handleVote, showButton, loadingVoting }) => {
  const agreeFontSize = useBreakpointValue({
    base: '10px',
    md: '12px'
  });
  const agreeLineHeight = useBreakpointValue({
    base: '14px',
    md: '16px'
  });
  const agreeTermsStyle = {
    fontWeight: 400,
    textAlign: 'left',
    fontSize: agreeFontSize,
    lineHeight: agreeLineHeight,
    paddingLeft: '2px',
    color: '#A9A9A9'
  };
  const [acceptedTerms, setAcceptedTerms] = React.useState(false);
  const [termsWarning, setTermsWarning] = React.useState(false);
  const acceptTerms = () => {
    if (!acceptedTerms) {
      setTermsWarning(false);
    }
    setAcceptedTerms(!acceptedTerms);
  };
  const _handleVote = () => {
    if (acceptedTerms) {
      handleVote();
    } else {
      setTermsWarning(true);
    }
  };
  return (
    <VStack w={'100%'}>
      <VotecontainerRoot>
        <Button id="BackButton" onPress={handleBack}>
          <BackArrowIcon alt="" src="/back-arrow.svg" />
        </Button>
        <Votecard>
          <Icon alt="" src="/1.svg" />
          <VoteToUnlockSelfMintingParent>
            <VoteToUnlock>Vote to Unlock SELF Minting</VoteToUnlock>
            <FrameParent>
              <Parent1>
                <B>{votes}</B>
                <AwesomePeopleAlready>Awesome people already voted</AwesomePeopleAlready>
              </Parent1>
              <FrameGroup>
                <SelfkeyIdHoldersMustVoteTParent>
                  <SelfkeyIdHolders>SelfKey iD Holders must vote to Unlock the MINTING function of the SELF token contract</SelfkeyIdHolders>
                </SelfkeyIdHoldersMustVoteTParent>
                <Actionfeedback id="VoteAndFeedbackSection" handleVote={_handleVote} showButton={showButton} loadingVoting={loadingVoting}>
                  <ThanksForVotingYouAreAwe id="SuccessMessage">
                    <ThanksForVoting>Thanks for Voting, you are awesome!</ThanksForVoting>
                  </ThanksForVotingYouAreAwe>
                  <Button1 id="VoteToUnlock" component="button" variant="primary" fixedSize={false} onPress={_handleVote}>
                    <Button2>Vote to Unlock</Button2>
                  </Button1>
                </Actionfeedback>
              </FrameGroup>
            </FrameParent>
          </VoteToUnlockSelfMintingParent>
        </Votecard>
      </VotecontainerRoot>
      {showButton && (
        <View mt={'9px'} mb={'64px'} alignItems={'center'}>
          <SkCheckbox acceptedTerms={acceptedTerms} acceptTerms={acceptTerms} alignItems={'flex-start'}>
            <HStack w={'775px'}>
              <Text {...agreeTermsStyle}>
                I have read and understand the
                <a href={'/selfkey-dao-governance-agreement'} target="_blank" rel="noopener noreferrer" style={{ textDecorationColor: '#FFFFFF' }}>
                  <Text
                    color={'#FFFFFF'}
                    fontWeight={agreeTermsStyle.fontWeight}
                    fontSize={agreeTermsStyle.fontSize}
                    lineHeight={agreeTermsStyle.lineHeight}
                    paddingLeft={agreeTermsStyle.paddingLeft}
                  >
                    SelfKey DAO Participation Agreement
                  </Text>
                </a>
                , including its attachments: the SelfKey DAO Constitution, the SelfKey DAO Rules and Procedures, and the SelfKey DAO Privacy Policy, as well as
                the SelfKey DAO Volunteer Agreement. I hereby agree to its provisions.
              </Text>
            </HStack>
          </SkCheckbox>
          {termsWarning && (
            <View w={'795px'} mt={'24px'} alignSelf={'left'}>
              <Text fontSize={'16px'} color={'#FF7575'} textAlign={'left'}>
                You have to accept core SelfKey DAO agreement.
              </Text>
            </View>
          )}
        </View>
      )}
    </VStack>
  );
};

export default VoteContainer;
