import React, { useContext, useEffect } from 'react';
import { HeroMvp } from './components/hero_mvp';
import { LandingBackground } from './layout/main_layout.styled';
import { NftContext, RoutingContext, Web3WagmiContext } from '../../common/context/app_context';
import { useHistory } from 'react-router-dom';
import { Box, Center, Text, VStack } from 'native-base';
import ProgressBar from './components/progress_bar';
import { FeaturesSection } from '../../common/components/features_section';
import { ModelLoader } from '../../common/components/model_loader';
import useDocumentTitle from '../../common/hooks/use_document_title';
import { APP_NAME, PAGES } from '../../common/lib/constants';

function LandingPageComponentMvp() {
  const history = useHistory();
  const { isLoadingUserStatus: isLoadingAuthorization } = useContext(NftContext);
  const { activeAddress: address, isConnecting, isLoadingSwitchNetwork } = useContext(Web3WagmiContext);
  const { userRoute, isRouting } = useContext(RoutingContext);
  const { setDocumentTitle } = useDocumentTitle();

  useEffect(() => {
    if (address) {
      if (userRoute && userRoute !== '/') {
        history.push(userRoute);
      }
    } else {
      setDocumentTitle(`${APP_NAME} - ${PAGES.LANDING}`);
    }
  }, [address, userRoute]);

  return (
    <LandingBackground>
      {isConnecting || isLoadingSwitchNetwork || isRouting || isLoadingAuthorization || (address && !userRoute) ? (
        <Center w="100%" alignItems={'center'} alignSelf={'flex-start'} mt={'80px'}>
          <Box w="90%" maxW="400">
            <Box h={{ base: '200px', md: '250px' }} alignSelf={'center'}>
              <ModelLoader model={'/3d/step-1/loading_cube.glb'} scale={0.3} verticalInclination={Math.PI / 3}></ModelLoader>
            </Box>
            <Text fontSize={{ base: '16px', lg: '22px' }} fontWeight={700} textAlign={'center'} mb={'24px'} mt={'40px'}>
              Loading...
            </Text>
            <ProgressBar />
          </Box>
        </Center>
      ) : (
        <VStack alignItems={'center'} space={{ base: '64px', md: '155px' }}>
          <HeroMvp />
          <FeaturesSection />
        </VStack>
      )}
    </LandingBackground>
  );
}

export { LandingPageComponentMvp };
