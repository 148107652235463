import { Heading, View, VStack } from 'native-base';
import React from 'react';
import { PagesBackground } from '../../../common/layout/main_layout.styled';
import { MainLayout } from '../layout/main_layout';
import { LoadingContainer } from './LoadingContainer';

export function SkWaitingTransaction({
  isWaitingForTransaction = true,
  skStyle = true,
  textWaiting = 'Waiting for allowance confirmation.',
  showNetworkSpeedMessage = true,
  fullLayout = false
}) {
  const Wrapper = fullLayout ? MainLayout : View;
  return (
    <Wrapper skStyle={skStyle}>
      <PagesBackground skStyle={skStyle}>
        <LoadingContainer
          content={
            <VStack mb={'24px'} mt={'40px'}>
              <Heading fontSize={{ base: '16px', md: '18px' }} lineHeight={'24px'} fontWeight={700} textAlign={'center'} fontFamily={'Plus Jakarta Sans'}>
                {isWaitingForTransaction ? 'Waiting for transaction.' : textWaiting}
              </Heading>
              {showNetworkSpeedMessage && (
                <Heading fontSize={{ base: '16px', md: '18px' }} lineHeight={'24px'} fontWeight={700} textAlign={'center'} fontFamily={'Plus Jakarta Sans'}>
                  This could take a few minutes depending on network speed.
                </Heading>
              )}
            </VStack>
          }
        />
      </PagesBackground>
    </Wrapper>
  );
}
