import React, { useEffect, useRef, useState } from 'react';
import { Flex, useBreakpointValue, View } from 'native-base';
import { PagesBackgroundLD } from '../layout/main_layout.styled';

const VideoBackground = React.memo(({ videoSrcs, loopLast = true, width, height, maxHeight, minWidth, objectFit, showOutlineGradient = true }) => {
  const videoRef = useRef(null);
  const [currentVideo, setCurrentVideo] = useState(0);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    let promises = [];
    videoSrcs.forEach(url => {
      const promise = new Promise((resolve, reject) => {
        const video = document.createElement('video');
        video.src = url;
        video.addEventListener('canplaythrough', resolve);
        video.addEventListener('error', reject);
        video.load();
      });
      promises.push(promise);
    });
    console.log('[DEBUG] VideoBackground preloading videos...');
    Promise.all(promises).then(() => {
      setLoaded(true);
      console.log('[DEBUG] VideoBackground preloading videos completed!');
    });
  }, []);

  const videoStyleGlobal = {
    width: width || '100%',
    height: height || '100%',
    maxHeight: maxHeight || '940px',
    objectFit: objectFit || 'cover'
  };
  const videoStyleResponsive = useBreakpointValue({
    base: {
      ...videoStyleGlobal,
      maxHeight: '548px'
    },
    md: {
      ...videoStyleGlobal,
      minWidth: minWidth
    }
  });

  const handleVideoEnded = () => {
    const videoIndex = currentVideo < videoSrcs.length - 1 ? currentVideo + 1 : currentVideo;
    if (videoRef.current && currentVideo !== videoIndex) {
      videoRef.current.src = videoSrcs[videoIndex];
      videoRef.current.play();
      setCurrentVideo(videoIndex);
    }
  };

  if (!loaded) {
    return <PagesBackgroundLD></PagesBackgroundLD>;
  }

  return (
    <Flex
      direction={'column'}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: -1,
        backgroundColor: '#000000'
      }}
    >
      <video
        ref={videoRef}
        autoPlay
        muted
        loop={loopLast && videoSrcs.length - 1 === currentVideo}
        style={videoStyleResponsive}
        onEnded={handleVideoEnded}
        preload={'auto'}
        playsInline={true}
      >
        <source src={videoSrcs[currentVideo]} type="video/mp4" />
      </video>
      {showOutlineGradient && (
        <View
          w={'100%'}
          h={'320px'}
          style={{
            background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%)',
            top: '-320px'
          }}
        />
      )}
    </Flex>
  );
});

export default VideoBackground;
