import { useState, useEffect, useCallback, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  AirdropContext,
  NftContext,
  RegistryContext,
  RewardsContext,
  RoutingContext,
  SelfTokenContext,
  StakingContext,
  VoteContext,
  Web3WagmiContext
} from '../../common/context/app_context';
import { useSignTypedData } from 'wagmi';
import usePost from '../../common/hooks/use_post';
import { ALPHAKEK_AI_URL, APP_NAME, PAGES } from '../../common/lib/constants';
import { handleError } from '../../common/lib/error_handler';
import { CachePolicies, useFetch } from 'use-http';
import { current as envConfigs } from 'env-configs/selfkey-org';
import useDocumentTitle from './use_document_title';
import { ConsentAgreement } from '../components/consent_agreement';

const useWelcomePage = () => {
  const { setDocumentTitle } = useDocumentTitle();
  const history = useHistory();
  const { pathname } = useLocation();

  const { hasNft, isLoadingNft } = useContext(NftContext);
  const { userRoute, isRouting } = useContext(RoutingContext);
  const { loadingStakingTotal } = useContext(StakingContext);
  const { consentPayload, routeMember } = useContext(RegistryContext);
  const { activeAddress: address } = useContext(Web3WagmiContext);
  const { lockingActive } = useContext(RewardsContext);
  const { activeProposal } = useContext(VoteContext);
  const { canReceiveAirdrop } = useContext(AirdropContext);
  const { loadingGetSelfMintingUnlocked, isSelfMintingUnblocked } = useContext(SelfTokenContext);

  const [showEligibleWarning, setShowEligibleWarning] = useState(false);
  const [consentView, setConsentView] = useState(null);
  const [showConsentModal, setShowConsentModal] = useState(false);
  const [loadingSignRequest, setLoadingSigningRequest] = useState(false);
  const [consentApproved, setConsentApproved] = useState(false);
  const [errorPoiStatus, setErrorPoiStatus] = useState(false);

  const { signTypedDataAsync, error: errorSigningConsent, reset: resetErrorSigningRequest } = useSignTypedData({});
  const [sendConsentRequest, { data: dataConsentResponse, loading: isLoadingConsentRequest, error: errorConsentRequest }] = usePost();
  const { get: getPoiCredentialStatus } = useFetch(`${envConfigs.serverUrl}/credential/poi`, { cachePolicy: CachePolicies.NETWORK_ONLY });

  const handleAgreeConsent = async () => {
    setLoadingSigningRequest(true);
    setShowConsentModal(false);
    try {
      const signature = await signTypedDataAsync({
        domain: {
          name: 'KYCS.Ai Consent',
          version: '1'
        },
        primaryType: 'KycsaiConsent',
        types: {
          KycsaiConsent: [{ name: 'content', type: 'string' }]
        },
        message: {
          content: consentPayload.consent
        }
      });
      await sendConsentRequest(consentPayload.consent_url, { signature });
      setConsentApproved(true);
      await routeMember();
    } catch (e) {
      handleError('[ERROR] Error signing consent', e);
    } finally {
      setLoadingSigningRequest(false);
    }
  };

  const lockHandleClick = useCallback(async () => {
    const statusResponse = await getPoiCredentialStatus(address);
    if (statusResponse.error === 'No POI credential' || statusResponse.error === 'Credential invalid or expired') {
      history.push('/member/poi-credential-start');
    } else if (statusResponse.status === 'Credential valid') {
      history.push('/member/lock');
    } else {
      setErrorPoiStatus('Something went wrong, please try again.');
    }
  }, [address, getPoiCredentialStatus, history]);

  const handleAlphaKekAIClick = () => {
    window.open(ALPHAKEK_AI_URL);
  };

  const inviteHandleClick = useCallback(() => {
    history.push('/member/invite-friends');
  }, [history]);

  const detailsHandleClick = useCallback(() => {
    history.push('/member/my-selfkey-id');
  }, [history]);

  const voteHandleClick = useCallback(() => {
    history.push('/member/vote');
  }, [history]);

  const airdropHandleClick = useCallback(() => {
    history.push('/member/airdrop');
  }, [history]);

  const handleDisabledClick = useCallback(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    setShowEligibleWarning(true);
  }, []);

  const isAuthorized = () => {
    return userRoute === '/member' && hasNft;
  };

  const isNotEligible = () => {
    return userRoute === '/not-eligible';
  };

  const isPublic = () => {
    return pathname === '/public';
  };

  const handleGetVerified = () => {
    history.push('/');
  };

  const handleGetSelfkeyId = () => {
    history.push('/');
  };

  const [pageTitle, setPageTitle] = useState('');
  useEffect(() => {
    let title = '';
    if (isPublic()) {
      title = `${APP_NAME} - ${PAGES.WELCOME_PUBLIC}`;
    } else if (isNotEligible()) {
      title = `${APP_NAME} - ${PAGES.WELCOME_NOT_ELIGIBLE}`;
    } else if (isAuthorized()) {
      title = `${APP_NAME} - ${PAGES.WELCOME_MEMBER}`;
    }
    setPageTitle(title);
    setDocumentTitle(title);
  }, [pathname, userRoute, hasNft]);

  useEffect(() => {
    setDocumentTitle(pageTitle);
  }, [pageTitle, setDocumentTitle]);

  useEffect(() => {
    if (userRoute && isNotEligible() && !isRouting && consentPayload && !consentApproved && !isLoadingConsentRequest) {
      setConsentView(<ConsentAgreement data={consentPayload} />);
      setShowConsentModal(true);
    } else {
      setShowConsentModal(false);
    }
  }, [userRoute, isRouting, consentPayload, consentApproved, isLoadingConsentRequest]);

  return {
    showEligibleWarning,
    consentView,
    showConsentModal,
    loadingSignRequest,
    errorPoiStatus,
    pageTitle,
    setShowEligibleWarning,
    setShowConsentModal,
    handleAgreeConsent,
    lockHandleClick,
    handleAlphaKekAIClick,
    inviteHandleClick,
    detailsHandleClick,
    voteHandleClick,
    airdropHandleClick,
    handleDisabledClick,
    setErrorPoiStatus,
    isAuthorized,
    handleGetVerified,
    canReceiveAirdrop,
    lockingActive,
    activeProposal,
    loadingStakingTotal,
    loadingGetSelfMintingUnlocked,
    isSelfMintingUnblocked,
    isNotEligible,
    handleGetSelfkeyId
  };
};

export default useWelcomePage;
