import React from 'react';
import { Box, Heading, HStack, Image, Spinner, Stack, Text, useBreakpointValue, View, VStack } from 'native-base';
import { PagesBackground } from '../../common/layout/main_layout.styled';
import { ModelLoader } from '../../common/components/model_loader';
import { JoinDiscordCard } from '../../common/components/join_discord_card';
import SkButton from './components/sk_button';
import { useHistory } from 'react-router-dom';
import { SkNumericInput } from './components/sk_input';
import { TransactionFailed } from './components/transaction_failed';
import { TransactionCompleted } from './components/staking_completed';
import ConfirmationModal from '../../common/components/confirmation_modal';
import TermsAndConditionsCheckbox from './components/terms_checkbox';
import SkFrame from './components/sk_frame';
import { SkBackButton } from './components/sk_back_button';
import { SkWaitingTransaction } from './components/sk_waiting_transaction';
import keyToken from '../../common/assets/icons/key-token.svg';
import selfToken from '../../common/assets/icons/self-token.svg';
import WarningModal from '../../common/components/warning_modal';
import LdButton from './components/sk_button';
import { RejectedPage } from './rejected_page';
import { POICheckFailed } from '../../common/components/poi_check_failed';
import { SelfieModal } from '../../common/components/selfie_modal';
import useWithdrawPage from '../../common/hooks/use_withdraw_page';
import { PAGES } from '../../common/lib/constants';
import { SigningConsentPage } from '../../common/components/signing-content-page';
import { HoverTooltip } from '../../common/components/hover-tooltip';

function TextLine({ children }) {
  return (
    <Text
      fontSize={{ base: '14px', md: '16px' }}
      lineHeight={{ base: '20px', md: '24px' }}
      fontWeight={400}
      color={'#FFEDD2'}
      textAlign={'center'}
      display={'flex'}
      alignItems={'center'}
    >
      {children}
    </Text>
  );
}

function WithdrawPageContainer() {
  const {
    isLoadingStaking,
    isWaitingWithdrawApproval,
    stakingTotal,
    loadingStakingTotal,
    hasError,
    withdrawTransaction,
    acceptedTerms,
    displayAcceptTermsWarning,
    keyToWithdraw,
    maxAmountWarning,
    minAmountWarning,
    showConfirmationModal,
    showTakeSelfieModal,
    showConsentModal,
    consentView,
    loadingSignConsent,
    handleAgreeConsent,
    errorMessage,
    error,
    eligibleError,
    poiCheckFailedError,
    formattedStakingTotal,
    handleWithdrawStake,
    handleWithdrawStakeConfirmation,
    handleTransactionCompleted,
    handleWithdrawCompleteContinue,
    handleNotEligibleOk,
    handlePOIContinue,
    checkValueAndSet,
    setMaxAmount,
    minStakeAmount,
    setShowConfirmationModal,
    setShowTakeSelfieModal,
    setShowConsentModal,
    loadingVka,
    loadingSkr,
    acceptTerms
  } = useWithdrawPage();
  const history = useHistory();

  const dialogContent = useBreakpointValue({
    base: (
      <VStack alignItems={'center'}>
        <TextLine>
          Your KEY <Image alt="key token" source={{ uri: keyToken }} w={'16px'} h={'16px'} /> tokens will be removed from the
        </TextLine>
        <TextLine>
          POI Contract and SELF <Image alt="self token" source={{ uri: selfToken }} w={'16px'} h={'16px'} /> token
        </TextLine>
        <TextLine>generation will stop.</TextLine>
      </VStack>
    ),
    md: (
      <VStack alignItems={'center'}>
        <TextLine>
          Your KEY <Image alt="key token" source={{ uri: keyToken }} w={'16px'} h={'16px'} /> tokens will be removed from the POI Contract and
        </TextLine>
        <TextLine>
          SELF <Image alt="self token" source={{ uri: selfToken }} w={'16px'} h={'16px'} /> token generation will stop.
        </TextLine>
      </VStack>
    )
  });

  if (eligibleError) {
    return (
      <RejectedPage
        title={'Oh, no!'}
        content={'You are not eligible for this action.'}
        actionLabel={'OK'}
        handleAction={handleNotEligibleOk}
        skStyle={false}
        eventPage={`Withdraw - Not Eligible`}
      />
    );
  }

  if (error) {
    return (
      <RejectedPage
        includeLayout={false}
        title={'Oh, no!'}
        content={'Something went wrong, please try again'}
        actionLabel={'Retry'}
        handleAction={handleWithdrawCompleteContinue}
        eventPage={`Withdraw - ${errorMessage}`}
      />
    );
  }

  if (isWaitingWithdrawApproval) {
    return <SkWaitingTransaction skStyle={false} />;
  } else if (hasError) {
    return <TransactionFailed title={'Withdraw failed'} onRetry={handleTransactionCompleted} skStyle={false} />;
  } else if (withdrawTransaction) {
    return <TransactionCompleted tx={withdrawTransaction} handleAction={handleTransactionCompleted} skStyle={false} />;
  }

  if (poiCheckFailedError) {
    return <POICheckFailed handleAction={handleWithdrawCompleteContinue} page={'Withdraw'} />;
  }

  if (loadingSignConsent) {
    return <SigningConsentPage />;
  }

  return (
    <PagesBackground>
      <Box flexDirection="column" alignItems="center" maxWidth={'1216px'} width={'100%'}>
        <SkBackButton eventName={PAGES.WITHDRAW} onPress={() => history.push('/member/lock')} />
        <VStack space={{ base: '40px', md: '64px' }}>
          <Stack direction={{ base: 'column', md: 'row' }} space={'32px'}>
            <Stack alignItems="center" textAlign="center" alignSelf={'center'} space="4" display={{ base: 'block', lg: 'none' }}>
              <View w={'310px'} h={'310px'}>
                <ModelLoader model={'/3d/step-1/self.glb'}></ModelLoader>
              </View>
            </Stack>

            <SkFrame h={{ base: '348px', md: '388px' }}>
              <VStack alignItems={'center'} justifyContent={'center'} h={'100%'} space={{ base: '32px', md: '40px' }}>
                <VStack alignItems={'center'} space={{ base: '12px', md: '8px' }}>
                  <Text fontWeight={400} fontSize={{ base: '16px', md: '20px' }} lineHeight={{ base: '24px', md: '32px' }} color={'#FFEDD2'}>
                    KEY locking your POI Credential:
                  </Text>
                  <HStack alignItems={'center'}>
                    {loadingStakingTotal ? (
                      <Spinner color={'indigo.500'} />
                    ) : (
                      <Heading
                        fontFamily={'Plus Jakarta Sans'}
                        fontWeight={700}
                        fontSize={{ base: '18px', md: '32px' }}
                        lineHeight={{ base: '22px', md: '40px' }}
                      >
                        <HoverTooltip content={`${stakingTotal} $KEY`} disabled={!formattedStakingTotal.wasRounded}>
                          <Text>
                            {formattedStakingTotal.formattedValue}
                            {formattedStakingTotal.wasRounded ? <Text fontSize={'12px'}>...</Text> : ''} $KEY
                          </Text>
                        </HoverTooltip>
                      </Heading>
                    )}
                    <Image alt="key token" source={{ uri: keyToken }} w={'33px'} h={'32px'} ml={'10px'} />
                  </HStack>
                  <VStack alignItems={'center'} space={{ base: '12px', md: '16px' }} mt={{ base: '20px', md: '16px' }}>
                    <SkNumericInput
                      onChange={value => {
                        checkValueAndSet(value);
                      }}
                      value={keyToWithdraw}
                      rightElement={
                        <SkButton
                          eventName={`${PAGES.WITHDRAW} - Max`}
                          w={{ base: '70px', md: '100px' }}
                          h={{ base: '40px', md: '48px' }}
                          fixedSize={false}
                          pr={'12px'}
                          isLoading={isLoadingStaking}
                          onPress={() => setMaxAmount()}
                        >
                          Max
                        </SkButton>
                      }
                    />
                    {maxAmountWarning && (
                      <Text fontWeight={400} fontSize={{ base: '10px', md: '12px' }} lineHeight={{ base: '14px', md: '16px' }} color={'warning.500'}>
                        The amount you input is more than you can withdraw.
                      </Text>
                    )}
                    {minAmountWarning && (
                      <Text
                        fontWeight={400}
                        fontSize={{ base: '10px', md: '12px' }}
                        lineHeight={{ base: '14px', md: '16px' }}
                        color={'warning.500'}
                        textAlign={'center'}
                      >
                        Minimum Lock amount is {minStakeAmount} KEY.
                      </Text>
                    )}
                  </VStack>
                </VStack>
                <VStack space={{ base: '12px', md: '16px' }} alignItems={'center'}>
                  <SkButton
                    eventName={'Unlock POI'}
                    w={{ base: '174px', md: '280px' }}
                    fixedSize={false}
                    onPress={() => handleWithdrawStake()}
                    isLoading={isWaitingWithdrawApproval || isLoadingStaking || loadingVka || loadingSkr || loadingSignConsent}
                    isDisabled={parseFloat(stakingTotal) === 0}
                  >
                    Unlock
                  </SkButton>
                </VStack>
                <VStack alignItems={'center'} space={{ base: '4px', md: '8px' }}>
                  <TermsAndConditionsCheckbox isChecked={acceptedTerms} onChange={e => acceptTerms(e)} />
                  {displayAcceptTermsWarning && (
                    <Heading
                      fontWeight={400}
                      fontSize={{ base: '10px', md: '12px' }}
                      lineHeight={{ base: '14px', md: '16px' }}
                      color={'#FF7575'}
                      textAlign={{ base: 'center', md: 'left' }}
                    >
                      Please agree to the Terms And Conditions before continuing.
                    </Heading>
                  )}
                </VStack>
              </VStack>
            </SkFrame>

            <Stack alignItems="center" textAlign="center" space="4" display={{ base: 'none', lg: 'block' }} minW={'440px'} minH={'440px'}>
              <ModelLoader model={'/3d/step-1/self.glb'}></ModelLoader>
            </Stack>
          </Stack>
        </VStack>
        <SkBackButton eventName={PAGES.WITHDRAW} onPress={() => history.push('/member/lock')} isMobile={true} my={'40px'} />
        <JoinDiscordCard my={{ base: '60px', md: '160px' }} skStyle={false} />
      </Box>
      <ConfirmationModal
        showModal={showConfirmationModal}
        title={
          <VStack>
            <Heading fontSize={{ base: '20px', md: '28px' }} lineHeight={{ base: '24px', md: '36px' }} fontWeight={700} textAlign={'center'}>
              Are you sure you want
            </Heading>
            <Heading
              fontSize={{ base: '20px', md: '28px' }}
              lineHeight={{ base: '24px', md: '36px' }}
              fontWeight={700}
              textAlign={'center'}
              display={'flex'}
              alignItems={'center'}
            >
              to unlock your KEY <Image alt="key token" source={{ uri: keyToken }} w={{ base: '18px', md: '32px' }} h={{ base: '18px', md: '32px' }} /> ?
            </Heading>
          </VStack>
        }
        content={dialogContent}
        actionLabel={'Continue'}
        handleAction={() => handleWithdrawStakeConfirmation()}
        handleClose={() => setShowConfirmationModal(false)}
      />
      <WarningModal
        showModal={showConsentModal}
        title={consentView}
        leftButton={
          <LdButton variant={'secondary'} fixedSize={false} w={'134px'} onPress={() => setShowConsentModal(false)}>
            Cancel
          </LdButton>
        }
        rightButton={
          <LdButton fixedSize={false} w={'134px'} onPress={() => handleAgreeConsent()}>
            Agree
          </LdButton>
        }
        handleClose={() => setShowConsentModal(false)}
      />
      <SelfieModal isOpen={showTakeSelfieModal} handleClose={() => setShowTakeSelfieModal(false)} handlePOIContinue={handlePOIContinue} />
    </PagesBackground>
  );
}

export { WithdrawPageContainer };
