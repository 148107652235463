import React from 'react';
import { Heading, Image, useBreakpointValue, View, VStack } from 'native-base';
import { PagesBackground } from '../../common/layout/main_layout.styled';
import SkButton from './components/sk_button';
import { APP_NAME, PAGES } from '../../common/lib/constants';
import poiStartImg from '../assets/imgs/poi-start.svg';
import { useHistory } from 'react-router-dom';
import ScrollableContent from './components/scrollable_content';
import { SkBackButton } from './components/sk_back_button';
import useDocumentTitle from '../../common/hooks/use_document_title';

function POICredentialStart() {
  const history = useHistory();
  const { setDocumentTitle } = useDocumentTitle();

  React.useEffect(() => {
    setDocumentTitle(`${APP_NAME} - ${PAGES.POI_CREDENTIAL_START}`);
  }, []);

  const handleStart = () => {
    history.push('/member/poi-credential-take-selfie');
  };
  const maxW = useBreakpointValue({ base: '100vw', xl: '1240px' });
  return (
    <PagesBackground paddingTop={'80px'}>
      <VStack alignItems={'center'} space={'40px'} mt={'24px'}>
        <View position={'absolute'} left={0} top={'40px'}>
          <SkBackButton eventName={PAGES.POI_CREDENTIAL_START + ' - Back Button'} onPress={() => history.push('/member')} />
        </View>
        <Heading
          fontFamily={'Mulish'}
          fontSize={{ base: '28px', md: '40px' }}
          fontWeight={{ base: 700, md: 800 }}
          lineHeight={{ base: '36px', md: '50.2px' }}
          textAlign={'center'}
          maxW={{ base: '320px', md: '801px' }}
        >
          You must perform a Selfie Check in order to obtain a POI Credential
        </Heading>
        <View maxW={maxW} h={'272px'} overflowX={'auto'} mb={'10px'}>
          <ScrollableContent>
            <Image alt="POI Credential Flow" source={{ uri: poiStartImg }} w={'1215px'} h={'232px'} maxW={'none'} />
          </ScrollableContent>
        </View>

        <SkButton eventName={PAGES.POI_CREDENTIAL_START + ' - Start'} onPress={handleStart} fixedSize={false} w={'180px'}>
          Start
        </SkButton>
        <SkBackButton eventName={PAGES.POI_CREDENTIAL_START + ' - Back Button'} onPress={() => history.push('/member')} isMobile={true} mb={'80px'} />
      </VStack>
    </PagesBackground>
  );
}

export { POICredentialStart };
