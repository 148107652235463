import { SkLink } from '../../../../common/components/sk_link.jsx';
import { SkBackButton } from '../sk_back_button.jsx';
import { VotingSection } from './VotingSection.js';
import styled from 'styled-components';
import VoteButton from './VoteButton.js';
import { HStack, Text, useBreakpointValue, View } from 'native-base';
import { SkCheckbox } from '../../../../common/components/sk_checkbox.jsx';
import React from 'react';

const TransparentBgIcon = styled.img`
  align-self: stretch;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  z-index: 0;
`;
const VoteToUnlock = styled.b`
  position: relative;
  line-height: 24px;
  text-align: left;
`;
const Div = styled.div`
  position: relative;
  line-height: 40px;
  font-weight: 800;
`;
const AwesomePeopleAlready = styled.div`
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
`;
const Parent1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  font-size: 36px;
`;
const SelfkeyIdHolders = styled.div`
  position: relative;
  line-height: 20px;
  display: inline-block;
  width: 251px;
`;
const MoreInfo1 = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  line-height: 20px;
`;
const ArrowSmallerIcon = styled.img`
  position: absolute;
  top: 5px;
  left: 67px;
  width: 15px;
  height: 12px;
`;
const MoreInfo = styled(SkLink)`
  position: relative;
  width: 82px;
  height: 20px;
  color: #fff;
`;
const SelfkeyIdHoldersMustVoteTParent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
`;
const Voteandfeedbacksection = styled(VotingSection)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  font-size: 16px;
  color: #fff;
`;
const FrameParent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  font-size: 14px;
  color: #ffedd2;
  font-family: Inter;
`;
const VoteToUnlockSelfMintingParent = styled.div`
  align-self: stretch;
  flex: 1;
  margin: 0 !important;
  position: absolute;
  height: 81.63%;
  top: 9.18%;
  bottom: 9.18%;
  left: calc(50% - 136px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  z-index: 1;
`;
const Votesectionmobile = styled.div`
  position: relative;
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  font-size: 20px;
  font-family: 'Plus Jakarta Sans';
`;
const BackIcon = styled.img`
  position: relative;
  width: 16px;
  height: 16px;
`;
const Button1 = styled.b`
  position: relative;
  line-height: 16px;
`;
const Button = styled.div`
  position: relative;
  //top: 456px;
  //left: calc(50% - 61px);
  //border-radius: 40px;
  //background-color: rgba(0, 0, 0, 0.2);
  //display: flex;
  //flex-direction: row;
  //align-items: center;
  //justify-content: center;
  //padding: 12px 32px;
  //gap: 8px;
  //text-align: left;
`;
const VotecontainermobileRoot = styled.div`
  position: relative;
  //top: 81px;
  //left: 20px;
  width: 320px;
  //height: 496px;
  text-align: center;
  font-size: 14px;
  color: #fff;
  font-family: Inter;
`;

const VoteContainerMobile = ({ votes, handleBack, handleVote, showButton, loadingVoting }) => {
  const agreeFontSize = useBreakpointValue({
    base: '10px',
    md: '12px'
  });
  const agreeLineHeight = useBreakpointValue({
    base: '14px',
    md: '16px'
  });
  const agreeTermsStyle = {
    fontWeight: 400,
    textAlign: 'left',
    fontSize: agreeFontSize,
    lineHeight: agreeLineHeight,
    paddingLeft: '2px',
    color: '#A9A9A9'
  };
  const [acceptedTerms, setAcceptedTerms] = React.useState(false);
  const [termsWarning, setTermsWarning] = React.useState(false);
  const acceptTerms = () => {
    if (!acceptedTerms) {
      setTermsWarning(false);
    }
    setAcceptedTerms(!acceptedTerms);
  };
  const _handleVote = () => {
    if (acceptedTerms) {
      handleVote();
    } else {
      setTermsWarning(true);
    }
  };
  return (
    <VotecontainermobileRoot>
      <Votesectionmobile>
        <TransparentBgIcon alt="" src="/transparent-bg1.svg" />
        <VoteToUnlockSelfMintingParent>
          <VoteToUnlock>Vote to Unlock SELF Minting</VoteToUnlock>
          <Parent1>
            <Div>{votes}</Div>
            <AwesomePeopleAlready>Awesome people already voted</AwesomePeopleAlready>
          </Parent1>
          <FrameParent>
            <SelfkeyIdHoldersMustVoteTParent>
              <SelfkeyIdHolders>SelfKey iD Holders must vote to Unlock the MINTING function of the SELF token contract</SelfkeyIdHolders>
            </SelfkeyIdHoldersMustVoteTParent>
            <Voteandfeedbacksection id="VoteAndFeedbackSection" handleVote={_handleVote} showButton={showButton} loadingVoting={loadingVoting}>
              <VoteButton />
            </Voteandfeedbacksection>
          </FrameParent>
        </VoteToUnlockSelfMintingParent>
      </Votesectionmobile>
      {showButton && (
        <View mt={'32px'}>
          <SkCheckbox acceptedTerms={acceptedTerms} acceptTerms={acceptTerms} alignItems={'flex-start'}>
            <HStack maxW={{ base: '298px', lg: '796px' }}>
              <Text {...agreeTermsStyle}>
                I have read and understand the
                <a href={'/selfkey-dao-governance-agreement'} target="_blank" rel="noopener noreferrer" style={{ textDecorationColor: '#FFFFFF' }}>
                  <Text
                    color={'#FFFFFF'}
                    fontWeight={agreeTermsStyle.fontWeight}
                    fontSize={agreeTermsStyle.fontSize}
                    lineHeight={agreeTermsStyle.lineHeight}
                    paddingLeft={agreeTermsStyle.paddingLeft}
                  >
                    SelfKey DAO Participation Agreement
                  </Text>
                </a>
                , including its attachments: the SelfKey DAO Constitution, the SelfKey DAO Rules and Procedures, and the SelfKey DAO Privacy Policy, as well as
                the SelfKey DAO Volunteer Agreement. I hereby agree to its provisions.
              </Text>
            </HStack>
          </SkCheckbox>
          {termsWarning && (
            <Text fontSize={'14px'} color={'#FF7575'} textAlign={'left'} mt={'24px'}>
              You have to accept core SelfKey DAO agreement.
            </Text>
          )}
        </View>
      )}
      <HStack justifyContent={'center'} mb={'40px'} mt={'64px'}>
        <SkBackButton onPress={handleBack} isMobile={true} w={'122px'} />
      </HStack>
    </VotecontainermobileRoot>
  );
};

export default VoteContainerMobile;
