import React, { Suspense, useEffect, useMemo, useRef, useState } from 'react';
import { animated, useSpring } from '@react-spring/three';
import { Canvas, extend, useThree } from '@react-three/fiber';
import { Environment, Html, OrbitControls, PerspectiveCamera, Stage, useAnimations, useGLTF } from '@react-three/drei';
import * as THREE from 'three';
import { presetsObj } from '@react-three/drei/helpers/environment-assets';
import { ErrorBoundary } from '@sentry/react';
import { Text } from 'native-base';

extend({ OrbitControls, PerspectiveCamera, Canvas });

function restoreContext(renderer) {
  const canvas = renderer.domElement;
  canvas.addEventListener(
    'webglcontextlost',
    function (event) {
      event.preventDefault();
      setTimeout(function () {
        renderer.forceContextRestore();
        renderer.resetState();
      }, 1);
    },
    false
  );
}

export const GLTFModel = React.memo(({ model, scale, glowingElements, glowColor, glowOpacity }) => {
  const { scene, animations } = useGLTF(model);

  glowingElements.forEach(elementName => {
    const element = scene.getObjectByName(elementName);
    element.traverse(node => {
      if (node.isMesh) {
        node.material = new THREE.MeshBasicMaterial({
          color: glowColor,
          transparent: true,
          blending: THREE.AdditiveBlending,
          opacity: glowOpacity
        });
      }
    });
  });
  const ref = useRef();
  const group = useRef();
  const { actions } = useAnimations(animations, group);

  const { gl } = useThree();

  useEffect(() => {
    Object.keys(actions).forEach(animation => {
      actions[animation].play();
    });
  }, []);

  useEffect(() => {
    return () => {
      restoreContext(gl);
    };
  }, []);

  const [isDraggingModel, setIsDraggingModel] = useState(false);
  document.onmousemove = function (evt) {
    if (isDraggingModel) {
      evt.preventDefault();
    }
  };
  document.onmouseup = function () {
    if (isDraggingModel) {
      setIsDraggingModel(false);
    }
  };

  const [springs, api] = useSpring(
    () => ({
      scale: scale ? scale : 1,
      onStart: () => {
        handleScale();
      },
      config: key => {
        switch (key) {
          case 'scale':
            return {
              mass: 4,
              friction: 10
            };
          case 'position':
            return { mass: 4, friction: 220 };
          case 'rotation':
            return { mass: 4, friction: 220 };
          default:
            return {};
        }
      }
    }),
    []
  );

  const handleScale = () => {
    api.start({
      scale: scale // * 1.1
    });
  };

  return (
    <animated.mesh
      scale={springs.scale}
      onPointerDown={e => {
        e.stopPropagation();
        setIsDraggingModel(true);
      }}
    >
      <primitive object={scene} ref={group} />
    </animated.mesh>
  );
});

export const ModelLoader = React.memo(
  ({
    model,
    distance = 50,
    autoRotate = true,
    rotateVertically = false,
    verticalInclination = 1.57079,
    enableZoom = false,
    enablePan = false,
    enableStage = true,
    stageEnv = 'city',
    stageIntensity = 1.5,
    scale = 1,
    shadows = false,
    glowingElements = [],
    glowColor = '#00FF00',
    glowOpacity = 1,
    loadLocalEnv = true
  }) => {
    const memo = useMemo(() => {
      return (
        <GLTFModel
          model={model}
          scale={scale}
          distance={distance}
          glowingElements={glowingElements}
          glowColor={glowColor}
          glowOpacity={glowOpacity}
        ></GLTFModel>
      );
    }, []);
    const ref = useRef();
    const extraProps = {
      enableZoom,
      enablePan
    };
    if (autoRotate) {
      extraProps['autoRotate'] = true;
    }
    if (!rotateVertically) {
      extraProps['maxPolarAngle'] = verticalInclination;
      extraProps['minPolarAngle'] = verticalInclination;
    }
    const stageProps = {
      shadows
    };
    const envProps = {};
    if (enableStage) {
      if (stageEnv && presetsObj[stageEnv]) {
        if (loadLocalEnv) {
          envProps.files = `${window.location.origin}/img/${presetsObj[stageEnv]}`;
          stageProps.environment = null;
        } else {
          stageProps.environment = stageEnv;
        }
      }
      if (stageIntensity) {
        stageProps.intensity = stageIntensity;
      }
    }
    return (
      <ErrorBoundary fallback={<Html> </Html>}>
        <Canvas>
          <Suspense fallback={<Html> </Html>}>
            <Stage {...stageProps} adjustCamera={false}>
              {loadLocalEnv && <Environment {...envProps} />}
              <OrbitControls {...extraProps} autoRotateSpeed={2} />
              <PerspectiveCamera makeDefault position={[0, 0, distance]} ref={ref} fov={25} />
              {memo}
            </Stage>
          </Suspense>
        </Canvas>
      </ErrorBoundary>
    );
  }
);
