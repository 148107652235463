import {SkBackButton} from "../sk_back_button";
import {SkButton} from "../../../../common/components/sk_button";
import {ModelLoader} from "../../../../common/components/model_loader";
import React, {FunctionComponent} from "react";
import styled from "styled-components";
import {AirdropClaimComponentType} from './AirdropClaimComponent'
import {Heading, Image} from 'native-base'
import selfToken from '../../../../common/assets/icons/self-token.svg'

const BackArrowIcon = styled.img`
  position: relative;
  width: 28px;
  height: 16px;
`;
const Button1 = styled(SkBackButton)`
  position: absolute;
  top: 0px;
  left: calc(50% - 36px);
  border-radius: 40px;
  background-color: rgba(0, 0, 0, 0.2);
  width: 72px;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px 24px;
  box-sizing: border-box;
`;
const Button = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 72px;
  height: 48px;
`;
const Self3Icon = styled.img`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 300px;
  height: 364px;
  object-fit: cover;
`;
const Dmodelframe = styled(ModelLoader)`
  position: relative;
  width: 300px;
  height: 364px;
`;
const DmodelframeParent = styled.div`
  display: flex;
  height: 364px; // manually set
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 40px;
`;
const Button4 = styled.b`
  position: relative;
  line-height: 16px;
`;
const Button3 = styled(SkButton)`
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 40px;
  background: linear-gradient(93.17deg, #2da1f8, #cd22c6);
  width: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px 40px;
  box-sizing: border-box;
`;
const Button2 = styled.div`
  position: relative;
  width: 200px;
  height: 48px;
  font-size: 16px;
  font-family: Inter;
`;
const FrameParent = styled.div`
  position: absolute;
  top: 0px;
  left: calc(50% - 216.25px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 64px;
`;
const ButtonParentRoot = styled.div`
  //position: absolute; // manually set
  position: relative; // manually set
  //top: 144px; // manually set
  //left: 352px; // manually set
  width: 955.5px;
  height: 576px;
  text-align: left;
  font-size: 48px;
  color: #fff;
  font-family: "Plus Jakarta Sans";
`;

const AirdropClaimContainer: FunctionComponent<AirdropClaimComponentType> = ({selfAmount, handleBack, handleClaim, loadingClaim}) => {
    return (
        <ButtonParentRoot>
            <Button>
                <Button1 id="BackButton" onPress={handleBack}>
                    <BackArrowIcon alt="" src="/back-arrow.svg"/>
                </Button1>
            </Button>
            <FrameParent>
                <DmodelframeParent>
                    <Dmodelframe id="SelfTokenModel" model="/3d/step-1/self.glb">
                        <Self3Icon alt="" src="/self-3@2x.png"/>
                    </Dmodelframe>
                    <Heading
                      fontFamily={'Plus Jakarta Sans'}
                      fontWeight={700}
                      fontSize={'48px'}
                      lineHeight={'60px'}
                      display={'inline-flex'}
                      alignItems={'center'}
                      justifyContent={'center'}
                      flexDirection={'row'}
                      flexWrap={'wrap'}
                    >
                      Claim {selfAmount} SELF{' '}
                      <Image alt="self token" source={{ uri: selfToken }} w={{ base: '18px', md: '32px' }} h={{ base: '18px', md: '32px' }} />
                      {' '}Tokens!
                    </Heading>
                </DmodelframeParent>
                <Button2>
                    <Button3 id="ClaimButton" fixedSize={false} onPress={handleClaim} isLoading={loadingClaim}
                             eventName={'Claim Airdrop'}>
                        <Button4>Claim</Button4>
                    </Button3>
                </Button2>
            </FrameParent>
        </ButtonParentRoot>
    );
};

export default AirdropClaimContainer;
