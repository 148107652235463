import React from 'react';
import { useBreakpointValue } from 'native-base';
import VoteContainer from './VoteContainer.js';
import VoteContainerMobile from './VoteContainerMobile.js';

function VoteComponent({ votes, showButton, handleBack, handleVote, handleMoreInfo, loadingVoting }) {
  const Vote = useBreakpointValue({ base: VoteContainerMobile, lg: VoteContainer });
  return (
    <Vote votes={votes} handleBack={handleBack} handleVote={handleVote} showButton={showButton} handleMoreInfo={handleMoreInfo} loadingVoting={loadingVoting} />
  );
}

export { VoteComponent };
