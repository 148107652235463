import React from 'react';
import 'react-app-protect/dist/index.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Protect from 'react-app-protect';
import { current as envConfigs } from 'env-configs/selfkey-org';
import { appEnv } from 'env-configs';
// import { MarketplaceCategoriesPage, MarketplaceCategoryItem } from './web-mktp';
import { createConfig, http, WagmiProvider, fallback } from 'wagmi';
import * as chains from 'wagmi/chains';
import { metaMask, walletConnect } from 'wagmi/connectors';
import { Web3WagmiProvider } from './common/context/web3_wagmi_provider';
// import { RestrictedCountriesProvider } from './common/context/restricted_countries_provider';
import { RewardsProvider } from './common/context/rewards_provider';
import NftProvider from './common/context/nft_provider';
import { RegistryProvider } from './common/context/registry_provider';
import { RoutingProvider } from './common/context/routing_provider';
import { LoadingProvider } from './common/context/loading_provider';
import { NotFoundPage } from './common/components/not-found-page';
import { MainLayout as MainLayoutLD, MainLayout } from './lockdao/pages/layout/main_layout';
import RouteWithLayout from './common/layout/route_with_layout';
import { SelfieCheckPage } from './selfkey-id/pages/selfie_check_page';
import { LandingPageContainer } from './selfkey-id/pages/landing_page_container';
import { PaymentPageContainer } from './selfkey-id/pages/payment_page_container';
import { VerifyPageContainer } from './selfkey-id/pages/verify_page_container';
import { NftPageContainer } from './selfkey-id/pages/nft_page_container';
import { DownloadsPageContainer } from './selfkey-id/pages/download_page_container';
import { EligibilityPage } from './selfkey-id/pages/eligibility_page_container';
import { EligibilityStartPageContainer } from './selfkey-id/pages/eligibility_start_page_container';
import { EligibilityCountryPageContainer } from './selfkey-id/pages/eligibility_country_page_container';
import { CountryCheckFailed } from './selfkey-id/pages/country_check_failed';
import { VerificationPageContainer } from './selfkey-id/pages/verification_page_container';
import { PrivacyPageContainer } from './lockdao/pages/privacy_page_container';
import { TermsPageContainer } from './lockdao/pages/terms_page_container';
import { SelfKeyIDPageContainer } from './lockdao/pages/selfkeyid_page_container';
import { WelcomePageContainer } from './lockdao/pages/welcome_page_container';
import { StakingPageContainer } from './lockdao/pages/staking_page_container';
import { WithdrawPageContainer } from './lockdao/pages/withdraw_page_container';
import { MintSelfPageContainer } from './lockdao/pages/mint_lock_page_container';
import { InvitationPageContainer } from './lockdao/pages/invitation_page_container';

import { RejectedPageContainer } from './lockdao/pages/rejected_page_container';
import { PublicKeyTermsPageContainer } from './lockdao/pages/public_key_terms_page_container';
import { Web3ProviderPoc } from './lockdao/pages/components/web3_wagmi_poc';
import { StakingProvider } from './lockdao/context/staking_provider';
// import { MintLockProvider } from './lockdao/context/mint_lock_provider';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';
import { MaintenancePage } from './common/components/maintenance-page';
import { TwitterPostPage } from './poc/TwitterPostPage';

import * as Sentry from '@sentry/react';
import { POICredentialStart } from './lockdao/pages/poi_credential_start';
import { POICredentialTakeSelfieContainer } from './lockdao/pages/poi_credential_take_selfie';
import { VotePageContainer } from './lockdao/pages/vote_page_container';
import { VoteProvider } from './common/context/vote_provider';
import { GovernanceAgreementPageContainer } from './lockdao/pages/governance_agreement';
import { AirdropClaimPageContainer } from './lockdao/pages/airdrop_claim_page_container';
import { AirdropProvider } from './common/context/airdrop_provider';
import { SelfTokenProvider } from './common/context/self_token_provider';
import { BridgePageContainer } from './lockdao/pages/bridge_page_container';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createWeb3Modal } from '@web3modal/wagmi/react';

const instance = createInstance({
  urlBase: 'https://analytics.selfkey.org',
  siteId: envConfigs.matomoSiteId,
  trackerUrl: 'https://analytics.selfkey.org/matomo.php', // optional, default value: `${urlBase}matomo.php`
  srcUrl: 'https://analytics.selfkey.org/matomo.js', // optional, default value: `${urlBase}matomo.js`
  disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
  heartBeat: {
    // optional, enabled by default
    active: true, // optional, default value: true
    seconds: 10 // optional, default value: `15
  },
  linkTracking: false, // optional, default value: true
  configurations: {
    // optional, default value: {}
    // any valid matomo configuration, all below are optional
    disableCookies: true,
    setSecureCookie: appEnv !== 'development',
    setRequestMethod: 'POST'
  }
});

const infuraUrls = {
  [chains.mainnet.id]: `https://mainnet.infura.io/v3/${envConfigs.infuraKey}`,
  [chains.polygon.id]: `https://polygon-mainnet.infura.io/v3/${envConfigs.infuraKey}`,
  [chains.polygonAmoy.id]: `https://polygon-amoy.infura.io/v3/${envConfigs.infuraKey}`,
  [chains.goerli.id]: `https://goerli.infura.io/v3/${envConfigs.infuraKey}`
};

const alchemyUrls = {
  [chains.mainnet.id]: `https://eth-mainnet.g.alchemy.com/v2/${envConfigs.alchemyKey}`,
  [chains.polygon.id]: `https://polygon-mainnet.g.alchemy.com/v2/${envConfigs.alchemyKey}`,
  [chains.polygonAmoy.id]: `https://polygon-amoy.g.alchemy.com/v2/${envConfigs.alchemyKey}`,
  [chains.goerli.id]: `https://eth-goerli.g.alchemy.com/v2/${envConfigs.alchemyKey}`
};

const rpcWithFallback = chainId =>
  fallback([
    http(infuraUrls[chainId], {
      retryCount: 2,
      retryDelay: 1000
    }),
    http(alchemyUrls[chainId], {
      retryCount: 2,
      retryDelay: 1000
    })
  ]);

const allChains = [chains.polygon, chains.polygonAmoy, chains.mainnet, chains.goerli];
// Set up client
const client = createConfig({
  chains: allChains,
  connectors: [
    new metaMask({
      extensionOnly: true
    }),
    new walletConnect({
      projectId: envConfigs.walletConnectProjectId,
      options: {
        projectId: envConfigs.walletConnectProjectId,
        showQrModal: true,
        infuraAPIKey: envConfigs.infuraKey
      }
    })
  ],
  transports: {
    [chains.polygon.id]: rpcWithFallback(chains.polygon.id),
    [chains.polygonAmoy.id]: rpcWithFallback(chains.polygonAmoy.id),
    [chains.mainnet.id]: rpcWithFallback(chains.mainnet.id),
    [chains.goerli.id]: rpcWithFallback(chains.goerli.id)
  }
  // publicClient,
  // webSocketPublicClient
});

const queryClient = new QueryClient();

createWeb3Modal({
  wagmiConfig: client,
  projectId: envConfigs.walletConnectProjectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
  enableOnramp: true // Optional - false as default
});

function AppRoot() {
  return (
    <MatomoProvider value={instance}>
      <LoadingProvider>
        <RoutingProvider>
          <WagmiProvider config={client}>
            <QueryClientProvider client={queryClient}>
              <Web3WagmiProvider allChains={allChains}>
                <VoteProvider>
                  <AirdropProvider>
                    <SelfTokenProvider>
                      <NftProvider>
                        <RewardsProvider>
                          <RegistryProvider>
                            <StakingProvider>
                              <Router>
                                <Switch>
                                  <Route path={'/twitter-posts'} exact>
                                    <TwitterPostPage />
                                  </Route>
                                  {/*{appEnv !== 'production' && (*/}
                                  {/*  <Route path="/marketplace" exact>*/}
                                  {/*    <MarketplaceCategoriesPage />*/}
                                  {/*  </Route>*/}
                                  {/*)}*/}
                                  {/*{appEnv !== 'production' && (*/}
                                  {/*  <Route path="/marketplace/:name/:sku?" exact>*/}
                                  {/*    <MarketplaceCategoryItem />*/}
                                  {/*  </Route>*/}
                                  {/*)}*/}
                                  <Switch>
                                    {/*MVP ROUTES*/}
                                    {/*Landing Page*/}
                                    <RouteWithLayout path={'/'} layout={MainLayout} component={LandingPageContainer} skStyle={true} exact />

                                    {/*Payment Page*/}
                                    <RouteWithLayout path={'/pay'} layout={MainLayout} component={PaymentPageContainer} skStyle={true} isSecure={true} />
                                    {/*Initiate Verification Page*/}
                                    <RouteWithLayout
                                      path={'/initiate-verification'}
                                      layout={MainLayout}
                                      component={VerifyPageContainer}
                                      skStyle={true}
                                      isSecure={true}
                                    />
                                    {/*Verification in Progress Page*/}
                                    <RouteWithLayout
                                      path={'/verifying'}
                                      layout={MainLayout}
                                      component={VerificationPageContainer}
                                      isSecure={true}
                                      skStyle={true}
                                      exact
                                    />
                                    {/*Eligible Page*/}
                                    <RouteWithLayout path={'/mint-sbt'} layout={MainLayout} component={EligibilityPage} isSecure={true} skStyle={true} exact />
                                    {/*Privacy Policy Page*/}
                                    <RouteWithLayout
                                      path={'/privacy-policy'}
                                      layout={MainLayout}
                                      component={PrivacyPageContainer}
                                      mvp={true}
                                      skStyle={true}
                                      exact
                                    />
                                    {/*Terms of Service Page*/}
                                    <RouteWithLayout
                                      path={'/terms-of-service'}
                                      layout={MainLayout}
                                      component={TermsPageContainer}
                                      mvp={true}
                                      skStyle={true}
                                      exact
                                    />
                                    {/*SelfKey DAO Governance Agreement*/}
                                    <RouteWithLayout
                                      path={'/selfkey-dao-governance-agreement'}
                                      layout={MainLayout}
                                      component={GovernanceAgreementPageContainer}
                                      mvp={true}
                                      skStyle={true}
                                      exact
                                    />
                                    {/*Welcome Page - public*/}
                                    <RouteWithLayout path={'/public'} layout={MainLayoutLD} component={WelcomePageContainer} mvp={true} exact />
                                    {/*Welcome Page - not eligible*/}
                                    <RouteWithLayout
                                      path={'/not-eligible'}
                                      layout={MainLayoutLD}
                                      component={WelcomePageContainer}
                                      mvp={true}
                                      isSecure={true}
                                      exact
                                    />
                                    {/*Welcome Page - member*/}
                                    <RouteWithLayout path={'/member'} layout={MainLayoutLD} component={WelcomePageContainer} mvp={true} isSecure={true} exact />
                                    {/*Invitation Page*/}
                                    <RouteWithLayout
                                      path={'/member/invite-friends'}
                                      layout={MainLayoutLD}
                                      component={InvitationPageContainer}
                                      mvp={true}
                                      isSecure={true}
                                      exact
                                    />
                                    {/*NFT Details Page*/}
                                    <RouteWithLayout path={'/member/my-selfkey-id'} layout={MainLayoutLD} component={NftPageContainer} isSecure={true} exact />
                                    {/*User Rejected Page*/}
                                    <RouteWithLayout
                                      path={'/rejected'}
                                      layout={MainLayoutLD}
                                      component={RejectedPageContainer}
                                      mvp={true}
                                      isSecure={true}
                                      exact
                                    />
                                    {/* Maintenance page */}
                                    <RouteWithLayout path={'/maintenance'} layout={MainLayoutLD} component={MaintenancePage} mvp={true} />
                                    {/* POI Credential Start page */}
                                    <RouteWithLayout
                                      path={'/member/poi-credential-start'}
                                      layout={MainLayoutLD}
                                      component={POICredentialStart}
                                      isSecure={true}
                                      mvp={true}
                                    />
                                    {/* POI Credential Take Selfie page */}
                                    <RouteWithLayout
                                      path={'/member/poi-credential-take-selfie'}
                                      layout={MainLayoutLD}
                                      isSecure={true}
                                      component={POICredentialTakeSelfieContainer}
                                      mvp={true}
                                    />
                                    {/*Staking Page*/}
                                    <RouteWithLayout
                                      path={'/member/lock'}
                                      layout={MainLayoutLD}
                                      component={StakingPageContainer}
                                      mvp={true}
                                      isSecure={true}
                                      exact
                                    />
                                    {/*Withdraw Page*/}
                                    <RouteWithLayout
                                      path={'/member/withdraw'}
                                      layout={MainLayoutLD}
                                      component={WithdrawPageContainer}
                                      mvp={true}
                                      isSecure={true}
                                      exact
                                    />
                                    {/*Vote Page*/}
                                    <RouteWithLayout
                                      path={'/member/vote'}
                                      layout={MainLayoutLD}
                                      component={VotePageContainer}
                                      mvp={true}
                                      isSecure={true}
                                      exact
                                    />
                                    {/*Airdrop Claim Page*/}
                                    <RouteWithLayout
                                      path={'/member/airdrop'}
                                      layout={MainLayoutLD}
                                      component={AirdropClaimPageContainer}
                                      mvp={true}
                                      isSecure={true}
                                      exact
                                    />
                                    {/*Mint Lock Page*/}
                                    <RouteWithLayout
                                      path={'/member/token-minting'}
                                      layout={MainLayoutLD}
                                      component={MintSelfPageContainer}
                                      mvp={true}
                                      isSecure={true}
                                      exact
                                    />
                                    {/*Bridge Self Page*/}
                                    <RouteWithLayout
                                      path={'/member/bridge-self'}
                                      layout={MainLayoutLD}
                                      component={BridgePageContainer}
                                      mvp={true}
                                      isSecure={true}
                                      exact
                                    />

                                    {/*NON MVP ROUTES*/}
                                    {/*SelfKey iD Needed*/}
                                    <RouteWithLayout
                                      path={'/selfkey.id'}
                                      layout={MainLayoutLD}
                                      component={SelfKeyIDPageContainer}
                                      mvp={true}
                                      isSecure={true}
                                      prodReady={false}
                                      exact
                                    />
                                    {/*Download Verifiable Credential Page*/}
                                    <RouteWithLayout
                                      path={'/downloads'}
                                      layout={MainLayout}
                                      component={DownloadsPageContainer}
                                      isSecure={true}
                                      skStyle={true}
                                      prodReady={false}
                                      exact
                                    />
                                    {/*Check Eligibility Start Page*/}
                                    <RouteWithLayout
                                      path={'/eligibility-start'}
                                      layout={MainLayout}
                                      component={EligibilityStartPageContainer}
                                      skStyle={true}
                                      prodReady={false}
                                      exact
                                    />
                                    {/*Check Eligibility Country Page*/}
                                    <RouteWithLayout
                                      path={'/eligibility-country/:country'}
                                      layout={MainLayout}
                                      component={EligibilityCountryPageContainer}
                                      skStyle={true}
                                      prodReady={false}
                                      exact
                                    />
                                    {/*Check Eligibility Country Failed Page*/}
                                    <RouteWithLayout
                                      path={'/country-check-failed'}
                                      layout={MainLayout}
                                      component={CountryCheckFailed}
                                      skStyle={true}
                                      prodReady={false}
                                      exact
                                    />
                                    {/*Selfie Check*/}
                                    <RouteWithLayout
                                      path={'/selfie-check'}
                                      layout={MainLayout}
                                      component={SelfieCheckPage}
                                      skStyle={true}
                                      prodReady={false}
                                      exact
                                    />
                                    {/*Public Key Terms Page*/}
                                    <RouteWithLayout
                                      path={'/public-key-terms'}
                                      layout={MainLayoutLD}
                                      component={PublicKeyTermsPageContainer}
                                      mvp={true}
                                      prodReady={false}
                                      exact
                                    />
                                    {/* Test or temporary routes */}
                                    <RouteWithLayout path={'/web3'} layout={MainLayoutLD} component={Web3ProviderPoc} mvp={true} prodReady={false} exact />

                                    {/* Not found - 404 page */}
                                    <RouteWithLayout layout={MainLayoutLD} component={NotFoundPage} mvp={true} />
                                  </Switch>
                                </Switch>
                              </Router>
                            </StakingProvider>
                          </RegistryProvider>
                        </RewardsProvider>
                      </NftProvider>
                    </SelfTokenProvider>
                  </AirdropProvider>
                </VoteProvider>
              </Web3WagmiProvider>
            </QueryClientProvider>
          </WagmiProvider>
        </RoutingProvider>
      </LoadingProvider>
    </MatomoProvider>
  );
}

function App() {
  if (envConfigs.passwordProtected) {
    return (
      <Protect
        boxTitle="Password protected"
        sha512="3d7fbb740d59a944157073f9e8e3f5fd3871412f0c59f62e2ab42d6b5ebcb42e0515d372a6451189f335d47d40152944c42648b29a88c6ed5fc99f493ae3fab1"
      >
        <AppRoot />
      </Protect>
    );
  } else {
    return <AppRoot />;
  }
}

export default Sentry.withProfiler(App);
