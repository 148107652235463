import styled from 'styled-components';
import Spinner from '../../assets/imgs/spinner.svg';
import SpinnerIcon from '../../assets/imgs/spinner-icon.svg';
import React from 'react';

const SpinningImage = styled.img`
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export const LoadingSpinner = ({ variant = 'small', ...props }) => {
  return <SpinningImage src={variant === 'large' ? Spinner : SpinnerIcon} {...props} />;
};
