export const JOIN_DISCORD_URL = 'https://discord.com/invite/Ft2U7DKg4X';

export const FETCH_NFT_BALANCE_INTERVAL = 5000;
export const FETCH_USER_STATUS_ON_VERIFY_INTERVAL = 5000;
export const FETCH_REWARD_USED_INTERVAL = 5000;
export const FETCH_REWARD_USED_ATTEMPTS = 4;

export const NFT_METADATA_ATTRIBUTES = {
  COUNTRY_OF_RESIDENCE: 'http://platform.selfkey.org/schema/attribute/country-of-residency.json',
  DATE_OF_BIRTH: 'http://platform.selfkey.org/schema/attribute/date-of-birth.json',
  EMAIL: 'http://platform.selfkey.org/schema/attribute/email.json',
  FIRST_NAME: 'http://platform.selfkey.org/schema/attribute/first-name.json',
  LAST_NAME: 'http://platform.selfkey.org/schema/attribute/last-name.json',
  NATIONALITY: 'http://platform.selfkey.org/schema/attribute/nationality.json'
};

export const MOCKED_NFT_METADATA = [
  {
    content:
      // '0x7b2276657273696f6e223a227832353531392d7873616c736132302d706f6c7931333035222c226e6f6e6365223a224d5046564e6650387273597165574a4d2f6b4943772b68466131666f67725835222c22657068656d5075626c69634b6579223a22593578734c6259387837334952757377427538756270396959684a385878314c684867746f5478333079633d222c2263697068657274657874223a22615036493646424c544935494a6e6d31774d5353312f615a495630463551314b34626a394c304b796339593d227d',
      '0x7b2276657273696f6e223a227832353531392d7873616c736132302d706f6c7931333035222c226e6f6e6365223a2230764b6d69745854355378434c34757a764f662b793735764a785a304c4e4b43222c22657068656d5075626c69634b6579223a2272585742686c4b43366962696b42434430364e44446c3935654e7059553476505a614b7630336254746a343d222c2263697068657274657874223a22737754676c703165537935576e6d59794c56304d564a4b36227d',
    schemaId: 'http://platform.selfkey.org/schema/attribute/country-of-residency.json'
  },
  {
    content:
      '0x7b2276657273696f6e223a227832353531392d7873616c736132302d706f6c7931333035222c226e6f6e6365223a22396d4b333433725959344a496f354c647143386652776d7a50467841434f6948222c22657068656d5075626c69634b6579223a2263797559535a4e436330592b4f6164736441486955376b416f30694a652f49644d644245535045446f6a6f3d222c2263697068657274657874223a224436426a41756e57662b656f4b333934626d50383061534258395a505a535848354f383d227d',
    schemaId: 'http://platform.selfkey.org/schema/attribute/date-of-birth.json'
  },
  {
    content:
      '0x7b2276657273696f6e223a227832353531392d7873616c736132302d706f6c7931333035222c226e6f6e6365223a22384136566f7443685145362b5841583554414b3452457370706f59697a367241222c22657068656d5075626c69634b6579223a226d30672f362b6c706d2b424c5062426332574c3334446f4f2b534b5154624d4a52454f644d4e6d332f6d673d222c2263697068657274657874223a222b326675596e62446f43382b5272506b7738357768447139493053766e4c7272617a4d63564e4e457278353135344f6d746a7975526268776667466f46472f3854577053227d',
    schemaId: 'http://platform.selfkey.org/schema/attribute/email.json'
  },
  {
    content:
      '0x7b2276657273696f6e223a227832353531392d7873616c736132302d706f6c7931333035222c226e6f6e6365223a225875596a62736763534743793334585078542f4b70596c7765314c5a4e767264222c22657068656d5075626c69634b6579223a22664c324a7353774d4175324f3137442f6d3330646f426f36547963545a336f684b647776616f57624132413d222c2263697068657274657874223a2231785a5136755a46382f306f443541615057494f617a6a333278413d227d',
    schemaId: 'http://platform.selfkey.org/schema/attribute/first-name.json'
  },
  {
    content:
      '0x7b2276657273696f6e223a227832353531392d7873616c736132302d706f6c7931333035222c226e6f6e6365223a22574b6b42577370314e654334796641507076674f6d74762f75444f7244346e66222c22657068656d5075626c69634b6579223a22724242377a6f414473313137466d30703746614f766b7452446f55426e562f646461587a79484a494d68453d222c2263697068657274657874223a2239564639674b75585a35546c4179544a672f434f6777456b6b52553d227d',
    schemaId: 'http://platform.selfkey.org/schema/attribute/last-name.json'
  },
  {
    content:
      '0x7b2276657273696f6e223a227832353531392d7873616c736132302d706f6c7931333035222c226e6f6e6365223a22696473552b6d4279786f7257453135642b77346d5a427252346c757249577566222c22657068656d5075626c69634b6579223a224452725a45334b3270612b7955696d4d2b344c37746665654d6272686f6b534970704d6b64306c616a52733d222c2263697068657274657874223a226e51556866513150683372694162516c6152714d5a38775a434f42376b51684a33543332386563593276634a7954786b466b466d4861416e4b796f4f6433633d227d',
    schemaId: 'http://platform.selfkey.org/schema/attribute/nationality.json'
  }
];
