import React from 'react';

export const JOIN_DISCORD_URL = 'https://discord.com/invite/Ft2U7DKg4X';
export const ALPHAKEK_AI_URL = 'https://www.alphakek.ai/';

export const APP_NAME = 'SelfKey DAO';
export const PAGES = {
  LANDING: 'Landing Page',
  PAYMENT: 'Payment',
  VERIFY: 'Initiate Verification',
  VERIFYING: 'Verification In Progress',
  WELCOME_PUBLIC: 'Home - Visitor',
  WELCOME_NOT_ELIGIBLE: 'Home - Not Eligible',
  WELCOME_MEMBER: 'Home',
  VOTE: 'Vote',
  LOCK_POI: 'Lock POI',
  WITHDRAW: 'Withdraw',
  TOKEN_MINTING: 'Token Minting',
  INVITE_FRIENDS: 'Invite Friends',
  MY_SELFKEY_ID: 'My SelfKey iD',
  ELIGIBLE_MINT_SK: 'SelfKey iD Minting',
  PRIVACY_POLICY: 'Privacy Policy',
  TERMS_OF_SERVICE: 'Terms of Service',
  REJECTED: 'Rejected',
  MAINTENANCE: 'Maintenance',
  POI_CREDENTIAL_START: 'POI Credential - Start',
  POI_CREDENTIAL_TAKE_SELFIE: 'POI Credential - Take Selfie',
  GOVERNANCE_AGREEMENT: 'Governance Agreement',
  AIRDROP: 'Airdrop',
  BRIDGE_SELF: 'Bridge SELF'
};

export const RP_CONFIG = {
  SELFIE: 'http://platform.selfkey.org/schema/misc/selfie.json'
};
