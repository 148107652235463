import React, { useContext, useEffect, useState } from 'react';
import { Box, Center, Flex, Heading, HStack, Image, Input, Spinner, Stack, Text, VStack } from 'native-base';
import { PagesBackground } from '../../common/layout/main_layout.styled';
import { RewardsContext, Web3WagmiContext } from '../../common/context/app_context';
import { TextGradient } from './components/text_gradient';
import SkButton from './components/sk_button';
import { ModelLoader } from '../../common/components/model_loader';
import { SkBackButton } from './components/sk_back_button';
import { useHistory } from 'react-router-dom';
import SkSpinner from './components/sk_spinner';
import { SkInputCopy } from './components/sk_input_copy';
import { getDID } from '../../common/lib/helpers';
import selfToken from '../../common/assets/icons/self-token.svg';
import { CachePolicies, useFetch } from 'use-http';
import { current as envConfigs } from 'env-configs/selfkey-org';
import WarningModal from '../../common/components/warning_modal';
import LdButton from './components/sk_button';
import useDocumentTitle from '../../common/hooks/use_document_title';
import { APP_NAME, PAGES } from '../../common/lib/constants';
import { POICheckFailed } from '../../common/components/poi_check_failed';
import { SelfieModal } from '../../common/components/selfie_modal';
import { formatEther, hexToBigInt } from 'viem';
import { SkWaitingTransaction } from './components/sk_waiting_transaction';
import { TransactionFailed } from './components/transaction_failed';
import { TransactionCompleted } from './components/staking_completed';
import useSkrProcess from '../../common/hooks/use_skr_flow';
import { SigningConsentPage } from '../../common/components/signing-content-page';

function InvitationPageContainer() {
  const {
    getRewards,
    callInviteCodeUsed,
    isLoadingInviteCodeUsed,
    waitingInviteCodeUsedTransaction,
    inviteCodeUsedTransaction,
    hasRedeemError,
    resetInviteCodeUsed,
    callClaim,
    isLoadingClaimTransaction,
    waitingClaimTransaction,
    claimTransaction,
    hasClaimError,
    resetClaim
  } = useContext(RewardsContext);
  const { setDocumentTitle } = useDocumentTitle();

  useEffect(() => {
    setDocumentTitle(`${APP_NAME} - ${PAGES.INVITE_FRIENDS}`);
  }, []);

  const history = useHistory();
  const { activeAddress: address } = useContext(Web3WagmiContext);
  const [rewardCode, setRewardCode] = useState();
  const [inputCode, setInputCode] = useState();
  const [isCodeVerified, setIsCodeVerified] = useState(undefined);
  const [isCodeVerifiedOnLoad, setIsCodeVerifiedOnLoad] = useState(undefined);

  const { get: getInviteCode } = useFetch(`${envConfigs.serverUrl}/user/invite-code`, { cachePolicy: CachePolicies.NETWORK_ONLY });
  const { get: isInviteCodeUsed } = useFetch(`${envConfigs.serverUrl}/rewards/invite-reward`, { cachePolicy: CachePolicies.NETWORK_ONLY });
  const { get: getRewardsAmount } = useFetch(`${envConfigs.serverUrl}/governance/reward/invite`, { cachePolicy: CachePolicies.NETWORK_ONLY });

  const [isLoadingInviteRewards, setIsLoadingInviteRewards] = useState(false);
  const [redeemMessage, setRedeemMessage] = useState();

  const { get: getUnclaimedRewards } = useFetch(`${envConfigs.serverUrl}/rewards/balance-of-unclaimed`, { cachePolicy: CachePolicies.NETWORK_ONLY });
  const [unclaimedRewards, setUnclaimedRewards] = useState(0);

  const [claimMessage, setClaimMessage] = useState();
  const [isClaimed, setIsClaimed] = useState(false);
  const [isLoadingClaim, setIsLoadingClaim] = useState(false);

  const [rewardsAmount, setRewardsAmount] = useState();

  const {
    getSkr,
    skr,
    getVka,
    showTakeSelfieModal,
    setShowTakeSelfieModal,
    showConsentModal,
    setShowConsentModal,
    consentView,
    loadingSignConsent,
    handleAgreeConsent,
    poiCheckFailedError,
    resetErrors
  } = useSkrProcess(
    address,
    data => {
      if (isLoadingInviteRewards) {
        callInviteCodeUsed(data).then(response => {
          console.log('[DEBUG] callInviteCodeUsed', response);
          if (response) {
            getUnclaimedRewardsBalance();
            getRewards();
            setIsLoadingInviteRewards(false);
            setIsCodeVerified(true);
            setRedeemMessage('SUCCESS');
          }
        });
      } else if (isLoadingClaim) {
        callClaim(data).then(response => {
          console.log('[DEBUG] callClaim', response);
          if (response) {
            setClaimMessage('Claim Successful!');
            setIsClaimed(true);
            getUnclaimedRewardsBalance();
            getRewards();
          }
        });
      }
    },
    (success, error) => {
      if (success) {
        if (isLoadingInviteRewards) {
          handleRedeem();
        } else if (isLoadingClaim) {
          handleClaimRewards();
        }
      } else {
        setIsLoadingClaim(false);
        setIsLoadingInviteRewards(false);
      }
    }
  );

  const setInputReward = code => {
    if (code !== inputCode) {
      setInputCode(code);
    }
  };

  const handleRedeem = async () => {
    getSkr(`${envConfigs.serverUrl}/rewards/invite-reward-request/${getDID(address)}`, { code: inputCode });
    setIsLoadingInviteRewards(true);
    setRedeemMessage(undefined);
  };

  const handleClaimRewards = async () => {
    getSkr(`${envConfigs.serverUrl}/rewards/claim-reward-request/${getDID(address)}`);
    setIsLoadingClaim(true);
    setClaimMessage(undefined);
  };

  async function getUnclaimedRewardsBalance() {
    const unclaimedRewards = await getUnclaimedRewards(getDID(address));
    if (unclaimedRewards?.total) {
      const unclaimed = formatEther(unclaimedRewards.total);
      console.log('[REWARDS] balance of rewards', unclaimed);
      setUnclaimedRewards(unclaimed);
    }
  }

  const handlePOIContinue = async base64Selfie => {
    setShowTakeSelfieModal(false);
    if (isLoadingInviteRewards) {
      await getVka(skr.SKR.url, { SKR: skr.SKR, selfie: base64Selfie });
    } else if (isLoadingClaim) {
      await getVka(skr.SKR.url, { SKR: skr.SKR, selfie: base64Selfie });
    }
  };

  useEffect(() => {
    getUnclaimedRewardsBalance();
  }, [address]);

  useEffect(() => {
    async function asyncEffect() {
      const amountResponse = await getRewardsAmount();
      if (amountResponse?.value) {
        setRewardsAmount(amountResponse.value);
      }
      const response = await getInviteCode(getDID(address));
      if (response?.code) {
        setRewardCode(response.code);
      }
      const inviteUsed = await isInviteCodeUsed(getDID(address));
      if (inviteUsed?.status !== undefined) {
        setIsCodeVerifiedOnLoad(inviteUsed.status);
      }
    }
    if (address) {
      asyncEffect();
    }
  }, [address]);

  const handleTransactionContinue = async completed => {
    if (hasRedeemError && completed) {
      const inviteUsed = await isInviteCodeUsed(getDID(address));
      if (inviteUsed?.status !== undefined) {
        setIsCodeVerifiedOnLoad(inviteUsed.status);
      }
    }
    if (hasRedeemError || inviteCodeUsedTransaction) {
      setIsLoadingInviteRewards(false);
      resetInviteCodeUsed();
    } else if (hasClaimError || claimTransaction) {
      setIsLoadingClaim(false);
      resetClaim();
    }
    await getUnclaimedRewardsBalance();
    await getRewards();
  };

  if (loadingSignConsent) {
    return <SigningConsentPage />;
  }

  if (poiCheckFailedError) {
    return <POICheckFailed handleAction={() => resetErrors()} page={PAGES.INVITE_FRIENDS} />;
  }

  if ((waitingInviteCodeUsedTransaction && !isLoadingInviteCodeUsed) || (waitingClaimTransaction && !isLoadingClaimTransaction)) {
    return (
      <SkWaitingTransaction
        textWaiting={'Please approve the transaction on your wallet.'}
        isWaitingForTransaction={false}
        showNetworkSpeedMessage={false}
        skStyle={false}
      />
    );
  } else if ((waitingInviteCodeUsedTransaction && isLoadingInviteCodeUsed) || (waitingClaimTransaction && isLoadingClaimTransaction)) {
    setDocumentTitle(`${APP_NAME} - ${PAGES.INVITE_FRIENDS} - Waiting for Transaction`);
    return <SkWaitingTransaction skStyle={false} />;
  } else if (hasRedeemError || hasClaimError) {
    setDocumentTitle(`${APP_NAME} - ${PAGES.INVITE_FRIENDS} - Error`);
    return <TransactionFailed title={hasRedeemError ? 'Redeem Failed' : 'Claim Failed'} onRetry={() => handleTransactionContinue(false)} skStyle={false} />;
  } else if (inviteCodeUsedTransaction || claimTransaction) {
    setDocumentTitle(`${APP_NAME} - ${PAGES.INVITE_FRIENDS} - Success`);
    return <TransactionCompleted tx={inviteCodeUsedTransaction || claimTransaction} handleAction={() => handleTransactionContinue(true)} skStyle={false} />;
  } else {
    setDocumentTitle(`${APP_NAME} - ${PAGES.INVITE_FRIENDS}`);
  }

  return (
    <PagesBackground>
      <Box flexDirection="column" justifyContent="flex-start">
        <HStack justifyContent="center" alignItems="flex-start" mt={{ base: '18px', md: 0 }} maxWidth={'1216px'} width={'100%'}>
          <Flex
            direction={{ base: 'column', lg: 'row' }}
            w={{ base: '100%', xl: '1216px' }}
            justifyContent={'flex-start'}
            alignItems={'top'}
            pb={{ base: '0', lg: '200px' }}
            px={{ base: '20px', xl: '0' }}
          >
            <Stack alignItems="center" textAlign="center" alignSelf={'center'} w={{ base: '312px', md: '477px' }} display={{ base: 'block', lg: 'none' }}>
              <Box h={{ base: '380px', md: '477px' }} w={{ base: '312px', md: '477px' }} alignSelf={'center'}>
                <ModelLoader model={'/3d/step-1/self.glb'}></ModelLoader>
              </Box>
            </Stack>

            <Stack direction={'column'} w={'100%'} alignItems={{ base: 'center', lg: 'flex-start' }} maxWidth={{ base: '100%', lg: '696px' }} space={'32px'}>
              <SkBackButton eventName={'Invite Friends Page'} onPress={() => history.push('/member')} />
              <Heading
                fontWeight={{ base: 800, md: 700 }}
                lineHeight={{ base: 32, md: 60 }}
                fontSize={{ base: 24, md: 48 }}
                textAlign={{ base: 'center', lg: 'left' }}
                maxW={{ base: 320, md: 659 }}
                fontFamily={'Plus Jakarta Sans'}
                alignItems={'center'}
              >
                <Text>Invite eligible members and mint </Text>
                {rewardsAmount ? (
                  <TextGradient>
                    {rewardsAmount} SELF{' '}
                    <Image alt="self token" source={{ uri: selfToken }} w={{ base: '20px', md: '48px' }} h={{ base: '20px', md: '48px' }} />
                  </TextGradient>
                ) : (
                  <Spinner />
                )}
                <Text>!</Text>
              </Heading>
              <VStack>
                <Text
                  fontWeight={400}
                  fontSize={'14px'}
                  lineHeight={'20px'}
                  color={'#FFEDD2'}
                  maxW={{ base: '280px', md: '100%' }}
                  textAlign={{ base: 'center', md: 'left' }}
                  mb={'16px'}
                >
                  Your Invite Code:
                </Text>
                {rewardCode === undefined ? <Spinner /> : <SkInputCopy value={rewardCode} w={'208px'} h={'48px'} />}
              </VStack>
              {isCodeVerifiedOnLoad === false && !isCodeVerified && (
                <Text
                  fontWeight={700}
                  fontSize={{ base: '18px', md: '32px' }}
                  lineHeight={{ base: '22px', md: '40px' }}
                  textAlign={{ base: 'center', md: 'left' }}
                  maxW={{ base: '264px', md: '592px' }}
                  mt={'32px'}
                >
                  You have not yet Redeemed your Invite code!
                </Text>
              )}
              {isLoadingInviteRewards ? (
                <HStack alignItems={'center'}>
                  <SkSpinner pr={'12px'} />
                  <Text
                    fontWeight={400}
                    fontSize={'14px'}
                    lineHeight={'20px'}
                    color={'#FFEDD2'}
                    maxW={{ base: '280px', md: '100%' }}
                    textAlign={{ base: 'center', md: 'left' }}
                  >
                    Checking your invite code, please wait...
                  </Text>
                </HStack>
              ) : (
                <VStack>
                  {isCodeVerifiedOnLoad === false && !isCodeVerified && (
                    <VStack>
                      <Text
                        fontWeight={400}
                        fontSize={'14px'}
                        lineHeight={'20px'}
                        color={'#FFEDD2'}
                        maxW={{ base: '267px', md: '100%' }}
                        textAlign={{ base: 'center', md: 'left' }}
                        mb={'16px'}
                      >
                        Input the code of the person who invited you:
                      </Text>
                      <Stack direction={{ base: 'column', md: 'row' }} space={'32px'} alignItems={'start'} mb={'12px'}>
                        <VStack alignSelf={{ base: 'center', md: 'start' }}>
                          <Input
                            variant="rounded"
                            placeholder="Code"
                            w={{ base: '232px', md: '280px' }}
                            h={{ base: '48px', md: '56px' }}
                            alignSelf={'center'}
                            borderRadius={'50px'}
                            py={'20px'}
                            px={'40px'}
                            fontSize={'16px'}
                            lineHeight={'24px'}
                            fontWeight={400}
                            _focus={{
                              borderWidth: '1px',
                              borderColor: '#FFFFFF',
                              background: 'rgba(0, 0, 0, 0.2)'
                            }}
                            _hover={{
                              borderWidth: '1px',
                              borderColor: '#FFFFFF',
                              background: 'rgba(0, 0, 0, 0.2)'
                            }}
                            focusOutlineColor={'#FFFFFF'}
                            value={inputCode}
                            onChangeText={value => setInputReward(value)}
                            onSubmitEditing={handleRedeem}
                          />
                        </VStack>
                        <Box alignSelf={{ base: 'center', md: 'start' }}>
                          <SkButton
                            eventName={`${PAGES.INVITE_FRIENDS} - Redeem`}
                            fixedSize={false}
                            onPress={() => handleRedeem()}
                            mt={{ base: 0, md: '6px' }}
                            isDisabled={!inputCode || isLoadingInviteRewards}
                            isLoading={isLoadingInviteRewards}
                          >
                            Redeem
                          </SkButton>
                        </Box>
                      </Stack>
                    </VStack>
                  )}
                  {isCodeVerified === false && !isLoadingInviteRewards ? (
                    <Text
                      fontWeight={400}
                      fontSize={{ base: '10px', md: '12px' }}
                      lineHeight={{ base: '14px', md: '16px' }}
                      color={'#FF7575'}
                      maxW={{ base: '267px', md: '100%' }}
                      textAlign={{ base: 'center', md: 'flex-start' }}
                      ml={{ base: 0, md: '20px' }}
                    >
                      {redeemMessage || 'Sorry, that code is not valid!'}
                    </Text>
                  ) : (
                    isCodeVerified &&
                    !isLoadingInviteRewards && (
                      <Text
                        fontWeight={400}
                        fontSize={{ base: '10px', md: '12px' }}
                        lineHeight={{ base: '14px', md: '16px' }}
                        color={'#48C281'}
                        textAlign={{ base: 'center', md: 'left' }}
                        maxW={{ base: '264px', md: '100%' }}
                        mt={'32px'}
                      >
                        Congratulations! You have successfully redeemed your Invite Code!
                      </Text>
                    )
                  )}
                  {unclaimedRewards > 0 && (
                    <VStack space={'28px'} mt={'64px'}>
                      <Text
                        fontWeight={700}
                        fontSize={{ base: '18px', md: '32px' }}
                        lineHeight={{ base: '22px', md: '40px' }}
                        textAlign={{ base: 'center', md: 'left' }}
                        maxW={{ base: 264, md: '100%' }}
                      >
                        Claim SELF
                      </Text>
                      {!isClaimed && (
                        <HStack space={'32px'} alignItems={'center'}>
                          <HStack alignItems={'center'}>
                            <Image alt="self token" source={{ uri: selfToken }} w={'32px'} h={'32px'} />!
                            <Text
                              fontWeight={700}
                              fontSize={{ base: '18px', md: '32px' }}
                              lineHeight={{ base: '22px', md: '40px' }}
                              textAlign={{ base: 'center', md: 'left' }}
                              maxW={{ base: 264, md: '100%' }}
                            >
                              {unclaimedRewards}
                            </Text>
                          </HStack>
                          <SkButton eventName={`${PAGES.INVITE_FRIENDS} - Claim`} fixedSize={false} onPress={handleClaimRewards} isLoading={isLoadingClaim}>
                            Claim
                          </SkButton>
                        </HStack>
                      )}
                      {claimMessage && (
                        <Text
                          fontWeight={400}
                          fontSize={{ base: '10px', md: '12px' }}
                          lineHeight={{ base: '14px', md: '16px' }}
                          textAlign={{ base: 'center', md: 'left' }}
                          maxW={{ base: 264, md: '100%' }}
                          color={isClaimed ? '#48C281' : '#FF7575'}
                        >
                          {claimMessage}
                        </Text>
                      )}
                    </VStack>
                  )}
                </VStack>
              )}
              <SkBackButton eventName={'Invite Friends Page'} onPress={() => history.push('/member')} isMobile={true} mb={'80px'} />
            </Stack>

            <Stack alignItems="center" textAlign="center" w={{ base: '312px', md: '477px' }} display={{ base: 'none', lg: 'block' }}>
              <Box h={{ base: '312px', md: '477px' }} w={{ base: '312px', md: '477px' }} alignSelf={'center'}>
                <ModelLoader model={'/3d/step-1/self.glb'}></ModelLoader>
              </Box>
            </Stack>
          </Flex>
        </HStack>
      </Box>
      <WarningModal
        showModal={showConsentModal}
        title={consentView}
        leftButton={
          <LdButton
            variant={'secondary'}
            fixedSize={false}
            w={'134px'}
            onPress={() => {
              setShowConsentModal(false);
              setIsLoadingInviteRewards(false);
              setIsLoadingClaim(false);
            }}
          >
            Cancel
          </LdButton>
        }
        rightButton={
          <LdButton fixedSize={false} w={'134px'} onPress={() => handleAgreeConsent()}>
            Agree
          </LdButton>
        }
        handleClose={() => {
          setShowConsentModal(false);
          setIsLoadingInviteRewards(false);
          setIsLoadingClaim(false);
        }}
      />
      <SelfieModal
        isOpen={showTakeSelfieModal}
        handleClose={() => {
          setShowTakeSelfieModal(false);
          setIsLoadingInviteRewards(false);
          setIsLoadingClaim(false);
        }}
        handlePOIContinue={handlePOIContinue}
      />
    </PagesBackground>
  );
}

export { InvitationPageContainer };
