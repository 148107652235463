import { Input } from 'native-base';
import React from 'react';
import { CopyButton } from '../../../common/components/copy_button';
import { truncateAddress } from '../../../common/lib/wallet_helpers';

export function SkInputCopy({ value, w, h }) {
  const extraProps = {};
  if (w) {
    extraProps.w = w;
  }
  if (h) {
    extraProps.h = h;
  }
  return (
    <Input
      fontSize={'16px'}
      fontWeight={700}
      lineHeight={'24px'}
      maxW={'280px'}
      variant={'rounded'}
      isReadOnly={true}
      overflowY={'hidden'}
      InputRightElement={<CopyButton value={value} mr={'16px'} />}
      value={truncateAddress(value, 18)}
      bgColor={'rgba(255, 255, 255, 0.1)'}
      borderWidth={0}
      py={'12px'}
      pl={'24px'}
      {...extraProps}
    />
  );
}
