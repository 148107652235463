import React, { useContext } from 'react';
import { Text, useBreakpointValue } from 'native-base';
import { truncateAddress } from '../lib/wallet_helpers';
import { Web3WagmiContext } from '../context/app_context';

const TransactionLink = ({ hash }) => {
  const { chainDetails } = useContext(Web3WagmiContext);
  const responsiveHash = useBreakpointValue({
    base: truncateAddress(hash, 23),
    md: hash
  });
  if (!hash || !chainDetails?.blockExplorers?.default?.url) return null;
  return (
    <a href={`${chainDetails.blockExplorers.default.url}/tx/${hash}`} target="_blank" rel="noopener noreferrer" style={{ textDecorationColor: '#FFFFFF' }}>
      <Text fontSize="12px" fontWeight="400" lineHeight="16px" color="#FFFFFF">
        {responsiveHash}
      </Text>
    </a>
  );
};

export default TransactionLink;
