import React, { useContext } from 'react';
import { Link, Stack, Text, useBreakpointValue } from 'native-base';
import metamaskFox from '../../selfkey-id/assets/icons/metamask-icon.svg';
import walletconnectLogo from '../../selfkey-id/assets/icons/wallet-connect-icon.svg';
import { useHistory } from 'react-router-dom';
import SkButton from '../../selfkey-id/pages/components/sk_button';
import { Web3WagmiContext } from '../context/app_context';
import { isBraveBrowser, isMetamaskAvailable, isMetaMaskSupported, isWalletConnectSupported, notSupportedBrowser } from '../lib/helpers';
import { isAndroid, isIOS } from 'react-device-detect';
import { PAGES } from '../lib/constants';
// import { appEnv } from 'env-configs';

function ConnectWallet({ mvp = false }) {
  const { connect } = useContext(Web3WagmiContext);
  const history = useHistory();
  // const { minStakingAmount } = props;
  const fontColor = 'darkBlue.100';
  // const fontColor = 'darkBlue.100';
  // const [modal, setModal] = useState({ showModal: false, variation: '' });
  const isMobileView = window.matchMedia('only screen and (max-width: 760px)').matches;

  // const toggleModal = (type, variation) => {
  //   setModal({ showModal: !modal.showModal, type, variation });
  // };

  const handleConnect = ({ type }) => {
    // TODO check if iOS or Android and redirect to proper store link
    if (isMetamaskAvailable()) {
      connect(type);
    } else {
      if (isIOS) {
        window.open('https://apps.apple.com/us/app/metamask-blockchain-wallet/id1438144202');
      } else if (isAndroid) {
        window.open('https://play.google.com/store/apps/details?id=io.metamask&hl=en&gl=US');
      } else {
        window.open('https://metamask.io/');
      }
    }
  };

  const flexConnectWalletHeading = useBreakpointValue({
    base: 24,
    md: 20
  });
  const spaceConnectButtons = useBreakpointValue({
    base: '10px',
    md: '30px'
  });
  const linksStyle = useBreakpointValue({
    base: {
      fontSize: '10px',
      lineHeight: '14px',
      fontWeight: 400,
      color: 'white'
    },
    md: {
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 400,
      color: 'white'
    }
  });

  return (
    <>
      {/* TODO: check if we need DashboardModal here - storybook did not like it */}
      {/* <DashboardModal showModal={modal.showModal} handlePress={toggleModal} type={'learnMore'} variation={modal.variation} /> */}
      <Stack space={'40px'}>
        <Text
          fontWeight={mvp ? 400 : 500}
          fontSize={mvp ? (isMobileView ? 14 : 16) : flexConnectWalletHeading}
          lineHeight={mvp ? (isMobileView ? 20 : 24) : 28}
          color={notSupportedBrowser() ? '#FF7575' : 'white'}
          textAlign={{ base: 'center', lg: 'left' }}
          alignSelf={{ base: 'center', lg: 'flex-start' }}
          maxW={{ base: '266px', md: '100%' }}
        >
          {notSupportedBrowser() ? (
            <div>
              <b>This browser is not supported by the application, please use Google Chrome or Safari.</b>
            </div>
          ) : (
            <div>
              <b>Connect</b> with your preferred wallet to get started!
            </div>
          )}
        </Text>
      </Stack>
      <Stack
        direction={isMobileView ? 'column' : 'row'}
        align={'center'}
        space={spaceConnectButtons}
        alignSelf={'center'}
        width={'100%'}
        justifyContent={{ base: 'center', lg: 'flex-start' }}
        alignItems="center"
        mt={'24px'}
      >
        {isMetaMaskSupported() && (
          <SkButton
            eventName={`${PAGES.LANDING} - MetaMask Connect`}
            variant="secondary"
            leftIcon={metamaskFox}
            onPress={() => {
              handleConnect({ type: 'metaMaskSDK' });
            }}
          >
            MetaMask
          </SkButton>
        )}
        {isWalletConnectSupported() && (
          <SkButton
            variant="secondary"
            leftIcon={walletconnectLogo}
            onPress={() => {
              handleConnect({ type: 'walletConnect' });
            }}
          >
            WalletConnect
          </SkButton>
        )}
      </Stack>
      <Text
        textAlign={{ base: 'center', md: 'left' }}
        alignSelf={{ base: 'center', md: 'flex-start' }}
        fontWeight={400}
        fontSize={{ base: '10px', md: '12px' }}
        lineHeight={{ base: '14px', md: '16px' }}
        color={'#A9A9A9'}
        mt={{ base: '24px', md: '48px' }}
        maxW={{ base: '240px', md: '100%' }}
      >
        By connecting your wallet, you agree to our{' '}
        <Link onPress={() => history.push('/terms-of-service')} _text={linksStyle}>
          Terms of Service
        </Link>{' '}
        and our{' '}
        <Link onPress={() => history.push('/privacy-policy')} _text={linksStyle}>
          Privacy Policy
        </Link>
        .
      </Text>
    </>
  );
}

export { ConnectWallet };
