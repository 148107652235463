import React from 'react';
import { PagesBackground } from '../../common/layout/main_layout.styled';
import { RtfContentPage } from '../../common/components/rtf_content';
import useDocumentTitle from '../../common/hooks/use_document_title';
import { APP_NAME, PAGES } from '../../common/lib/constants';

function TermsPageContainer() {
  const { setDocumentTitle } = useDocumentTitle();

  React.useEffect(() => {
    setDocumentTitle(`${APP_NAME} - ${PAGES.TERMS_OF_SERVICE}`);
  }, []);
  return (
    <PagesBackground>
      <RtfContentPage isTos={true} />
    </PagesBackground>
  );
}

export { TermsPageContainer };
