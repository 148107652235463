import React, { useContext, useEffect, useState } from 'react';
import { Box, Heading, Image, Spinner, Stack, Text, useBreakpointValue, View, VStack } from 'native-base';
import { PagesBackground } from '../../common/layout/main_layout.styled';
import { ModelLoader } from '../../common/components/model_loader';
import { JoinDiscordCard } from '../../common/components/join_discord_card';
import SkButton from './components/sk_button';
import { useHistory } from 'react-router-dom';
import { RegistryContext, SelfTokenContext, Web3WagmiContext } from '../../common/context/app_context';
import { SkNumericInput } from './components/sk_input';
import SkFrame from './components/sk_frame';
import { convertNumber } from '../../common/lib/helpers';
import { SkBackButton } from './components/sk_back_button';
import selfToken from '../../common/assets/icons/self-token.svg';
import switchIcon from '../../common/assets/icons/switch-icon.svg';
import useDocumentTitle from '../../common/hooks/use_document_title';
import { APP_NAME, PAGES } from '../../common/lib/constants';
import { appEnv } from 'env-configs';
import { useBalance } from 'wagmi';
import { formatUnits } from 'viem';
import { SkWaitingTransaction } from './components/sk_waiting_transaction';
import { HoverTooltip } from '../../common/components/hover-tooltip';
import TransactionLink from '../../common/components/transaction_link';
import { TransactionFailed } from './components/transaction_failed';

function BridgePageContainer() {
  const { setDocumentTitle } = useDocumentTitle();
  const { activeAddress: address, switchNetwork, allChains, isPolygonNetwork, contracts } = useContext(Web3WagmiContext);
  const { approveSelfAmount, waitingAllowanceApproval, selfAllowance, refetchSelfAllowance, clearErrors, hasError } = useContext(RegistryContext);
  const { selfBridge, isLoadingBridge, waitingSelfBridgeTransaction, selfBridgeTransaction, selfBridgeCompleted, resetBridgeState, hasBridgeError } =
    useContext(SelfTokenContext);

  const history = useHistory();

  const [selfToBridge, setSelfToBridge] = React.useState('');
  const [maxAmountWarning, setMaxAmountWarning] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const CHECK_BURN_URL = (network, txHash) =>
    `https://proof-generator.polygon.technology/api/v1/${network}/exit-payload/${txHash}?eventSignature=0x8c5261668696ce22758910d05bab8f186d6eb247ceac2af2e82c7dc17669b036`;
  const MUMBAI_CHECK_BURN_URL = txHash => CHECK_BURN_URL('mumbai', txHash);
  const MATIC_CHECK_BURN_URL = txHash => CHECK_BURN_URL('matic', txHash);

  const checkBurnCheckpointTx = async txHash => {
    if (!isPolygonNetwork) {
      return;
    }
    const url = appEnv === 'production' ? MATIC_CHECK_BURN_URL(txHash) : MUMBAI_CHECK_BURN_URL(txHash);
    const response = await fetch(url);
    const data = await response.json();
    return data;
  };

  const handleBridgeSelf = async () => {
    const amountValid = selfToBridge > 0 && checkAmount();
    if (amountValid && !maxAmountWarning) {
      setIsLoading(true);
      if (parseFloat(selfAllowance.value) < parseFloat(selfToBridge)) {
        await approveSelfAmount(selfToBridge, isPolygonNetwork ? contracts?.selfToken : contracts?.selfTokenEth);
      } else {
        await selfBridge(selfToBridge);
      }
    }
  };

  const getChain = network => allChains.find(c => c.network === network);

  const {
    data: selfBalance,
    isLoading: isLoadingSelfBalance,
    refetch: refetchSelfBalance
  } = useBalance({
    address: address,
    token: contracts?.selfToken,
    chainId: getChain(appEnv === 'production' ? 'matic' : 'maticmum').id,
    enabled: Boolean(contracts?.selfToken)
  });
  const [formattedSelfBalance, setFormattedSelfBalance] = useState({ formattedValue: null, wasRounded: false });
  const [selfBalanceValue, setSelfBalanceValue] = useState();
  useEffect(() => {
    if (selfBalance) {
      const value = formatUnits(selfBalance.value, selfBalance.decimals);
      setSelfBalanceValue(value);
      setFormattedSelfBalance(convertNumber(value, true));
    }
  }, [selfBalance]);

  const {
    data: selfEthBalance,
    isLoading: isLoadingSelfEthBalance,
    refetch: refetchSelfEthBalance
  } = useBalance({
    address: address,
    token: contracts?.selfTokenEth,
    chainId: getChain(appEnv === 'production' ? 'homestead' : 'goerli').id,
    enabled: Boolean(contracts?.selfTokenEth)
  });
  const [formattedSelfBalanceEth, setFormattedSelfBalanceEth] = useState({ formattedValue: null, wasRounded: false });
  const [selfEthBalanceValue, setSelfEthBalanceValue] = useState();
  useEffect(() => {
    if (selfEthBalance) {
      const value = formatUnits(selfEthBalance.value, selfEthBalance.decimals);
      setSelfEthBalanceValue(value);
      setFormattedSelfBalanceEth(convertNumber(value, true));
    }
  }, [selfEthBalance]);

  const checkAmount = () => {
    const valid = parseFloat(selfToBridge) <= (isPolygonNetwork ? parseFloat(selfBalanceValue) : parseFloat(selfEthBalanceValue));
    setMaxAmountWarning(selfToBridge > 0 && !valid);
    return valid;
  };

  useEffect(() => {
    checkAmount();
  }, [isPolygonNetwork]);

  const [previousSelfAllowance, setPreviousSelfAllowance] = useState(selfAllowance);
  useEffect(() => {
    if (!selfBridgeCompleted && selfAllowance?.value !== previousSelfAllowance?.value) {
      handleBridgeSelf();
      setPreviousSelfAllowance(selfAllowance);
    }
  }, [selfAllowance, selfBridgeCompleted]);

  useEffect(() => {
    if (selfBridgeCompleted && selfBridgeTransaction) {
      handleCompleted();
      setIsLoading(false);
    }
  }, [selfBridgeCompleted, selfBridgeTransaction]);

  const handleSwitchNetwork = () => {
    if (appEnv === 'production') {
      if (isPolygonNetwork) {
        switchNetwork(getChain('homestead').id);
      } else {
        switchNetwork(getChain('matic').id);
      }
    } else {
      if (isPolygonNetwork) {
        switchNetwork(getChain('goerli').id);
      } else {
        switchNetwork(getChain('maticmum').id);
      }
    }
  };

  const [pendingTxHash, setPendingTxHash] = useState(
    !!localStorage.getItem('pendingBurnCheckpointTx') && JSON.parse(localStorage.getItem('pendingBurnCheckpointTx'))
  );
  useEffect(() => {
    if (pendingTxHash) {
      startPolling();
    }
  }, [pendingTxHash]);
  const CHECKPOINT_INTERVAL = 10000;
  const [checkpointSuccess, setCheckpointSuccess] = useState(false);
  let intervalId;
  const checkCheckpoint = async () => {
    if (!pendingTxHash?.hash) {
      return;
    }
    const checkpointResponse = await checkBurnCheckpointTx(pendingTxHash.hash);
    if (checkpointResponse) {
      const success = !checkpointResponse?.error;
      setCheckpointSuccess(success);
      if (success) {
        stopPolling();
      }
    }
  };
  const startPolling = () => {
    intervalId = setInterval(checkCheckpoint, CHECKPOINT_INTERVAL);
  };
  const stopPolling = () => {
    clearInterval(intervalId);
    setPendingTxHash(null);
    localStorage.removeItem('pendingBurnCheckpointTx');
  };

  const handleCompleted = async () => {
    if (!pendingTxHash?.hash) {
      const pendingTx = { hash: selfBridgeTransaction.transactionHash, bridgedAmont: selfToBridge };
      setPendingTxHash(pendingTx);
      localStorage.setItem('pendingBurnCheckpointTx', JSON.stringify(pendingTx));
    }

    refetchSelfAllowance();
    refetchSelfBalance();
    setSelfToBridge('');
    setIsLoading(false);
    startPolling();
  };

  const handleReset = () => {
    resetBridgeState();
    clearErrors();
    setIsLoading(false);
  };

  const handleSetMaxAmount = () => {
    setSelfToBridge(isPolygonNetwork ? selfBalanceValue : selfEthBalanceValue);
  };

  useEffect(() => {
    setDocumentTitle(`${APP_NAME} - ${PAGES.BRIDGE_SELF}`);
  }, []);

  const title = useBreakpointValue({
    base: <></>,
    md: <Heading>Bridge SELF</Heading>
  });

  if (hasError || hasBridgeError) {
    return <TransactionFailed title={'Bridge failed'} onRetry={handleReset} skStyle={false} />;
  } else if (waitingAllowanceApproval || waitingSelfBridgeTransaction || isLoadingBridge || isLoading) {
    return <SkWaitingTransaction isWaitingForTransaction={!waitingAllowanceApproval} skStyle={false} />;
  }

  return (
    <PagesBackground>
      <Box flexDirection="column" alignItems="center" maxWidth={'1216px'} width={'100%'}>
        <View position={'absolute'} left={0}>
          <SkBackButton eventName={PAGES.BRIDGE_SELF} onPress={() => history.push('/member/token-minting')} />
        </View>
        <VStack space={{ base: '40px', md: '64px' }}>
          <Stack direction={{ base: 'column', md: 'row' }} space={'32px'}>
            <Stack alignItems="center" textAlign="center" alignSelf={'center'} space="4" display={{ base: 'block', lg: 'none' }}>
              <View w={'310px'} h={'310px'}>
                <ModelLoader model={'/3d/step-1/self.glb'}></ModelLoader>
              </View>
            </Stack>

            <VStack space={'40px'} justifyContent={'center'} alignItems={'center'}>
              <SkFrame
                h={{ base: maxAmountWarning ? '734px' : '708px', md: maxAmountWarning ? '588px' : '556px' }}
                w={{ base: '320px', md: '592px' }}
                px={{ base: '20px', md: '40px' }}
              >
                <VStack alignItems={'center'} justifyContent={'center'} h={'100%'} space={{ base: '32px', md: '40px' }}>
                  {title}
                  <VStack alignItems={'center'} space={{ base: '12px', md: '8px' }}>
                    <Stack
                      direction={{
                        base: isPolygonNetwork ? 'column' : 'column-reverse',
                        md: isPolygonNetwork ? 'row' : 'row-reverse'
                      }}
                      justifyContent={'space-between'}
                      w={{ base: '100%', md: '512px' }}
                      space={{ base: '24px', md: '0' }}
                    >
                      <VStack alignItems={{ base: 'center', md: isPolygonNetwork ? 'flex-start' : 'flex-end' }}>
                        <Text fontSize={{ base: '14px', md: '16px' }} lineHeight={{ base: '20px', md: '24px' }} fontWeight={400} color={'#FFEDD2'}>
                          {isPolygonNetwork ? 'From' : 'To'}
                        </Text>
                        <Text
                          fontSize={{ base: '16px', md: '18px' }}
                          lineHeight={{ base: '22px', md: '24px' }}
                          fontWeight={700}
                          mt={{ base: '12px', md: '16px' }}
                          mb={'8px'}
                        >
                          Polygon Mumbai
                        </Text>
                        <Text
                          fontSize={{ base: '14px', md: '16px' }}
                          lineHeight={{ base: '20px', md: '24px' }}
                          fontWeight={400}
                          display={'flex'}
                          alignItems={'center'}
                        >
                          {isLoadingSelfBalance ? (
                            <>
                              <Spinner color={'indigo.500'} /> SELF{' '}
                            </>
                          ) : (
                            <HoverTooltip content={`${selfBalanceValue} SELF`} disabled={!formattedSelfBalance.wasRounded}>
                              <Text>
                                {formattedSelfBalance.formattedValue}
                                {formattedSelfBalance.wasRounded ? <Text fontSize={'12px'}>...</Text> : ''} SELF{' '}
                              </Text>
                            </HoverTooltip>
                          )}
                          <Image alt="self token" source={{ uri: selfToken }} w={'16px'} h={'16px'} />
                        </Text>
                      </VStack>
                      <SkButton
                        eventName={'Bridge SELF'}
                        w={'140px'}
                        variant={'secondary'}
                        fixedSize={false}
                        padding={'12px 20px 12px 20px'}
                        onPress={() => handleSwitchNetwork()}
                        isDisabled={isLoadingSelfBalance}
                        leftIcon={switchIcon}
                        leftIconSize={'16px'}
                      >
                        Switch
                      </SkButton>
                      <VStack alignItems={{ base: 'center', md: isPolygonNetwork ? 'flex-end' : 'flex-start' }}>
                        <Text fontSize={{ base: '14px', md: '16px' }} lineHeight={{ base: '20px', md: '24px' }} fontWeight={400} color={'#FFEDD2'}>
                          {isPolygonNetwork ? 'To' : 'From'}
                        </Text>
                        <Text
                          fontSize={{ base: '16px', md: '18px' }}
                          lineHeight={{ base: '22px', md: '24px' }}
                          fontWeight={700}
                          mt={{ base: '12px', md: '16px' }}
                          mb={'8px'}
                        >
                          Ethereum Goerli
                        </Text>
                        <Text
                          fontSize={{ base: '14px', md: '16px' }}
                          lineHeight={{ base: '20px', md: '24px' }}
                          fontWeight={400}
                          display={'flex'}
                          alignItems={'center'}
                        >
                          {isLoadingSelfEthBalance ? (
                            <>
                              <Spinner color={'indigo.500'} /> SELF{' '}
                            </>
                          ) : (
                            <HoverTooltip content={`${setSelfEthBalanceValue} SELF`} disabled={!formattedSelfBalanceEth.wasRounded}>
                              <Text>
                                {formattedSelfBalanceEth.formattedValue}
                                {formattedSelfBalanceEth.wasRounded ? <Text fontSize={'12px'}>...</Text> : ''} SELF{' '}
                              </Text>
                            </HoverTooltip>
                          )}
                          <Image alt="self token" source={{ uri: selfToken }} w={'16px'} h={'16px'} />
                        </Text>
                      </VStack>
                    </Stack>
                  </VStack>
                  <VStack space={{ base: '40px', md: '40px' }} alignItems={'center'}>
                    <VStack alignItems={'center'} space={{ base: '12px', md: '16px' }} mt={{ base: '20px', md: 0 }}>
                      <SkNumericInput
                        placeholder={'Enter an amount'}
                        onChange={value => {
                          setMaxAmountWarning(parseFloat(value) > (isPolygonNetwork ? parseFloat(selfBalanceValue) : parseFloat(selfEthBalanceValue)));
                          setSelfToBridge(value);
                        }}
                        value={selfToBridge}
                        rightElement={
                          <SkButton
                            eventName={`${PAGES.WITHDRAW} - Max`}
                            w={{ base: '70px', md: '100px' }}
                            h={{ base: '40px', md: '48px' }}
                            fixedSize={false}
                            pr={'12px'}
                            isLoading={isLoadingSelfBalance}
                            onPress={() => handleSetMaxAmount()}
                          >
                            Max
                          </SkButton>
                        }
                      />
                      {maxAmountWarning && (
                        <Text fontWeight={400} fontSize={{ base: '10px', md: '12px' }} lineHeight={{ base: '14px', md: '16px' }} color={'warning.500'}>
                          You don't have enough SELF
                        </Text>
                      )}
                    </VStack>
                    <SkButton
                      eventName={'Bridge SELF'}
                      w={{ base: '114px', md: '114px' }}
                      fixedSize={false}
                      onPress={() => handleBridgeSelf()}
                      isLoading={waitingSelfBridgeTransaction || waitingAllowanceApproval || isLoadingBridge}
                      isDisabled={parseFloat(selfBalance) === 0}
                    >
                      Bridge
                    </SkButton>
                    <Text fontWeight={400} fontSize={{ base: '12px', md: '12px' }} lineHeight={{ base: '16px', md: '16px' }} color={'#A9A9A9'}>
                      The bridging service is provided by a third-party platform, not by SelfKey DAO. By using this service, you acknowledge that the bridging
                      process and its security are solely the responsibility of the third-party service. SelfKey DAO does not have control over and is not
                      liable for, any issues that may arise during bridging. SelfKey DAO will not be able to help if something goes wrong while using the
                      Bridging services of this third party.
                    </Text>
                  </VStack>
                </VStack>
              </SkFrame>

              {pendingTxHash?.hash && (
                <VStack alignItems={'center'} w={'100%'}>
                  <TransactionLink hash={pendingTxHash.hash} />
                  <Text
                    fontSize={{ base: '14px', md: '16px' }}
                    lineHeight={{ base: '20px', md: '24px' }}
                    fontWeight={400}
                    color={'#FFEDD2'}
                    textAlign={'center'}
                    mt={'40px'}
                  >
                    {checkpointSuccess
                      ? 'Transaction completed.'
                      : `Bridged ${pendingTxHash.bridgedAmont} SELF to ${
                          isPolygonNetwork ? 'Goerli' : 'Mumbai'
                        }, transaction has not been checkpointed yet, please wait.`}
                  </Text>
                </VStack>
              )}
            </VStack>
            {/*<Stack alignItems="center" textAlign="center" space="4" display={{ base: 'none', lg: 'block' }} minW={'440px'} minH={'440px'}>*/}
            {/*  <ModelLoader model={'/3d/step-1/self.glb'}></ModelLoader>*/}
            {/*</Stack>*/}
          </Stack>
        </VStack>
        <SkBackButton eventName={PAGES.BRIDGE_SELF} onPress={() => history.push('/member/token-minting')} isMobile={true} my={'40px'} />
        <JoinDiscordCard my={{ base: '60px', md: '160px' }} skStyle={false} />
      </Box>
    </PagesBackground>
  );
}

export { BridgePageContainer };
