import React, { useContext } from 'react';
import { HStack, Stack, Text, useBreakpointValue, View, VStack, Flex, Heading } from 'native-base';
import { PagesBackground } from '../../common/layout/main_layout.styled';
import { useHistory } from 'react-router-dom';
import { NftContext, Web3WagmiContext } from '../../common/context/app_context';
import SkButton from '../../lockdao/pages/components/sk_button';
import { truncateAddress } from '../../common/lib/wallet_helpers';

import { ModelLoader } from '../../common/components/model_loader';
import { SkBackButton } from '../../lockdao/pages/components/sk_back_button';
import { JoinDiscordCard } from '../../common/components/join_discord_card';
import useDocumentTitle from '../../common/hooks/use_document_title';
import { APP_NAME, PAGES } from '../../common/lib/constants';
import { CopyButton } from '../../common/components/copy_button';
import SkFrame from '../../lockdao/pages/components/sk_frame';
import { isMetamaskAvailable } from '../../common/lib/helpers';
import { useImportToken } from '../../common/hooks/use_import_token';

function NftPageContainer() {
  const { setDocumentTitle } = useDocumentTitle();

  React.useEffect(() => {
    setDocumentTitle(`${APP_NAME} - ${PAGES.MY_SELFKEY_ID}`);
  }, []);

  const history = useHistory();
  const { /*activeAddress: address,*/ chainDetails, contracts } = useContext(Web3WagmiContext);
  const { nftAddress, tokenId } = useContext(NftContext);
  const [loadingImportSBT, importSBT] = useImportToken({
    tokenType: 'ERC721',
    tokenAddress: contracts.selfkeyIdNft,
    tokenId: `${tokenId}`
  });
  // const [avatar, setAvatar] = useState();

  // useEffect(() => {
  //   const doAsync = async () => {
  //     try {
  //       const avatarImage = await loadAvatarImage({ address });
  //       setAvatar(avatarImage);
  //     } catch (err) {
  //       console.error(err);
  //     }
  //   };
  //   if (address) {
  //     doAsync();
  //   }
  // }, [address]);

  const handleViewOnPolyscan = () => {
    window.open(`${chainDetails.blockExplorers.default.url}/token/${nftAddress}?a=${tokenId}`);
  };

  const detailsBackground = useBreakpointValue({
    base: "url('/nft-details-mobile-bg.svg')",
    md: "url('/nft-details-bg.svg')"
  });
  const importButtonSize = useBreakpointValue({
    base: 'small',
    md: 'normal'
  });
  const importButtonMargin = useBreakpointValue({
    base: '32px',
    md: '40px'
  });
  const nftDimension = useBreakpointValue({ base: '320px', md: '394px' });
  return (
    <PagesBackground skStyle={false}>
      <Flex alignItems={'center'} w={'100%'} maxW={'1216px'}>
        <View position={'absolute'} left={0}>
          <SkBackButton eventName={PAGES.MY_SELFKEY_ID + ' - Back Button'} onPress={() => history.push('/member')} />
        </View>
        <VStack space={{ base: '40px', md: '64px' }}>
          <Stack direction={{ base: 'column', md: 'row' }} space={'32px'} alignItems={{ base: 'center', md: 'flex-start' }}>
            <Stack alignItems="center" textAlign="center" alignSelf={'center'} space="4" display={{ base: 'block', md: 'none' }}>
              <View w={'310px'} h={'310px'}>
                <ModelLoader model={'/3d/step-1/NFT.glb'} scale={1.1} stageEnv={'city'} stageIntensity={2}></ModelLoader>
              </View>
            </Stack>
            <VStack space={'40px'}>
              <SkFrame h={{ base: '240px', md: '296px' }}>
                <VStack alignItems={'center'} justifyContent={'center'} h={'100%'} space={{ base: '12px', md: '24px' }}>
                  <VStack space={'6px'}>
                    <Text fontWeight={400} fontSize={'16px'} lineHeight={'24px'} color={'#FFEDD2'} textAlign={'center'}>
                      NFT Address
                    </Text>
                    <HStack justifyContent={'space-between'} space={'6px'}>
                      <Text fontWeight={700} fontSize={'16px'} lineHeight={'24px'} isTruncated>
                        {truncateAddress(nftAddress, 23)}
                      </Text>
                      <CopyButton value={nftAddress} />
                    </HStack>
                  </VStack>
                  <VStack space={'6px'}>
                    <Text fontWeight={400} fontSize={'16px'} lineHeight={'24px'} color={'#FFEDD2'} textAlign={'center'}>
                      NFT ID
                    </Text>
                    <HStack justifyContent={'space-between'}>
                      <Text fontWeight={700} fontSize={'16px'} lineHeight={'24px'}>
                        {tokenId}
                      </Text>
                      <CopyButton value={tokenId} />
                    </HStack>
                  </VStack>
                  <SkButton
                    eventName={`${PAGES.MY_SELFKEY_ID} - View in Blockchain Explorer`}
                    onPress={handleViewOnPolyscan}
                    size={importButtonSize}
                    fixedSize={false}
                  >
                    View in Blockchain Explorer
                  </SkButton>
                </VStack>
              </SkFrame>
              {isMetamaskAvailable() && (
                <SkFrame h={{ base: '168px', md: '188px' }}>
                  <VStack alignItems={'center'} justifyContent={'center'} h={'100%'} space={{ base: '32px', md: '24px' }}>
                    <Heading
                      fontFamily={'Plus Jakarta Sans'}
                      fontWeight={700}
                      fontSize={{ base: '20px', md: '28px' }}
                      lineHeight={{ base: '24px', md: '36px' }}
                      display={'flex'}
                      alignItems={'center'}
                    >
                      Display SBT in your wallet
                    </Heading>
                    <SkButton
                      eventName={'Import SBT'}
                      w={{ base: '97px', md: '222px' }}
                      fixedSize={false}
                      size={importButtonSize}
                      isLoading={loadingImportSBT}
                      onPress={importSBT}
                    >
                      Import
                    </SkButton>
                  </VStack>
                </SkFrame>
              )}
            </VStack>

            <Stack alignItems="center" textAlign="center" space="4" display={{ base: 'none', md: 'block' }} w={'440px'} h={'440px'}>
              <ModelLoader model={'/3d/step-1/NFT.glb'} scale={1.1} stageEnv={'city'} stageIntensity={2}></ModelLoader>
            </Stack>
          </Stack>
          <Flex alignItems={'center'}>
            <SkBackButton eventName={'My SelfKey iD Page'} onPress={() => history.push('/member')} isMobile={true} />
          </Flex>
          <JoinDiscordCard alignSelf={'center'} mt={{ base: '24px', md: '92px' }} mb={{ base: '130px', md: '160px' }} skStyle={false} />
        </VStack>
      </Flex>
    </PagesBackground>
  );
}

export { NftPageContainer };
