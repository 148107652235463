import { Heading, HStack, Image, Text, VStack } from 'native-base';
import React from 'react';
import { TextGradient as TextGradientSK } from '../../selfkey-id/pages/components/text_gradient';
import { TextGradient as TextGradientLD } from '../../lockdao/pages/components/text_gradient';
import { HowItWorks } from './how_it_works';
import { EnsTeaser } from './ens_teaser';
import { MintSelf } from './mint_self';
import { NftTeaser } from './nft_teaser';
import { Benefits } from './benefits';
import { current as envConfigs } from 'env-configs/selfkey-org';

export const FeatureTitle = ({ title, titleWhite, titleGradient, skStyle = true, icon, ...props }) => {
  return (
    <Heading
      fontWeight={{ base: 800, md: 700 }}
      fontSize={{ base: '28px', md: '48px' }}
      lineHeight={{ base: '36px', md: '60px' }}
      textAlign={'center'}
      alignSelf={'center'}
      maxW={{ base: '320px', md: '669px' }}
      fontFamily={'Plus Jakarta Sans'}
      {...props}
      display={'flex'}
      alignItems={'center'}
    >
      <span>
        {title ? '' : `${titleWhite} `}
        {skStyle ? <TextGradientSK>{titleGradient || title}</TextGradientSK> : <TextGradientLD>{titleGradient || title}</TextGradientLD>}
      </span>
      {icon && icon}
    </Heading>
  );
};
export function FeaturesSection(props) {
  return (
    <VStack alignItems={'center'} space={{ base: '60px', md: '120px' }} mb={{ base: '48px', md: '160px' }}>
      <HowItWorks link={envConfigs.howItWorksUrl} />
      {/*<Benefits link={link} />*/}
      {/*<FeatureTitle title={'Coming Soon:'} />*/}
      {/*<MintSelf link={envConfigs.mintSelfUrl} />*/}
      {/*<NftTeaser link={envConfigs.nftCollectionUrl} />*/}
      {/*<EnsTeaser link={link} />*/}
    </VStack>
  );
}
