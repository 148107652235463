import React, { useEffect } from 'react';
import { Box, Flex, Heading, HStack, Stack, useBreakpointValue, View, Text, VStack } from 'native-base';
import { PagesBackground } from '../../common/layout/main_layout.styled';
import { TextGradient } from './components/text_gradient';
import SkButton from './components/sk_button';
import LdButton from '../../lockdao/pages/components/sk_button';
import { JoinDiscordCard } from '../../common/components/join_discord_card';
import { ModelLoader } from '../../common/components/model_loader';
import { MintingCompleted } from './minting_completed';
import { MintingFailed } from './minting_failed';
import WarningModal from '../../common/components/warning_modal';
import useDocumentTitle from '../../common/hooks/use_document_title';
import { APP_NAME, PAGES } from '../../common/lib/constants';
import { SelfieModal } from '../../common/components/selfie_modal';
import { POICheckFailed } from '../../common/components/poi_check_failed';
import { SkCheckbox } from '../../common/components/sk_checkbox';
import { SigningConsentPage } from '../../common/components/signing-content-page';
import useEligibility from '../../common/hooks/use_eligibility';

function Link({ href, label, ...props }) {
  return (
    <a href={href} target="_blank" rel="noopener noreferrer" style={{ textDecorationColor: '#FFFFFF', lineBreak: 'anywhere' }}>
      <Text color={'#FFFFFF'} fontWeight={400} fontSize={{ base: '14px', md: '16px' }} lineHeight={{ base: '20px', md: '24px' }} {...props}>
        {label}
      </Text>
    </a>
  );
}

function EligibilityPage() {
  const { setDocumentTitle } = useDocumentTitle();
  const {
    acceptedTerms,
    termsWarning,
    acceptTerms,
    handleClaim,
    handlePOIContinue,
    handleAgreeConsent,
    mintingTransactionHash,
    mintError,
    isWaitingForWallet,
    isLoadingNftMinting,
    loadingSkr,
    loadingVka,
    showTakeSelfieModal,
    setShowTakeSelfieModal,
    showConsentModal,
    setShowConsentModal,
    consentView,
    loadingSignConsent,
    errorMessage,
    error,
    eligibleError,
    poiCheckFailedError,
    consentError,
    resetErrors
  } = useEligibility();

  const agreeFontSize = useBreakpointValue({
    base: '10px',
    md: '12px'
  });
  const agreeLineHeight = useBreakpointValue({
    base: '14px',
    md: '16px'
  });
  const agreeTextAlign = useBreakpointValue({
    base: 'center',
    md: 'left'
  });
  const agreeTermsStyle = {
    fontWeight: 400,
    textAlign: agreeTextAlign,
    fontSize: agreeFontSize,
    lineHeight: agreeLineHeight,
    paddingLeft: '2px',
    color: '#A9A9A9'
  };

  const nftDimension = useBreakpointValue({ base: '360px', md: '460px' });

  useEffect(() => {
    setDocumentTitle(`${APP_NAME} - ${PAGES.ELIGIBLE_MINT_SK}`);
  }, []);

  if (!isLoadingNftMinting && !isWaitingForWallet && !loadingSkr && !loadingVka && !loadingSignConsent) {
    if (mintingTransactionHash) {
      setDocumentTitle(`${APP_NAME} - ${PAGES.ELIGIBLE_MINT_SK} - Success`);
      return <MintingCompleted />;
    } else if (mintError || eligibleError || error) {
      if (eligibleError) {
        setDocumentTitle(`${APP_NAME} - ${PAGES.ELIGIBLE_MINT_SK} - Not Eligible`);
      } else {
        setDocumentTitle(`${APP_NAME} - ${PAGES.ELIGIBLE_MINT_SK} - Error`);
      }
      return <MintingFailed onRetry={() => resetErrors()} errorMessage={errorMessage} />;
    } else if (consentError) {
      return <MintingFailed title={'Consent Request Failed'} onRetry={() => resetErrors()} errorMessage={errorMessage} />;
    } else if (poiCheckFailedError) {
      // setDocumentTitle(`${APP_NAME} - ${PAGES.ELIGIBLE_MINT_SK} - POI Check Failed`);
      return <POICheckFailed handleAction={() => resetErrors()} page={PAGES.ELIGIBLE_MINT_SK} />;
    } else {
      setDocumentTitle(`${APP_NAME} - ${PAGES.ELIGIBLE_MINT_SK}`);
    }
  }

  if (loadingSignConsent) {
    return <SigningConsentPage />;
  }

  return (
    <PagesBackground skStyle={true}>
      <Box flexDirection="column" justifyContent="flex-start" mt={{ base: 0, lg: '60px' }} maxWidth={'1216px'} width={'100%'}>
        <HStack justifyContent="center" alignItems="flex-start">
          <Flex
            direction={{ base: 'column', lg: 'row' }}
            w={{ base: '100%', xl: '1216px' }}
            justifyContent={'space-between'}
            pb={{ base: '0', lg: '128px' }}
            px={{ base: '20px', xl: '0' }}
          >
            <Stack alignItems="center" textAlign="center" alignSelf={'center'} display={{ base: 'block', lg: 'none' }} mb={'30px'}>
              <View w={nftDimension} h={nftDimension}>
                <ModelLoader model={'/3d/step-1/NFT.glb'} scale={1} stageEnv={'city'} stageIntensity={2}></ModelLoader>
              </View>
            </Stack>

            <Stack
              direction={'column'}
              w={'100%'}
              alignItems={{ base: 'center', md: 'flex-start' }}
              justifyContent={'center'}
              maxWidth={{ base: '100%', lg: '696px' }}
              mt={{ base: 0, md: '40px' }}
            >
              <Text
                fontWeight={400}
                fontSize={{ base: '16px', md: '20px' }}
                lineHeight={{ base: '24px', md: '32px' }}
                maxW={{ base: '320px', md: '100%' }}
                textAlign={{ base: 'center', md: 'left' }}
                mb={'12px'}
              >
                Claim your SelfKey iD
              </Text>
              <Heading
                fontWeight={800}
                lineHeight={{ base: '40px', md: '80px' }}
                fontSize={{ base: '32px', md: '72px' }}
                textAlign={{ base: 'center', md: 'left' }}
                mb={'40px'}
                fontFamily={'Plus Jakarta Sans'}
              >
                SoulBound <TextGradient>NFT</TextGradient>
              </Heading>
              <Box mb={'44px'}>
                <SkButton
                  eventName={`${PAGES.ELIGIBLE_MINT_SK} - Claim`}
                  onPress={handleClaim}
                  fixedSize={false}
                  w={{ base: '108px', md: '222px' }}
                  h={{ base: '40px', md: '48px' }}
                  isLoading={loadingSignConsent || isLoadingNftMinting || loadingSkr || loadingVka}
                >
                  Claim
                </SkButton>
              </Box>
              <VStack space={{ base: '16px', md: '22px' }}>
                <Text
                  fontWeight={700}
                  fontSize={{ base: '14px', md: '16px' }}
                  lineHeight={{ base: '20px', md: '24px' }}
                  textAlign={{ base: 'center', md: 'left' }}
                >
                  Network non-refundable 'gas fees' apply, set by the blockchain, not SelfKey.
                </Text>
                <Text
                  fontWeight={400}
                  fontSize={{ base: '14px', md: '16px' }}
                  lineHeight={{ base: '20px', md: '24px' }}
                  maxW={{ base: '100%', md: '592px' }}
                  textAlign={{ base: 'center', md: 'left' }}
                >
                  All transactions are final and irreversible; no refund, and no investment advice. DLTs involve recording and transmitting data immutably,
                  including personal information. Use of our services is at your own risk and discretion. For more details, refer to our
                  <Link href={'/terms-of-service'} label={'Terms and Conditions'} px={'4px'} />
                  and
                  <Link href={'/privacy-policy'} label={'Privacy Policy'} pl={'4px'} />.
                </Text>
                <View mt={{ base: '16px', md: '9px' }} mb={'64px'} alignItems={{ base: 'center', md: 'flex-start' }}>
                  <SkCheckbox acceptedTerms={acceptedTerms} acceptTerms={acceptTerms} alignItems={'flex-start'}>
                    <HStack w={{ base: '304px', md: '568px' }}>
                      <Text {...agreeTermsStyle} mt={'-5px'}>
                        By continuing, you'll be accepting the core SelfKey DAO agreement. Click
                        <Link href={'/selfkey-dao-governance-agreement'} label={'here'} px={'4px'} fontSize={{ base: '10px', md: '12px' }} />
                        to explore and start your journey.
                      </Text>
                    </HStack>
                  </SkCheckbox>
                  {termsWarning && (
                    <View mt={{ base: '16px', md: '24px' }} alignSelf={'left'}>
                      <Text fontSize={{ base: '14px', md: '16px' }} color={'#FF7575'} textAlign={{ base: 'center', md: 'left' }}>
                        You have to accept core SelfKey DAO agreement.
                      </Text>
                    </View>
                  )}
                </View>
              </VStack>
            </Stack>

            <Stack alignItems="center" textAlign="center" space="4" display={{ base: 'none', lg: 'block' }}>
              <View w={nftDimension} h={nftDimension}>
                <ModelLoader model={'/3d/step-1/NFT.glb'} scale={1} stageEnv={'city'} stageIntensity={2}></ModelLoader>
              </View>
            </Stack>
          </Flex>
        </HStack>
        <Stack alignItems="center" textAlign="center" mt={{ base: '80px', md: 0 }}>
          <JoinDiscordCard mb={{ base: '80px', md: '160px' }} />
        </Stack>
      </Box>
      <WarningModal
        showModal={showConsentModal}
        title={consentView}
        leftButton={
          <LdButton
            variant={'secondary'}
            fixedSize={false}
            w={'134px'}
            onPress={() => {
              setShowConsentModal(false);
            }}
          >
            Cancel
          </LdButton>
        }
        rightButton={
          <LdButton fixedSize={false} w={'134px'} onPress={() => handleAgreeConsent()}>
            Agree
          </LdButton>
        }
        handleClose={() => {
          setShowConsentModal(false);
        }}
      />
      <SelfieModal
        isOpen={showTakeSelfieModal}
        handlePOIContinue={handlePOIContinue}
        handleClose={() => {
          setShowTakeSelfieModal(false);
        }}
      />
    </PagesBackground>
  );
}

export { EligibilityPage };
