import React from 'react';
import { FeatureTitle } from './features_section';
import { LottieAnimationCard } from './lottie_animation_card';
import EasyInOnScroll from './easy-in-on-scroll';
import { useBreakpointValue } from 'native-base';
import howItWorksAnimation from '../assets/lotties/how_it_works.json';
import howItWorksAnimationMobile from '../assets/lotties/how_it_works_mobile.json';
import { SkLink } from './sk_link';

const SeeMoreLink = props => <SkLink text={'See More'} {...props} />;

export function HowItWorks({ link }) {
  const responsiveView = useBreakpointValue({
    base: <LottieAnimationCard animation={howItWorksAnimationMobile} />,
    md: <LottieAnimationCard animation={howItWorksAnimation} />
  });
  return (
    <EasyInOnScroll>
      <FeatureTitle title={'How it works'} pb={{ base: '32px', md: '64px' }} />
      {responsiveView}
      <SeeMoreLink url={link} mt={'12px'} />
    </EasyInOnScroll>
  );
}
