import React, { useContext } from 'react';
import { Web3WagmiContext } from '../../common/context/app_context';
import { useSignTypedData } from 'wagmi';
import { useHistory } from 'react-router-dom';
import { POICredentialTakeSelfie } from './modal_poi_credential_take_selfie';

function POICredentialTakeSelfieContainer() {
  const history = useHistory();
  const { activeAddress: address } = useContext(Web3WagmiContext);
  const { signTypedDataAsync } = useSignTypedData({});
  return <POICredentialTakeSelfie handleAction={() => history.push('/member/lock')} address={address} signTypedDataAsync={signTypedDataAsync} />;
}

export { POICredentialTakeSelfieContainer };
