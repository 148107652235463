import React, { useContext, useEffect, useState } from 'react';
import { useReadContract } from 'wagmi';
import { SelfTokenContext, Web3WagmiContext } from './app_context';
import { handleError } from '../lib/error_handler';
import selfTokenABI from '../assets/abis/SelfToken.json';
import selfBridgeABI from '../assets/abis/FxSelfChildTunnel.json';
import { CachePolicies, useFetch } from 'use-http';
import { current as envConfigs } from 'env-configs/selfkey-org';
import { formatUnits, parseEther } from 'viem';
import { useContractWriteAndWait } from '../hooks/use_contract_write_and_wait';

export function SelfTokenProvider({ children }) {
  const { activeAddress: address, isConnected, isConnecting: isAccountLoading, contracts, unsupportedChain, isPolygonNetwork } = useContext(Web3WagmiContext);

  const [selfBalance, setSelfBalance] = useState();

  const { get: getSelfMintingUnlocked, loading: loadingGetSelfMintingUnlocked } = useFetch(`${envConfigs.serverUrl}/governance/self-minting-unlocked`, {
    cachePolicy: CachePolicies.NETWORK_ONLY
  });
  const [isSelfMintingUnblocked, setIsSelfMintingUnblocked] = useState(false);
  const checkMintSelfUnblocked = async () => {
    const resp = await getSelfMintingUnlocked();
    setIsSelfMintingUnblocked(resp?.status);
  };
  useEffect(() => {
    checkMintSelfUnblocked();
  }, []);

  const {
    refetch: refetchSelfBalance,
    isLoading: isLoadingSelfBalance,
    isRefetching: isRefetchingSelfBalance,
    isFetched: isSelfBalanceFetched,
    data: selfBalanceData,
    status: selfBalanceStatus,
    error: selfBalanceError
  } = useReadContract({
    address: contracts?.selfToken,
    abi: selfTokenABI.abi,
    functionName: 'balanceOf',
    args: [address],
    query: {
      enabled: Boolean(!!address && !!contracts?.selfToken && isConnected && !isAccountLoading)
    }
  });
  useEffect(() => {
    if (typeof selfBalanceData === 'bigint') {
      if (selfBalanceStatus === 'success' || (!isRefetchingSelfBalance && isSelfBalanceFetched)) {
        const balance = formatUnits(selfBalanceData, 18);
        // console.log('[SELF TOKEN] balanceOf', selfBalanceData, balance);
        setSelfBalance(balance);
      } else if (selfBalanceStatus === 'error') {
        handleError('[SELF TOKEN] balanceOf', selfBalanceError);
        setSelfBalance(null);
      }
    }
  }, [selfBalanceStatus, isRefetchingSelfBalance, isSelfBalanceFetched, selfBalanceData]);

  const {
    write: selfMint,
    transaction: selfMintTransaction,
    completed: selfMintCompleted,
    waitingTransaction: waitingSelfMintTransaction,
    isLoading: isLoadingSelfMint,
    hasError,
    resetState: resetSelfMint
  } = useContractWriteAndWait({
    contractAddress: contracts?.selfToken,
    functionName: 'selfMint',
    contractAbi: selfTokenABI.abi
  });

  const callSelfMinting = async params => {
    console.log('[SELF TOKEN] Self Minting request', params);
    return await selfMint({
      args: [params.amount, params.param, params.timestamp, params.signer, params.signature]
    });
  };

  // SELF BRIDGE
  const {
    write: selfBridge,
    transaction: selfBridgeTransaction,
    completed: selfBridgeCompleted,
    waitingTransaction: waitingSelfBridgeTransaction,
    isLoading: isLoadingSelfBridge,
    isLoadingTransaction: isLoadingSelfBridgeTransaction,
    hasError: hasBridgeError,
    resetState: resetSelfBridge
  } = useContractWriteAndWait({
    contractAddress: isPolygonNetwork ? contracts?.selfBridge : contracts?.selfBridgeEth,
    functionName: 'bridge',
    contractAbi: selfBridgeABI.abi
  });

  const callSelfBridge = async amount => {
    console.log('[SELF BRIDGE] Self Bridge request', parseEther(amount));
    return await selfBridge({
      args: [parseEther(amount)]
    });
  };

  return (
    <SelfTokenContext.Provider
      value={{
        selfBalance,
        refetchSelfBalance,
        isLoadingSelfBalance: isLoadingSelfBalance || isRefetchingSelfBalance,
        waitingTransaction: waitingSelfMintTransaction,
        selfMinting: callSelfMinting,
        hasError,
        selfMintingCompleted: selfMintCompleted,
        selfMintingTransaction: selfMintTransaction,
        resetState: resetSelfMint,
        isLoading: isLoadingSelfMint || waitingSelfMintTransaction,
        loadingGetSelfMintingUnlocked,
        isSelfMintingUnblocked,
        // SELF BRIDGE
        waitingSelfBridgeTransaction,
        selfBridge: callSelfBridge,
        hasBridgeError,
        selfBridgeCompleted,
        selfBridgeTransaction,
        resetBridgeState: resetSelfBridge,
        isLoadingBridge: isLoadingSelfBridge || isLoadingSelfBridgeTransaction
      }}
    >
      {children}
    </SelfTokenContext.Provider>
  );
}
