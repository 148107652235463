import React from 'react';
import { Link, Text, View, VStack } from 'native-base';
import { PagesBackground } from '../../common/layout/main_layout.styled';
import { useHistory } from 'react-router-dom';
import SkButton from './components/sk_button';
import { ModelLoader } from '../../common/components/model_loader';
import { MainLayout } from '../../selfkey-id/pages/layout/main_layout';
import { APP_NAME, JOIN_DISCORD_URL, PAGES } from '../../common/lib/constants';
import useDocumentTitle from '../../common/hooks/use_document_title';

function RejectedPage({ showTerms, title, content, actionLabel, handleAction, skStyle, eventPage = PAGES.REJECTED, includeLayout = true }) {
  const Wrapper = includeLayout ? MainLayout : View;
  const history = useHistory();
  const { setDocumentTitle } = useDocumentTitle();

  React.useEffect(() => {
    setDocumentTitle(`${APP_NAME} - ${eventPage}`);
  }, []);

  const handleContactSupport = () => {
    window.open(JOIN_DISCORD_URL);
  };

  return (
    <Wrapper>
      <PagesBackground skStyle={skStyle}>
        <VStack alignItems={'center'} space={'40px'} mt={'16px'}>
          <ModelLoader model={`/3d/${skStyle ? 'step-1' : 'step-2'}/fail.glb`} scale={0.5}></ModelLoader>
          <Text fontSize={{ base: '18px', md: '48px' }} fontWeight={700} lineHeight={{ base: '22px', md: '60px' }} letterSpacing={'0em'} textAlign={'center'}>
            {title}
          </Text>
          <VStack alignItems={'center'}>
            <Text
              maxW={{ base: '280px', md: 'none' }}
              fontWeight={400}
              fontSize={'16px'}
              lineHeight={'24px'}
              textAlign={'center'}
              color={'#FFEDD2'}
              mb={'24px'}
            >
              {content}
            </Text>
            {showTerms && (
              <Link
                onPress={() => history.push('/terms-of-service')}
                _text={{
                  paddingLeft: '1',
                  fontSize: '16px',
                  fontWeight: 400,
                  lineHeight: '24px',
                  color: 'white',
                  textAlign: 'center'
                }}
              >
                Terms and conditions
              </Link>
            )}
          </VStack>
          {actionLabel && handleAction && (
            <SkButton eventName={`${eventPage} - ${actionLabel}`} onPress={handleAction} fixedSize={false} w={'182px'} mb={{ base: '140px', md: 0 }}>
              {actionLabel}
            </SkButton>
          )}
          <VStack alignItems={'center'} space={'24px'} mt={'23px'}>
            {title === 'Verification Failed!' && (
              <Text maxW={{ base: '280px', md: 'none' }} fontWeight={400} fontSize={'16px'} lineHeight={'24px'} textAlign={'center'}>
                Please contact Support if you think this message is an error.
              </Text>
            )}
            <SkButton eventName={`${title} - Contact Support`} fixedSize={false} w={'193px'} variant={'secondary'} onPress={handleContactSupport}>
              Contact Support
            </SkButton>
          </VStack>
        </VStack>
      </PagesBackground>
    </Wrapper>
  );
}

export { RejectedPage };
