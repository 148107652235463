import React, { useContext, useEffect, useState } from 'react';
import { LoadingContext, NftContext, Web3WagmiContext } from './app_context';
import nftABI from '../assets/abis/SelfkeyIdSoulboundNFT.json';
import { current as envConfigs } from 'env-configs/selfkey-org';
import { CachePolicies, useFetch } from 'use-http';
import * as Sentry from '@sentry/react';
import { handleError } from '../lib/error_handler';
import { useContractWriteAndWait } from '../hooks/use_contract_write_and_wait';

function NftProvider({ children }) {
  const { activeAddress: address, isConnected, isConnecting: isAccountLoading, contracts } = useContext(Web3WagmiContext);
  const { setIsWaitingForWallet, setIsLoadingNftMinting } = useContext(LoadingContext);
  const [hasNft, setHasNft] = useState(undefined);
  const [tokenId, setTokenId] = useState();

  const [previousAddress, setPreviousAddress] = useState(address);
  useEffect(() => {
    if (address && previousAddress !== address) {
      setHasNft(false);
      setPreviousAddress(address);
      resetMint();
    }
  }, [address]);

  const { get: getNftBalance, loading: isLoadingNft } = useFetch(`${envConfigs.serverUrl}/user/sbt`, { cachePolicy: CachePolicies.NETWORK_ONLY });
  const updateNftBalance = () => {
    getNftBalance(address)
      .then(response => {
        // console.log('[NFT] getNftBalance', response);
        const nftMinted = response?.balanceOf === 1;
        setHasNft(nftMinted);
        if (nftMinted) {
          setIsLoadingNftMinting(false);
          setTokenId(response.tokenId);
        }
      })
      .catch(e => {
        handleError('[NFT] getNftBalance', e);
        setHasNft(false);
      });
  };

  useEffect(() => {
    if (!!address && !!contracts?.selfkeyIdNft && isConnected && !isAccountLoading) {
      updateNftBalance();
    }
  }, [address, contracts?.selfkeyIdNft, isConnected, isAccountLoading]);

  const {
    write: mint,
    transaction: mintTransaction,
    waitingTransaction: waitingMintTransaction,
    isLoading: isLoadingMintingResponse,
    isLoadingTransaction: isLoadingMinting,
    hasError: hasMintError,
    resetState: resetMint
  } = useContractWriteAndWait({
    contractAddress: contracts?.selfkeyIdNft,
    functionName: 'mint',
    contractAbi: nftABI.abi,
    transactionCallback: () => {
      updateNftBalance();
    },
    errorCallback: () => {
      setIsLoadingNftMinting(false);
    }
  });

  const mintNft = async (to, params, timestamp, signer, signature) => {
    console.log('[NFT] mint request', { to, params, timestamp, signer, signature });
    setIsLoadingNftMinting(true);
    try {
      const response = await mint({
        args: [to, params, timestamp, signer, signature]
      });
      setIsWaitingForWallet(false);
      return response;
    } catch (e) {
      setIsLoadingNftMinting(false);
      return null;
    }
  };

  return (
    <NftContext.Provider
      value={{
        hasNft,
        refetchNft: updateNftBalance,
        nftAddress: contracts?.selfkeyIdNft,
        tokenId,
        isLoadingNft,
        refetchTokenId: updateNftBalance,
        mintNft,
        isLoadingMinting: isLoadingMinting || isLoadingMintingResponse || waitingMintTransaction,
        mintingTransactionHash: mintTransaction?.hash,
        mintError: hasMintError,
        clearMintError: resetMint
      }}
    >
      {children}
    </NftContext.Provider>
  );
}
export default Sentry.withProfiler(NftProvider);
