import { useMatomo } from '@datapunt/matomo-tracker-react';
import { useCallback } from 'react';
import { appEnv } from 'env-configs';

const useDocumentTitle = () => {
  const { trackPageView } = useMatomo();
  const setDocumentTitle = useCallback(title => {
    if (title) {
      document.title = title;
      if (appEnv !== 'production') {
        console.log('[DEBUG] trackPageView', title);
      }
      trackPageView({
        documentTitle: title
      });
    }
  }, []);
  return { setDocumentTitle };
};
export default useDocumentTitle;
