import React, { useState } from 'react';
import { LoadingContext } from './app_context';

export function LoadingProvider({ children }) {
  const [isWaitingForWallet, setIsWaitingForWallet] = useState(false);
  const [isLoadingNftMinting, setIsLoadingNftMinting] = useState(false);

  return (
    <LoadingContext.Provider
      value={{
        isWaitingForWallet,
        setIsWaitingForWallet,
        isLoadingNftMinting,
        setIsLoadingNftMinting
      }}
    >
      {children}
    </LoadingContext.Provider>
  );
}
