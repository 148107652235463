import React from 'react';
import { useLottie } from 'lottie-react';
import loadingAnimation from '../../assets/loading_vector.json';
import { Box } from 'native-base';

const ProgressBar = () => {
  const options = {
    animationData: loadingAnimation,
    loop: true
  };

  const { View } = useLottie(options);

  return (
    <Box maxW="600" w={'100%'} alignSelf={'center'}>
      {View}
    </Box>
  );
};

export default ProgressBar;
