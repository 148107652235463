import React from 'react';
import { Image, Modal, Stack, VStack } from 'native-base';
import alertIcon from '../assets/icons/alert-big.svg';

export default function WarningModal({ showModal, handleClose, title, leftButton, rightButton, actionButton }) {
  return (
    <Modal isOpen={showModal} onClose={handleClose} height="100vh" _backdrop={{ bg: '#030c1e', opacity: 0.9 }}>
      <Modal.Content
        w={'100%'}
        maxWidth={{ base: '350px', md: '560px' }}
        maxH={'680px'}
        borderRadius="16px"
        borderWidth="1px"
        borderColor="#0385E4"
        bg={'#000000'}
        shadow="2"
        p="40px"
        justifyContent={'center'}
      >
        <VStack space={'40px'} alignItems={'center'}>
          <Image
            source={{
              uri: alertIcon
            }}
            alt="Alert"
            w={{ base: '96px', md: '120px' }}
            h={{ base: '96px', md: '120px' }}
            resizeMode="contain"
          />
          <VStack w={{ base: '322px', md: '480px' }} space={'32px'} alignItems={'center'}>
            {title}
          </VStack>
          <Stack space={'24px'}>
            {actionButton && actionButton}
            <Stack space={{ base: '24px', md: '50px' }} justifyContent={'center'} direction={{ base: 'column', md: 'row' }}>
              {leftButton && leftButton}
              {rightButton && rightButton}
            </Stack>
          </Stack>
        </VStack>
      </Modal.Content>
    </Modal>
  );
}
