import styled from 'styled-components';

const ThanksForVoting = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  line-height: 20px;
`;
const SuccessmessageRoot = styled.div`
  position: relative;
  //top: 304px;
  //left: 564px;
  width: 245px;
  height: 20px;
  text-align: center;
  font-size: 14px;
  color: #48c281;
  font-family: Inter;
`;

const SuccessMessage = ({}) => {
  return (
    <SuccessmessageRoot>
      <ThanksForVoting>Thanks for Voting, you are awesome!</ThanksForVoting>
    </SuccessmessageRoot>
  );
};

export default SuccessMessage;
