import { Text, VStack } from 'native-base';
import React from 'react';

export const ConsentAgreement = ({ data }) => {
  return (
    <VStack alignItems={'center'} space={'40px'} mb={'20px'}>
      <Text fontWeight={400} fontSize={'16px'} lineHeight={'24px'} textAlign={'center'}>
        {data.consent_body}
      </Text>
      <VStack alignItems={'center'} space={'10px'}>
        <VStack>
          <Text fontWeight={400} fontSize={'12px'} lineHeight={'16px'} textAlign={'center'}>
            {data.issuer_name} Privacy Policy
          </Text>
          <a href={data.issuer_url} target="_blank" rel="noopener noreferrer" style={{ textDecorationColor: '#BAEBFF', textDecoration: 'none' }}>
            <Text fontWeight={400} fontSize={'12px'} lineHeight={'16px'} textAlign={'center'} color={'#BAEBFF'}>
              {data.issuer_url}
            </Text>
          </a>
        </VStack>
        <VStack>
          <Text fontWeight={400} fontSize={'12px'} lineHeight={'16px'} textAlign={'center'}>
            {data.relying_party_name} Privacy Policy
          </Text>
          <a href={data.relying_party_url} target="_blank" rel="noopener noreferrer" style={{ textDecorationColor: '#BAEBFF', textDecoration: 'none' }}>
            <Text fontWeight={400} fontSize={'12px'} lineHeight={'16px'} textAlign={'center'} color={'#BAEBFF'}>
              {data.relying_party_url}
            </Text>
          </a>
        </VStack>
      </VStack>
    </VStack>
  );
};
