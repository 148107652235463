import styles from './footer.module.css';
import { Stack, HStack, Image, Link, Text, useBreakpointValue, VStack } from 'native-base';
import React from 'react';
import { useHistory } from 'react-router-dom';
import facebook20Icon from './assets/facebook.svg';
import facebook24Icon from './assets/facebook1.svg';
import twitter20Icon from './assets/twitter.svg';
import twitter24Icon from './assets/twitter1.svg';
import linkedin20Icon from './assets/linkedin.svg';
import linkedin24Icon from './assets/linkedin1.svg';
import youtube20Icon from './assets/youtube.svg';
import youtube24Icon from './assets/youtube1.svg';
import telegram20Icon from './assets/telegram.svg';
import telegram24Icon from './assets/telegram1.svg';

function SocialIcons() {
  const iconSize = useBreakpointValue({
    base: '20px',
    lg: '24px'
  });
  const socialIconsSpace = useBreakpointValue({
    base: '24px',
    lg: '40px'
  });
  const facebookIcon = useBreakpointValue({
    base: facebook20Icon,
    lg: facebook24Icon
  });
  const twitterIcon = useBreakpointValue({
    base: twitter20Icon,
    lg: twitter24Icon
  });
  const linkedinIcon = useBreakpointValue({
    base: linkedin20Icon,
    lg: linkedin24Icon
  });
  const youtubeIcon = useBreakpointValue({
    base: youtube20Icon,
    lg: youtube24Icon
  });
  const telegramIcon = useBreakpointValue({
    base: telegram20Icon,
    lg: telegram24Icon
  });
  return (
    <HStack justifyContent={'center'} alignItems={'center'} space={socialIconsSpace}>
      {/*<Link href={JOIN_DISCORD_URL} isExternal _text={{ fontSize: 'xs' }} fontWeight="500">
              <Image src={discordIcon} alt="Alternate Text" w={iconSize} h={iconSize} />
            </Link>*/}
      <Link href="https://www.facebook.com/SelfKeyHQ/" isExternal _text={{ fontSize: 'xs' }} fontWeight="500">
        <Image src={facebookIcon} alt="Alternate Text" w={iconSize} h={iconSize} />
      </Link>
      <Link href="https://twitter.com/selfkey" isExternal _text={{ fontSize: 'xs' }} fontWeight="500">
        <Image src={twitterIcon} alt="Alternate Text" w={iconSize} h={iconSize} />
      </Link>
      <Link href="https://www.linkedin.com/company/selfkey/" isExternal _text={{ fontSize: 'xs' }} fontWeight="500">
        <Image src={linkedinIcon} alt="Alternate Text" w={iconSize} h={iconSize} />
      </Link>
      <Link href="https://www.youtube.com/channel/UCsilze3-MhbCY3_QkKul3PQ" isExternal _text={{ fontSize: 'xs' }} fontWeight="500">
        <Image src={youtubeIcon} alt="Alternate Text" w={iconSize} h={iconSize} />
      </Link>
      {/*<Link href="https://www.reddit.com/r/selfkey/" isExternal _text={{ fontSize: 'xs' }} fontWeight="500">
              <Image src={redditIcon} alt="Alternate Text" w={iconSize} h={iconSize} />
            </Link>*/}
      <Link href="https://t.me/selfkeyfoundation" isExternal _text={{ fontSize: 'xs' }} fontWeight="500">
        <Image src={telegramIcon} alt="Alternate Text" w={iconSize} h={iconSize} />
      </Link>
    </HStack>
  );
}
function SelfKeyLinks() {
  const history = useHistory();
  const today = new Date();
  const year = today.getFullYear();
  const fontSize = useBreakpointValue({
    base: '10px',
    lg: '12px'
  });
  return (
    <VStack width={'100%'} m="auto" alignItems="center" justifyContent={'center'} space={'16px'} mb={{ base: '40px', md: '80px' }}>
      <Text fontSize={fontSize} fontWeight="400" lineHeight={{ base: '14px', md: '16px' }} color="#7d7d7d" textAlign={'center'}>
        2017-{year} by Selfkey
      </Text>
      <Link onPress={() => history.push('/privacy-policy')} _text={{ fontSize: fontSize, fontWeight: '400', lineHeight: '16px', color: '#FFFFFF' }}>
        Privacy Policy
      </Link>
      <Link onPress={() => history.push('/terms-of-service')} _text={{ fontSize: fontSize, fontWeight: '400', lineHeight: '16px', color: '#FFFFFF' }}>
        Terms & Conditions
      </Link>
      <Link
        onPress={() => history.push('/selfkey-dao-governance-agreement')}
        _text={{ fontSize: fontSize, fontWeight: '400', lineHeight: '16px', color: '#FFFFFF' }}
      >
        SelfKey DAO Governance Agreement
      </Link>
    </VStack>
  );
}
export function Footer() {
  const footerLogoMarginTop = useBreakpointValue({
    base: '40px',
    lg: '80px'
  });
  return (
    <div>
      <div className={styles.footerDesktop}>
        <div className={styles.vectorParent}>
          <img className={styles.frameInner} alt="" src="/ellipse-footer.svg" />
          <div className={styles.frameChild1} />
        </div>
        <Stack
          width={'100%'}
          maxWidth={'1024px'}
          mx={'auto'}
          mt={footerLogoMarginTop}
          space={{ base: '56px', md: '56px' }}
          direction={{ base: 'column' }}
          justifyContent="space-between"
          alignItems={'center'}
        >
          <img className={styles.logoIcon4} alt="" src="/logo-footer.svg" />
          <SocialIcons />
          <SelfKeyLinks />
        </Stack>
      </div>
      <div className={styles.footerMobile}>
        <div className={styles.background}>
          <div className={styles.backgroundChild} />
          <img className={styles.backgroundItem} alt="" src="/ellipse-footer-mobile.svg" />
          <div className={styles.backgroundInner} />
        </div>
        <Stack
          width={'100%'}
          maxWidth={'1024px'}
          mx={'auto'}
          mt={footerLogoMarginTop}
          space="40px"
          direction={{ base: 'column' }}
          justifyContent="space-between"
          alignItems={'center'}
        >
          <img className={styles.logoIcon6} alt="" src="/logo-footer-mobile.svg" />
          <SocialIcons />
          <SelfKeyLinks />
        </Stack>
      </div>
    </div>
  );
}
