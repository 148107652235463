import React from 'react';
import styled from 'styled-components';
import { useBreakpointValue } from 'native-base';

export const PagesBackground = ({ skStyle, children, ...props }) => {
  return skStyle ? <PagesBackgroundSK {...props}>{children}</PagesBackgroundSK> : <PagesBackgroundLD {...props}>{children}</PagesBackgroundLD>;
};
export const PagesBackgroundSK = styled.div`
  ${({ paddingTop }) => {
    if (paddingTop) {
      return `padding-top: ${paddingTop};`;
    } else {
      return 'padding-top: 128px;';
    }
  }}
  min-height: 80vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-image: url('/bg_hexa_pattern.svg'), url('/bg-patterns.svg');
  background-repeat: no-repeat;
  background-position-x: center;
  background-color: rgba(0, 0, 0);
`;

export const PagesBackgroundLD = ({ children, ...props }) => {
  const bg = useBreakpointValue({
    base: <PagesBackgroundMobile {...props}>{children}</PagesBackgroundMobile>,
    md: <PagesBackgroundDesktop {...props}>{children}</PagesBackgroundDesktop>
  });
  return bg;
};

export const PagesBackgroundDesktop = styled.div`
  ${({ paddingTop }) => {
    if (paddingTop) {
      return `padding-top: ${paddingTop};`;
    } else {
      return 'padding-top: 128px;';
    }
  }}
  min-height: 80vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-image: url('/bg-pattern-left.svg'), url('/bg.svg'), url('/bg-pattern-right.svg');
  background-repeat: no-repeat;
  background-position: left top, center top, right top;
  background-blend-mode: overlay;
`;

export const PagesBackgroundMobile = styled.div`
  ${({ paddingTop }) => {
    if (paddingTop) {
      return `padding-top: ${paddingTop};`;
    } else {
      return 'padding-top: 96px;';
    }
  }}
  min-height: 80vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-image: url('/bg-pattern-left-mobile.svg'), url('/bg.svg'), url('/bg-pattern-right-mobile.svg');
  //background-size: auto, cover, auto;
  background-repeat: no-repeat;
  background-position: left top, center top, right top;
  background-blend-mode: overlay;
`;
