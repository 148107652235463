import { useState } from 'react';
import { isMetamaskAvailable } from '../lib/helpers';
import { handleError } from '../lib/error_handler';

export const useImportToken = ({ tokenType, tokenAddress, tokenSymbol, tokenDecimals, tokenImage, tokenId }) => {
  const [loading, setLoading] = useState(false);

  async function addToken() {
    try {
      // Check if MetaMask is installed
      if (isMetamaskAvailable()) {
        let params;
        if (tokenType === 'ERC20') {
          params = {
            type: tokenType,
            options: {
              address: tokenAddress,
              symbol: tokenSymbol,
              decimals: tokenDecimals,
              image: tokenImage
            }
          };
        } else if (tokenType === 'ERC721') {
          params = {
            type: tokenType,
            options: {
              address: tokenAddress,
              tokenId
            }
          };
        }
        setLoading(true);
        // Suggest the token to the MetaMask wallet
        const wasAdded = await window.ethereum.request({
          method: 'wallet_watchAsset',
          params
        });
        if (wasAdded) {
          console.log('Token was added to MetaMask');
        } else {
          console.log('Token was not added to MetaMask');
        }
      }
    } catch (error) {
      handleError('Error adding token to MetaMask', error);
    } finally {
      setLoading(false);
    }
  }

  return [loading, addToken];
};
