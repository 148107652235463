import React, { useState, useRef } from 'react';
import './scrollable_content.css';

const ScrollableContent = ({ children }) => {
  const [scrolling, setScrolling] = useState(false);
  const [scrollPosition, setScrollPosition] = useState({ left: 0, top: 0 });
  const imageRef = useRef(null);
  const containerRef = useRef(null);

  const handleMouseDown = e => {
    setScrolling(true);
    setScrollPosition({
      left: e.clientX,
      top: e.clientY,
      scrollLeft: containerRef.current.scrollLeft,
      scrollTop: containerRef.current.scrollTop
    });
  };

  const handleMouseUp = () => {
    setScrolling(false);
  };

  const handleMouseMove = e => {
    if (!scrolling) return;

    const deltaX = e.clientX - scrollPosition.left;
    const deltaY = e.clientY - scrollPosition.top;

    containerRef.current.scrollLeft = scrollPosition.scrollLeft - deltaX;
    containerRef.current.scrollTop = scrollPosition.scrollTop - deltaY;
  };

  return (
    <div className="scrollable-image-container" ref={containerRef}>
      <div className="scrollable-image" onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} onMouseMove={handleMouseMove} ref={imageRef}>
        {children}
      </div>
    </div>
  );
};

export default ScrollableContent;
