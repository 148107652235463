import { Checkbox, HStack, Icon, Link, Text, useBreakpointValue } from 'native-base';
import React from 'react';
import { useHistory } from 'react-router-dom';

export default function TermsAndConditionsCheckbox({ isChecked, onChange }) {
  const history = useHistory();
  const agreeTermsStyle = useBreakpointValue({
    base: {
      paddingLeft: '2px',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '20px',
      color: 'white'
    },
    md: {
      paddingLeft: '2px',
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '24px',
      color: 'white'
    }
  });
  return (
    <Checkbox
      isChecked={isChecked}
      onChange={onChange}
      alignSelf={{ base: 'center', md: 'left' }}
      letterSpacing={0}
      m={0}
      colorScheme={'green'}
      borderColor={'white'}
      borderWidth={'1px'}
      bg={'rgba(0, 0, 0, 0.2)'}
      _hover={{ borderColor: 'white' }}
      _icon={{ color: 'white' }}
      pr={0}
      icon={
        <Icon>
          <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 5.5L4 8L8.5 1" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </Icon>
      }
    >
      <HStack>
        <Text {...agreeTermsStyle}>I agree with</Text>
        <a href={'/terms-of-service'} target="_blank" rel="noopener noreferrer" style={{ textDecorationColor: '#FFFFFF' }}>
          <Text {...agreeTermsStyle}>Terms and Conditions</Text>
        </a>
        <Text {...agreeTermsStyle} pl={0} ml={0}>
          .
        </Text>
      </HStack>
    </Checkbox>
  );
}
