import { useState } from 'react';
import axios from 'axios';

const usePost = () => {
  const [status, setStatus] = useState(null);
  const [data, setData] = useState(null);
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const sendRequest = async (url, requestData, timeout) => {
    const configs = {};
    if (timeout) {
      configs.timeout = timeout;
    }
    setLoading(true);
    setStatus(null);
    setResponse(null);
    setData(null);
    setError(null);
    try {
      const response = await axios.post(url, requestData, configs);
      setStatus(response.data.status || 200);
      setResponse(response);
      setData(response.data);
      setError(null);
      return response.data;
    } catch (error) {
      setStatus(error?.response?.status);
      setResponse(error?.response);
      setData(null);
      setError(error?.response?.data?.error || error);
      return null;
    } finally {
      setLoading(false);
    }
  };

  return [sendRequest, { data, loading, error, response, status }];
};

export default usePost;
