import { appEnv } from 'env-configs';
import * as Sentry from '@sentry/react';
export const handleError = (message, exception) => {
  if (appEnv === 'development') {
    console.error(message, exception);
  } else {
    Sentry.captureMessage(message);
    Sentry.captureException(exception);
  }
};
