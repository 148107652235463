import React, { useEffect, useRef, useState } from 'react';
import { View } from 'native-base';

const EasyInOnScroll = ({ slideUp = false, children, duration = 0.5, translateX = '-10px', style }) => {
  const [isVisible, setVisible] = useState(false);
  const ref = useRef(null);
  const styles = slideUp ? createStylesSlideUp(duration) : createStyles(duration, translateX);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setVisible(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref]);

  return (
    <View ref={ref} style={[isVisible ? styles.inView : styles.outView, style]}>
      {children}
    </View>
  );
};

const createStyles = (duration, translateX) => ({
  inView: {
    opacity: 1,
    transform: 'none',
    transition: `opacity ${duration}s ease-in, transform ${duration}s ease-in`
  },
  outView: {
    opacity: 0,
    transform: `translateX(${translateX})`,
    transition: `opacity ${duration}s ease-out, transform ${duration}s ease-out`
  }
});
const createStylesSlideUp = duration => ({
  inView: {
    top: 0,
    opacity: 1,
    transition: `all .5s ease-in-out`,
    transitionDelay: `${duration}s`
  },
  outView: {
    position: 'relative',
    top: '100px',
    opacity: 0,
    transition: `all .5s ease-in-out`,
    transitionDelay: `${duration}s`
  }
});
export default EasyInOnScroll;
