import React from 'react';
import loadingIcon from '../../assets/icons/loading.png';
import styled, { keyframes } from 'styled-components';
import { Flex } from 'native-base';

const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const AnimatedImage = styled.img`
  animation: ${spin} 0.75s linear infinite;
`;

const Sk_spinner = props => {
  return (
    <Flex alignItems={'center'} {...props}>
      <AnimatedImage src={loadingIcon} w={'16px'} h={'16px'} alt={'loading...'} />
    </Flex>
  );
};

export default Sk_spinner;
