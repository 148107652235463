import { Box, Center, Heading, VStack } from 'native-base';
import { ModelLoader } from '../../../common/components/model_loader';
import ProgressBar from './progress_bar';
import React from 'react';

export function LoadingContainer({ model = '/3d/step-1/loading_cube.glb', scale = 0.3, verticalInclination = Math.PI / 3, content = 'Loading' }) {
  return (
    <Center w="100%" mt={'80px'} alignItems={'center'}>
      <Box w="90%">
        <Box h={{ base: '200px', md: '250px' }} alignSelf={'center'}>
          <ModelLoader model={model} scale={scale} verticalInclination={verticalInclination}></ModelLoader>
        </Box>
        {content}
        <ProgressBar />
      </Box>
    </Center>
  );
}
