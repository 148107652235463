import React, {FunctionComponent} from 'react';
import { useBreakpointValue } from 'native-base';
import AirdropClaimContainerMobile from './AirdropClaimContainerMobile'
import AirdropClaimContainer from './AirdropClaimContainer'

export type AirdropClaimComponentType = {
  selfAmount?: string;
  handleBack: () => void;
  handleClaim: () => void;
  loadingClaim: boolean;
};

const AirdropClaimComponent  = (props: AirdropClaimComponentType)=> {
  const AirdropClaim = useBreakpointValue({ base: AirdropClaimContainerMobile, lg: AirdropClaimContainer });
  return (
    <AirdropClaim {...props} />
  );
}

export { AirdropClaimComponent };
