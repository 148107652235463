import { PagesBackground } from '../layout/main_layout.styled';
import { Flex, Heading, Image, Text, useBreakpointValue, VStack } from 'native-base';
import React from 'react';
import SkButtonSK from '../../selfkey-id/pages/components/sk_button';
import SkButtonLD from '../../lockdao/pages/components/sk_button';
import { useHistory } from 'react-router-dom';
import Image404 from '../assets/images/404.png';
import { TextGradient } from '../../lockdao/pages/components/text_gradient';
import useDocumentTitle from '../hooks/use_document_title';

const Title = ({ children }) => (
  <Heading
    fontWeight={700}
    fontSize={{ base: '28px', md: '48px' }}
    lineHeight={{ base: '36px', md: '60px' }}
    textAlign={'center'}
    mb={{ base: '8px', md: '16px' }}
    fontFamily={'Plus Jakarta Sans'}
  >
    {children}
  </Heading>
);

export function NotFoundPage({ skStyle }) {
  const history = useHistory();
  const SkButton = skStyle ? SkButtonSK : SkButtonLD;
  const { setDocumentTitle } = useDocumentTitle();

  React.useEffect(() => {
    setDocumentTitle('SelfKey DAO - Not Found');
  }, []);

  const Header = useBreakpointValue({
    base: () => (
      <Title>
        Opps!
        <br /> Something went wrong!
      </Title>
    ),
    md: () => <Title>Opps! Something went wrong!</Title>
  });

  return (
    <PagesBackground skStyle={skStyle}>
      <VStack alignItems={'center'}>
        <TextGradient>
          <Header />
        </TextGradient>
        <Text
          fontWeight={400}
          fontSize={{ base: '14px', md: '16px' }}
          lineHeight={{ base: '20px', md: '24px' }}
          textAlign={'center'}
          mb={{ base: '24px', md: '4px' }}
        >
          The page you are looking for is not available
        </Text>
        <Image
          alt="Not Found"
          source={{ uri: Image404 }}
          w={{ base: '345px', md: '965px' }}
          h={{ base: '199px', md: '556px' }}
          mb={{ base: '48px', md: '34px' }}
        />
        <Flex alignItems={'center'}>
          <SkButton eventName={'Go to Homepage - Not Found Page'} fixedSize={false} onPress={() => history.push('/')} mb={{ base: '24px', md: '280px' }}>
            Go to Homepage
          </SkButton>
        </Flex>
      </VStack>
    </PagesBackground>
  );
}
