import React, { useState } from 'react';
import { HStack, Image, Menu, Pressable, Text } from 'native-base';
import arrowUp from '../assets/icons/arrow-up.svg';
import arrowDown from '../assets/icons/arrow-down.svg';
import styled from 'styled-components';

const SkScrollView = styled.div`
  max-height: 216px;
  margin-right: 12px;
  margin-top: 16px;

  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: linear-gradient(107.37deg, #00e0ff 0%, #2da1f8 100%);
    border-radius: 10px; // Rounded corners for the scrollbar thumb
  }
`;

const Dropdown = ({ selected, options, onChange, width }) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleSelectCurrency = currency => {
    setIsOpen(false);
    onChange(currency);
  };
  if (!selected || !options) {
    return null;
  }
  return (
    <Menu
      borderRadius={'16px'}
      bg={'rgba(0, 0, 0, 0.85)'}
      borderWidth={0}
      mt={1}
      pt={0}
      w={width ? width : '290px'}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      placement={'bottom'}
      trigger={triggerProps => {
        return (
          <Pressable
            {...triggerProps}
            onPress={() => {
              setIsOpen(!isOpen);
              triggerProps.onPress();
            }}
          >
            <HStack
              alignItems={'center'}
              justifyContent={'space-between'}
              background={'rgba(0, 0, 0, 0.2)'}
              borderWidth={'1px'}
              borderRadius={'50px'}
              borderColor={'#939393'}
              borderStyle={'solid'}
              h={{ base: '40px', md: '48px' }}
              pl={{ base: '14px', md: '24px' }}
              pr={'12px'}
            >
              <HStack alignItems={'center'} space={'8px'}>
                <Image source={{ uri: selected.icon }} w={'24px'} h={'24px'} alt={selected.label} />
                <HStack alignItems={'center'} space={'4px'}>
                  <Text fontWeight={400} fontSize={{ base: '14px', md: '16px' }} lineHeight={{ base: '20px', md: '24px' }} mr={'4px'}>
                    {selected.formatted} {selected.label}
                  </Text>
                  {selected.formattedOriginal && (
                    <Text
                      color={'#7D7D7D'}
                      fontSize={{ base: '14px', md: '16px' }}
                      lineHeight={{ base: '20px', md: '24px' }}
                      display={{ base: 'none', md: 'block' }}
                    >
                      <strike>
                        {selected.formattedOriginal} {selected.label}
                      </strike>
                    </Text>
                  )}
                  {selected.discount && (
                    <Text color={'#48C281'} fontSize={'12px'} lineHeight={'16px'}>
                      {selected.discount}% OFF
                    </Text>
                  )}
                </HStack>
              </HStack>
              <Image source={{ uri: isOpen ? arrowUp : arrowDown }} w={'16px'} h={'16px'} alt={'select currency'} />
            </HStack>
          </Pressable>
        );
      }}
    >
      <SkScrollView>
        {options.map(option => {
          return (
            <Menu.Item
              _hover={{ bg: 'rgba(0, 0, 0, 0)' }}
              _focus={{ bg: 'rgba(0, 0, 0, 0)' }}
              onPress={() => handleSelectCurrency(option)}
              px={{ base: '4px', md: '12px' }}
              py={'8px'}
              key={option.label}
            >
              <HStack alignItems={'center'} space={'8px'}>
                <Image source={{ uri: option.icon }} w={'24px'} h={'24px'} alt={option.label} />
                <HStack alignItems={'center'} space={'4px'}>
                  <Text fontWeight={400} fontSize={{ base: '14px', md: '16px' }} lineHeight={{ base: '20px', md: '24px' }} mr={'4px'}>
                    {option.formatted} {option.label}
                  </Text>
                  {option.formattedOriginal && (
                    <Text
                      color={'#7D7D7D'}
                      fontSize={{ base: '14px', md: '16px' }}
                      lineHeight={{ base: '20px', md: '24px' }}
                      display={{ base: 'none', md: 'block' }}
                    >
                      <strike>
                        {option.formattedOriginal} {option.label}
                      </strike>
                    </Text>
                  )}
                  {option.discount && (
                    <Text color={'#48C281'} fontSize={'12px'} lineHeight={'16px'}>
                      {option.discount}% OFF
                    </Text>
                  )}
                </HStack>
              </HStack>
            </Menu.Item>
          );
        })}
      </SkScrollView>
    </Menu>
  );
};

export default Dropdown;
