import React, { useContext, useState } from 'react';
import { Box, Flex, Heading, HStack, Image, Stack, Text } from 'native-base';
import { PagesBackground } from '../../common/layout/main_layout.styled';
import locksImage from '../assets/imgs/locks.svg';
import { TextGradient } from './components/text_gradient';
import SkButton from './components/sk_button';
import { useHistory } from 'react-router-dom';
import { getNftMetadata } from '../lib/metaproof_api';
import { NftContext } from '../../common/context/app_context';
import { parseNftCountry } from '../lib/nft_helpers';

function EligibilityStartPageContainer() {
  const history = useHistory();
  const { tokenId } = useContext(NftContext);
  const [isLoadingNftMetadata, setIsLoadingNftMetadata] = useState(false);

  const handleStart = async () => {
    setIsLoadingNftMetadata(true);
    const nftMetadata = await getNftMetadata({ tokenId });
    setIsLoadingNftMetadata(false);
    console.log('[NFT METADATA]', nftMetadata);
    const encryptedMetadata = JSON.parse(nftMetadata.content);
    const encryptedCountry = parseNftCountry(encryptedMetadata);
    if (encryptedCountry) {
      history.push(`/eligibility-country/${encryptedCountry}`);
    } else {
      history.push('/country-check-failed');
    }
  };
  return (
    <PagesBackground skStyle={true}>
      <Box flexDirection="column" justifyContent="flex-start" mt={{ base: '18px', lg: '111px' }} maxWidth={'1216px'} width={'100%'}>
        <HStack justifyContent="center" alignItems="flex-start">
          <Flex
            direction={{ base: 'column', lg: 'row' }}
            w={{ base: '100%', xl: '1216px' }}
            justifyContent={'space-between'}
            pb={{ base: '0', lg: '200px' }}
            px={{ base: '20px', xl: '0' }}
          >
            <Stack alignItems="center" textAlign="center" alignSelf={'center'} space="4" pb={'33px'} display={{ base: 'block', lg: 'none' }}>
              <Image
                source={{
                  uri: locksImage
                }}
                alt="Alternate Text"
                w={'240px'}
                h={'238px'}
                resizeMode="contain"
                alignSelf="right"
              />
            </Stack>

            <Stack direction={'column'} w={'100%'} alignItems={{ base: 'center', md: 'flex-start' }} maxWidth={{ base: '100%', lg: '696px' }}>
              <Heading
                fontWeight={800}
                lineHeight={{ base: 40, md: 60, lg: 80 }}
                fontSize={{ base: 32, md: 52, lg: 72 }}
                textAlign={{ base: 'center', md: 'left' }}
              >
                Invite your friends
                <br />
                and earn <TextGradient>10 SELF!</TextGradient>
              </Heading>
              <Text
                fontWeight={400}
                fontSize={{ base: '14px', md: '16px' }}
                lineHeight={{ base: '20px', md: '24px' }}
                color={'#FFEDD2'}
                py={{ base: '24px', md: '40px' }}
                maxW={{ base: '280px', md: '100%' }}
                textAlign={{ base: 'center', md: 'left' }}
              >
                Check to see if you're eligible to receive SELF tokens.
              </Text>
              <Box>
                <SkButton
                  eventName={'Start - Eligibility Start'}
                  onPress={handleStart}
                  size={'small'}
                  isLoading={isLoadingNftMetadata}
                  isDisabled={isLoadingNftMetadata}
                >
                  Start
                </SkButton>
              </Box>
            </Stack>

            <Stack alignItems="center" textAlign="center" space="4" display={{ base: 'none', lg: 'block' }}>
              <Image
                source={{
                  uri: locksImage
                }}
                alt="Alternate Text"
                w={'372px'}
                h={'420px'}
                resizeMode="contain"
                alignSelf="right"
              />
            </Stack>
          </Flex>
        </HStack>
      </Box>
    </PagesBackground>
  );
}

export { EligibilityStartPageContainer };
