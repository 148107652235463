import { HStack, Image, Text } from 'native-base';
import arrowFwd from '../assets/icons/arrow-smaller.svg';
import arrowFwdLD from '../assets/icons/arrow-smaller-ld.svg';
import React from 'react';

export const SkLink = ({ text = 'More Info', url, skStyle = true, ...props }) => {
  return (
    <a href={url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
      <HStack space={'6px'} alignItems={'center'} justifyContent={'center'} style={{ cursor: 'pointer' }} {...props}>
        <Text fontWeight={400} fontSize={{ base: '14px', md: '16px' }} lineHeight={{ base: '20px', md: '24px' }}>
          {text}
        </Text>
        <Image
          source={{
            uri: skStyle ? arrowFwd : arrowFwdLD
          }}
          alt={text}
          w="15px"
          h="12px"
        />
      </HStack>
    </a>
  );
};
