import React from 'react';
import { Stack } from 'native-base';
import ConnectorSwitcher from './connector_switcher';

export default function DisconnectButton({ isShortened, handleClick, skStyle = false }) {
  return (
    <Stack alignItems={'center'} direction={'row'} space={2} bgColor="transparent" borderRadius="12px" p={'2px'}>
      <ConnectorSwitcher handleClick={handleClick} isShortened={isShortened} skStyle={skStyle} />
    </Stack>
  );
}
