/**
 * helper function to pretty-print json object to string
 * @param {object} json
 * @returns {string}
 */
import { ethers } from 'ethers';
import { isChrome, isFirefox, isIOS, isMobile, isSafari } from 'react-device-detect';

function objectToString(json) {
  let text = '';
  text += json
    ? JSON.stringify(json)
        .replace(/{|}|"|\[|\]/g, '')
        .replace(/,/g, ', ')
    : '';
  text += '';
  return text;
}

function keccag256(data) {
  return ethers.utils.keccak256(ethers.utils.toUtf8Bytes(data));
}

function validateEmail(inputEmail) {
  const re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return re.test(inputEmail);
}

function downloadJsonFile(fileName, jsonData) {
  const json = JSON.stringify(jsonData);
  const blob = new Blob([json], { type: 'application/json' });
  const href = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = href;
  link.download = fileName + '.json';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
}

function msToTime(ms) {
  let seconds = (ms / 1000).toFixed(1);
  let minutes = (ms / (1000 * 60)).toFixed(1);
  let hours = (ms / (1000 * 60 * 60)).toFixed(1);
  let days = (ms / (1000 * 60 * 60 * 24)).toFixed(1);
  if (seconds < 60) return seconds + ' seconds';
  else if (minutes < 60) return minutes + ' minutes';
  else if (hours < 24) return hours + ' hours';
  else return days + ' days';
}

function decimalAdjust(type, value, exp) {
  type = String(type);
  if (!['round', 'floor', 'ceil'].includes(type)) {
    throw new TypeError("The type of decimal adjustment must be one of 'round', 'floor', or 'ceil'.");
  }
  exp = Number(exp);
  value = Number(value);
  if (exp % 1 !== 0 || Number.isNaN(value)) {
    return NaN;
  } else if (exp === 0) {
    return Math[type](value);
  }
  const [magnitude, exponent = 0] = value.toString().split('e');
  const adjustedValue = Math[type](`${magnitude}e${exponent - exp}`);
  // Shift back
  const [newMagnitude, newExponent = 0] = adjustedValue.toString().split('e');
  return Number(`${newMagnitude}e${+newExponent + exp}`);
}

// Decimal round
const round10 = (value, exp) => decimalAdjust('round', value, exp);
// Decimal floor
const floor10 = (value, exp) => decimalAdjust('floor', value, exp);
// Decimal ceil
const ceil10 = (value, exp) => decimalAdjust('ceil', value, exp);

function convertNumber(value, returnRounded = false) {
  let formattedValue, wasRounded;

  const checkRounding = (original, floored, precision) => {
    const originalStr = parseFloat(original);
    const flooredStr = parseFloat(floored);
    return {
      formattedValue: formatCurrency(floored, precision),
      wasRounded: originalStr !== flooredStr
    };
  };
  if (value < 0.000001) {
    ({ formattedValue, wasRounded } = checkRounding(value, floor10(value, -18), 18));
  } else if (value < 0.01) {
    ({ formattedValue, wasRounded } = checkRounding(value, floor10(value, -6), 6));
  } else if (value < 0.1) {
    ({ formattedValue, wasRounded } = checkRounding(value, floor10(value, -4), 4));
  } else if (value < 1_000_000) {
    ({ formattedValue, wasRounded } = checkRounding(value, floor10(value, -2), 2));
  } else if (value < 1_000_000_000) {
    let floored = floor10(value / 1_000_000, -2);
    formattedValue = floored + 'M';
    wasRounded = value / 1_000_000 !== floored;
  } else if (value < 1_000_000_000_000) {
    let floored = floor10(value / 1_000_000_000, -2);
    formattedValue = floored + 'B';
    wasRounded = value / 1_000_000_000 !== floored;
  } else {
    let floored = floor10(value / 1_000_000_000_000, -2);
    formattedValue = floored + 'T';
    wasRounded = value / 1_000_000_000_000 !== floored;
  }
  return returnRounded ? { formattedValue, wasRounded } : formattedValue;
}

function formatCurrency(value, maximumFractionDigits = 2, minimumFractionDigits = 1) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits,
    minimumFractionDigits
  });
  return formatter.format(value).substring(1);
}

function isMetamaskAvailable() {
  const provider = window.ethereum || window.web3?.currentProvider;
  return !!provider && !provider?.isBraveWallet;
}

function getDID(address) {
  return `did:ethr:${address}`;
}

function checkStyle(skStyle) {
  return window.location.pathname === '/' ? true : localStorage.getItem('selfkey_theme') !== null ? localStorage.getItem('selfkey_theme') === 'true' : skStyle;
}

function isMetaMaskMobile() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  return userAgent.includes('MetaMaskMobile');
}

function isBraveBrowser() {
  if (navigator.brave !== undefined) {
    return true;
  }
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  return /Brave/i.test(userAgent);
}

function notSupportedBrowser() {
  return ((isBraveBrowser() || isFirefox) && isMobile) || isMetaMaskMobile();
}

function isMetaMaskSupported() {
  return !notSupportedBrowser() && !(isMobile && (isSafari || isChrome));
}

function isWalletConnectSupported() {
  return !notSupportedBrowser() && !isMetaMaskMobile();
}

export {
  objectToString,
  keccag256,
  validateEmail,
  downloadJsonFile,
  msToTime,
  formatCurrency,
  convertNumber,
  isMetamaskAvailable,
  getDID,
  checkStyle,
  isBraveBrowser,
  isMetaMaskMobile,
  notSupportedBrowser,
  isMetaMaskSupported,
  isWalletConnectSupported
};
