import React, { useState } from 'react';
import { RoutingContext } from './app_context';

export function RoutingProvider({ children }) {
  const [route, setRoute] = useState();
  const [isRouting, setIsRouting] = useState(false);
  return (
    <RoutingContext.Provider
      value={{
        userRoute: route,
        setUserRoute: setRoute,
        isRouting,
        setIsRouting
      }}
    >
      {children}
    </RoutingContext.Provider>
  );
}
