import React, { useContext, useEffect, useState } from 'react';
import { Heading, Image, Link, Text, VStack } from 'native-base';
import { PagesBackground } from '../../common/layout/main_layout.styled';
import countryIcon from '../assets/icons/country-icon.svg';
import SkButton from './components/sk_button';
import { useHistory } from 'react-router-dom';
import { RestrictedCountriesContext, Web3WagmiContext } from '../../common/context/app_context';
import { CachePolicies, useFetch } from 'use-http';
import { current as envConfigs } from 'env-configs/selfkey-org';
import { handleError } from '../../common/lib/error_handler';

function EligibilityCountryPageContainer(props) {
  const history = useHistory();
  const { activeAddress: address } = useContext(Web3WagmiContext);
  const { post: isAllowedForCrypto, loading } = useFetch(`${envConfigs.serverUrl}/credential/allowed-for-crypto/did:ethr:${address}`, {
    retries: 1,
    retryDelay: 5000,
    retryOn({ attempt, error, response }) {
      console.warn(`[POI SERVICE - isAllowedForCrypto] Error #${attempt} - address ${address} - response:`, response, error);
      return response && response.status !== 200;
    },
    cachePolicy: CachePolicies.NETWORK_ONLY
  });
  const { setCountry, isRestrictedCountry } = useContext(RestrictedCountriesContext);
  const [errorMessage, setErrorMessage] = useState();

  const handleApprove = async () => {
    try {
      setErrorMessage(undefined);
      const encryptedCountry = props.match.params.country;
      console.log('[ENCRYPTED COUNTRY]', encryptedCountry);
      const country = await Window.ethereum?.request({ method: 'eth_decrypt', params: [encryptedCountry, address] });
      console.log('[DECRYPTED COUNTRY]', country);
      const allowed = await isAllowedForCrypto({ country });
      console.log('[COUNTRY ALLOWED]', allowed);
      if (allowed) {
        setCountry(country);
      } else {
        history.push('/rejected');
      }
    } catch (e) {
      handleError('Error decrypting country', e);
      setErrorMessage('Ops, something went wrong.');
    }
  };
  useEffect(() => {
    if (isRestrictedCountry !== undefined && !loading) {
      history.push(`/member/${isRestrictedCountry ? 'rejected' : 'invitation'}`);
    }
  }, [isRestrictedCountry, loading]);
  return (
    <PagesBackground skStyle={true}>
      <VStack space={'40px'}>
        <Image
          source={{
            uri: countryIcon
          }}
          alt="Verification in Progress"
          w={{ base: '120px', md: '200px' }}
          h={{ base: '120px', md: '200px' }}
          resizeMode="contain"
          alignSelf="center"
        />
        <Heading
          fontSize={{ base: '16px', md: '18px' }}
          fontWeight={700}
          lineHeight={{ base: '20px', md: '24px' }}
          textAlign={'center'}
          maxW={{ base: '320px', md: '696px' }}
        >
          We need to check your country of residence in order to determine if you are eligible to receive SELF tokens according to our{' '}
          <Link onPress={() => history.push('/terms-of-service')}>Terms of Service</Link>.
        </Heading>
        <Text textAlign={'center'} color={'#FFEDD2'}>
          Please grant access to the following data point(s):
        </Text>
        <Text fontSize={{ base: '16px', md: '18px' }} fontWeight={700} lineHeight={{ base: '20px', md: '24px' }} textAlign={'center'}>
          COUNTRY
        </Text>
        <SkButton eventName={'Approve - Eligibility Country'} onPress={handleApprove} size={'small'} isLoading={loading} isDisabled={loading}>
          Approve
        </SkButton>
        {errorMessage && (
          <Heading fontWeight={400} fontSize={{ base: '14px', lg: '16px' }} lineHeight={{ base: '14px', lg: '16px' }} color={'#FF7575'} textAlign={'center'}>
            {errorMessage}
          </Heading>
        )}
        <Text
          fontSize={{ base: '10px', md: '12px' }}
          fontWeight={400}
          lineHeight={{ base: '14px', md: '16px' }}
          textAlign={'center'}
          color={'#7D7D7D'}
          mb={'56px'}
        >
          Please see our{' '}
          <Link
            onPress={() => history.push('/privacy-policy')}
            _text={{
              color: 'white'
            }}
          >
            Privacy Policy
          </Link>
          .
        </Text>
      </VStack>
    </PagesBackground>
  );
}

export { EligibilityCountryPageContainer };
