import './index.css';
import React from 'react';

import App from './app.jsx';
import { NativeBaseProvider, extendTheme } from 'native-base';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';
import '@fontsource/inter/800.css';

import '@fontsource/mulish/300.css';
import '@fontsource/mulish/400.css';
import '@fontsource/mulish/500.css';
import '@fontsource/mulish/600.css';
import '@fontsource/mulish/700.css';
import '@fontsource/mulish/800.css';

import '@fontsource/lato/300.css';
import '@fontsource/lato/400.css';
// import '@fontsource/lato/500.css';
// import '@fontsource/lato/600.css';
import '@fontsource/lato/700.css';
// import '@fontsource/lato/800.css';
import '@fontsource/lato/900.css';

import '@fontsource/plus-jakarta-sans/300.css';
import '@fontsource/plus-jakarta-sans/400.css';
import '@fontsource/plus-jakarta-sans/500.css';
import '@fontsource/plus-jakarta-sans/600.css';
import '@fontsource/plus-jakarta-sans/700.css';
import '@fontsource/plus-jakarta-sans/800.css';

import { createRoot } from 'react-dom/client';
import { appEnv } from 'env-configs';
import { current as envConfigs } from 'env-configs/selfkey-org.js';
import Hotjar from '@hotjar/browser';

import './global.css';
import { handleError } from './common/lib/error_handler.js';

if (!!envConfigs.hotjarSiteId && !!envConfigs.hotjarVersion) {
  try {
    Hotjar.init(3679819, 6);
    // Hotjar.init(envConfigs.hotjarSiteId, envConfigs.hotjarVersion); // prevent weird error (fixed value for now)
  } catch (e) {
    handleError('Error initializing Hotjar', e);
  }
}

try {
  Sentry.init({
    dsn: 'https://864d5173be91c4d4dcddc05ee27ba9bf@o4507927333961728.ingest.de.sentry.io/4507927628152912',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.1,
    environment: appEnv
  });
} catch (e) {
  console.log('Error initializing sentry', e);
}

const theme = extendTheme({
  config: {
    initialColorMode: 'dark'
  },
  fonts: {
    heading: 'Plus Jakarta Sans',
    body: 'Inter',
    mono: 'Inter'
  },
  colors: {
    green: {
      50: '#f0fdf4',
      100: '#dcfce7',
      200: '#bbf7d0',
      300: '#86efac',
      400: '#4ade80',
      500: '#48C281',
      600: '#16a34a',
      700: '#15803d',
      800: '#166534',
      900: '#14532d'
    }
  }
});

if (typeof BigInt.prototype.toJSON !== 'function') {
  BigInt.prototype.toJSON = function () {
    return this.toString();
  };
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <NativeBaseProvider theme={theme}>
    <App />
  </NativeBaseProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
