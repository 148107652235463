import React, { useContext, useEffect, useState } from 'react';
import { RewardsContext, Web3WagmiContext } from './app_context';
import metaproofRewardsABI from '../../selfkey-id/config/MetaproofRewards.json';
import selfkeyInviteCodeRegistryABI from '../assets/abis/SelfkeyInviteCodeRegistry.json';
import selfkeyUnclaimedRegistryABI from '../assets/abis/SelfkeyUnclaimedRegistry.json';
import { useReadContract } from 'wagmi';
import { CachePolicies, useFetch } from 'use-http';
import { current as envConfigs } from 'env-configs/selfkey-org';
import { getDID } from '../lib/helpers';
import { handleError } from '../lib/error_handler';
import { useContractWriteAndWait } from '../hooks/use_contract_write_and_wait';

const REFETCH_REWARDS_INTERVAL = 5000;
export function RewardsProvider({ children }) {
  // const { hasNft } = useContext(NftContext);
  const [rewards, setRewards] = useState();
  // const [pollingRewards, setPollingRewards] = useState(false);
  const {
    get: _getRewards,
    loading: isRewardsLoading,
    error: errorRewardsLoading,
    abort: abortGetRewards
  } = useFetch(`${envConfigs.serverUrl}/rewards/balance-of-rewards`, {
    cachePolicy: CachePolicies.NETWORK_ONLY
  });
  const {
    post: authRewards,
    loading: loadingAuthRewards,
    error: errorAuthRewards
  } = useFetch(`${envConfigs.serverUrl}/rewards/task/auth`, {
    retries: 1,
    retryDelay: 5000,
    retryOn({ attempt, error, response }) {
      console.warn(`[REWARDS - authorization] Error #${attempt} - response:`, response, error);
      return response && response.status !== 200 && response.status !== 403;
    },
    cachePolicy: CachePolicies.NETWORK_ONLY
  });

  // let intervalId;
  // const startPolling = () => {
  //   abortGetRewards();
  //   intervalId = setInterval(getRewards, REFETCH_REWARDS_INTERVAL);
  // };
  // const stopPolling = () => {
  //   abortGetRewards();
  //   setPollingRewards(undefined);
  //   setRewards(undefined);
  //   clearInterval(intervalId);
  // };
  //
  const { activeAddress: address, baseAddress, isConnected, isConnecting: isAccountLoading, contracts } = useContext(Web3WagmiContext);
  // useEffect(() => {
  //   if (address !== baseAddress) {
  //     stopPolling();
  //   } else if (hasNft && address && (!pollingRewards || pollingRewards !== address)) {
  //     startPolling();
  //   }
  //
  //   return () => {
  //     stopPolling();
  //   };
  // }, [address, baseAddress, hasNft]);

  const [lockingActive, setLockingActive] = useState(undefined);
  const { get: getLockingStatus, loading: isLockingStatusLoading } = useFetch(`${envConfigs.serverUrl}/rewards/locking-status`, {
    cachePolicy: CachePolicies.NETWORK_ONLY
  });
  useEffect(() => {
    async function doAsync() {
      const response = await getLockingStatus(getDID(address));
      setLockingActive(!!response?.active);
    }
    if (address) {
      doAsync();
    }
  }, [address]);

  const getRewards = async () => {
    // setPollingRewards(address);
    if (!isRewardsLoading) {
      const response = await _getRewards(getDID(address));
      setRewards(response);
    }
  };
  const {
    post: submitRewards,
    loading: loadingSubmitRewards,
    error: errorSubmitRewards
  } = useFetch(`${envConfigs.serverUrl}/rewards/task/submit`, {
    retries: 1,
    retryDelay: 5000,
    retryOn({ attempt, error, response }) {
      console.warn(`[REWARDS - authorization] Error #${attempt} - response:`, response, error);
      return response && response.status !== 200 && response.status !== 403;
    },
    cachePolicy: CachePolicies.NETWORK_ONLY
  });

  // TODO check if it will be needed (legacy SK.iD rewards logic)
  const [rewardCode, setRewardCode] = useState();
  const [isRewardUsed, setIsRewardUsed] = useState();
  const [inputReward, setInputReward] = useState();
  const [isInputRewardValid, setIsInputRewardValid] = useState();

  const setInputRewardCode = async code => {
    setInputReward(code);
    setIsInputRewardValid(undefined);
    await refetchIsRewardCodeValid();
  };

  const {
    data: rewardCodeData,
    status: getRewardCodeStatus,
    error: getRewardCodeError
  } = useReadContract({
    address: contracts?.metaproofRewards,
    abi: metaproofRewardsABI.abi,
    functionName: 'getRewardCode',
    args: [address],
    query: {
      enabled: address && contracts?.metaproofRewards && isConnected && !isAccountLoading
    }
  });
  useEffect(() => {
    if (getRewardCodeStatus === 'success') {
      // console.log('[REWARDS] getRewardCode', address, rewardCodeData);
      setRewardCode(rewardCodeData);
    } else if (getRewardCodeStatus === 'error') {
      handleError('[REWARDS] getRewardCode', getRewardCodeError);
    }
  }, [getRewardCodeStatus]);

  const { refetch: refetchIsRewardUsed } = useReadContract({
    address: contracts?.metaproofRewards,
    abi: metaproofRewardsABI.abi,
    functionName: 'isRewardUsed',
    args: [address],
    onSuccess(data) {
      // console.log('[REWARDS] isRewardUsed', address, data);
      setIsRewardUsed(data);
    },
    onError(e) {
      handleError('[REWARDS] isRewardUsed', e);
    },
    query: {
      enabled: address && contracts?.metaproofRewards && isConnected && !isAccountLoading
    }
  });

  const {
    data: isRewardCodeValidData,
    status: getIsRewardCodeValidStatus,
    error: getIsRewardCodeValidError,
    refetch: refetchIsRewardCodeValid
  } = useReadContract({
    address: contracts?.metaproofRewards,
    abi: metaproofRewardsABI.abi,
    functionName: 'isRewardCodeValid',
    args: [inputReward],
    query: {
      enabled: inputReward !== undefined && contracts?.metaproofRewards && isConnected && !isAccountLoading
    }
  });
  useEffect(() => {
    if (getIsRewardCodeValidStatus === 'success') {
      // console.log('[REWARDS] isRewardCodeValid', inputReward, isRewardCodeValidData);
      setIsInputRewardValid(isRewardCodeValidData);
    } else if (getIsRewardCodeValidStatus === 'error') {
      handleError('[REWARDS] isRewardCodeValid', getIsRewardCodeValidError);
      setIsInputRewardValid(false);
    }
  }, [getIsRewardCodeValidStatus]);

  // Invite Code Rewards
  const {
    write: registerInviteCodeUsed,
    transaction: inviteCodeUsedTransaction,
    waitingTransaction: waitingInviteCodeUsedTransaction,
    isLoadingTransaction: isLoadingInviteCodeUsed,
    hasError: hasRedeemError,
    resetState: resetRegisterInviteCodeUsed
  } = useContractWriteAndWait({
    contractAddress: contracts?.inviteCodeRegistry,
    functionName: 'selfRegisterInviteCodeUsed',
    contractAbi: selfkeyInviteCodeRegistryABI.abi
  });

  const callInviteCodeUsed = async params => {
    console.log('[REGISTER INVITE CODE USED] invite code used request', params);
    return await registerInviteCodeUsed({
      args: [params.to, params.amount, params.param, params.timestamp, params.signer, params.signature]
    });
  };

  // Claim Rewards
  const {
    write: registerClaim,
    transaction: claimTransaction,
    waitingTransaction: waitingClaimTransaction,
    isLoadingTransaction: isLoadingClaimTransaction,
    hasError: hasClaimError,
    resetState: resetClaim
  } = useContractWriteAndWait({
    contractAddress: contracts?.unclaimedRewards,
    functionName: 'selfClaim',
    contractAbi: selfkeyUnclaimedRegistryABI.abi
  });

  const callClaim = async params => {
    console.log('[REGISTER CLAIM] claim request', params);
    return await registerClaim({
      args: [params.to, params.amount, params.param, params.timestamp, params.signer, params.signature]
    });
  };

  return (
    <RewardsContext.Provider
      value={{
        rewards,
        setRewards,
        getRewards,
        isRewardsLoading,
        errorRewardsLoading,
        authRewards,
        loadingAuthRewards,
        errorAuthRewards,
        submitRewards,
        loadingSubmitRewards,
        errorSubmitRewards,
        // TODO check if still needed
        rewardCode,
        isRewardUsed,
        setInputRewardCode,
        isInputRewardValid,
        refetchIsRewardUsed,
        lockingActive,
        isLockingStatusLoading,
        // Register Redeem Rewards
        callInviteCodeUsed,
        isLoadingInviteCodeUsed,
        waitingInviteCodeUsedTransaction,
        inviteCodeUsedTransaction,
        hasRedeemError,
        resetInviteCodeUsed: resetRegisterInviteCodeUsed,
        // Register Claim
        callClaim,
        isLoadingClaimTransaction,
        waitingClaimTransaction,
        claimTransaction,
        hasClaimError,
        resetClaim
      }}
    >
      {children}
    </RewardsContext.Provider>
  );
}
