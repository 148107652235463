import React, { useContext, useEffect, useState } from 'react';
import { VoteContext, Web3WagmiContext } from './app_context';
import voteABI from '../assets/abis/SelfkeyDaoVoting.json';
import { CachePolicies, useFetch } from 'use-http';
import { current as envConfigs } from 'env-configs/selfkey-org';
import { LoadingSpinner } from '../../selfkey-id/pages/components/loading_spinner';
import { useContractWriteAndWait } from '../hooks/use_contract_write_and_wait';

export function VoteProvider({ children }) {
  const { activeAddress: address, isConnected, isConnecting: isAccountLoading, contracts, unsupportedChain } = useContext(Web3WagmiContext);

  const { get: getActiveProposals } = useFetch(`${envConfigs.serverUrl}/governance/active-proposals`, { cachePolicy: CachePolicies.NETWORK_ONLY });
  const { get: getProposal, loading: loadingActiveProposal } = useFetch(`${envConfigs.serverUrl}/governance/proposal`, {
    cachePolicy: CachePolicies.NETWORK_ONLY
  });
  const [activeProposal, setActiveProposal] = useState(null);
  const getActiveProposal = async () => {
    const proposals = await getActiveProposals();
    if (proposals?.status === 'ok' && proposals?.activeProposals?.voteId) {
      const activeProposal = await getProposal(`${proposals.activeProposals.voteId}/${address}`);
      if (activeProposal && activeProposal.active) {
        setActiveProposal(activeProposal);
      }
    }
  };

  useEffect(() => {
    if (address) {
      getActiveProposal();
    }
  }, [address]);

  const [votes, setVotes] = useState(<LoadingSpinner />);
  const [showVoteButton, setShowVoteButton] = useState(true);
  useEffect(() => {
    if (activeProposal?.active) {
      if (activeProposal.votes) {
        const votesCount = activeProposal.votes;
        setVotes(votesCount < 100 ? 'Many' : votesCount);
      }
      setShowVoteButton(!!activeProposal.canVote);
    }
  }, [activeProposal]);

  const {
    write: vote,
    transaction: voteTransaction,
    waitingTransaction: waitingVoteTransaction,
    isLoading: isLoadingVote,
    isLoadingTransaction: isLoadingVoteTransaction,
    completed: voteCompleted,
    hasError,
    resetState: reset
  } = useContractWriteAndWait({
    contractAddress: contracts?.voting,
    functionName: 'vote',
    contractAbi: voteABI.abi
  });

  const callVote = async params => {
    console.log('[VOTE] vote request', params);
    return await vote({
      args: [params.to, params.amount, params.param, params.timestamp, params.signer, params.signature]
    });
  };

  return (
    <VoteContext.Provider
      value={{
        waitingVoteTransaction,
        vote: callVote,
        hasError,
        voteCompleted,
        voteTransaction,
        resetVoteState: reset,
        activeProposal,
        showVoteButton,
        votes,
        refetchActiveProposal: getActiveProposal,
        isLoadingActiveProposal: loadingActiveProposal
      }}
    >
      {children}
    </VoteContext.Provider>
  );
}
