import { Menu, Pressable, Text, useBreakpointValue } from 'native-base';
import React, { useContext, useEffect, useState } from 'react';
import { Web3WagmiContext } from '../../../common/context/app_context';
import metamaskFox from '../../assets/icons/metamask-icon.svg';
import walletConnectIcon from '../../assets/icons/wallet-connect-icon.svg';
import disconnectIcon from '../../../common/assets/icons/disconnect.svg';
import SkButtonLD from './sk_button';
import SkButtonSK from '../../../selfkey-id/pages/components/sk_button';
import { truncateAddress } from '../../../common/lib/wallet_helpers';
import { isMetamaskAvailable, isMetaMaskSupported, isWalletConnectSupported } from '../../../common/lib/helpers';
import { isAndroid, isIOS } from 'react-device-detect';
import { appEnv } from 'env-configs';

export default function ConnectorSwitcher({ isShortened, handleClick, skStyle = false }) {
  const { activeAddress: accountAddress, connector, connect, isConnected } = useContext(Web3WagmiContext);
  const [connectorIcon, setConnectorIcon] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleConnect = ({ type }) => {
    // TODO check if iOS or Android and redirect to proper store link
    if (isMetamaskAvailable()) {
      setIsOpen(false);
      connect(type);
    } else {
      if (isIOS) {
        window.open('https://apps.apple.com/us/app/metamask-blockchain-wallet/id1438144202');
      } else if (isAndroid) {
        window.open('https://play.google.com/store/apps/details?id=io.metamask&hl=en&gl=US');
      } else {
        window.open('https://metamask.io/');
      }
    }
  };

  useEffect(() => {
    if (connector?.id === 'metaMaskSDK' || connector?.id === 'io.metamask') {
      setConnectorIcon(metamaskFox);
    } else if (connector?.id === 'walletConnect') {
      setConnectorIcon(walletConnectIcon);
    }
  }, [connector]);

  const truncatedAddress = useBreakpointValue({
    base: truncateAddress(accountAddress, 12),
    md: truncateAddress(accountAddress, 23)
  });
  const fixedSize = useBreakpointValue({
    base: false,
    md: true
  });
  const SkButton = skStyle ? SkButtonSK : SkButtonLD;
  return (
    <Menu
      borderRadius={'16px'}
      bg={'rgba(0, 0, 0, 0.85)'}
      borderWidth={0}
      mt={1}
      minW={'178px'}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      placement={'bottom right'}
      crossOffset={isConnected ? -10 : 0}
      trigger={triggerProps => {
        return (
          <Pressable {...triggerProps}>
            {isConnected ? (
              <SkButton
                eventName={'Connected Dropdown'}
                fixedSize={fixedSize}
                size="small"
                variant="secondary"
                leftIcon={connectorIcon}
                onPress={() => {
                  setIsOpen(!isOpen);
                  triggerProps.onPress();
                }}
              >
                <Text isTruncated textAlign={{ base: 'center', md: 'left' }} fontSize="14px" fontWeight={700} lineHeight={'16px'}>
                  {accountAddress && isShortened ? truncatedAddress : accountAddress || ''}
                </Text>
              </SkButton>
            ) : (
              <SkButton
                eventName={'Connect Wallet'}
                fixedSize={fixedSize}
                size={'small'}
                onPress={() => {
                  setIsOpen(!isOpen);
                  triggerProps.onPress();
                }}
              >
                Connect Wallet
              </SkButton>
            )}
          </Pressable>
        );
      }}
    >
      {isMetaMaskSupported() && (
        <Menu.Item
          _hover={{ bg: 'rgba(0, 0, 0, 0)' }}
          _focus={{ bg: 'rgba(0, 0, 0, 0)' }}
          onPress={() => handleConnect({ type: 'metaMask' })}
          px={0}
          py={'6px'}
        >
          <SkButton
            eventName={'Metamask Connect'}
            size="small"
            variant="secondary"
            leftIcon={metamaskFox}
            onPress={() => handleConnect({ type: 'metaMaskSDK' })}
            w={'100%'}
          >
            <Text>Metamask</Text>
          </SkButton>
        </Menu.Item>
      )}
      {isWalletConnectSupported() && (
        <Menu.Item
          _hover={{ bg: 'rgba(0, 0, 0, 0)' }}
          _focus={{ bg: 'rgba(0, 0, 0, 0)' }}
          onPress={() => handleConnect({ type: 'walletConnect' })}
          px={0}
          py={'6px'}
        >
          <SkButton size="small" variant="secondary" leftIcon={walletConnectIcon} onPress={() => handleConnect({ type: 'walletConnect' })}>
            <Text>WalletConnect</Text>
          </SkButton>
        </Menu.Item>
      )}
      {isConnected && (
        <Menu.Item _hover={{ bg: 'rgba(0, 0, 0, 0)' }} _focus={{ bg: 'rgba(0, 0, 0, 0)' }} onPress={handleClick} px={0} py={'6px'}>
          <SkButton eventName={'Disconnect'} size="small" variant="secondary" leftIcon={disconnectIcon} onPress={handleClick} w={'100%'}>
            <Text>Disconnect</Text>
          </SkButton>
        </Menu.Item>
      )}
    </Menu>
  );
}
