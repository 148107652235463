import { Flex, Heading, VStack } from 'native-base';
import SkButton from '../../lockdao/pages/components/sk_button';
import discordIcon from '../assets/icons/discord.svg';
import React, { useState } from 'react';
import { SkInput } from './sk_input';
import { validateEmail } from '../lib/helpers';
import { subscribeToNewsletter } from '../api/newsletter';
import { JOIN_DISCORD_URL } from '../lib/constants';

export function SubscribeAndJoinSection(props) {
  const [email, setEmail] = useState('');
  const [subscribeError, setSubscribeError] = useState();
  const [subscribeSuccess, setSubscribeSuccess] = useState();
  const [success, setSuccess] = useState(false);
  const [subscribingNewsletter, setSubscribingNewsletter] = useState(false);
  const _setEmail = value => {
    setEmail(value);
    if (validateEmail(email)) {
      setSubscribeError(null);
    }
  };
  const handleSubscribeNewsletter = async () => {
    if (validateEmail(email)) {
      setSubscribingNewsletter(true);
      const ok = await subscribeToNewsletter(email);
      setSuccess(!!ok);
      if (!ok) {
        setSubscribeError('Something went wrong, please try again.');
      } else {
        setEmail("You're subscribed!");
        setSubscribeSuccess('Thank you for Subscribing to the SelfKey Newsletter!');
      }
      setSubscribingNewsletter(false);
    } else {
      setSubscribeError('Invalid email');
    }
  };
  const handleJoinDiscord = () => {
    window.open(JOIN_DISCORD_URL);
  };
  return (
    <Flex direction={{ base: 'columns', md: 'row' }} alignItems={{ base: 'center', md: 'flex-start' }} {...props}>
      <Flex direction={{ base: 'columns', md: 'row' }} alignItems={{ base: 'center', md: 'flex-start' }} mb={{ base: '40px', md: 0 }}>
        <Heading
          fontWeight={700}
          fontSize={{ base: '16px', md: '18px' }}
          lineHeight={{ base: '22px', md: '24px' }}
          fontFamily={'Plus Jakarta Sans'}
          maxW={{ base: '100%', md: '221px' }}
          mb={{ base: '16px', md: 0 }}
          mr={{ base: 0, md: '32px' }}
        >
          Subscribe to the SelfKey DAO Newsletter
        </Heading>
        <VStack alignItems={'center'} space={'10px'}>
          <SkInput
            eventName={'Subscribe to newsletter'}
            value={email}
            onChange={v => _setEmail(v)}
            placeholder={'Email address'}
            onSubmit={handleSubscribeNewsletter}
            isLoading={subscribingNewsletter}
            isDisabled={success}
            isChecked={success}
          />
          {!!subscribeError && (
            <Heading fontWeight={400} fontSize={'12px'} lineHeight={'16px'} color={'#FF7575'} textAlign={'center'}>
              {subscribeError}
            </Heading>
          )}
          {!!subscribeSuccess && (
            <Heading fontWeight={400} fontSize={'12px'} lineHeight={'16px'} color={'#48C281'} textAlign={'center'}>
              {subscribeSuccess}
            </Heading>
          )}
        </VStack>
      </Flex>
      <Flex direction={{ base: 'columns', md: 'row' }} alignItems={'center'} mb={{ base: '40px', md: 0 }} ml={{ base: 0, md: '100px' }}>
        <Heading
          fontWeight={700}
          fontSize={{ base: '16px', md: '18px' }}
          lineHeight={{ base: '22px', md: '24px' }}
          fontFamily={'Plus Jakarta Sans'}
          maxW={{ base: '100%', md: '142px' }}
          mb={{ base: '16px', md: 0 }}
          mr={{ base: 0, md: '32px' }}
        >
          Join Our Discord channel
        </Heading>
        <SkButton eventName={'Join Discord'} leftIcon={discordIcon} leftIconSize={'16px'} fixedSize={false} onPress={handleJoinDiscord}>
          Join Discord
        </SkButton>
      </Flex>
    </Flex>
  );
}
