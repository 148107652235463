import React, { useEffect, useMemo } from 'react';
import { Box, Flex, Heading, HStack, Spinner, Stack, Text, VStack } from 'native-base';
import { ConnectWallet } from '../../../common/components/connect_wallet';
import { TextGradient } from './text_gradient';
import { ModelLoader } from '../../../common/components/model_loader';
import { WhitepaperAndSocial } from '../../../common/components/whitepapper_and_social';
import EasyInOnScroll from '../../../common/components/easy-in-on-scroll';
import { CachePolicies, useFetch } from 'use-http';
import { current as envConfigs } from 'env-configs/selfkey-org';

const SubTitle = ({ children }) => (
  <Heading
    fontWeight={{ base: 700, md: 800 }}
    fontSize={{ base: 16, md: 20 }}
    lineHeight={{ base: 22, md: 25 }}
    color={'#BAEBFF'}
    textAlign={{ base: 'center', lg: 'left' }}
    fontFamily={'Plus Jakarta Sans'}
  >
    {children}
  </Heading>
);

export const HeroMvp = React.memo(() => {
  const { get: getGovernanceJoinCurrencies, loading: loadingGovernanceCurrencies } = useFetch(`${envConfigs.serverUrl}/governance/join`, {
    cachePolicy: CachePolicies.NETWORK_ONLY
  });
  const [hasPaymentFee, setHasPaymentFee] = React.useState(true);
  async function fetchCurrencies() {
    const currenciesResponse = await getGovernanceJoinCurrencies();
    if (!!currenciesResponse) {
      const noFee = currenciesResponse.length === 0 || currenciesResponse.some(currency => currency.value === 0);
      setHasPaymentFee(!noFee);
    }
  }
  useEffect(() => {
    fetchCurrencies();
  }, []);

  const priceLabel = loadingGovernanceCurrencies ? <Spinner color={'indigo.500'} /> : hasPaymentFee ? '- low price - limited time' : 'for FREE';

  const MemoizedModel = useMemo(() => <ModelLoader model={'/3d/step-1/NFT.glb'}></ModelLoader>, []);
  return (
    <Box flexDirection="column" justifyContent="flex-start" maxWidth={'1216px'} width={'100%'}>
      <HStack justifyContent="center" alignItems="flex-start">
        <Flex direction={{ base: 'column', lg: 'row' }} w={{ base: '100%', xl: '1216px' }} justifyContent={'space-between'} px={{ base: '20px', xl: '0' }}>
          <Stack
            alignItems="center"
            textAlign="center"
            alignSelf={'center'}
            space="4"
            pb={'33px'}
            display={{ base: 'block', lg: 'none' }}
            w={'320px'}
            h={'380px'}
          >
            {MemoizedModel}
          </Stack>

          <Stack direction={'column'} w={'100%'} align={'center'} maxWidth={{ base: '100%', lg: '696px' }}>
            <EasyInOnScroll duration={0.2}>
              <Heading
                fontWeight={800}
                lineHeight={{ base: 40, md: 60, lg: 80 }}
                fontSize={{ base: 32, md: 52, lg: 72 }}
                textAlign={{ base: 'center', lg: 'left' }}
                fontFamily={'Plus Jakarta Sans'}
              >
                SELFKEY <TextGradient>DAO</TextGradient>
              </Heading>
            </EasyInOnScroll>
            <EasyInOnScroll duration={0.3}>
              <Text
                fontWeight={400}
                fontSize={{ base: 16, md: 20 }}
                lineHeight={{ base: 24, md: 32 }}
                color={'#BAEBFF'}
                textAlign={{ base: 'center', lg: 'left' }}
                mt={'16px'}
                mb={'24px'}
                alignSelf={{ base: 'center', lg: 'flex-start' }}
                maxW={{ base: '280px', md: '100%' }}
              >
                Decentralized Self-Sovereign Identity Network
              </Text>
            </EasyInOnScroll>
            <EasyInOnScroll duration={0.3} style={{ marginBottom: '24px' }}>
              <VStack alignItems={{ base: 'center', md: 'left' }} textAlign={{ base: 'center', lg: 'left' }}>
                <TextGradient>
                  <SubTitle>Get the SelfKey iD {priceLabel}!</SubTitle>
                </TextGradient>
              </VStack>
            </EasyInOnScroll>
            <EasyInOnScroll duration={0.4}>
              <ConnectWallet mvp={true} />
            </EasyInOnScroll>
            <EasyInOnScroll duration={0.5} translateX={'-15px'}>
              <WhitepaperAndSocial mt={'40px'} justifyContentDesktop={'flex-start'} />
            </EasyInOnScroll>
          </Stack>

          <Stack alignItems="center" textAlign="center" space="4" display={{ base: 'none', lg: 'block' }} w={'440px'} h={'440px'}>
            {MemoizedModel}
          </Stack>
        </Flex>
      </HStack>
    </Box>
  );
});
