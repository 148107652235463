import React, { useState, useEffect } from 'react';
import { HStack, Text } from 'native-base';
import { MechanicalCounter } from 'mechanical-counter';

const Countdown = ({ time, onComplete }) => {
  const [remainingTime, setRemainingTime] = useState(Math.ceil(time / 1000)); // Convert to seconds

  useEffect(() => {
    if (remainingTime <= 0) {
      onComplete();
      return;
    }

    const intervalId = setInterval(() => {
      setRemainingTime(prevTime => prevTime - 1);
    }, 1000);

    return () => clearInterval(intervalId); // Cleanup interval on component unmount or time change
  }, [remainingTime, onComplete]);

  const formatTime = seconds => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const minutesStr = `${minutes > 0 ? minutes + ' minute' : ''}${minutes > 1 ? 's' : ''}${minutes > 0 ? ' and ' : ''}`;
    const secondsStr = `${remainingSeconds > 0 ? remainingSeconds + ' second' : ''}${remainingSeconds > 1 ? 's' : ''}`;
    return `The link will expire in ${minutesStr}${secondsStr}`;
  };

  return (
    <HStack justifyContent={'center'}>
      {remainingTime > 0 ? (
        <Text>
          <MechanicalCounter text={formatTime(remainingTime)} height={'2em'} />
        </Text>
      ) : (
        <Text>Time's up!</Text>
      )}
    </HStack>
  );
};

export default Countdown;
