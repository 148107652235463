import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import ScrollToTop from '../../selfkey-id/pages/components/scroll_to_top';
import { LoadingContext, NftContext, RegistryContext, RewardsContext, RoutingContext, Web3WagmiContext } from '../context/app_context';
import { Box, Center, Text, VStack } from 'native-base';
import ProgressBar from '../../selfkey-id/pages/components/progress_bar';
import { PagesBackground } from './main_layout.styled';
import { UnsupportedNetwork } from '../components/unsupported_network';
import { isSelfKeyDaoServiceOnline, isSelfKeyDaoUnderMaintenance } from '../../selfkey-id/lib/metaproof_api';
import { OfflinePageContainer } from '../components/offline_page_container';
import { MainLayout } from '../../selfkey-id/pages/layout/main_layout';
import { ModelLoader } from '../components/model_loader';
import { SkWaitingTransaction } from '../../lockdao/pages/components/sk_waiting_transaction';
import { MaintenancePage } from '../components/maintenance-page';
import { appEnv } from 'env-configs';
import { NotFoundPage } from '../components/not-found-page';
import { SelfieModal } from '../components/selfie_modal';
import { POICheckFailed } from '../components/poi_check_failed';
import { checkStyle } from '../lib/helpers';

export function RouteWithLayout({ skStyle = false, prodReady = true, ...props }) {
  const [isServiceDown, setIsServiceDown] = useState(false);
  const [isUnderMaintenance, setIsUnderMaintenance] = useState(false);
  const { layout: Layout, component: Component, mvp, isSecure, ...rest } = props;
  const { activeAddress: address, baseAddress, isConnecting, unsupportedChain } = useContext(Web3WagmiContext);
  const { hasNft, isLoadingNft, isLoadingUserStatus: isLoadingAuthorization } = useContext(NftContext);
  const { userRoute, isRouting } = useContext(RoutingContext);
  const { isWaitingForWallet, isLoadingNftMinting } = useContext(LoadingContext);
  const { getRewards, lockingActive, isLockingStatusLoading } = useContext(RewardsContext);
  const { showTakeSelfieModal, setShowTakeSelfieModal, poiCheckFailedError, handlePOIContinue, setPoiCheckFailedError } = useContext(RegistryContext);

  const checkMetaProofServiceHealth = async () => {
    const isOnline = await isSelfKeyDaoServiceOnline();
    setIsServiceDown(!isOnline);
    const underMaintenance = await isSelfKeyDaoUnderMaintenance();
    setIsUnderMaintenance(underMaintenance);
  };

  const handlePOICheckError = () => {
    setShowTakeSelfieModal(true);
    setPoiCheckFailedError(false);
  };

  useEffect(() => {
    const doAsync = async () => {
      await checkMetaProofServiceHealth();
    };
    doAsync();
  }, []);

  useEffect(() => {
    if (hasNft && address) {
      getRewards();
    }
  }, [hasNft, address, props.path]);

  useEffect(() => {
    if (userRoute && !isRouting) {
      localStorage.setItem('selfkey_theme', !userRoute.startsWith('/member'));
    }
  }, [userRoute, isRouting]);
  const isSkStyle = checkStyle(skStyle);

  const MemoizedModel = useMemo(() => <ModelLoader model={'/3d/step-1/loading_cube.glb'} scale={0.3} verticalInclination={Math.PI / 3}></ModelLoader>, []);
  const notAuthorized = !address && !isRouting && !isLoadingNft && !isConnecting && !baseAddress;
  if (isServiceDown) {
    return <OfflinePageContainer retry={checkMetaProofServiceHealth} skStyle={isSkStyle} />;
  }
  if (isUnderMaintenance) {
    return <MaintenancePage skStyle={isSkStyle}></MaintenancePage>;
  }
  if (!prodReady && appEnv === 'production') {
    return <NotFoundPage skStyle={isSkStyle}></NotFoundPage>;
  }
  if (unsupportedChain && !notAuthorized && props.path !== '/member/bridge-self') {
    return <UnsupportedNetwork skStyle={isSkStyle} />;
  }
  const stakingRoute = props.path === '/member/lock' || props.path === '/member/withdraw';
  if (stakingRoute && !isLockingStatusLoading && lockingActive === false) {
    return <Redirect to={'/member'} />;
  }
  if (showTakeSelfieModal) {
    return (
      <MainLayout skStyle={isSkStyle}>
        <PagesBackground skStyle={isSkStyle}>
          <SelfieModal isOpen={showTakeSelfieModal} handlePOIContinue={handlePOIContinue} />
        </PagesBackground>
      </MainLayout>
    );
  }
  if (isSecure) {
    if (notAuthorized) {
      return <Redirect to={'/'} />;
    } else if (userRoute && (!props.path.startsWith(userRoute) || (props.path === '/mint-sbt' && userRoute === '/member')) && userRoute !== '/not-eligible') {
      return <Redirect to={userRoute} />;
    } else if (
      (!userRoute || isRouting || isLoadingAuthorization || isLoadingNft || isLockingStatusLoading) &&
      userRoute !== '/initiate-verification' &&
      userRoute !== '/verifying' &&
      props.path !== '/member/bridge-self'
    ) {
      return (
        <MainLayout skStyle={isSkStyle}>
          <PagesBackground skStyle={isSkStyle}>
            <Center w="100%" h="100%" alignSelf={'flex-start'} mt={'80px'} alignItems={'center'}>
              <Box w="90%" maxW="400">
                <Box h={{ base: '200px', md: '250px' }} alignSelf={'center'}>
                  {MemoizedModel}
                </Box>
                <Text fontSize={{ base: '16px', lg: '22px' }} fontWeight={700} textAlign={'center'} mb={'24px'} mt={'40px'}>
                  Loading...
                </Text>
                <ProgressBar />
              </Box>
            </Center>
          </PagesBackground>
        </MainLayout>
      );
    } else if (!hasNft && (isWaitingForWallet || isLoadingNftMinting)) {
      return (
        <SkWaitingTransaction
          textWaiting={'Please approve the transaction on your wallet.'}
          isWaitingForTransaction={isLoadingNftMinting}
          showNetworkSpeedMessage={isLoadingNftMinting}
          fullLayout={true}
        />
      );
    }
  }

  if (poiCheckFailedError) {
    return <POICheckFailed handleAction={handlePOICheckError} />;
  }

  return (
    <Route
      {...rest}
      render={matchProps => (
        <>
          <ScrollToTop />
          <Layout activeTab={props.activeTab} publicAccess={props.publicAccess} mvp={mvp} skStyle={isSkStyle}>
            <Component {...matchProps} publicAccess={props.publicAccess} mvp={mvp} skStyle={isSkStyle} />
          </Layout>
        </>
      )}
    />
  );
}

export default RouteWithLayout;
