import React from 'react';
import { Box } from 'native-base';
import { HeaderBar } from '../../../common/components/header_bar';
import { Footer } from '../../../common/components/footer';
import { Body } from './main_layout.styled';

export function MainLayout({ skStyle = true, ...props }) {
  return (
    <Body mvp={props.mvp}>
      <Box justifyContent={'center'}>
        <HeaderBar mvp={props.mvp} skStyle={skStyle} />
        <>
          {props.children}
          <Footer mvp={props.mvp} />
        </>
      </Box>
    </Body>
  );
}
