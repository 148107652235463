import { useState, useEffect, useContext } from 'react';
import { ethers } from 'ethers';
import { parseUnits } from 'viem';
import { CachePolicies, useFetch } from 'use-http';
import { convertNumber, downloadJsonFile } from '../../common/lib/helpers';
import { StakingContext, Web3WagmiContext } from '../../common/context/app_context';
import useSkrProcess from '../../common/hooks/use_skr_flow';
import { current as envConfigs } from 'env-configs/selfkey-org';
import { useHistory } from 'react-router-dom';

const useStakingPage = () => {
  const history = useHistory();
  const {
    minStakeAmount,
    isLoadingMinStakeAmount,
    keyBalance,
    refetchKeyBalance,
    isRefetchingKeyBalance,
    setStakePayload,
    stake,
    keyAllowance,
    approveKeyAmount,
    isLoadingStaking,
    isWaitingAllowanceApproval,
    isWaitingStakingApproval,
    stakingTotal,
    loadingStakingTotal,
    hasError,
    stakingTransaction,
    clearTransactions
  } = useContext(StakingContext);

  const { activeAddress: address } = useContext(Web3WagmiContext);
  const [keyToStake, setKeyToStake] = useState('');
  const [isMaxAmountPending, setIsMaxAmountPending] = useState(false);
  const [minAmountWarning, setMinAmountWarning] = useState(false);
  const [maxAmountWarning, setMaxAmountWarning] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [displayAcceptTermsWarning, setDisplayAcceptTermsWarning] = useState(false);

  const { get: getPOICredential } = useFetch(`${envConfigs.serverUrl}/credential/selfkey-poi`, { cachePolicy: CachePolicies.NETWORK_ONLY });

  const {
    getSkr,
    skr,
    loadingSkr,
    getVka,
    loadingVka,
    showTakeSelfieModal,
    setShowTakeSelfieModal,
    showConsentModal,
    setShowConsentModal,
    consentView,
    loadingSignConsent,
    handleAgreeConsent,
    errorMessage,
    error,
    eligibleError,
    poiCheckFailedError,
    resetErrors
  } = useSkrProcess(
    address,
    data => {
      if (keyAllowance < parseFloat(keyToStake)) {
        const amount = ethers.utils.parseUnits(keyToStake, 18);
        approveKeyAmount(amount);
        setStakePayload(data.to, data.amount, data.param, data.timestamp, data.signer, data.signature);
      } else {
        stake(data.to, data.amount, data.param, data.timestamp, data.signer, data.signature);
      }
    },
    (success, error) => {
      if (success) {
        handleStakeNow();
      }
    }
  );

  const acceptTerms = checked => {
    setAcceptedTerms(checked);
    if (checked) {
      setDisplayAcceptTermsWarning(false);
    }
  };

  const setMaxAmount = () => {
    setIsMaxAmountPending(true);
    refetchKeyBalance();
  };

  const validateMinStakeAmount = amount => {
    return parseFloat(amount || 0) >= parseFloat(minStakeAmount) - parseFloat(stakingTotal);
  };

  const handleStakeNow = async () => {
    if (!acceptedTerms) {
      setDisplayAcceptTermsWarning(true);
    }
    const amountValid = validateMinStakeAmount(keyToStake) && parseFloat(keyToStake) <= parseFloat(keyBalance);
    setMinAmountWarning(!amountValid);
    if (acceptedTerms && amountValid) {
      const amount = parseUnits(keyToStake, 18).toString();
      await getSkr(`${envConfigs.serverUrl}/rewards/staking-request/${address}`, { amount });
    }
  };

  const handleStakingCompleteContinue = () => {
    clearTransactions();
    setKeyToStake('');
    setAcceptedTerms(false);
    resetErrors();
  };

  const handleDownloadCredential = async () => {
    const cred = await getPOICredential(address);
    downloadJsonFile(`poi-${address}`, cred);
  };

  const handleNotEligibleOk = () => {
    resetErrors();
    history.push('/member');
  };

  const handlePOIContinue = async base64Selfie => {
    setShowTakeSelfieModal(false);
    await getVka(skr.SKR.url, { SKR: skr.SKR, selfie: base64Selfie });
  };

  useEffect(() => {
    if (isMaxAmountPending && !isRefetchingKeyBalance && keyBalance) {
      setIsMaxAmountPending(false);
      setKeyToStake(keyBalance);
      setMinAmountWarning(false);
      setMaxAmountWarning(false);
    }
  }, [isMaxAmountPending, isRefetchingKeyBalance, keyBalance]);

  const [formattedStakingTotal, setFormattedStakingTotal] = useState({ formattedValue: null, wasRounded: false });
  useEffect(() => {
    setFormattedStakingTotal(convertNumber(stakingTotal, true));
  }, [stakingTotal]);

  return {
    minStakeAmount,
    isLoadingMinStakeAmount,
    keyBalance,
    isRefetchingKeyBalance,
    keyToStake,
    setKeyToStake,
    isMaxAmountPending,
    setIsMaxAmountPending,
    minAmountWarning,
    setMinAmountWarning,
    maxAmountWarning,
    setMaxAmountWarning,
    acceptedTerms,
    setAcceptedTerms,
    displayAcceptTermsWarning,
    setDisplayAcceptTermsWarning,
    showTakeSelfieModal,
    setShowTakeSelfieModal,
    showConsentModal,
    setShowConsentModal,
    consentView,
    loadingSignConsent,
    errorMessage,
    error,
    eligibleError,
    poiCheckFailedError,
    formattedStakingTotal,
    loadingSkr,
    loadingVka,
    isLoadingStaking,
    isWaitingAllowanceApproval,
    isWaitingStakingApproval,
    stakingTransaction,
    hasError,
    loadingStakingTotal,
    stakingTotal,
    acceptTerms,
    setMaxAmount,
    validateMinStakeAmount,
    handleStakeNow,
    handleStakingCompleteContinue,
    handleDownloadCredential,
    handleNotEligibleOk,
    handlePOIContinue,
    handleAgreeConsent
  };
};

export default useStakingPage;
