import React from 'react';
import { Box, Center, Flex, Heading, HStack, Image, Stack, Text, useBreakpointValue, View, VStack } from 'native-base';
import { PagesBackground } from '../../common/layout/main_layout.styled';
import { ModelLoader } from '../../common/components/model_loader';
import SkButton from './components/sk_button';
import ProgressBar from './components/progress_bar';
import { HomeHero } from '../../common/components/home_hero';
import { GlassBackground } from '../../common/components/glass_background';
import { HomeSecondaryActions } from '../../common/components/home_secondary_actions';
import { WhitepaperAndSocial } from '../../common/components/whitepapper_and_social';
import { HomeBackground } from '../../selfkey-id/pages/layout/main_layout.styled';
// import gradientBg from '../../common/assets/images/gradient-bg-2.png';
// import gradientBgMobile from '../../common/assets/images/gradient-bg-mobile-2.png';
import { SubscribeAndJoinSection } from '../../common/components/subscribe_and_join_section';
import WarningModal from '../../common/components/warning_modal';
import LdButton from './components/sk_button';
import { RejectedPage } from './rejected_page';
import ActionCards, { ActionButton, ActionCard, TextLine } from './components/ActionCards';
import { FeatureTitle } from '../../common/components/features_section';
import alphaKekAi from '../../common/assets/images/alphakek-ai.png';
import { PAGES } from '../../common/lib/constants';
import { useLocation } from 'react-router-dom';
import useWelcomePage from '../../common/hooks/use_welcome_page';

const WelcomePageContainer = React.memo(() => {
  const { pathname } = useLocation();
  const {
    showEligibleWarning,
    consentView,
    showConsentModal,
    loadingSignRequest,
    errorPoiStatus,
    pageTitle,
    setShowEligibleWarning,
    setShowConsentModal,
    handleAgreeConsent,
    lockHandleClick,
    handleAlphaKekAIClick,
    inviteHandleClick,
    detailsHandleClick,
    voteHandleClick,
    airdropHandleClick,
    handleDisabledClick,
    setErrorPoiStatus,
    isAuthorized,
    handleGetVerified,
    canReceiveAirdrop,
    lockingActive,
    activeProposal,
    loadingGetSelfMintingUnlocked,
    isSelfMintingUnblocked,
    isNotEligible,
    handleGetSelfkeyId
  } = useWelcomePage();

  // const bg = useBreakpointValue({
  //   base: gradientBgMobile,
  //   md: gradientBg
  // });

  const alphaKekCardDimensions = useBreakpointValue({
    base: {
      width: '324px',
      height: '192px',
      py: '32px'
    },
    md: {
      width: '336px',
      height: '188px',
      py: '24px'
    }
  });

  if (errorPoiStatus) {
    return (
      <RejectedPage
        title={'Oh, no!'}
        content={errorPoiStatus}
        actionLabel={'Retry'}
        handleAction={() => setErrorPoiStatus(false)}
        eventPage={`${PAGES.WELCOME_MEMBER} - POI Credential Status Error`}
      />
    );
  }

  if (loadingSignRequest) {
    return (
      <PagesBackground skStyle={false}>
        <Center w="100%" alignSelf={'flex-start'} mt={'80px'} alignItems={'center'}>
          <Box w="90%" maxW="400">
            <Box h={{ base: '200px', md: '250px' }} alignSelf={'center'}>
              <ModelLoader model={'/3d/step-1/loading_cube.glb'} scale={0.3} verticalInclination={Math.PI / 3}></ModelLoader>
            </Box>
            <Text fontSize={{ base: '16px', md: '18px' }} fontWeight={700} textAlign={'center'} mb={'24px'} mt={'40px'}>
              Please Sign the message on your wallet in order to provide consent
            </Text>
            <ProgressBar />
          </Box>
        </Center>
      </PagesBackground>
    );
  }
  return (
    <HomeBackground>
      <Box flexDirection="column" alignItems="center" maxWidth={'1216px'} width={'100%'} mt={{ base: 0, md: '44px' }}>
        <Flex
          direction={{ base: 'column', lg: 'row' }}
          w={{ base: '100%', xl: '1216px' }}
          justifyContent={'space-between'}
          px={{ base: '20px', xl: '0' }}
          // mb={'100px'}
          zIndex={10}
        >
          <Stack alignItems="center" textAlign="center" alignSelf={'center'} space="4" pb={'33px'} display={{ base: 'block', lg: 'none' }}>
            <View w={'310px'} h={'380px'}>
              <ModelLoader model={'/3d/step-1/self.glb'}></ModelLoader>
            </View>
          </Stack>

          <Stack
            direction={'column'}
            w={'100%'}
            alignItems={{ base: 'center', lg: 'flex-start' }}
            justifyContent={'center'}
            maxWidth={{ base: '100%', lg: '767px' }}
            space={'24px'}
          >
            <HomeHero />
            {pathname === '/public' && (
              <GlassBackground w={{ base: '320px', md: '696px' }} h={{ base: '176px', md: '112px' }} px={'32px'} py={'24px'} disabled={!isAuthorized()}>
                <Stack
                  direction={{ base: 'column', md: 'row' }}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  space={{ base: '24px', md: 0 }}
                  h={'100%'}
                >
                  <Stack direction={{ base: 'column', md: 'row' }} alignItems={'center'} space={{ base: '16px', md: '24px' }}>
                    <HStack alignItems={'center'}>
                      <Heading
                        maxW={{ base: '230px', md: '367px' }}
                        fontWeight={700}
                        fontSize={{ base: '20px', md: '22px' }}
                        lineHeight={{ base: '24px', md: '34px' }}
                        textAlign={{ base: 'center', md: 'left' }}
                        fontFamily={'Plus Jakarta Sans'}
                      >
                        Get Verified and Join SelfKey DAO!
                      </Heading>
                    </HStack>
                  </Stack>
                  <SkButton eventName={`${pageTitle} - Get Verified`} fixedSize={false} w={{ base: '128px', md: '156px' }} onPress={() => handleGetVerified()}>
                    Get Verified
                  </SkButton>
                </Stack>
              </GlassBackground>
            )}

            <ActionCards
              airdrop={{
                handleClick: airdropHandleClick,
                handleDisabledClick: handleDisabledClick,
                disabled: !isAuthorized(),
                visible: canReceiveAirdrop
              }}
              lock={{
                handleClick: lockHandleClick,
                handleDisabledClick: handleDisabledClick,
                disabled: !isAuthorized(),
                visible: lockingActive
              }}
              invite={{
                handleClick: inviteHandleClick,
                handleDisabledClick: handleDisabledClick,
                disabled: !isAuthorized(),
                visible: true
              }}
              vote={{
                handleClick: voteHandleClick,
                handleDisabledClick: handleDisabledClick,
                disabled: !isAuthorized(),
                visible: activeProposal?.active && !loadingGetSelfMintingUnlocked && !isSelfMintingUnblocked
              }}
              details={{
                handleClick: detailsHandleClick,
                handleDisabledClick: handleDisabledClick,
                disabled: !isAuthorized() && !isNotEligible(),
                visible: true
              }}
            />

            <View display={{ base: 'block', md: 'none' }} mt={'8px'} mb={'64px'}>
              <WhitepaperAndSocial />
            </View>
          </Stack>

          <VStack space={'76px'} display={{ base: 'none', lg: 'block' }}>
            <Stack alignItems="center" textAlign="center" space="4" minW={'440px'} h={'477px'}>
              <ModelLoader model={'/3d/step-1/self.glb'} scale={1} stageEnv={'city'} stageIntensity={2}></ModelLoader>
            </Stack>
            <WhitepaperAndSocial />
          </VStack>
        </Flex>
        <HomeSecondaryActions />
        <VStack alignItems={'center'} mb={{ base: '60px', md: '120px' }}>
          <FeatureTitle
            titleWhite={'Projects using'}
            titleGradient={'SelfKey iD'}
            skStyle={false}
            mt={{ base: 0, md: '120px' }}
            mb={{ base: '40px', md: '64px' }}
          />
          <ActionCard
            height={alphaKekCardDimensions.height}
            width={alphaKekCardDimensions.width}
            cardPy={alphaKekCardDimensions.py}
            halfSize={true}
            title={
              <VStack alignItems={'center'} space={'16px'} mb={{ base: 0, md: '8px' }}>
                <Image alt="self token" source={{ uri: alphaKekAi }} w={'216px'} h={'28px'} mx={'4px'} />
                <TextLine textAlign={{ base: 'center', md: 'left' }}>Powerful Crypto Research AI Tool</TextLine>
              </VStack>
            }
            actionButton={
              <ActionButton halfSize={false} eventName={`AlphaKEK AI - Check it Out`} handleClick={handleAlphaKekAIClick}>
                Check it Out
              </ActionButton>
            }
          ></ActionCard>
        </VStack>
        {/*<FeatureTitle title={'Coming Soon:'} skStyle={false} mt={{ base: 0, md: '100px' }} />*/}
        {/*<SkCarousel titleStyle={'linear-gradient(93.17deg, #2DA1F8 0%, #CD22C6 100%)'} bg={bg} skStyle={false}>*/}
        {/*  <ComingSoonSelfMinting seeMoreLink={envConfigs.selfMintingUrl} />*/}
        {/*  /!*<ComingSoonPoiStaking seeMoreLink={envConfigs.poiStakingUrl} />*!/*/}
        {/*  /!*<ComingSoonNftCollection seeMoreLink={envConfigs.nftCollectionUrl} />*!/*/}
        {/*</SkCarousel>*/}
        <SubscribeAndJoinSection mt={{ base: '32px', md: '64px' }} mb={{ base: '40px', md: '200px' }} />
      </Box>
      <WarningModal
        showModal={showEligibleWarning}
        title={
          <VStack>
            <Heading fontSize={{ base: '20px', md: '28px' }} lineHeight={{ base: '24px', md: '36px' }} fontWeight={700} textAlign={'center'}>
              You need to be an eligible SelfKey iD holder before you can access this section.
            </Heading>
          </VStack>
        }
        leftButton={
          <SkButton
            eventName={`${pageTitle} - Eligible Warning Modal - Close`}
            variant={'secondary'}
            fixedSize={false}
            onPress={() => setShowEligibleWarning(false)}
          >
            Close
          </SkButton>
        }
        rightButton={
          <SkButton eventName={`${pageTitle} - Eligible Warning Modal - Get Selfkey iD`} fixedSize={false} onPress={() => handleGetSelfkeyId()} w={'185px'}>
            Get SelfKey iD
          </SkButton>
        }
        handleClose={() => setShowEligibleWarning(false)}
      />
      <WarningModal
        showModal={showConsentModal}
        title={consentView}
        leftButton={
          <LdButton variant={'secondary'} fixedSize={false} w={'134px'} onPress={() => setShowConsentModal(false)}>
            Cancel
          </LdButton>
        }
        rightButton={
          <LdButton fixedSize={false} w={'134px'} onPress={() => handleAgreeConsent()}>
            Agree
          </LdButton>
        }
        handleClose={() => setShowConsentModal(false)}
      />
    </HomeBackground>
  );
});

export { WelcomePageContainer };
