import { useState, useEffect, useContext } from 'react';
import { RegistryContext, Web3WagmiContext } from '../context/app_context';
import { CachePolicies, useFetch } from 'use-http';
import { parseUnits, formatUnits } from 'viem';
import erc20ABI from '../../common/assets/abis/ERC20.json';
import { useBalance, useReadContracts } from 'wagmi';
import { formatCurrency } from '../lib/helpers';
import { current as envConfigs } from 'env-configs/selfkey-org';
import useDocumentTitle from '../../common/hooks/use_document_title';
import { APP_NAME, PAGES } from '../lib/constants';

const usePaymentPage = () => {
  const { setDocumentTitle } = useDocumentTitle();
  const { activeAddress: address } = useContext(Web3WagmiContext);
  const {
    payRegistry,
    paymentTransactionHash,
    setTokenAddress,
    payTokenRegistry,
    allowance,
    waitingAllowanceApproval,
    waitingPayTransaction,
    approveAmount,
    hasError,
    paymentCompleted
  } = useContext(RegistryContext);

  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [displayAcceptTermsWarning, setDisplayAcceptTermsWarning] = useState(false);
  const [isFirstBalanceRefetch, setIsFirstBalanceRefetch] = useState(true);
  const [pendingPayment, setPendingPayment] = useState(false);
  const [hasMinBalance, setHasMinBalance] = useState(false);
  const [displayBalanceWarning, setDisplayBalanceWarning] = useState(hasMinBalance && !isFirstBalanceRefetch);

  const { get: getGovernanceJoinCurrencies, loading: loadingGovernanceCurrencies } = useFetch(`${envConfigs.serverUrl}/governance/join`, {
    cachePolicy: CachePolicies.NETWORK_ONLY
  });
  const { get: getGovernanceCoupon, loading: loadingGovernanceCoupon } = useFetch(`${envConfigs.serverUrl}/governance/coupon`, {
    cachePolicy: CachePolicies.NETWORK_ONLY
  });
  const [validCoupon, setValidCoupon] = useState(undefined);
  const [currencies, setCurrencies] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [tokenParam, setTokenParam] = useState({});

  const result = useReadContracts({
    allowFailure: false,
    contracts: [
      {
        address: tokenParam.token,
        abi: erc20ABI,
        functionName: 'balanceOf',
        args: [address]
      },
      {
        address: tokenParam.token,
        abi: erc20ABI,
        functionName: 'decimals'
      },
      {
        address: tokenParam.token,
        abi: erc20ABI,
        functionName: 'symbol'
      }
    ],
    query: {
      enabled: !selectedCurrency?.native
    }
  });
  const resultNative = useBalance({ address, query: { enabled: selectedCurrency?.native } });

  const currentBalance = selectedCurrency?.native ? resultNative?.data : { value: result?.data?.[0], decimals: result?.data?.[1], symbol: result?.data?.[2] };
  const isLoadingBalance = selectedCurrency?.native ? resultNative?.isLoading : result?.isLoading;
  const isRefetchingBalance = selectedCurrency?.native ? resultNative?.isRefetching : result?.isRefetching;
  const refetchBalance = async () => (selectedCurrency?.native ? resultNative?.refetch() : await result?.refetch());

  const [coupon, setCoupon] = useState('');
  const [loadingCoupon, setLoadingCoupon] = useState(false);
  const handleApplyCoupon = async () => {
    if (!coupon) return;
    const couponResponse = await getGovernanceCoupon(`/${coupon}/${address}`);
    if (couponResponse?.error) {
      await fetchCurrencies();
      setValidCoupon(false);
      return;
    }
    updateCurrencies(couponResponse);
    setValidCoupon(true);
  };

  const handleSelectCurrency = currency => {
    if (currency.native) {
      setTokenParam({});
    } else {
      setTokenParam({ token: currency.contract });
      setTokenAddress(currency.contract);
    }
    setSelectedCurrency(currency);
  };

  const updateCurrencies = currenciesData => {
    const currencyMap =
      currenciesData?.length &&
      currenciesData.map(currency => {
        const formatted = formatCurrency(formatUnits(currency.value, currency.decimals), currency.decimals, 0);
        let mappedCurrency = {
          label: currency.token,
          formatted,
          value: currency.value,
          icon: currency.url,
          native: currency.native,
          contract: currency.contract,
          decimals: currency.decimals
        };
        if (currency.discount > 0) {
          const formattedOriginal = formatCurrency(formatUnits(currency.originalPrice, currency.decimals), currency.decimals, 0);
          mappedCurrency = {
            ...mappedCurrency,
            formattedOriginal,
            originalValue: currency.originalPrice,
            discount: currency.discount
          };
        }
        return mappedCurrency;
      });
    setCurrencies(currencyMap);
    if (currencyMap?.length > 0) {
      if (!selectedCurrency) {
        handleSelectCurrency(currencyMap[0]);
      } else {
        handleSelectCurrency(currencyMap.find(currency => currency.label === selectedCurrency.label));
      }
    }
  };

  async function fetchCurrencies() {
    const currenciesResponse = await getGovernanceJoinCurrencies();
    updateCurrencies(currenciesResponse);
  }

  useEffect(() => {
    fetchCurrencies();
  }, []);

  useEffect(() => {
    if (!hasError) {
      setDocumentTitle(`${APP_NAME} - ${PAGES.PAYMENT}`);
    }
  }, [hasError, setDocumentTitle]);

  const updateHasMinBalance = () => {
    if (selectedCurrency?.value !== undefined && currentBalance?.value !== undefined) {
      setHasMinBalance(selectedCurrency.value <= currentBalance.value);
    }
  };

  useEffect(() => {
    updateHasMinBalance();
  }, [selectedCurrency, currentBalance]);

  useEffect(() => {
    if (!isLoadingBalance && !isRefetchingBalance && pendingPayment) {
      updateHasMinBalance();
      payment();
    }
  }, [pendingPayment, isLoadingBalance, isRefetchingBalance]);

  useEffect(() => {
    if (hasMinBalance && !isFirstBalanceRefetch) {
      setDisplayBalanceWarning(false);
    }
  }, [hasMinBalance]);

  const paymentPreCheck = async () => {
    await refetchBalance();
    setPendingPayment(true);
  };

  const payment = async () => {
    setPendingPayment(false);
    if (!acceptedTerms) {
      setDisplayAcceptTermsWarning(true);
      return;
    }
    if (!hasMinBalance) {
      setIsFirstBalanceRefetch(false);
      setDisplayBalanceWarning(true);
      return;
    } else {
      setDisplayBalanceWarning(false);
    }
    if (selectedCurrency.native) {
      payRegistry(selectedCurrency.value, coupon);
    } else {
      if (allowance < selectedCurrency.value) {
        const value = parseUnits(selectedCurrency.formatted, currentBalance.decimals);
        approveAmount(value, coupon);
      } else {
        const value = parseUnits(selectedCurrency.formatted, currentBalance.decimals);
        payTokenRegistry(value, selectedCurrency.contract, coupon);
      }
    }
  };

  const acceptTerms = checked => {
    setAcceptedTerms(checked);
    if (checked) {
      setDisplayAcceptTermsWarning(false);
    }
  };

  return {
    currencies,
    selectedCurrency,
    currentBalance,
    isLoadingBalance,
    isRefetchingBalance,
    acceptedTerms,
    displayAcceptTermsWarning,
    hasMinBalance,
    displayBalanceWarning,
    validCoupon,
    coupon,
    loadingCoupon,
    loadingGovernanceCurrencies,
    loadingGovernanceCoupon,
    waitingAllowanceApproval,
    waitingPayTransaction,
    paymentCompleted,
    hasError,
    paymentTransactionHash,
    paymentPreCheck,
    handleApplyCoupon,
    setCoupon,
    handleSelectCurrency,
    acceptTerms
  };
};

export default usePaymentPage;
