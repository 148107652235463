import React, { useContext, useEffect, useState } from 'react';
import { StakingContext, Web3WagmiContext } from '../../common/context/app_context';
// import lockDaoStakingABI from '../config/SelfkeyStaking.json';
import selfKeyPoiLockABI from '../config/SelfkeyPoiLock.json';
import { useReadContract } from 'wagmi';
import { formatUnits } from 'viem';
import keyTokenABI from '../config/KeyToken.json';
import { CachePolicies, useFetch } from 'use-http';
import { current as envConfigs } from 'env-configs/selfkey-org';
import { handleError } from '../../common/lib/error_handler';
import { useContractWriteAndWait } from '../../common/hooks/use_contract_write_and_wait';

export function StakingProvider({ children }) {
  const { activeAddress: address, isConnected, isConnecting: isAccountLoading, contracts, unsupportedChain } = useContext(Web3WagmiContext);

  const [minStakeAmount, setMinStakeAmount] = useState();
  const [minWithdrawAmount, setMinWithdrawAmount] = useState();
  const [keyBalance, setKeyBalance] = useState();
  const [stakePayload, setStakePayload] = useState();
  const [keyAllowance, setKeyAllowance] = useState();
  const [stakingTotal, setStakingTotal] = React.useState(0);
  const [lockTimeDuration, setLockTimeDuration] = useState();

  const { get: getStakingAmount, loading: loadingStakingTotal } = useFetch(`${envConfigs.serverUrl}/rewards/balance-of-staking`, {
    cachePolicy: CachePolicies.NETWORK_ONLY
  });

  const getStakingTotal = async () => {
    try {
      const response = await getStakingAmount(address);
      if (response?.total) {
        setStakingTotal(formatUnits(response.total, 18));
      }
    } catch (e) {
      handleError('Error getting staking total', e);
    }
  };

  useEffect(() => {
    if (address) {
      getStakingTotal();
    }
  }, [address]);

  // NOT AVAILABLE IN THE NEW CONTRACT
  // const { isLoading: isLoadingTimeLockDuration } = useReadContract({
  //   address: contracts?.staking,
  //   abi: lockDaoStakingABI.abi,
  //   functionName: 'timeLockDuration',
  //   args: [],
  //   onSuccess(data) {
  //     const lockTime = parseFloat(utils.formatUnits(BigNumber.from(data), 18));
  //     // console.log('[LOCK_POI] lockTimeDuration', data, lockTime);
  //     setLockTimeDuration(lockTime);
  //   },
  //   onError(e) {
  //     handleError('[LOCK_POI] lockTimeDuration', e);
  //   },
  //   enabled: Boolean(address && contracts?.staking && isConnected && !isAccountLoading)
  // });

  const {
    data: minLockAmountData,
    status: minLockAmountStatus,
    error: minLockAmountError,
    isLoading: isLoadingMinStakeAmount
  } = useReadContract({
    address: contracts?.staking,
    abi: selfKeyPoiLockABI.abi,
    functionName: 'minLockAmount',
    args: [],
    query: {
      enabled: Boolean(address && contracts?.staking && isConnected && !isAccountLoading && !unsupportedChain)
    }
  });
  useEffect(() => {
    if (typeof minLockAmountData === 'bigint') {
      if (minLockAmountStatus === 'success') {
        const minAmount = formatUnits(minLockAmountData, 18);
        // console.log('[LOCK_POI] minStakeAmount', minLockAmountData, minAmount);
        setMinStakeAmount(minAmount);
      } else if (minLockAmountStatus === 'error') {
        handleError('[LOCK_POI] minStakeAmount', minLockAmountError);
      }
    }
  }, [minLockAmountStatus, minLockAmountData]);

  const {
    data: minUnlockAmountData,
    status: minUnlockAmountStatus,
    error: minUnlockAmountError,
    isLoading: isLoadingMinWithdrawAmount
  } = useReadContract({
    address: contracts?.staking,
    abi: selfKeyPoiLockABI.abi,
    functionName: 'minUnlockAmount',
    args: [],
    query: {
      enabled: Boolean(address && contracts?.staking && isConnected && !isAccountLoading && !unsupportedChain)
    }
  });
  useEffect(() => {
    if (typeof minUnlockAmountData === 'bigint') {
      if (minUnlockAmountStatus === 'success') {
        const minAmount = formatUnits(minUnlockAmountData, 18);
        // console.log('[LOCK_POI] minWithdrawAmount', minUnlockAmountData, minAmount);
        setMinWithdrawAmount(minAmount);
      } else if (minUnlockAmountStatus === 'error') {
        handleError('[LOCK_POI] minWithdrawAmount', minUnlockAmountError);
      }
    }
  }, [minUnlockAmountStatus, minUnlockAmountData]);

  const {
    data: keyBalanceData,
    status: keyBalanceStatus,
    error: keyBalanceError,
    refetch: refetchKeyBalance,
    isRefetching: isRefetchingKeyBalance,
    isFetched: isKeyBalanceFetched
  } = useReadContract({
    address: contracts?.keyToken,
    abi: keyTokenABI.abi,
    functionName: 'balanceOf',
    args: [address],
    query: {
      enabled: Boolean(!!address && contracts?.keyToken && isConnected && !isAccountLoading && !unsupportedChain)
    }
  });
  useEffect(() => {
    if (typeof keyBalanceData === 'bigint') {
      if (keyBalanceStatus === 'success' || (!isRefetchingKeyBalance && isKeyBalanceFetched)) {
        const balance = formatUnits(keyBalanceData, 18);
        // console.log('[KEY] balanceOf', keyBalanceData, balance);
        setKeyBalance(balance);
      } else if (keyBalanceStatus === 'error') {
        handleError('[KEY] balanceOf', keyBalanceError);
      }
    }
  }, [keyBalanceStatus, isKeyBalanceFetched, isRefetchingKeyBalance, keyBalanceData]);

  const {
    data: allowanceData,
    status: allowanceStatus,
    error: allowanceError,
    refetch: refetchAllowance,
    isRefetching: isRefetchingAllowance,
    isFetched: isAllowanceFetched
  } = useReadContract({
    address: contracts?.keyToken,
    abi: keyTokenABI.abi,
    functionName: 'allowance',
    args: [address, contracts?.staking],
    query: {
      enabled: Boolean(!!address && contracts?.keyToken && contracts?.staking && isConnected && !isAccountLoading && !unsupportedChain)
    }
  });
  useEffect(() => {
    if (typeof allowanceData === 'bigint') {
      if (allowanceStatus === 'success' || (!isRefetchingAllowance && isAllowanceFetched)) {
        const allowance = formatUnits(allowanceData, 18);
        // console.log('[KEY] allowance', allowanceData, allowance);
        setKeyAllowance(parseFloat(allowance));
      } else if (allowanceStatus === 'error') {
        handleError('[KEY] allowance', allowanceError);
      }
    }
  }, [allowanceStatus, isRefetchingAllowance, isAllowanceFetched, allowanceData]);

  const {
    write: approveKey,
    waitingTransaction: waitingAllowanceApproval,
    isLoadingTransaction: isLoadingAllowanceTransaction,
    hasError: hasErrorAllowance,
    resetState: resetAllowance
  } = useContractWriteAndWait({
    contractAddress: contracts?.keyToken,
    functionName: 'approve',
    contractAbi: keyTokenABI.abi,
    transactionCallback: data => {
      console.log(`[KEY TOKEN] approve transactionCallback`, data);
      refetchAllowance();
      // hook staking right after approval
      if (stakePayload?.to) {
        stakeAsync(stakePayload?.to, stakePayload?.amount, stakePayload?.param, stakePayload?.timestamp, stakePayload?.signer, stakePayload?.signature);
      }
    }
  });
  const approveKeyAmount = async amount => {
    console.log('[KEY] approve request', amount);
    return await approveKey({
      args: [contracts?.staking, amount]
    });
  };

  const {
    write: stake,
    transaction: stakingTransaction,
    waitingTransaction: waitingStakingTransaction,
    isLoadingTransaction: isLoadingStakingTransaction,
    hasError: hasErrorStake,
    resetState: resetStake
  } = useContractWriteAndWait({
    contractAddress: contracts?.staking,
    functionName: 'lock',
    contractAbi: selfKeyPoiLockABI.abi,
    transactionCallback: data => {
      setStakePayload(null);
      refetchKeyBalance();
      refetchAllowance();
      getStakingTotal();
    }
  });

  const _setStakePayload = (to, amount, param, timestamp, signer, signature) => {
    const stakeParams = {
      to,
      amount,
      param,
      timestamp,
      signer,
      signature
    };
    setStakePayload(stakeParams);
  };

  const stakeAsync = async (to, amount, param, timestamp, signer, signature) => {
    console.log('[KEY] stake request', { to, amount, param, timestamp, signer, signature });
    await stake({
      args: [to, amount, param, timestamp, signer, signature],
      gas: 265000
    });
    refetchAllowance();
  };

  const {
    write: withdrawKey,
    transaction: withdrawTransaction,
    waitingTransaction: waitingWithdrawTransaction,
    isLoadingTransaction: isLoadingWithdrawTransaction,
    hasError: hasErrorWithdraw,
    resetState: resetWithdraw
  } = useContractWriteAndWait({
    contractAddress: contracts?.staking,
    functionName: 'unlock',
    contractAbi: selfKeyPoiLockABI.abi,
    transactionCallback: data => {
      getStakingTotal();
    }
  });

  const withdrawAmount = async (address, amount, param, timestamp, signer, signature) => {
    console.log('[KEY] withdraw request', { address, amount, param, timestamp, signer, signature });
    return await withdrawKey({
      args: [address, amount, param, timestamp, signer, signature]
    });
  };

  const clearError = () => {
    resetAllowance();
    resetStake();
    resetWithdraw();
  };

  const clearTransactions = () => {
    resetStake();
    resetWithdraw();
    resetAllowance();
  };

  return (
    <StakingContext.Provider
      value={{
        minStakeAmount,
        isLoadingMinStakeAmount,
        minWithdrawAmount,
        isLoadingMinWithdrawAmount,
        keyBalance,
        refetchKeyBalance,
        isRefetchingKeyBalance,
        stake: stakeAsync,
        setStakePayload: _setStakePayload,
        stakingTransaction,
        withdrawTransaction,
        keyAllowance,
        approveKeyAmount,
        isLoadingStakingTransaction,
        isLoadingWithdrawTransaction,
        stakingTotal,
        loadingStakingTotal,
        isWaitingAllowanceApproval: waitingAllowanceApproval,
        isWaitingStakingApproval: waitingStakingTransaction,
        isWaitingWithdrawApproval: waitingWithdrawTransaction,
        isLoadingStaking: isLoadingAllowanceTransaction || isLoadingStakingTransaction || waitingAllowanceApproval || waitingStakingTransaction,
        hasError: hasErrorAllowance || hasErrorStake || hasErrorWithdraw,
        clearError,
        clearTransactions,
        withdrawAmount,
        lockTimeDuration
      }}
    >
      {children}
    </StakingContext.Provider>
  );
}
