import { Modal, ScrollView } from 'native-base';
import { POICredentialTakeSelfie } from '../../lockdao/pages/modal_poi_credential_take_selfie';
import React from 'react';

export function SelfieModal({ isOpen, handleClose, handlePOIContinue }) {
  return (
    <Modal isOpen={isOpen} onClose={handleClose} height="100vh" _backdrop={{ bg: '#030c1e', opacity: 0.9 }} size={'full'}>
      <Modal.Content
        maxWidth={'95%'}
        maxH={'95%'}
        borderRadius="16px"
        borderWidth="1px"
        borderColor="#0385E4"
        bg={'#000000'}
        shadow="2"
        justifyContent={'center'}
      >
        {!!handleClose && <Modal.CloseButton onPress={handleClose} />}
        <Modal.Body p={0}>
          <ScrollView>
            <POICredentialTakeSelfie mode={'modal'} handleAction={handlePOIContinue} isOpen={isOpen} />
          </ScrollView>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
}
