import React from 'react';
import { Checkbox, Flex, Heading, HStack, Icon, Link, Stack, Text, View, VStack } from 'native-base';
import { LandingBackground } from './layout/main_layout.styled';
import { useHistory } from 'react-router-dom';
import SkButton from './components/sk_button';
import { PaymentFailed } from './payment_failed';
import { PaymentCompleted } from './payment_completed';
import { ModelLoader } from '../../common/components/model_loader';
import { SkWaitingTransaction } from '../../lockdao/pages/components/sk_waiting_transaction';
import { TextGradient } from './components/text_gradient';
import { GlassBackground } from '../../common/components/glass_background';
import { FeaturesSection } from '../../common/components/features_section';
import Dropdown from '../../common/components/dropdown';
import { SkInput } from '../../common/components/sk_input';
import { PAGES } from '../../common/lib/constants';
import { BridgeButton } from '../../common/components/brigde_button';
import usePaymentPage from '../../common/hooks/use_payment_page';

function PaymentPageContainer() {
  const history = useHistory();
  const {
    currencies,
    selectedCurrency,
    isLoadingBalance,
    isRefetchingBalance,
    acceptedTerms,
    displayAcceptTermsWarning,
    displayBalanceWarning,
    validCoupon,
    coupon,
    loadingCoupon,
    loadingGovernanceCurrencies,
    loadingGovernanceCoupon,
    waitingAllowanceApproval,
    waitingPayTransaction,
    paymentCompleted,
    hasError,
    paymentTransactionHash,
    paymentPreCheck,
    handleApplyCoupon,
    setCoupon,
    handleSelectCurrency,
    acceptTerms
  } = usePaymentPage();

  if (hasError) {
    return <PaymentFailed />;
  }

  if (waitingAllowanceApproval || waitingPayTransaction) {
    return <SkWaitingTransaction isWaitingForTransaction={!waitingAllowanceApproval} />;
  }

  if (paymentCompleted) {
    return <PaymentCompleted hash={paymentTransactionHash} />;
  }

  const agreeTermsStyle = {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16px',
    paddingLeft: '2px',
    color: 'white'
  };
  const skipNowStyle = {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16px',
    color: '#B7B7B7'
  };

  return (
    <LandingBackground>
      <VStack justifyContent="flex-start" alignItems="center" w="100%" space={{ base: '20px', md: '32px' }}>
        <HStack width={'100%'} justifyContent="center" alignItems="flex-start" mb={{ base: '40px', md: '40px' }}>
          <Flex
            direction={{ base: 'column', md: 'column', lg: 'row' }}
            w={'100%'}
            justifyContent={'space-between'}
            maxWidth={{ base: '480px', lg: '992px', xl: '1216px' }}
          >
            <Stack alignItems="center" textAlign="center" alignSelf={'center'} space="4" pb={5} display={{ base: 'block', lg: 'none' }}>
              <View w={{ base: '321px', md: '440px' }} h={{ base: '321px', md: '440px' }}>
                <ModelLoader model={'/3d/step-1/NFT.glb'} scale={0.9} stageEnv={'city'} stageIntensity={2}></ModelLoader>
              </View>
            </Stack>

            <Stack
              direction={'column'}
              w={'100%'}
              align={{ base: 'center', md: 'left' }}
              alignItems={{ base: 'center', md: 'left' }}
              maxWidth={{ lg: '696px' }}
            >
              <Heading
                fontWeight={{ base: 800, md: 700 }}
                fontSize={{ base: '32px', md: '48px' }}
                lineHeight={{ base: '40px', md: '60px' }}
                textAlign={{ base: 'center', lg: 'left' }}
                alignSelf={{ base: 'center', md: 'flex-start' }}
                maxW={{ base: '320px', md: '669px' }}
                fontFamily={'Plus Jakarta Sans'}
                pb={{ base: '12px', md: '8px' }}
              >
                SELFKEY <TextGradient>iD</TextGradient> Verification
              </Heading>
              <VStack space={{ base: '24px', md: '40px' }} maxW={{ base: '266px', md: '100%' }} alignSelf={{ base: 'center', md: 'flex-start' }}>
                <Text
                  fontWeight={400}
                  fontSize={{ base: '16px', md: '20px' }}
                  lineHeight={{ base: '24px', md: '32px' }}
                  color={'#BAEBFF'}
                  textAlign={{ base: 'center', md: 'flex-start' }}
                >
                  The Journey Starts With SelfKey iD
                </Text>
                <Text
                  fontWeight={400}
                  fontSize={{ base: '14px', md: '16px' }}
                  lineHeight={{ base: '20px', md: '24px' }}
                  textAlign={{ base: 'center', md: 'flex-start' }}
                >
                  Payment is required in order to continue.
                </Text>
              </VStack>
              <VStack mt={'24px'}>
                <GlassBackground
                  w={{ base: '322px', md: '592px' }}
                  h={{ base: validCoupon === undefined ? '460px' : '494px', md: validCoupon === undefined ? '344px' : '378px' }}
                  px={{ base: '6px', md: '32px' }}
                  py={'32px'}
                >
                  <Text fontWeight={700} fontSize={{ base: '14px', md: '16px' }} lineHeight={{ base: '20px', md: '24px' }} textAlign={'center'}>
                    These are limited time rates, so get in early!
                  </Text>
                  {currencies.length > 1 ? (
                    <VStack space={{ base: '24px', md: '32px' }}>
                      <Stack justifyContent={'space-between'} space={'24px'} alignItems={'center'} direction={{ base: 'column', md: 'row' }} w={'453px'}>
                        <View w={{ base: '290px', md: '395px' }}>
                          <Dropdown
                            selected={selectedCurrency}
                            options={currencies}
                            onChange={handleSelectCurrency}
                            width={{ base: '290px', md: '395px' }}
                          ></Dropdown>
                        </View>
                        <SkButton
                          eventName={`${PAGES.PAYMENT} - Pay ${selectedCurrency?.formatted} ${selectedCurrency?.label}`}
                          onPress={paymentPreCheck}
                          isLoading={!selectedCurrency}
                          isDisabled={displayAcceptTermsWarning || isLoadingBalance || isRefetchingBalance || loadingGovernanceCurrencies}
                          fixedSize={false}
                          w={'88px'}
                          h={'48px'}
                          mr={'10px'}
                        >
                          Pay
                        </SkButton>
                      </Stack>
                      <VStack space={'16px'}>
                        <Stack justifyContent={'space-between'} space={'24px'} alignItems={'center'} direction={{ base: 'column', md: 'row' }} w={'453px'}>
                          <View w={{ base: '290px', md: '395px' }}>
                            <SkInput
                              eventName={`${PAGES.PAYMENT} - Apply Coupon`}
                              value={coupon}
                              onChange={v => setCoupon(v)}
                              placeholder={'Have a discount code?'}
                              onSubmit={handleApplyCoupon}
                              isLoading={loadingCoupon}
                              width={{ base: '290px', md: '395px' }}
                              height={{ base: '40px', md: '48px' }}
                              showSubmitButton={false}
                            ></SkInput>
                          </View>
                          <SkButton
                            eventName={`${PAGES.PAYMENT} - Apply Coupon`}
                            onPress={handleApplyCoupon}
                            isLoading={loadingGovernanceCoupon || loadingGovernanceCurrencies}
                            fixedSize={false}
                            variant={'secondary'}
                            w={'88px'}
                            h={'48px'}
                            mr={'10px'}
                          >
                            Apply
                          </SkButton>
                        </Stack>
                        {validCoupon !== undefined && (
                          <Heading
                            fontWeight={400}
                            fontSize={'12px'}
                            lineHeight={'16px'}
                            color={validCoupon ? '#48C281' : '#FF7575'}
                            textAlign={{ base: 'center', md: 'left' }}
                          >
                            {validCoupon ? 'Valid discount code, discount applied.' : 'Invalid discount code.'}
                          </Heading>
                        )}
                      </VStack>
                    </VStack>
                  ) : (
                    <SkButton
                      eventName={`${PAGES.PAYMENT} - Pay ${selectedCurrency?.formatted} ${selectedCurrency?.label}`}
                      onPress={paymentPreCheck}
                      isLoading={!selectedCurrency}
                      isDisabled={displayAcceptTermsWarning || isLoadingBalance || isRefetchingBalance}
                    >
                      Pay {selectedCurrency?.value ? `${selectedCurrency?.formatted} ${selectedCurrency?.label}` : ''}
                    </SkButton>
                  )}
                  {(displayAcceptTermsWarning || displayBalanceWarning) && (
                    <Heading fontWeight={400} fontSize={'14px'} lineHeight={'16px'} color={'#FF7575'} textAlign={{ base: 'center', md: 'left' }}>
                      {displayAcceptTermsWarning
                        ? 'You have to agree with terms and conditions.'
                        : `Please make sure you have enough ${selectedCurrency?.label} in your wallet.`}
                    </Heading>
                  )}
                  <Stack direction={{ base: 'column', md: 'row' }} justifyContent={'space-between'} alignItems={'center'} space={'24px'}>
                    <VStack>
                      <Checkbox
                        isChecked={acceptedTerms}
                        onChange={e => acceptTerms(e)}
                        alignSelf={{ base: 'center', md: 'left' }}
                        letterSpacing={0}
                        m={0}
                        colorScheme={'green'}
                        borderColor={'white'}
                        borderWidth={'1px'}
                        bg={'rgba(0, 0, 0, 0.2)'}
                        _hover={{ borderColor: 'white' }}
                        _icon={{ color: 'white' }}
                        pb={0}
                        pr={0}
                        icon={
                          <Icon>
                            <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M1 5.5L4 8L8.5 1" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                          </Icon>
                        }
                      >
                        <HStack alignItems={'center'}>
                          <Text {...agreeTermsStyle}>I agree to the </Text>
                          <a href={'/terms-of-service'} target="_blank" rel="noopener noreferrer" style={{ textDecorationColor: '#FFFFFF' }}>
                            <Text {...agreeTermsStyle}>terms of service</Text>
                          </a>
                          <Text {...agreeTermsStyle} pl={0} ml={0}>
                            .
                          </Text>
                        </HStack>
                      </Checkbox>
                    </VStack>
                    <Link onPress={() => history.push('/public')} _text={skipNowStyle} _web={skipNowStyle}>
                      Skip For Now
                    </Link>
                  </Stack>
                </GlassBackground>
                <BridgeButton page={PAGES.PAYMENT} w={'100%'} mt={'40px'} alignItems={{ base: 'center', md: 'flex-start' }} skStyle={true} />
              </VStack>
            </Stack>

            <Stack alignItems="center" textAlign="center" display={{ base: 'none', md: 'none', lg: 'block' }}>
              <View w={'440px'} h={'440px'}>
                <ModelLoader model={'/3d/step-1/NFT.glb'} scale={1} stageEnv={'city'} stageIntensity={2}></ModelLoader>
              </View>
            </Stack>
          </Flex>
        </HStack>
        <FeaturesSection />
      </VStack>
    </LandingBackground>
  );
}

export { PaymentPageContainer };
