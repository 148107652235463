import React from 'react';
import { Box, Tooltip } from 'native-base';

export const HoverTooltip = ({ content, children, disabled }) => {
  const [isVisible, setIsVisible] = React.useState(false);

  const handleMouseEnter = () => setIsVisible(!disabled);
  const handleMouseLeave = () => setIsVisible(false);

  return (
    <Tooltip label={content} isOpen={isVisible} placement={'bottom right'} onClose={() => setIsVisible(false)} onOpen={() => setIsVisible(!disabled)}>
      <Box onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {children}
      </Box>
    </Tooltip>
  );
};
