import { PagesBackground } from '../layout/main_layout.styled';
import { Heading, Text, View, VStack } from 'native-base';
import React from 'react';
import { ModelLoader } from './model_loader';
import { MainLayout } from '../../lockdao/pages/layout/main_layout';
import useDocumentTitle from '../hooks/use_document_title';
import { APP_NAME, PAGES } from '../lib/constants';

export function MaintenancePage({ skStyle }) {
  const { setDocumentTitle } = useDocumentTitle();

  React.useEffect(() => {
    setDocumentTitle(`${APP_NAME} - ${PAGES.MAINTENANCE}`);
  }, []);

  return (
    <MainLayout>
      <PagesBackground skStyle={skStyle}>
        <VStack alignItems={'center'} mt={{ base: '105px', md: '28px' }}>
          <View w={'100%'} h={{ base: '130px', md: '210px' }} mb={'30px'}>
            <ModelLoader model={'/3d/step-1/gears.glb'} scale={0.6}></ModelLoader>
          </View>
          <Heading
            fontWeight={700}
            fontSize={{ base: '24px', md: '48px' }}
            lineHeight={{ base: '32px', md: '60px' }}
            textAlign={'center'}
            mb={{ base: '8px', md: '16px' }}
            fontFamily={'Plus Jakarta Sans'}
          >
            Site under Maintenance
          </Heading>
          <Text
            fontWeight={400}
            fontSize={{ base: '14px', md: '16px' }}
            lineHeight={{ base: '20px', md: '24px' }}
            textAlign={'center'}
            mb={{ base: '24px', md: '4px' }}
            color={'#FFEDD2'}
            maxW={{ base: '220px', md: '100%' }}
          >
            We should be back shortly. Thank you for your patience.
          </Text>
        </VStack>
      </PagesBackground>
    </MainLayout>
  );
}
