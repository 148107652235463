import { VStack } from 'native-base';
import { SkButton } from './sk_button';
import { DisclaimerText } from './disclaimer_text';
import React from 'react';

export function BridgeButton({ page, skStyle, ...props }) {
  const handleBridgeKeyToPolygon = () => {
    window.open('https://wallet.polygon.technology/polygon/bridge/deposit');
  };
  return (
    <VStack space={{ base: '32px', md: '40px' }} {...props}>
      <SkButton
        skStyle={skStyle}
        eventName={`${page} - Bridge Key to Polygon`}
        w={{ base: '199px', md: '226px' }}
        fixedSize={false}
        onPress={handleBridgeKeyToPolygon}
        ml={{ base: 0, md: '10px' }}
      >
        Bridge KEY to Polygon
      </SkButton>
      <DisclaimerText maxW={{ base: '320px', md: '100%' }}>
        The bridging service is provided by a third-party platform, not by SelfKey DAO. By using this service, you acknowledge that the bridging process and its
        security are solely the responsibility of the third-party service. SelfKey DAO does not have control over and is not liable for, any issues that may
        arise during bridging. SelfKey DAO will not be able to help if something goes wrong while using the Bridging services of this third party.
      </DisclaimerText>
    </VStack>
  );
}
