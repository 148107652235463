import React from 'react';
import { RejectedPage } from './rejected_page';
import useDocumentTitle from '../../common/hooks/use_document_title';
import { APP_NAME, PAGES } from '../../common/lib/constants';

function RejectedPageContainer() {
  const { setDocumentTitle } = useDocumentTitle();

  React.useEffect(() => {
    setDocumentTitle(`${APP_NAME} - ${PAGES.REJECTED}`);
  }, []);

  return (
    <RejectedPage
      title={'Oh, no!'}
      content={'Sorry, looks like your SelfKey.iD Application account was rejected.\nDetails regarding this have been sent to you via email.'}
      showTerms={false}
      skStyle={true}
    />
  );
}

export { RejectedPageContainer };
