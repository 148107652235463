import React, { useEffect, useState } from 'react';
import { EMFJS, RTFJS, WMFJS } from 'rtf.js';
import raw from 'raw.macro';
import styled from 'styled-components';
import * as Sentry from '@sentry/react';
import { handleError } from '../lib/error_handler';
import { useBreakpointValue } from 'native-base';

const StyledText = styled.div`
  margin-bottom: 20px;
  span {
    color: white !important;
    line-height: 20px !important;
  }
  a {
    pointer-events: none;
    cursor: default;
  }
`;

function RtfContentPage({ isTos, isPP, isGovAgreement }) {
  const [htmlElements, setHtmlElements] = useState();

  const maxW = useBreakpointValue({
    base: '350px',
    md: '700px'
  });

  const rawDoc = isTos
    ? raw('../assets/docs/toc.rtf')
    : isPP
    ? raw('../assets/docs/pp.rtf')
    : isGovAgreement
    ? raw('../assets/docs/governance-agreement.rtf')
    : '';

  function stringToArrayBuffer(string) {
    const buffer = new ArrayBuffer(string.length);
    const bufferView = new Uint8Array(buffer);
    for (let i = 0; i < string.length; i++) {
      bufferView[i] = string.charCodeAt(i);
    }
    return buffer;
  }

  RTFJS.loggingEnabled(false);
  WMFJS.loggingEnabled(false);
  EMFJS.loggingEnabled(false);

  const doc = new RTFJS.Document(stringToArrayBuffer(rawDoc));
  useEffect(() => {
    if (!htmlElements) {
      doc
        .render()
        .then(function (elements) {
          setHtmlElements(elements);
        })
        .catch(error => {
          handleError('[RTF] render', error);
        });
    }
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: maxW,
        alignItems: 'flex-start'
      }}
    >
      {htmlElements?.length && htmlElements.map((elem, index) => <StyledText key={`index-${index}`} dangerouslySetInnerHTML={{ __html: elem.innerHTML }} />)}
    </div>
  );
}

export { RtfContentPage };
