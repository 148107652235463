import { Heading, Stack, Text, VStack } from 'native-base';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { GlassBackground } from './glass_background';
import SkButton from '../../lockdao/pages/components/sk_button';
import { subscribeToNewsletter } from '../api/newsletter';
import { validateEmail } from '../lib/helpers';
import { SkInput } from './sk_input';

export function HomeSecondaryActions(props) {
  const history = useHistory();
  const { pathname } = useLocation();
  const [email, setEmail] = useState('');
  const [subscribeError, setSubscribeError] = useState();
  const [subscribingNewsletter, setSubscribingNewsletter] = useState(false);
  const _setEmail = value => {
    setEmail(value);
    if (validateEmail(email)) {
      setSubscribeError(null);
    }
  };
  const handleSubscribeNewsletter = async () => {
    if (validateEmail(email)) {
      setSubscribingNewsletter(true);
      const ok = await subscribeToNewsletter(email);
      if (!ok) {
        setSubscribeError('Something went wrong, please try again.');
      }
      setSubscribingNewsletter(false);
      setEmail('');
    } else {
      setSubscribeError('Invalid email');
    }
  };

  if (pathname === '/not-eligible') {
    return (
      <Stack direction={{ base: 'column', md: 'row' }} alignItems={'center'} width={'100%'} mt={'40px'} space={{ base: '18px', md: '32px' }} zIndex={10}>
        <Text fontWeight={700} fontSize={'18px'} lineHeight={'24px'}>
          Stay Up to Date
        </Text>
        <VStack alignItems={'center'} space={'10px'}>
          <SkInput
            eventName={'Subscribe to newsletter'}
            value={email}
            onChange={v => _setEmail(v)}
            placeholder={'Email address'}
            onSubmit={handleSubscribeNewsletter}
            isLoading={subscribingNewsletter}
          />
          {!!subscribeError && (
            <Heading fontWeight={400} fontSize={'12px'} lineHeight={'16px'} color={'#FF7575'} textAlign={'center'}>
              {subscribeError}
            </Heading>
          )}
        </VStack>
      </Stack>
    );
  } else if (pathname === '/public') {
    return (
      <></>
      /* <GlassBackground w={{ base: '320px', md: '800px' }} h={{ base: '176px', md: '128px' }} p={{ base: '32px', md: '40px' }} mt={'80px'}>
        <Stack direction={{ base: 'column', md: 'row' }} justifyContent={'space-between'} alignItems={'center'} space={{ base: '24px', md: 0 }}>
          <Text
            maxW={{ base: '230px', md: '100%' }}
            fontWeight={{ base: 700, md: 800 }}
            fontSize={{ base: '20px', md: '28px' }}
            lineHeight={{ base: '24px', md: '36px' }}
            textAlign={{ base: 'center', md: 'left' }}
          >
            Get Verified and Join The SelfKey DAO Community!
          </Text>
          <SkButton fixedSize={false} w={{ base: '128px', md: '156px' }} h={{ base: '42px', md: '46px' }} onPress={() => history.push('/')}>
            Get Verified
          </SkButton>
        </Stack>
      </GlassBackground>*/
    );
  }
}
