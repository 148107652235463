import { Flex, Text, VStack } from 'native-base';
import SkButton from './components/sk_button';
import { PagesBackground } from '../../common/layout/main_layout.styled';
import React, { useContext } from 'react';
import { NftContext } from '../../common/context/app_context';
import { ModelLoader } from '../../common/components/model_loader';
import { JOIN_DISCORD_URL } from '../../common/lib/constants';

export function MintingFailed({ title = 'Minting failed', onRetry, errorMessage }) {
  const { clearMintError } = useContext(NftContext);
  // const { refetchPayments } = useContext(RegistryContext);
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  const handleRetry = () => {
    clearMintError();
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    onRetry();
    // refetchPayments();
  };
  const handleContactSupport = () => {
    window.open(JOIN_DISCORD_URL);
  };
  const error = errorMessage === 'Scorecard failed' ? 'You are not eligible for this action.' : 'Something went wrong, please try again.';
  return (
    <PagesBackground skStyle={true}>
      <VStack space={'40px'}>
        <ModelLoader model={'/3d/step-1/fail.glb'} scale={0.5}></ModelLoader>
        <Text fontSize={{ base: '18px', md: '48px' }} fontWeight={700} lineHeight={{ base: '22px', md: '60px' }} letterSpacing={'0em'} textAlign={'center'}>
          {title}
        </Text>
        <Text maxW={{ base: '280px', md: 'none' }} textAlign={'center'} color={'#FFEDD2'} mb={'23px'}>
          {error}
        </Text>
        <VStack alignItems={'center'} space={'64px'}>
          <SkButton eventName={'Retry - Minting Failed'} fixedSize={false} onPress={handleRetry}>
            Retry
          </SkButton>
          <SkButton eventName={`${title} - Contact Support`} fixedSize={false} variant={'secondary'} onPress={handleContactSupport}>
            Contact Support
          </SkButton>
        </VStack>
      </VStack>
    </PagesBackground>
  );
}
