import React, { useEffect } from 'react';
import { Modal, Text, VStack } from 'native-base';
// import { Linking } from 'react-native';
import { isDesktop } from 'react-device-detect';
import Countdown from './countdown';

const AutoRedirectModal = ({ isOpen, onClose, link, ttl }) => {
  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        openLink();
      }, 1000); // Adjust delay as needed
    }
  }, [isOpen, link]);

  const openLink = () => {
    try {
      window.open(link, '_blank');
      if (isDesktop) {
        onClose();
      }
    } catch (err) {
      console.error('Failed to open link:', err);
    }

    // Linking.openURL(link)
    //   .then(() => isDesktop && onClose())
    //   .catch(err => console.error('Failed to open link:', err));
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} height="100vh" _backdrop={{ bg: '#030c1e', opacity: 0.9 }}>
      <Modal.Content
        w={'100%'}
        maxWidth={{ base: '350px', md: '560px' }}
        borderRadius="16px"
        borderWidth="1px"
        borderColor="#0385E4"
        bg={'#000000'}
        shadow="2"
        p="40px"
        justifyContent={'center'}
      >
        <VStack space={4} alignItems="center">
          <Text>The link should open automatically.</Text>
          <Text>
            If it doesn't, please click{' '}
            <Text onPress={() => openLink()} color="blue.500" underline>
              here
            </Text>{' '}
            to redirect manually.
          </Text>
          <Countdown time={ttl} onComplete={() => onClose()}></Countdown>
        </VStack>
      </Modal.Content>
    </Modal>
  );
};

export default AutoRedirectModal;
