import React, { forwardRef } from 'react';
import { HStack, Image, Pressable, Spinner, Text, useBreakpointValue } from 'native-base';
import styled from 'styled-components';
import useClickEvent from '../../../common/hooks/use_click_event';

const BorderGradientWrapper = styled.div`
  position: relative;
  overflow: hidden;
  color: #fff;
  z-index: 0;
  border-radius: 40px;
  width: calc(100% - 20px);

  ${({ fixedSize, fullWidth, onlyIcon, size, hasIcon }) => {
    return `
      ${
        fixedSize
          ? `
        padding: 8px;
        min-height: ${onlyIcon ? 'none' : size === 'small' ? '24px' : '40px'};
        min-width: ${onlyIcon ? 'none' : size === 'small' ? '138px' : '264px'};
        `
          : `
          padding: ${onlyIcon ? 'none' : size === 'small' ? (hasIcon ? '6px 6px 6px 6px' : '8px 12px 8px 12px') : '16px 20px 16px 20px'};
        `
      }
        ${fullWidth ? 'width: calc(100% - 16px);' : ''}
      `;
  }}

  display: flex;
  justify-content: center;
  align-items: center;
  ${({ disabled }) => {
    return `
      opacity: ${disabled ? 0.5 : 1};
      cursor: ${disabled ? 'auto' : 'pointer'};
    `;
  }};

  i {
    content: '';
    position: absolute;
    z-index: -1;
    inset: 0;
    border-radius: 40px;
    padding: 1px; /* the thickness of the border */
    /* the below will do the magic */
    -webkit-mask: linear-gradient(
          0deg,
          rgba(0, 0, 0, ${({ variant }) => (variant === 'secondary' ? 0.9 : 0)}),
          rgba(0, 0, 0, ${({ variant }) => (variant === 'secondary' ? 0.9 : 0)})
        )
        content-box,
      linear-gradient(93.17deg, #20bdff 0%, #5433ff 100%);
    -webkit-mask-composite: xor; /* needed for old browsers until the below is more supported */
    mask-composite: exclude; /* this will exclude the first layer from the second so only the padding area will be kept visible */

    &:before {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      width: 100%;
      height: 100%;
      background: linear-gradient(93.17deg, #20bdff 0%, #5433ff 100%);
      transform: translate(-50%, -50%);
      /* use for animation effect on hover */
      //transition: transform 0.7s linear;
    }
  }

  /* use for animation effect on hover */
  //&:hover i::before {
  //  transform: translate(-50%, -50%) rotate(315deg);
  //}

  &:hover {
    box-shadow: 0 0 16px ${({ variant }) => (variant === 'secondary' ? '#5433ff' : '#0385E4')};
    border-radius: 40px;
    transition: all 0.3s ease-in-out 0s;
  }
`;

const SkButton = forwardRef(({ onPress, isDisabled, eventName, ...props }, ref) => {
  const { setButtonClickEvent } = useClickEvent();
  const handlePress = () => {
    if (onPress && !isDisabled && !props.isLoading) {
      onPress();
      eventName && setButtonClickEvent(eventName);
    } else if (isDisabled && props.onDisabledClick) {
      props.onDisabledClick();
      eventName && setButtonClickEvent(`${eventName} disabled`);
    }
  };
  const defaultFontSize = useBreakpointValue({ base: '14px', md: '16px' });
  const onlyIcon = !props.children && props.leftIcon;
  return (
    <Pressable justifyContent="center" alignItems="center" ref={ref} onPress={handlePress} {...props}>
      <BorderGradientWrapper
        disabled={props.isDisabled || props.isLoading}
        size={props.size}
        variant={props.variant}
        onlyIcon={onlyIcon}
        hasIcon={!!props.leftIcon || !!props.rightIcon}
        fixedSize={props.fixedSize !== undefined ? props.fixedSize : true}
        fullWidth={props.fullWidth !== undefined ? props.fullWidth : false}
        ref={ref}
      >
        <i></i>
        <HStack alignItems={'center'} space="3" px={props.noPadding ? '0px' : '10px'}>
          {props.leftIcon && !props.isLoading && (
            <Image
              source={{
                uri: props.leftIcon
              }}
              ref={ref}
              alt="icon"
              w={props.leftIconSize ? props.leftIconSize : props.size === 'small' ? '20px' : '40px'}
              h={props.leftIconSize ? props.leftIconSize : props.size === 'small' ? '20px' : '40px'}
              resizeMode="contain"
            />
          )}
          {props.isLoading && <Spinner colorScheme={'white'} />}
          {props.children && (
            <Text fontWeight={'700'} fontSize={props.size === 'small' ? '12px' : defaultFontSize} lineHeight={defaultFontSize} color={'#FFFFFF'}>
              {props.children}
            </Text>
          )}
        </HStack>
      </BorderGradientWrapper>
    </Pressable>
  );
});

SkButton.displayName = 'SkButton';
export default SkButton;
