import React, { useContext, useEffect, useState } from 'react';
import { AirdropContext, Web3WagmiContext } from './app_context';
import airdropABI from '../assets/abis/SelfkeyDaoSelfAirdrop.json';
import { CachePolicies, useFetch } from 'use-http';
import { current as envConfigs } from 'env-configs/selfkey-org';
import { formatUnits } from 'viem';
import { LoadingSpinner } from '../../selfkey-id/pages/components/loading_spinner';
import { useContractWriteAndWait } from '../hooks/use_contract_write_and_wait';
import { convertNumber } from '../lib/helpers';

export function AirdropProvider({ children }) {
  const { activeAddress: address, isConnected, isConnecting: isAccountLoading, contracts, unsupportedChain } = useContext(Web3WagmiContext);
  const { write, completed, transaction, waitingTransaction, isLoading, hasError, resetState } = useContractWriteAndWait({
    contractAddress: contracts?.airdrop,
    functionName: 'selfAirdrop',
    contractAbi: airdropABI.abi
  });

  const { get: getActiveProposals } = useFetch(`${envConfigs.serverUrl}/governance/active-proposals`, {
    cachePolicy: CachePolicies.NETWORK_ONLY
  });

  const { get: getAirdropProposalDetails } = useFetch(`${envConfigs.serverUrl}/rewards/airdrop-proposal`, {
    cachePolicy: CachePolicies.NETWORK_ONLY
  });
  const [activeProposal, setActiveProposal] = useState(null);
  const getActiveProposal = async () => {
    const proposals = await getActiveProposals();
    if (proposals?.status === 'ok' && proposals?.activeProposals?.airdropId) {
      const activeProposal = await getAirdropProposalDetails(`${proposals.activeProposals.airdropId}/${address}`);
      if (activeProposal && activeProposal.active) {
        setActiveProposal(activeProposal);
      }
    }
  };

  useEffect(() => {
    if (address) {
      getActiveProposal();
    }
  }, [address]);

  const [airdropAmount, setAirdropAmount] = useState(<LoadingSpinner />);
  const [canReceiveAirdrop, setCanReceiveAirdrop] = useState(false);
  useEffect(() => {
    if (activeProposal?.active) {
      if (activeProposal.amount) {
        setAirdropAmount(convertNumber(formatUnits(activeProposal.amount, 18), false));
      }
      setCanReceiveAirdrop(!!activeProposal.canReceive);
    }
  }, [activeProposal]);

  const callSelfAirdrop = async params =>
    await write({
      args: [params.to, params.param, params.timestamp, params.signer, params.signature]
    });

  return (
    <AirdropContext.Provider
      value={{
        waitingAirdropTransaction: waitingTransaction,
        airdrop: callSelfAirdrop,
        hasError,
        airdropCompleted: completed,
        airdropTransaction: transaction,
        resetAirdropState: resetState,
        activeProposal,
        airdropAmount,
        canReceiveAirdrop,
        isLoading,
        refetchActiveProposal: getActiveProposal
      }}
    >
      {children}
    </AirdropContext.Provider>
  );
}
