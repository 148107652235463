import React from 'react';
import { VStack } from 'native-base';
import Hero from './components/hero';

function LandingPageComponent({ web3 }) {
  return (
    <VStack mt="55px" mb="0" space="8" justifyContent={'center'}>
      <Hero isConnected={!!web3} />
    </VStack>
  );
}

export { LandingPageComponent };
