import React from 'react';
import { Button, Container, Heading, Stack } from 'native-base';
import { ConnectWallet } from '../../../common/components/connect_wallet';
import avatarsGif from '../../assets/imgs/poi-landing.gif';
import avatarsBg from '../../assets/imgs/poi-landing-avatars-bg.png';
import styled from 'styled-components';

const TextGradient = styled.b`
  background-image: linear-gradient(to right, #2da1f8, #cd22c6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
`;

export default function Hero({ isConnected = false }) {
  const isMobile = window.matchMedia('only screen and (max-width: 760px)').matches;

  return (
    <Container mx={'auto'} maxWidth={'800px'} px={isMobile && 5}>
      <Stack textAlign={'center'} space={{ base: 8, md: 10 }} py={{ base: 20, md: 36 }} mt="20">
        <Heading size={{ base: 'xl', sm: '2xl', md: '2xl' }} fontWeight={600} lineHeight={50} fontSize={40}>
          Get a free <TextGradient>NFT</TextGradient> and customize it
          <br />
        </Heading>

        <Stack alignItems="center" textAlign="center" space="4">
          {!isMobile && (
            <>
              <img src={avatarsGif} width="500px" align={'center'} />
              <img src={avatarsBg} style={{ position: 'absolute', zIndex: -1 }} />
            </>
          )}
          <Stack direction={'column'} space={2} align={'center'} alignSelf={'center'} position={'relative'}>
            {!isConnected && <ConnectWallet />}
            {isConnected && (
              <Button size={'lg'} href={'/v0/dashboard'} bg={'#0099FF'} color={'white'} _hover={{ bg: '#0187F0' }} rounded={'full'}>
                Get Started
              </Button>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Container>
  );
}

export { Hero };
