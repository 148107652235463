import React from 'react';
import { Box } from 'native-base';
import { HeaderBar } from '../../../common/components/header_bar';
import { Footer } from '../../../common/designs/footer';

export const MainLayout = React.memo(props => {
  return (
    <Box justifyContent={'center'} backgroundColor={'black'}>
      <HeaderBar mvp={props.mvp} skStyle={props.skStyle} />
      <>
        {props.children}
        <Footer mvp={props.mvp} />
      </>
    </Box>
  );
});
