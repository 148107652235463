import React, { useContext, useEffect, useState } from 'react';
import { Flex, HStack, Image, Text, useBreakpointValue, View, VStack } from 'native-base';
import { PagesBackground } from '../../common/layout/main_layout.styled';
import SkButton from './components/sk_button';
import { APP_NAME, PAGES } from '../../common/lib/constants';
import poiTakeSelfieImg from '../assets/imgs/poi-take-selfie.png';
import useClickEvent from '../../common/hooks/use_click_event';
import { CaptureImage, useVideoCapture } from '../../selfkey-id/pages/selfie_check_page';
import { current as envConfigs } from 'env-configs/selfkey-org';
import usePost from '../../common/hooks/use_post';
import { RejectedPage } from './rejected_page';
import { ConsentAgreement } from '../../common/components/consent_agreement';
import LdButton from './components/sk_button';
import WarningModal from '../../common/components/warning_modal';
import { SkCheckbox } from '../../common/components/sk_checkbox';
import { handleError } from '../../common/lib/error_handler';
import { SuccessPage } from './success_page';
import { useHistory } from 'react-router-dom';
import keyToken from '../../common/assets/icons/key-token.svg';
import { SkBackButton } from './components/sk_back_button';
import useDocumentTitle from '../../common/hooks/use_document_title';
import { RegistryContext } from '../../common/context/app_context';

function POICredentialTakeSelfie({ mode = 'full', handleAction, address, signTypedDataAsync, isOpen }) {
  const history = useHistory();
  const { setDocumentTitle } = useDocumentTitle();

  React.useEffect(() => {
    if ((mode === 'modal' && isOpen) || mode === 'full') {
      setDocumentTitle(`${APP_NAME} - ${PAGES.POI_CREDENTIAL_TAKE_SELFIE}${mode === 'full' ? '' : ' - Modal'}`);
    }
  }, [isOpen]);

  const { videoRef, canvasRef, startCamera, stopCamera, captureImage, isCameraActive, capturedImage, onRetry, setCapturedImage } = useVideoCapture();
  const [errorTitle, setErrorTitle] = useState(false);
  const [errorContent, setErrorContent] = useState(false);
  const [errorAction, setErrorAction] = useState('Retry');
  const [results, setResults] = useState(null);

  const [showConsentModal, setShowConsentModal] = useState(false);
  const [consent, setConsent] = useState(null);
  const [consentView, setConsentView] = useState(null);
  const [sendConsentRequest, { status: consentStatus, loading: loadingConsent }] = usePost();
  const [consentApproved, setConsentApproved] = useState(false);
  const [loadingSignConsent, setLoadingSignConsent] = useState(false);

  const { consentPayload, checkAuthorized, resetConsentPayload } = useContext(RegistryContext);

  const [vkrSuccess, setVkrSuccess] = useState(false);

  const { setButtonClickEvent } = useClickEvent();
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [errors, setErrors] = React.useState({});

  const [requestSkr, { data: skrData, loading: loadingSkr, status: statusSkrRequest }] = usePost();
  const [sendSkr, { data: sendSkrData, loading: loadingSendSkr, error: errorSendSkr, status: statusSendSkr, response: sendSkrResponse }] = usePost();
  const [sendVkr, { data: vkrData, loading: loadingVkr, status: statusVkr }] = usePost();

  const acceptTerms = checked => {
    setAcceptedTerms(checked);
    setButtonClickEvent(`${PAGES.POI_CREDENTIAL_TAKE_SELFIE} - Accept Terms: ${checked}`);
    if (checked) {
      setErrors({});
    }
  };
  const handleTakeSelfie = () => {
    if (!acceptedTerms) {
      setErrors({
        ...errors,
        agreement: 'You have to agree with terms and conditions.'
      });
      return;
    }
    captureImage();
  };

  const handleRetry = () => {
    setErrorTitle(false);
    setErrorContent(false);
    setResults(null);
    setCapturedImage(null);
    if (mode === 'full') {
      startCamera();
    } else {
      stopCamera();
    }
    if (errorTitle === 'Not Eligible!') {
      history.push('/member');
    }
  };

  const handleAgreeConsent = async () => {
    setLoadingSignConsent(true);
    setShowConsentModal(false);
    const consentData = consent ? consent : consentPayload;
    try {
      const signature = await signTypedDataAsync({
        domain: {
          name: 'KYCS.Ai Consent',
          version: '1'
        },
        primaryType: 'KycsaiConsent',
        types: {
          KycsaiConsent: [{ name: 'content', type: 'string' }]
        },
        message: {
          content: consentData.consent
        }
      });
      await sendConsentRequest(consentData.consent_url, { signature });
      setConsentApproved(true);
      if (skrData?.SKR?.url) {
        await sendSkr(skrData.SKR.url, { SKR: skrData.SKR, selfie: capturedImage });
      }
    } catch (e) {
      handleError('Error signing consent', e);
    } finally {
      setLoadingSignConsent(false);
    }
  };

  const handleSuccessAction = () => {
    setVkrSuccess(false);
    setErrorContent(null);
    setAcceptedTerms(false);
    setCapturedImage(null);
    handleAction();
  };

  const handleBack = () => {
    stopCamera();
    history.push('/member/poi-credential-start');
  };

  const agreeFontSize = useBreakpointValue({
    base: '10px',
    md: '12px'
  });
  const agreeLineHeight = useBreakpointValue({
    base: '14px',
    md: '16px'
  });
  const agreeTermsStyle = {
    fontWeight: 400,
    fontSize: agreeFontSize,
    lineHeight: agreeLineHeight,
    paddingLeft: '2px',
    color: '#A9A9A9'
  };

  useEffect(() => {
    let stream;
    if (mode === 'full' || isOpen) {
      startCamera().then(ms => (stream = ms));
    }
    // Cleanup function to turn off the camera stream when the component is unmounted
    return () => {
      stopCamera(stream);
    };
  }, [isOpen]);

  useEffect(() => {
    mode === 'full' && checkAuthorized && checkAuthorized();
  }, []);

  useEffect(() => {
    if (mode === 'full' && consentPayload) {
      setConsent(consentPayload);
      setConsentView(() => <ConsentAgreement data={consentPayload} />);
      setShowConsentModal(true);
    }
  }, [consentPayload]);

  useEffect(() => {
    if (!loadingConsent && consentStatus === 200) {
      resetConsentPayload();
    }
  }, [loadingConsent, consentStatus]);

  useEffect(() => {
    if (capturedImage) {
      setResults(null);
      if (mode === 'full') {
        requestSkr(`${envConfigs.serverUrl}/credential/poi-check-request/${address}`);
      } else {
        handleAction(capturedImage);
        setVkrSuccess(false);
        setErrorContent(null);
        setAcceptedTerms(false);
        setCapturedImage(null);
      }
    }
  }, [capturedImage]);

  useEffect(() => {
    if (!loadingSkr && skrData?.SKR && statusSkrRequest === 200) {
      sendSkr(skrData.SKR.url, { SKR: skrData.SKR, selfie: capturedImage });
    } else if (statusSkrRequest && statusSkrRequest !== 200) {
      setErrorAction('Retry');
      setErrorTitle('Oh, no!');
      setErrorContent('Something went wrong, please try again.');
    }
  }, [loadingSkr, skrData, statusSkrRequest]);

  useEffect(() => {
    if (!loadingSendSkr && sendSkrData?.status === 'ok' && sendSkrData?.payload) {
      if (sendSkrData.verifyUrl) {
        sendVkr(sendSkrData.verifyUrl, sendSkrData);
      }
    } else if (statusSendSkr && statusSendSkr !== 200) {
      if (statusSendSkr === 422 && errorSendSkr === 'POI check failed') {
        setErrorAction('Retry');
        setErrorTitle('Verification Failed!');
        setErrorContent(
          'The selfie provided did not match your selfie collected during the SelfKey iD Verification.\n' +
            '\n' +
            'Please make sure you are using a better quality camera.\n' +
            "For best results, using your phone's camera is recommended."
        );
      } else if (statusSendSkr === 422 && (errorSendSkr === 'Invalid SKR' || errorSendSkr === 'Scorecard failed')) {
        setErrorAction('Continue');
        setErrorTitle('Not Eligible!');
        setErrorContent('It looks like you are not eligible for this action at this time.');
      } else if (statusSendSkr === 451 && !consentApproved) {
        const skr = sendSkrResponse?.data;
        setConsent(skr);
        setConsentView(() => <ConsentAgreement data={skr} />);
        setShowConsentModal(true);
      } else {
        setErrorAction('Retry');
        setErrorTitle('Oh, no!');
        setErrorContent('Something went wrong, please try again.');
      }
    }
  }, [loadingSendSkr, sendSkrData, statusSendSkr, errorSendSkr]);

  useEffect(() => {
    if (!loadingVkr && vkrData?.status === 'ok' && vkrData?.credential) {
      console.log('POI Credential', vkrData.credential);
      setVkrSuccess(true);
    } else if (statusVkr && statusVkr !== 200) {
      setErrorAction('Retry');
      setErrorTitle('Oh, no!');
      setErrorContent('Something went wrong, please try again.');
    }
  }, [loadingVkr, statusVkr, vkrData]);

  if (errorContent) {
    return (
      <RejectedPage
        includeLayout={false}
        title={errorTitle}
        content={errorContent}
        actionLabel={errorAction}
        handleAction={handleRetry}
        eventPage={`${PAGES.POI_CREDENTIAL_TAKE_SELFIE} - ${errorContent}`}
      />
    );
  }

  if (vkrSuccess) {
    return (
      <SuccessPage
        includeLayout={false}
        title={'Congratulations!'}
        content={
          <VStack>
            <Text mb={'20px'}>Your Proof of Individuality (POI) Credential has been generated.</Text>
            <Text display={'flex'} alignItems={'center'}>
              In order to gain the benefits of the POI Credential, you must lock it with KEY{' '}
              <Image alt="key token" source={{ uri: keyToken }} w={'16px'} h={'16px'} />
            </Text>
          </VStack>
        }
        actionLabel={mode === 'full' ? 'Lock my POI Credential' : 'Continue'}
        handleAction={handleSuccessAction}
        eventPage={`${PAGES.POI_CREDENTIAL_TAKE_SELFIE} - Congratulations!`}
      />
    );
  }

  return (
    <PagesBackground paddingTop={mode === 'full' ? null : '0'}>
      <VStack alignItems={'center'} space={{ base: '32px', md: '40px' }} maxWidth={'1216px'} width={'100%'}>
        {mode === 'full' && (
          <View position={'absolute'} left={0} top={'40px'}>
            <SkBackButton eventName={PAGES.POI_CREDENTIAL_TAKE_SELFIE + ' - Back Button'} onPress={() => handleBack()} />
          </View>
        )}
        {!isCameraActive ? (
          <Image alt="Take Selfie" source={{ uri: poiTakeSelfieImg }} w={{ base: '260px', md: '448px' }} h={{ base: '260px', md: '448px' }} />
        ) : (
          <Flex alignItems={'center'}>
            <canvas ref={canvasRef} width="400" height="400" style={{ display: 'none' }} />
            <CaptureImage
              videoRef={videoRef}
              canvasRef={canvasRef}
              captureImage={captureImage}
              isCameraActive={isCameraActive}
              capturedImage={capturedImage}
              loadingCamera={true}
              onRetry={() => {
                setResults(null);
                onRetry();
              }}
            />
          </Flex>
        )}
        {!capturedImage && (
          <Text
            fontFamily={{ base: 'Inter', md: 'Lato' }}
            fontSize={{ base: '14px', md: '16px' }}
            lineHeight={{ base: '20px', md: '24px' }}
            maxW={{ base: '260px', md: '100%' }}
            color={'#FFEDD2'}
            textAlign={'center'}
          >
            Stare directly into the camera and press the 'Take Selfie' button.
          </Text>
        )}
        <SkButton
          eventName={PAGES.POI_CREDENTIAL_TAKE_SELFIE}
          onPress={handleTakeSelfie}
          fixedSize={false}
          w={'180px'}
          isDisabled={capturedImage}
          isLoading={loadingSkr || loadingSendSkr || loadingSignConsent}
        >
          Take Selfie
        </SkButton>
        {'agreement' in errors && <Text color={'rgb(239, 68, 68)'}>{errors.agreement}</Text>}
        <SkCheckbox
          acceptedTerms={acceptedTerms}
          acceptTerms={acceptTerms}
          alignItems={'flex-start'}
          mt={'20px'}
          mb={{ base: mode === 'full' ? '31px' : '20px', md: mode === 'full' ? '335px' : '40px' }}
        >
          <HStack maxW={{ base: '298px', md: '796px' }}>
            <Text {...agreeTermsStyle}>
              We require a selfie for verification to provide you with a secure and personalized service. This may involve capturing and analyzing your image to
              ensure it matches the identity document provided during the initial verification process. Our partner, kycs.ai, an independent third-party entity,
              manages this process. Rest assured, your selfie will not be stored on our backend but will be used for this verification by kycs.ai. By
              proceeding, you give explicit consent to collecting your selfie image, the automated decision-making process, and using your selfie for
              verification purposes. If you do not wish to give this consent, please refrain from using this service. If you have any questions, please contact
              us or refer to our privacy policy (
              <a href={'https://selfkey.org/privacy-policy'} target="_blank" rel="noopener noreferrer" style={{ textDecorationColor: '#FFFFFF' }}>
                <Text
                  color={'#FFFFFF'}
                  fontWeight={agreeTermsStyle.fontWeight}
                  fontSize={agreeTermsStyle.fontSize}
                  lineHeight={agreeTermsStyle.lineHeight}
                  paddingLeft={agreeTermsStyle.paddingLeft}
                >
                  https://selfkey.org/privacy-policy
                </Text>
              </a>
              ) or kycs.ai privacy policy (
              <a href={'https://kycs.ai/privacy-policy'} target="_blank" rel="noopener noreferrer" style={{ textDecorationColor: '#FFFFFF' }}>
                <Text
                  color={'#FFFFFF'}
                  fontWeight={agreeTermsStyle.fontWeight}
                  fontSize={agreeTermsStyle.fontSize}
                  lineHeight={agreeTermsStyle.lineHeight}
                  paddingLeft={agreeTermsStyle.paddingLeft}
                >
                  https://kycs.ai/privacy-policy
                </Text>
              </a>
              ).
            </Text>
          </HStack>
        </SkCheckbox>
        {mode === 'full' && (
          <SkBackButton
            eventName={PAGES.POI_CREDENTIAL_TAKE_SELFIE + ' - Back Button'}
            onPress={() => history.push('/member/poi-credential-start')}
            isMobile={true}
            mb={'80px'}
          />
        )}
      </VStack>
      <WarningModal
        showModal={showConsentModal}
        title={consentView}
        leftButton={
          <LdButton variant={'secondary'} fixedSize={false} w={'134px'} onPress={() => setShowConsentModal(false)}>
            Cancel
          </LdButton>
        }
        rightButton={
          <LdButton fixedSize={false} w={'134px'} onPress={() => handleAgreeConsent()}>
            Agree
          </LdButton>
        }
        handleClose={() => setShowConsentModal(false)}
      />
    </PagesBackground>
  );
}

export { POICredentialTakeSelfie };
