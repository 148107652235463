import { PagesBackground } from '../layout/main_layout.styled';
import { Box, Center, Text } from 'native-base';
import { ModelLoader } from './model_loader';
import ProgressBar from '../../selfkey-id/pages/components/progress_bar';
import React from 'react';

export const SigningConsentPage = props => (
  <PagesBackground>
    <Center w="100%" alignSelf={'flex-start'} mt={'80px'} alignItems={'center'}>
      <Box w="90%" maxW="400">
        <Box h={{ base: '200px', md: '250px' }} alignSelf={'center'}>
          <ModelLoader model={'/3d/step-1/loading_cube.glb'} scale={0.3} verticalInclination={Math.PI / 3}></ModelLoader>
        </Box>
        <Text fontSize={{ base: '16px', md: '18px' }} fontWeight={700} textAlign={'center'} mb={'24px'} mt={'40px'}>
          Please Sign the message on your wallet in order to provide consent
        </Text>
        <ProgressBar />
      </Box>
    </Center>
  </PagesBackground>
);
