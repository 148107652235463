import { Checkbox, Icon, useBreakpointValue, VStack } from 'native-base';
import React from 'react';

export const SkCheckbox = ({ acceptedTerms, acceptTerms, children, ...props }) => {
  const agreeFontSize = useBreakpointValue({
    base: '10px',
    md: '12px'
  });
  const agreeLineHeight = useBreakpointValue({
    base: '14px',
    md: '16px'
  });
  const agreeTermsStyle = {
    fontWeight: 400,
    fontSize: agreeFontSize,
    lineHeight: agreeLineHeight,
    paddingLeft: '2px',
    color: '#A9A9A9'
  };
  return (
    <VStack>
      <Checkbox
        {...props}
        isChecked={acceptedTerms}
        onChange={e => acceptTerms(e)}
        alignSelf={{ base: 'center', md: 'left' }}
        letterSpacing={0}
        m={0}
        colorScheme={'green'}
        borderColor={'white'}
        borderWidth={'1px'}
        bg={'rgba(0, 0, 0, 0.2)'}
        _hover={{ borderColor: 'white' }}
        _icon={{ color: 'white' }}
        pb={0}
        pr={0}
        icon={
          <Icon>
            <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 5.5L4 8L8.5 1" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </Icon>
        }
      >
        {children}
      </Checkbox>
    </VStack>
  );
};
