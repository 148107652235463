import { View } from 'native-base';

function GlassBackground(props) {
  return (
    <View
      style={{
        border: '1px solid transparent',
        background: 'linear-gradient(100.17deg, rgba(255, 255, 255, 0.12) 17.83%, rgba(255, 255, 255, 0.05) 69.86%)',
        backgroundClip: 'padding-box, border-box',
        backdropFilter: !props.disabled ? 'blur(4px)' : ''
      }}
      borderRadius={'16px'}
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={{ base: 'center', md: 'stretch' }}
      {...props}
    >
      <View
        style={{
          background: 'linear-gradient(135deg, rgba(205, 34, 198, 0) 57.05%, rgba(205, 34, 198, 0.1) 97.3%)',
          transform: 'rotate(180deg)'
        }}
        borderRadius={'16px'}
        w={'100%'}
        maxW={'160px'}
        h={'100%'}
        maxH={'155px'}
        position={'absolute'}
        top={0}
        left={0}
      ></View>
      <View
        style={{
          background: 'linear-gradient(135deg, rgba(3, 133, 228, 0) 52.2%, rgba(3, 133, 228, 0.15) 97.3%)'
        }}
        borderRadius={'16px'}
        w={'100%'}
        maxW={'160px'}
        h={'100%'}
        maxH={'155px'}
        position={'absolute'}
        bottom={0}
        right={0}
      ></View>
      {props.children}
    </View>
  );
}

export { GlassBackground };
