import { MIN_STAKING_AMOUNT } from '../config/index.js';
import { keccag256 } from '../../common/lib/helpers.js';
import { current as envConfigs } from 'env-configs/selfkey-org.js';

/**
 * "" = no credential, credential is not yet created
 * "valid" = default state after staking
 * "underDispute"
 * "revoked"
 * "disabled" = when user withdraws stake
 *
 * @param {*} param0
 * @returns string|boolean
 */
function getCredentialState({ credential, staking }) {
  // TODO: support other credential states
  return credential ? (credential && staking.balance >= MIN_STAKING_AMOUNT ? 'active' : 'disabled') : false;
}

function isCredentialDisabled({ credential, staking }) {
  const state = getCredentialState({ credential, staking });
  return state === false || state === 'disabled';
}

function getCredentialFileUri(did) {
  if (!did) {
    return false;
  }
  did = did.toLowerCase();
  return `${envConfigs.serverUrl}/credential/${did}`;
}

const SK_ENTRY_FEE = keccag256('SK_ENTRY_FEE');
const REGISTRY_CREDENTIAL_TYPE = keccag256('SK_CRED_METAPROOF');

const REGISTRY_ALLOWED_CRYPTO = keccag256('SK_CRED_METAPROOF_ALLOWED_CRYPTO');

export { getCredentialState, isCredentialDisabled, getCredentialFileUri, REGISTRY_CREDENTIAL_TYPE, REGISTRY_ALLOWED_CRYPTO, SK_ENTRY_FEE };
