import React, { useEffect, useState } from 'react';
import { useAccount, useConnect, useDisconnect, useSwitchChain } from 'wagmi';
import { Web3WagmiContext } from './app_context';
import { appEnv } from 'env-configs';
import { current as envConfigs } from 'env-configs/selfkey-org';
import { handleError } from '../lib/error_handler';

const network = appEnv === 'production' ? 'Polygon' : 'Polygon Amoy';

export function Web3WagmiProvider({ children, allChains }) {
  const { address, isConnecting, isConnected, connector, chain } = useAccount({});
  const { connectors, connectAsync, error: errorConnect } = useConnect({ connector });
  const { disconnectAsync, isLoading: isLoadingDisconnect } = useDisconnect();
  const {
    chains,
    error: errorSwitchNetwork,
    isLoading: isLoadingSwitchNetwork,
    pendingChainId,
    switchChain
  } = useSwitchChain({
    throwForSwitchChainNotSupported: false
  });

  const chainDetails = allChains.find(c => c.id === chain?.id);

  const [contracts, setContracts] = useState();
  const [activeChain, setActiveChain] = useState(chain);
  const [unsupportedChain, setUnsupportedChain] = useState();
  useEffect(() => {
    if (chain && envConfigs) {
      console.log('[WEB3] Chain detected', chain);
      setUnsupportedChain(chain.name !== network);
    }
    setActiveChain(chain);
  }, [chain]);

  useEffect(() => {
    if (envConfigs) {
      console.log('[WEB3] EnvConfigs detected', envConfigs);
      setContracts({
        nft: envConfigs.nftContractAddress,
        keyToken: envConfigs.keyTokenContractAddress,
        selfkeyPaymentsRegistry: envConfigs.selfkeyPaymentRegistryContractAddress,
        selfkeyIdNft: envConfigs.selfkeyIdNftContractAddress,
        lockToken: envConfigs.lockTokenContractAddress,
        registry: envConfigs.registryContractAddress,
        usdc: envConfigs.usdcContractAddress,
        metaproofNft: envConfigs.metaproofNftContractAddress,
        staking: envConfigs.stakingContractAddress,
        voting: envConfigs.selfkeyDaoVoting,
        airdrop: envConfigs.selfAirdropContractAddress,
        inviteCodeRegistry: envConfigs.inviteCodeRegistryContractAddress,
        unclaimedRewards: envConfigs.unclaimedRewardsRegistryContractAddress,
        selfToken: envConfigs.selfTokenContractAddress,
        selfTokenEth: envConfigs.selfTokenContractEthAddress,
        selfBridge: envConfigs.selfBridgeRootTunnelAddress,
        selfBridgeEth: envConfigs.selfBridgeRootTunnelEthAddress
      });
    }
  }, [envConfigs]);

  const [activeAddress, setActiveAddress] = useState(address);
  useEffect(() => {
    if (!address) {
      console.log('[WEB3] Disconnected from account', activeAddress);
    } else if (address !== activeAddress) {
      console.log('[WEB3] Address detected', address);
    }
    localStorage.removeItem('publicKey');
    setActiveAddress(address);
  }, [address]);

  // useEffect(() => {
  //   const doAsync = async () => {
  //     try {
  //       await connectAsync({ connector: JSON.parse(currentConnector) });
  //     } catch (e) {
  //       //console.log('Error attempting to reestablish wallet connection', e);
  //     }
  //   };
  //   const currentConnector = localStorage.getItem('wagmi.wallet');
  //   if (!isConnecting && !isLoadingDisconnect && !connector?.id && currentConnector) {
  //     doAsync();
  //   }
  // }, [connector, isConnecting]);

  const connect = async connectorId => {
    try {
      const data = await connectAsync({
        chainId: allChains.find(c => c.name === network).id,
        connector: connectors.find(item => item.id === connectorId)
      });
      console.log('[WEB3] Connected to account', data.account);
    } catch (e) {
      // console.error(e);
    }
  };

  const disconnect = async () => {
    try {
      for (const c of connectors) {
        await disconnectAsync({ connector: c });
      }
    } catch (e) {
      handleError('Error on disconnect', e);
    }
  };

  const isPolygonNetwork = activeChain?.network === 'matic' || activeChain?.network === 'maticmum';

  return (
    <Web3WagmiContext.Provider
      value={{
        connect,
        disconnect,
        isConnecting,
        isConnected,
        errorConnect,
        activeAddress,
        baseAddress: address,
        connector,
        contracts,
        activeChain,
        chains,
        chainDetails,
        switchNetwork: switchChain,
        isLoadingSwitchNetwork,
        pendingChainId,
        errorSwitchNetwork,
        unsupportedChain,
        network,
        allChains,
        isPolygonNetwork
      }}
    >
      {children}
    </Web3WagmiContext.Provider>
  );
}
