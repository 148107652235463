import React from 'react'
import {SkBackButton} from "../sk_back_button";
import {SkButton} from "../../../../common/components/sk_button";
import { ModelLoader } from "../../../../common/components/model_loader";
import styled from "styled-components";
import {FunctionComponent} from 'react'
import {AirdropClaimComponentType} from './AirdropClaimComponent'
import {View} from 'native-base'

const Self3Icon = styled.img`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 200px;
  height: 243px;
  object-fit: cover;
`;
const Dmodelframe = styled(ModelLoader)`
  position: relative;
  width: 243px;
  height: 243px;
`;
const Claim30001Self = styled.b`
  //position: absolute;
  //top: 0px;
  //left: 0px;
  line-height: 36px;
  //display: inline-block;
  //white-space: pre-wrap;
  //width: 252px;
`;
const SelfIcon = styled.img`
  //position: absolute;
  top: 42px;
  left: 94px;
  width: 24px;
  height: 24px;
  overflow: hidden;
  object-fit: cover;
`;
const Claim30001SelfTokensParent = styled.div`
  position: relative;
  width: 252px;
  height: 72px;
`;
const Button2 = styled.b`
  position: relative;
  line-height: 16px;
`;
const Button1 = styled(SkButton)`
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 40px;
  background: linear-gradient(93.17deg, #2da1f8, #cd22c6);
  width: 161px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px 32px;
  box-sizing: border-box;
`;
const Button = styled.div`
  position: relative;
  width: 161px;
  height: 40px;
  text-align: left;
  font-size: 14px;
  font-family: Inter;
`;
const DmodelframeParent = styled.div`
  //position: absolute;
  //top: 0px;
  //left: calc(50% - 126px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 40px;
`;
const BackIcon = styled.img`
  position: relative;
  width: 16px;
  height: 16px;
  object-fit: cover;
`;
const Button4 = styled(SkBackButton)`
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 40px;
  background-color: rgba(0, 0, 0, 0.2);
  border: 1px solid #2da1f8;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px 32px;
  gap: 8px;
`;
const Button3 = styled.div`
  position: absolute;
  top: 499px;
  left: 65px;
  width: 122px;
  height: 40px;
  text-align: left;
  font-size: 14px;
  font-family: Inter;
`;
const FrameParentRoot = styled.div`
  position: relative;
  //top: 76px;
  //left: 54px;
  //width: 252px;
  //height: 539px;
  text-align: center;
  font-size: 28px;
  color: #fff;
  font-family: "Plus Jakarta Sans";
`;

const AirdropMobileComponent: FunctionComponent<AirdropClaimComponentType> = ({selfAmount, handleBack, handleClaim, loadingClaim}) => {
  return (
    <FrameParentRoot>
      <DmodelframeParent>
        <View w={'243px'} h={'243px'}>
          <Dmodelframe id="SelfTokenModel" model="/3d/step-1/self.glb">
          </Dmodelframe>
        </View>
        <Claim30001SelfTokensParent id="ClaimContent">
          <Claim30001Self>
            Claim {selfAmount} <br/>
            SELF <SelfIcon alt="" src="/self.svg"/> Tokens!
          </Claim30001Self>
        </Claim30001SelfTokensParent>
        <Button>
          <Button1 id="ClaimButton" fixedSize={false} onPress={handleClaim} isLoading={loadingClaim} eventName={'Claim Airdrop'}>
            <Button2>Claim</Button2>
          </Button1>
        </Button>
      </DmodelframeParent>
      <View display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'center'} mt={'70px'} mb={'40px'}>
        <SkBackButton id="BackButton" onPress={handleBack} isMobile={true} eventName={'Back Button'} />
      </View>
    </FrameParentRoot>
  );
};

export default AirdropMobileComponent;
