import { useMatomo } from '@datapunt/matomo-tracker-react';
import { useCallback } from 'react';
import { appEnv } from 'env-configs';

const useClickEvent = () => {
  const { trackEvent } = useMatomo();
  const setButtonClickEvent = useCallback(event => {
    if (event) {
      if (appEnv !== 'production') {
        console.log('[DEBUG] trackEvent/button', event);
      }
      trackEvent({ name: `${event} Click`, category: event, action: 'button-click' });
    }
  }, []);
  return { setButtonClickEvent };
};
export default useClickEvent;
