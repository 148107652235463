import React, { useMemo } from 'react';
import styled from 'styled-components';
import VideoBackground from '../../../common/components/video_background';
import video from '../../../common/assets/videos/id_hero.mp4';
import videoHomeHero from '../../../common/assets/videos/hero_full.mp4';
import { PagesBackgroundLD } from '../../../common/layout/main_layout.styled';
import { useBreakpointValue } from 'native-base';

export const Body = styled.div`
  ${({ mvp }) =>
    mvp
      ? ''
      : `
background-image: radial-gradient(circle closest-corner at var(--first-gradient-coords), rgba(40, 72, 122, 0.5), transparent),
radial-gradient(circle closest-corner at var(--second-gradient-coords), rgba(40, 72, 122, 0.5), transparent);
--first-gradient-coords: 20% 40%;
--second-gradient-coords: 80% 60%;
  `}
`;

export const GradientBackground = styled.div`
  padding-top: 52px;
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000;
  background-image: url('/bg_gradient.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

export const Background = styled.div`
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  //background-color: #000000;
  ${({ paddingTop }) => {
    return `padding-top: ${paddingTop ? paddingTop : '100px'}`;
  }}
`;

export function LandingBackground({ children }) {
  const paddingTop = useBreakpointValue({
    base: '80px',
    md: '100px'
  });
  return (
    <Background paddingTop={paddingTop}>
      <VideoBackground videoSrcs={[video]} />
      {children}
    </Background>
  );
}

export const HomeBackground = React.memo(({ children }) => {
  const paddingTop = useBreakpointValue({
    base: '80px',
    md: '100px'
  });
  const videoBg = useMemo(() => {
    return <VideoBackground videoSrcs={[videoHomeHero]} loopLast={false} />;
  }, []);
  return (
    <Background paddingTop={paddingTop}>
      {videoBg}
      {children}
    </Background>
  );
});
