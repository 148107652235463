import { useEffect, useState } from 'react';
import { useWriteContract, useWaitForTransactionReceipt } from 'wagmi';
import { handleError } from '../lib/error_handler';

export function useContractWriteAndWait({ contractAddress, contractAbi, functionName, transactionCallback, errorCallback }) {
  const [waitingTransaction, setWaitingTransaction] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [transaction, setTransaction] = useState('');
  const [hasError, setHasError] = useState(undefined);

  const pendingTransaction = !!localStorage.getItem(`txContract${contractAddress}`) && JSON.parse(localStorage.getItem(`txContract${contractAddress}`));

  const { writeContractAsync: writeAsync, data: writeResponse, isPending, reset } = useWriteContract();

  const {
    isLoading: isLoadingTransaction,
    status,
    data,
    error
  } = useWaitForTransactionReceipt({
    enabled: !!writeResponse || !!pendingTransaction,
    hash: writeResponse || pendingTransaction
  });
  useEffect(() => {
    if (status === 'success') {
      console.log(`[${functionName}:${contractAddress}] wait for transaction`, data);
      localStorage.removeItem(`txContract${contractAddress}`);
      reset();
      setTransaction(data);
      setWaitingTransaction(false);
      setCompleted(true);
      if (transactionCallback) {
        transactionCallback(data);
      }
    } else if (status === 'error') {
      handleError(`[${functionName}:${contractAddress}] wait for transaction`, error);
      setWaitingTransaction(false);
      setHasError(error);
      if (errorCallback) {
        errorCallback(error);
      }
      localStorage.removeItem(`txContract${contractAddress}`);
    }
  }, [status]);

  const write = async args => {
    console.log(`[${functionName}:${contractAddress}] request`, args);
    localStorage.removeItem(`txContract${contractAddress}`);
    setWaitingTransaction(true);
    try {
      const response = await writeAsync({
        address: contractAddress,
        abi: contractAbi,
        functionName: functionName,
        gasLimit: 250000,
        ...args
      });
      console.log(`[${functionName}:${contractAddress}] response`, response);
      localStorage.setItem(`txContract${contractAddress}`, JSON.stringify(response));
      return response;
    } catch (e) {
      handleError(`[${functionName}:${contractAddress}] error`, e);
      setWaitingTransaction(false);
      if ((e.message || e).indexOf('underlying network changed') === -1) {
        setHasError(e);
        if (errorCallback) {
          errorCallback(e);
        }
      }
    }
  };

  const resetState = () => {
    setTransaction(null);
    setHasError(false);
    reset();
  };

  return {
    write,
    completed,
    transaction,
    waitingTransaction,
    isLoading: isPending,
    isLoadingTransaction,
    hasError,
    resetState
  };
}
