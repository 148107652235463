import React, { useContext } from 'react';
import { Flex, Stack, Text, useBreakpointValue, View, VStack } from 'native-base';
import { PagesBackground } from '../../common/layout/main_layout.styled';
import { useHistory } from 'react-router-dom';
import SkButton from './components/sk_button';
import BackIcon from '../assets/icons/back-icon.svg';
import { isAndroid, isIOS } from 'react-device-detect';
import { Web3WagmiContext } from '../../common/context/app_context';
import { useSignTypedData } from 'wagmi';
import { downloadJsonFile, getDID } from '../../common/lib/helpers';
import { ModelLoader } from '../../common/components/model_loader';
import { current as envConfigs } from 'env-configs/selfkey-org';
import { handleError } from '../../common/lib/error_handler';

function DownloadsPageContainer() {
  const { activeAddress: address } = useContext(Web3WagmiContext);
  const history = useHistory();
  const { signTypedDataAsync } = useSignTypedData({});

  const handleDownloadCredentials = async () => {
    // We need to sign the request with EIP 712 to verify the user that is requesting the download
    // is the owner of the wallet
    const domain = {
      name: 'MetaProofDapp',
      version: '1'
    };
    const types = {
      request: [
        { name: 'wallet', type: 'address' },
        { name: 'credential', type: 'string' }
      ]
    };
    const msg = {
      wallet: address,
      credential: 'MetaproofCredential'
    };
    try {
      const signature = await signTypedDataAsync({
        domain,
        types,
        value: msg
      });
      console.log('Signature', signature);
      // const recoveredAddress = ethers.utils.verifyTypedData(domain, types, msg, signature);
      // console.log('recoveredAddress', recoveredAddress);
      // We need to send a POST request to POI Service get the JSON and stream it to the user as a download
      try {
        const response = await fetch(`${envConfigs.serverUrl}/credential/${getDID(address)}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            msg,
            signature
          })
        }).catch(function (error) {
          handleError('Error sending signed message', error);
        });
        const credential = await response.json();
        // console.log('Credentials downloaded', credential);
        downloadJsonFile(`credential_${address}`, credential);
      } catch (e) {
        handleError('Error downloading credentials', e);
      }
    } catch (e) {
      handleError('Error signing', e);
    }
  };

  const handleDownloadWallet = () => {
    if (isIOS) {
      window.open('https://selfkey.org/selfkey-wallet-ios-download');
    } else if (isAndroid) {
      window.open('https://selfkey.org/selfkey-wallet-android-download');
    } else {
      window.open('https://selfkey.org/download/');
    }
  };

  const detailsBackground = useBreakpointValue({
    base: "url('/nft-details-mobile-bg.svg')",
    md: "url('/nft-details-bg.svg')"
  });
  const buttonSize = useBreakpointValue({
    base: 'small',
    md: 'normal'
  });
  return (
    <PagesBackground skStyle={true}>
      <VStack space={{ base: '40px', md: '64px' }}>
        <Stack direction={{ base: 'column', md: 'row' }} space={'32px'}>
          <View
            style={{
              backgroundImage: detailsBackground,
              backgroundSize: 'cover'
            }}
            w={{ base: '320px', md: '384px' }}
            h={{ base: '292px', md: '384px' }}
            px={{ base: '20px', md: '40px' }}
            justifyContent={'center'}
          >
            <VStack alignItems={'center'} justifyContent={'space-around'} h={'100%'}>
              <View w={{ base: '140px', md: '190px' }} h={{ base: '140px', md: '190px' }} mt={'10px'}>
                <ModelLoader model={'/3d/step-1/download.glb'} scale={0.7}></ModelLoader>
              </View>
              <Text textAlign={'center'} fontWeight={400} fontSize={{ base: '14px', md: '16px' }} lineHeight={{ base: '20px', md: '24px' }}>
                This Verifiable Credential proves that you passed the MetaProof Check
              </Text>
              <SkButton
                eventName={'Download Credential'}
                onPress={handleDownloadCredentials}
                size={buttonSize}
                fixedSize={false}
                mt={{ base: '32px', md: '38px' }}
                mb={{ base: '32px', md: '42px' }}
              >
                Download Credential
              </SkButton>
            </VStack>
          </View>
          <View
            style={{
              backgroundImage: detailsBackground,
              backgroundSize: 'cover'
            }}
            w={{ base: '320px', md: '384px' }}
            h={{ base: '292px', md: '384px' }}
            px={{ base: '20px', md: '40px' }}
            justifyContent={'center'}
          >
            <VStack alignItems={'center'} justifyContent={'space-around'} h={'100%'}>
              <View w={{ base: '140px', md: '190px' }} h={{ base: '140px', md: '190px' }} mt={'10px'}>
                <ModelLoader model={'/3d/step-1/logo.glb'} scale={0.7}></ModelLoader>
              </View>
              <Text textAlign={'center'} fontWeight={400} fontSize={{ base: '14px', md: '16px' }} lineHeight={{ base: '20px', md: '24px' }}>
                Get The SelfKey Wallet and safely Store your Verifiable Credential
              </Text>
              <SkButton
                eventName={'Download Wallet'}
                onPress={handleDownloadWallet}
                size={buttonSize}
                fixedSize={false}
                mt={{ base: '32px', md: '38px' }}
                mb={{ base: '32px', md: '42px' }}
              >
                Download Wallet
              </SkButton>
            </VStack>
          </View>
        </Stack>
        <Flex alignItems={'center'}>
          <SkButton
            eventName={'Back - Downloads'}
            onPress={() => history.push('/member')}
            leftIcon={BackIcon}
            leftIconSize={'16px'}
            variant={'secondary'}
            size={buttonSize}
            fixedSize={false}
            mb={{ base: '100px', lg: '260px' }}
          >
            Back
          </SkButton>
        </Flex>
      </VStack>
    </PagesBackground>
  );
}

export { DownloadsPageContainer };
