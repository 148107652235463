import React, { useContext } from 'react';
import { PagesBackground } from '../../../common/layout/main_layout.styled';
import { Text, VStack } from 'native-base';
import { Web3WagmiContext } from '../../../common/context/app_context';

export function Web3ProviderPoc() {
  const {
    connect,
    disconnect,
    isConnecting,
    isConnected,
    errorConnect,
    activeAddress,
    activeChain,
    chains,
    chainDetails,
    switchNetwork,
    isLoadingSwitchNetwork,
    pendingChainId,
    errorSwitchNetwork
  } = useContext(Web3WagmiContext);

  return (
    <PagesBackground>
      <VStack>
        {activeAddress && <Text>Address {activeAddress}</Text>}
        {chainDetails && (
          <Text>
            Connected to {chainDetails.name} ({chainDetails?.network}
            {' - '}
            {chainDetails.id})
          </Text>
        )}

        {chains.map(x => (
          <button disabled={!switchNetwork || x.id === activeChain?.id} key={x.id} onClick={() => switchNetwork?.(x.id)}>
            {x.name}
            {isLoadingSwitchNetwork && pendingChainId === x.id && ' (switching)'}
          </button>
        ))}

        <VStack>
          <button disabled={isConnecting || isConnected} onClick={() => connect('metaMask')}>
            Connect Metamask
          </button>
          <button disabled={isConnecting || isConnected} onClick={() => connect('walletConnect')}>
            Connect ConnectWallet
          </button>
          <button disabled={!isConnected} onClick={() => disconnect()}>
            Disconnect
          </button>
        </VStack>

        <Text color={'red.700'}>{(errorConnect && errorConnect.message) || (errorSwitchNetwork && errorSwitchNetwork.message)}</Text>
      </VStack>
    </PagesBackground>
  );
}
