import React from 'react';
import { Text, View, VStack } from 'native-base';
import { PagesBackground } from '../../common/layout/main_layout.styled';
import SkButton from './components/sk_button';
import { ModelLoader } from '../../common/components/model_loader';
import { appEnv } from 'env-configs';
import useDocumentTitle from '../../common/hooks/use_document_title';
import { APP_NAME } from '../../common/lib/constants';

function SelfKeyIDPageContainer() {
  const { setDocumentTitle } = useDocumentTitle();

  React.useEffect(() => {
    setDocumentTitle(`${APP_NAME} - SelfKey iD Needed`);
  }, []);

  const handleGetSelfKeyId = () => {
    window.open(appEnv === 'production' ? 'https://selfkey.id/' : 'https://selfkey-id-staging.gc.selfkey.org/');
  };
  return (
    <PagesBackground>
      <VStack space={'40px'} alignItems={'center'}>
        <View w={'100%'} h={'260px'}>
          <ModelLoader model={'/3d/step-1/NFT.glb'} scale={0.8}></ModelLoader>
        </View>
        <Text fontSize={{ base: '24px', md: '48px' }} fontWeight={700} lineHeight={{ base: '32px', md: '60px' }} letterSpacing={'0em'} textAlign={'center'}>
          SelfKey iD Needed
        </Text>
        <Text maxW={{ base: '280px', md: 'none' }} fontWeight={400} fontSize={'16px'} lineHeight={'24px'} textAlign={'center'} color={'#FFEDD2'} mb={'24px'}>
          You must get a SelfKey iD in order to continue.
        </Text>
        <SkButton eventName={'Get SelfKey iD'} fixedSize={false} onPress={handleGetSelfKeyId} mb={'252px'}>
          Get SelfKey iD
        </SkButton>
      </VStack>
    </PagesBackground>
  );
}

export { SelfKeyIDPageContainer };
