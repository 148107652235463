import React from 'react';

const TWITTER_CLIENT_ID = 'QUg1Slk4cWhEUVpFTU1LSXgxWFI6MTpjaQ';

const isLocal = false;
const SERVER_URL = isLocal ? "http://www.localhost:8080" : 'https://discord-bot-staging-dsf3f7lktq-ew.a.run.app';

function getTwitterOauthUrl() {
  const rootUrl = 'https://twitter.com/i/oauth2/authorize';
  const options = {
    redirect_uri: `${SERVER_URL}/auth/twitter`,
    client_id: TWITTER_CLIENT_ID,
    state: 'state',
    response_type: 'code',
    code_challenge: 'y_SfRG4BmOES02uqWeIkIgLQAlTBggyf_G7uKT51ku8',
    code_challenge_method: 'S256',
    scope: ['users.read', 'tweet.read'].join(' ') // add/remove scopes as needed
  };
  const qs = new URLSearchParams(options).toString();
  return `${rootUrl}?${qs}`;
}

export function TwitterPostPage() {
  const query = new URLSearchParams(window.location.search);
  const handle = query.get('handle');

  return (
    <div>
      <h1 style={{ color: 'white' }}>Twitter Post</h1>
      {handle ? (
        <div>
          <h2 style={{ color: 'white' }}>Hello {handle}</h2>
          <p style={{ color: 'white' }}>You are now logged in</p>
          <button onClick={() => {
            const genreatedContent = 'SelfkeyDAO is amazing project!';
            const url = `https://twitter.com/intent/tweet?text=${genreatedContent}&hashtags=selfkey`;
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.target = '_blank';
            anchor.click();
          }}>Click here to share your generated post!</button>
        </div>
      ) : (
        <a className="a-button row-container" href={getTwitterOauthUrl()} style={{ color: 'white' }}>
        Login here
      </a>
      )}
    </div>
  );
}
