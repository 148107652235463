import React from 'react';
import { HStack, Image, Link, Modal, Stack, Text, useBreakpointValue, VStack } from 'native-base';
import SkButton from '../../lockdao/pages/components/sk_button';
import alertIcon from '../assets/icons/alert-big.svg';
import { useHistory } from 'react-router-dom';

export default function ConfirmationModal({ showModal, handleClose, handleAction, title, content, actionLabel }) {
  const history = useHistory();
  const seeTermsStyle = useBreakpointValue({
    base: {
      paddingLeft: '2px',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '20px',
      color: 'white'
    },
    md: {
      paddingLeft: '2px',
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '24px',
      color: 'white'
    }
  });
  return (
    <Modal isOpen={showModal} onClose={handleClose} height="100vh" _backdrop={{ bg: '#030c1e', opacity: 0.9 }}>
      <Modal.Content
        w={'100%'}
        maxWidth={{ base: '350px', md: '560px' }}
        borderRadius="16px"
        borderWidth="1px"
        borderColor="#0385E4"
        bg={'#000000'}
        shadow="2"
        p="40px"
        justifyContent={'center'}
      >
        <VStack space={'40px'} alignItems={'center'}>
          <Image
            source={{
              uri: alertIcon
            }}
            alt="Alert"
            w={{ base: '96px', md: '120px' }}
            h={{ base: '96px', md: '120px' }}
            resizeMode="contain"
          />
          <VStack w={{ base: '322px', md: '480px' }} space={'32px'} alignItems={'center'}>
            {title}
            <Text
              w={{ base: '322px', md: '480px' }}
              fontSize={{ base: '14px', md: '16px' }}
              lineHeight={{ base: '20px', md: '24px' }}
              fontWeight={400}
              color={'#FFEDD2'}
              textAlign={'center'}
            >
              {content}
            </Text>
            <HStack>
              <Text {...seeTermsStyle}>Please see</Text>
              <Link onPress={() => history.push('/terms-of-service')} _text={seeTermsStyle} _web={seeTermsStyle}>
                Terms and Conditions
              </Link>
              <Text {...seeTermsStyle} pl={0} ml={0}>
                .
              </Text>
            </HStack>
          </VStack>
          <Stack space={{ base: '24px', md: '50px' }} justifyContent={'center'} direction={{ base: 'column-reverse', md: 'row' }}>
            <SkButton
              eventName={`${actionLabel || 'Confirm'} - Confirmation Modal - ${title}`}
              fixedSize={false}
              variant={'secondary'}
              onPress={handleAction}
              w={'152px'}
            >
              {actionLabel || 'Confirm'}
            </SkButton>
            <SkButton eventName={`Cancel - Confirmation Modal - ${title}`} fixedSize={false} onPress={handleClose} w={'152px'}>
              Cancel
            </SkButton>
          </Stack>
        </VStack>
      </Modal.Content>
    </Modal>
  );
}
