import React, { useContext, useEffect, useState } from 'react';
import { NftContext, RegistryContext, RoutingContext, Web3WagmiContext } from './app_context';
import selfkeyPaymentsABI from '../assets/abis/SelfkeyPaymentRegistry.json';
import erc20ABI from '../assets/abis/ERC20.json';
import { useReadContract } from 'wagmi';
import { SK_ENTRY_FEE } from '../../selfkey-id/lib/credentials';
import { FETCH_USER_STATUS_ON_VERIFY_INTERVAL } from '../../selfkey-id/lib/constants';
import { useMemberRoute } from '../hooks/use_member_route';
import { handleError } from '../lib/error_handler';

import { formatEther, formatUnits, parseEther } from 'viem';
import { useContractWriteAndWait } from '../hooks/use_contract_write_and_wait';

export const UserStatus = {
  newUser: 'NEW USER',
  pending: 'PENDING',
  cancelled: 'CANCELLED',
  rejected: 'REJECTED',
  approved: 'APPROVED', // log if approved is received, should not
  eligibleForInvitation: 'ELIGIBLE FOR INVITATION',
  eligibleForMinting: 'ELIGIBLE FOR MINTING',
  member: 'MEMBER'
};

export function RegistryProvider({ children }) {
  const { activeAddress: address, isConnected, isConnecting: isAccountLoading, contracts, unsupportedChain, isPolygonNetwork } = useContext(Web3WagmiContext);
  const { hasNft, isLoadingNft } = useContext(NftContext);
  const { userRoute } = useContext(RoutingContext);

  const [allowance, setAllowance] = useState();
  const [selfAllowance, setSelfAllowance] = useState();

  const [hasError, setHasError] = useState(undefined);
  const [paymentCompleted, setPaymentCompleted] = useState(false);
  const [paymentTransactionHash, setPaymentTransactionHash] = useState('');

  const commonEnabledConditionsIgnoreNft = !!address && isConnected && !isAccountLoading && !unsupportedChain;
  const commonEnabledConditions = commonEnabledConditionsIgnoreNft && hasNft === false;

  const clearErrors = () => {
    if (tokenAddress) {
      refetchAllowance();
    }
    resetPay();
    resetPayToken();
    resetAllowance();
  };

  const {
    write: pay,
    completed: payCompleted,
    transaction: payTransaction,
    waitingTransaction: waitingPayTransaction,
    isLoading: isLoadingPay,
    isLoadingTransaction: isLoadingPayTransaction,
    hasError: hasErrorPay,
    resetState: resetPay
  } = useContractWriteAndWait({
    contractAddress: contracts?.selfkeyPaymentsRegistry,
    functionName: 'pay',
    contractAbi: selfkeyPaymentsABI.abi
  });

  const payRegistry = async (amount, coupon) =>
    await pay({
      args: [SK_ENTRY_FEE, coupon],
      from: address,
      value: amount,
      gas: 250000
    });

  const [tokenAddress, setTokenAddress] = useState();

  const {
    write: payToken,
    completed: payTokenCompleted,
    transaction: payTokenTransaction,
    waitingTransaction: waitingPayTokenTransaction,
    isLoading: isLoadingPayToken,
    isLoadingTransaction: isLoadingPayTokenTransaction,
    hasError: hasErrorPayToken,
    resetState: resetPayToken
  } = useContractWriteAndWait({
    contractAddress: contracts?.selfkeyPaymentsRegistry,
    functionName: 'payToken',
    contractAbi: selfkeyPaymentsABI.abi
  });

  const payTokenRegistry = async (amount, contract, coupon) =>
    await payToken({
      args: [amount, contract, SK_ENTRY_FEE, coupon]
    });

  const [approvedTokenAmount, setApprovedTokenAmount] = useState();
  const [tokenCoupon, setTokenCoupon] = useState('');

  const {
    write: approve,
    completed: allowanceCompleted,
    transaction: allowanceTransaction,
    waitingTransaction: waitingAllowanceApproval,
    isLoading: isLoadingAllowance,
    isLoadingTransaction: isLoadingAllowanceTransaction,
    hasError: hasErrorAllowance,
    resetState: resetAllowance
  } = useContractWriteAndWait({
    contractAddress: tokenAddress,
    functionName: 'approve',
    contractAbi: erc20ABI,
    transactionCallback: data => {
      console.log(`[${tokenAddress}] approve transactionCallback`, data);
      // todo check if it's allowance from pay or self bridge
      payTokenRegistry(approvedTokenAmount, tokenAddress, tokenCoupon)
        .then(data => {
          // console.log('[PAYMENT] payment started', data);
        })
        .catch(e => {
          handleError('[PAYMENT] payment failed to start', e);
          if ((e.message || e).indexOf('underlying network changed') === -1) {
            setHasError(e);
          }
        })
        .finally(() => {
          setApprovedTokenAmount(null);
        });
    }
  });

  const approveAmount = async (amount, coupon) => {
    console.log(`[${tokenAddress}] approve request`, amount, coupon);
    const response = await approve({
      args: [contracts?.selfkeyPaymentsRegistry, amount]
    });
    setTokenCoupon(coupon);
    setApprovedTokenAmount(amount);
  };

  const {
    refetch: refetchAllowance,
    status: allowanceStatus,
    data: allowanceData,
    error: allowanceError
  } = useReadContract({
    address: tokenAddress,
    abi: erc20ABI,
    functionName: 'allowance',
    args: [address, contracts?.selfkeyPaymentsRegistry],
    query: {
      enabled: Boolean(!!tokenAddress && commonEnabledConditions && contracts?.selfkeyPaymentsRegistry)
    }
  });
  useEffect(() => {
    if (allowanceStatus === 'success') {
      console.log('[REGISTRY] allowance', allowanceData);
      setAllowance(allowanceData);
    } else if (allowanceStatus === 'error') {
      handleError('[REGISTRY] allowance', allowanceError);
    }
  }, [allowanceStatus]);

  const approveSelfAmount = async (amount, token) => {
    console.log(`[${token}] approve request`, amount, parseEther(amount), token);
    try {
      const response = await approve({
        args: [isPolygonNetwork ? contracts.selfBridge : contracts.selfBridgeEth, parseEther(amount)],
        address: token
      });
      setApprovedTokenAmount(amount);
      console.log(`[${tokenAddress}] approve response`, response);

      return response;
    } catch (e) {
      handleError(`[${token}] approve error`, e);
    }
  };

  const {
    refetch: refetchSelfAllowance,
    status: selfAllowanceStatus,
    data: selfAllowanceData,
    error: selfAllowanceError,
    isRefetching: isRefetchingSelfAllowance,
    isFetched: isSelfAllowanceFetched
  } = useReadContract({
    address: isPolygonNetwork ? contracts?.selfToken : contracts?.selfTokenEth,
    abi: erc20ABI,
    functionName: 'allowance',
    args: [address, isPolygonNetwork ? contracts?.selfBridge : contracts?.selfBridgeEth],
    query: {
      enabled: false //commonEnabledConditionsIgnoreNft && !!contracts?.selfToken && !!contracts?.selfTokenEth && !!contracts?.selfBridge && !!contracts?.selfBridgeEth
    }
  });
  useEffect(() => {
    if (typeof selfAllowanceData === 'bigint') {
      if (selfAllowanceStatus === 'success' || (!isRefetchingSelfAllowance && isSelfAllowanceFetched)) {
        const value = formatUnits(selfAllowanceData, 18); // Number(BigNumber.from(data).toNumber() / PAYMENT_MULTIPLIER).toFixed(2);
        // console.log('[REGISTRY] self allowance', { selfAllowanceData, value });
        setSelfAllowance({ data: selfAllowanceData, value });
      } else if (selfAllowanceStatus === 'error') {
        handleError('[REGISTRY] self allowance', selfAllowanceError);
      }
    }
  }, [selfAllowanceStatus, isRefetchingSelfAllowance, isSelfAllowanceFetched, selfAllowanceData]);

  const {
    routeMember,
    consentPayload,
    resetConsentPayload,
    checkAuthorized,
    showTakeSelfieModal,
    setShowTakeSelfieModal,
    handlePOIContinue,
    poiCheckFailedError,
    setPoiCheckFailedError
  } = useMemberRoute();

  // useEffect(() => {
  //   setWaitingAllowanceApproval(isLoadingAllowanceTransaction);
  // }, [isLoadingAllowanceTransaction]);

  const [previousAddress, setPreviousAddress] = useState(address);
  useEffect(() => {
    const doAsync = async () => {
      setPreviousAddress(address);
      await routeMember();
    };
    if (!isLoadingNft && address && previousAddress !== address) {
      doAsync();
    }
  }, [address, isLoadingNft]);

  useEffect(() => {
    const doAsync = async () => {
      await routeMember();
    };
    if (!address || !!unsupportedChain || !isConnected || isAccountLoading || isLoadingPayTransaction || isLoadingAllowanceTransaction || isLoadingNft) {
      return;
    }
    doAsync();
  }, [address, unsupportedChain, isConnected, isAccountLoading, isLoadingPayTransaction, isLoadingAllowanceTransaction, isLoadingNft]);

  useEffect(() => {
    if (!unsupportedChain) {
      clearErrors();
    }
  }, [unsupportedChain]);

  useEffect(() => {
    setPaymentCompleted(false);
  }, [address]);

  useEffect(() => {
    setPaymentCompleted(payCompleted || payTokenCompleted);
  }, [payCompleted, payTokenCompleted]);

  useEffect(() => {
    if (payTransaction?.transactionHash) {
      setPaymentTransactionHash(payTransaction.transactionHash);
    } else if (payTokenTransaction?.transactionHash) {
      setPaymentTransactionHash(payTokenTransaction.transactionHash);
    }
  }, [payTransaction, payTokenTransaction]);

  useEffect(() => {
    if (!isLoadingNft && address && (userRoute === '/initiate-verification' || userRoute === '/verifying')) {
      const interval = setInterval(() => {
        routeMember();
      }, FETCH_USER_STATUS_ON_VERIFY_INTERVAL);
      return () => {
        clearInterval(interval);
      };
    }
  }, [address, userRoute, isLoadingNft]);

  return (
    <RegistryContext.Provider
      value={{
        waitingAllowanceApproval,
        waitingPayTransaction: waitingPayTransaction || waitingPayTokenTransaction,
        payRegistry,
        setTokenAddress,
        payTokenRegistry,
        approveAmount,
        allowance,
        hasError,
        clearErrors,
        paymentCompleted,
        setPaymentCompleted,
        payTransaction: payTransaction || payTokenTransaction,
        paymentTransactionHash,
        consentPayload,
        resetConsentPayload,
        checkAuthorized,
        routeMember,
        showTakeSelfieModal,
        setShowTakeSelfieModal,
        handlePOIContinue,
        poiCheckFailedError,
        setPoiCheckFailedError,
        approveSelfAmount,
        selfAllowance,
        refetchSelfAllowance
      }}
    >
      {children}
    </RegistryContext.Provider>
  );
}
