import { useContext, useEffect, useState } from 'react';
import { StakingContext, Web3WagmiContext } from '../../common/context/app_context';
import { parseUnits } from 'viem';
import useSkrProcess from '../../common/hooks/use_skr_flow';
import { useHistory } from 'react-router-dom';
import { current as envConfigs } from 'env-configs/selfkey-org';
import { APP_NAME, PAGES } from '../../common/lib/constants';
import { convertNumber } from '../../common/lib/helpers';
import useDocumentTitle from '../../common/hooks/use_document_title';

const useWithdrawPage = () => {
  const { setDocumentTitle } = useDocumentTitle();
  const { activeAddress: address } = useContext(Web3WagmiContext);
  const {
    isLoadingStaking,
    isWaitingWithdrawApproval,
    stakingTotal,
    loadingStakingTotal,
    hasError,
    withdrawTransaction,
    clearTransactions,
    withdrawAmount,
    minStakeAmount,
    refetchKeyBalance
  } = useContext(StakingContext);
  const history = useHistory();

  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [displayAcceptTermsWarning, setDisplayAcceptTermsWarning] = useState(false);
  const [keyToWithdraw, setKeyToWithdraw] = useState('');
  const [maxAmountWarning, setMaxAmountWarning] = useState(false);
  const [minAmountWarning, setMinAmountWarning] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const {
    getSkr,
    skr,
    loadingSkr,
    getVka,
    loadingVka,
    showTakeSelfieModal,
    setShowTakeSelfieModal,
    showConsentModal,
    setShowConsentModal,
    consentView,
    loadingSignConsent,
    handleAgreeConsent,
    errorMessage,
    error,
    eligibleError,
    poiCheckFailedError,
    resetErrors
  } = useSkrProcess(
    address,
    data => {
      withdrawAmount(data.to, data.amount, data.param, data.timestamp, data.signer, data.signature);
    },
    (success, error) => {
      if (success) {
        handleWithdrawStakeConfirmation();
      }
    }
  );

  useEffect(() => {
    if (error || hasError) {
      setDocumentTitle(`${APP_NAME} - ${PAGES.WITHDRAW} - Error`);
    } else if (withdrawTransaction) {
      setDocumentTitle(`${APP_NAME} - ${PAGES.WITHDRAW} - Success`);
    } else {
      setDocumentTitle(`${APP_NAME} - ${PAGES.WITHDRAW}`);
    }
  }, [error, hasError, withdrawTransaction, setDocumentTitle]);

  const [formattedStakingTotal, setFormattedStakingTotal] = useState({ formattedValue: null, wasRounded: false });
  useEffect(() => {
    setFormattedStakingTotal(convertNumber(stakingTotal, true));
  }, [stakingTotal]);

  const acceptTerms = checked => {
    setAcceptedTerms(checked);
    if (checked) {
      setDisplayAcceptTermsWarning(false);
    }
  };

  const handleWithdrawStake = () => {
    if (!acceptedTerms) {
      setDisplayAcceptTermsWarning(true);
    }
    const amountValid = keyToWithdraw > 0 && parseFloat(keyToWithdraw) <= parseFloat(stakingTotal);
    if (acceptedTerms && amountValid && !minAmountWarning && !maxAmountWarning) {
      const showConfirmation = parseFloat(stakingTotal) === parseFloat(keyToWithdraw);
      if (showConfirmation) {
        setShowConfirmationModal(true);
      } else {
        handleWithdrawStakeConfirmation();
      }
    }
  };

  const handleWithdrawStakeConfirmation = async () => {
    setShowConfirmationModal(false);
    const amount = parseUnits(keyToWithdraw, 18);
    await getSkr(`${envConfigs.serverUrl}/rewards/staking-withdraw-request/${address}`, { amount });
  };

  const handleTransactionCompleted = () => {
    resetErrors();
    setKeyToWithdraw('');
    setAcceptedTerms(false);
    clearTransactions();
  };

  const handleWithdrawCompleteContinue = () => {
    clearTransactions();
    setKeyToWithdraw('');
    setAcceptedTerms(false);
    resetErrors();
  };

  const handleNotEligibleOk = () => {
    resetErrors();
    history.push('/member');
  };

  const handlePOIContinue = async base64Selfie => {
    setShowTakeSelfieModal(false);
    await getVka(skr.SKR.url, { SKR: skr.SKR, selfie: base64Selfie });
  };

  const checkValueAndSet = value => {
    setMaxAmountWarning(parseFloat(value) > parseFloat(stakingTotal));
    setMinAmountWarning(parseFloat(stakingTotal) - parseFloat(value) < parseFloat(minStakeAmount) && parseFloat(value) < parseFloat(stakingTotal));
    setKeyToWithdraw(value);
  };

  const setMaxAmount = () => {
    setKeyToWithdraw(stakingTotal);
    setMaxAmountWarning(false);
    setMinAmountWarning(false);
  };

  return {
    isLoadingStaking,
    isWaitingWithdrawApproval,
    stakingTotal,
    loadingStakingTotal,
    hasError,
    withdrawTransaction,
    clearTransactions,
    withdrawAmount,
    minStakeAmount,
    refetchKeyBalance,
    acceptedTerms,
    setAcceptedTerms,
    displayAcceptTermsWarning,
    setDisplayAcceptTermsWarning,
    keyToWithdraw,
    setKeyToWithdraw,
    maxAmountWarning,
    setMaxAmountWarning,
    minAmountWarning,
    setMinAmountWarning,
    showConfirmationModal,
    setShowConfirmationModal,
    getSkr,
    skr,
    loadingSkr,
    getVka,
    loadingVka,
    showTakeSelfieModal,
    setShowTakeSelfieModal,
    showConsentModal,
    setShowConsentModal,
    consentView,
    loadingSignConsent,
    handleAgreeConsent,
    errorMessage,
    error,
    eligibleError,
    poiCheckFailedError,
    resetErrors,
    formattedStakingTotal,
    acceptTerms,
    handleWithdrawStake,
    handleWithdrawStakeConfirmation,
    handleTransactionCompleted,
    handleWithdrawCompleteContinue,
    handleNotEligibleOk,
    handlePOIContinue,
    checkValueAndSet,
    setMaxAmount
  };
};

export default useWithdrawPage;
