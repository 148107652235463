import { PagesBackground } from '../layout/main_layout.styled';
import { Box, Center, Flex, Heading, Text } from 'native-base';
import polygonTestnetIcon from '../../selfkey-id/assets/icons/polygon-testnet.svg';
import ethMainnetIcon from '../assets/icons/eth-icon.svg';
import React, { useContext } from 'react';
import { Web3WagmiContext } from '../context/app_context';
import { MainLayout } from '../../lockdao/pages/layout/main_layout';
import SkButtonSK from '../../selfkey-id/pages/components/sk_button';
import SkButtonLD from '../../lockdao/pages/components/sk_button';

export function UnsupportedNetwork({ skStyle }) {
  const { network, switchNetwork, allChains } = useContext(Web3WagmiContext);

  const getCurrentChain = () => allChains.find(c => c.name === network);

  const handleSelectNetwork = () => {
    switchNetwork({ chainId: getCurrentChain().id });
  };
  const SkButton = skStyle ? SkButtonSK : SkButtonLD;

  return (
    <MainLayout skStyle={skStyle}>
      <PagesBackground skStyle={skStyle}>
        <Center w="100%" h="calc(100vh - 96px)" alignItems={'center'} pb={'96px'}>
          <Box w="90%">
            <Text
              fontSize={{ base: '14px', md: '16px' }}
              lineHeight={{ base: '20px', md: '24px' }}
              fontWeight={400}
              textAlign={'center'}
              mb={'24px'}
              color={'#BAEBFF'}
            >
              You are currently connected to an unsupported network.
            </Text>
            <Heading
              fontSize={{ base: '18px', md: '32px' }}
              lineHeight={{ base: '22px', md: '40px' }}
              fontWeight={700}
              textAlign={'center'}
              mb={'40px'}
              fontFamily={'Plus Jakarta Sans'}
            >
              No worries, just click the button bellow
              <br />
              to set the correct Network.
            </Heading>
            <Flex alignItems={'center'}>
              <SkButton
                eventName={`Switch Network - ${getCurrentChain()?.name}`}
                fixedSize={false}
                variant="secondary"
                size="small"
                leftIcon={network === 'maticmum' ? polygonTestnetIcon : ethMainnetIcon}
                onPress={() => handleSelectNetwork()}
              >
                <Text>{getCurrentChain()?.name}</Text>
              </SkButton>
            </Flex>
          </Box>
        </Center>
      </PagesBackground>
    </MainLayout>
  );
}
