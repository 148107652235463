import React from 'react';
import { Link, Text, View, VStack } from 'native-base';
import { PagesBackground } from '../../common/layout/main_layout.styled';
import { useHistory } from 'react-router-dom';
import SkButton from './components/sk_button';
import { ModelLoader } from '../../common/components/model_loader';
import { MainLayout } from '../../selfkey-id/pages/layout/main_layout';
import { APP_NAME, PAGES } from '../../common/lib/constants';
import useDocumentTitle from '../../common/hooks/use_document_title';

function SuccessPage({ title, content, actionLabel, handleAction, skStyle, eventPage, includeLayout = true }) {
  const Wrapper = includeLayout ? MainLayout : View;
  const { setDocumentTitle } = useDocumentTitle();

  React.useEffect(() => {
    setDocumentTitle(`${APP_NAME} - ${eventPage}`);
  }, []);

  return (
    <Wrapper>
      <PagesBackground skStyle={skStyle}>
        <VStack alignItems={'center'} space={'40px'} mt={'16px'}>
          <ModelLoader model={`/3d/${skStyle ? 'step-1' : 'step-2'}/check.glb`} scale={0.5}></ModelLoader>
          <Text fontSize={{ base: '18px', md: '48px' }} fontWeight={700} lineHeight={{ base: '22px', md: '60px' }} letterSpacing={'0em'} textAlign={'center'}>
            {title}
          </Text>
          <VStack alignItems={'center'}>
            <Text
              maxW={{ base: '280px', md: 'none' }}
              fontWeight={400}
              fontSize={'16px'}
              lineHeight={'24px'}
              textAlign={'center'}
              color={'#FFEDD2'}
              mb={'24px'}
            >
              {content}
            </Text>
          </VStack>
          {actionLabel && handleAction && (
            <SkButton eventName={`${eventPage} - ${actionLabel}`} onPress={handleAction} fixedSize={false} w={'244px'}>
              {actionLabel}
            </SkButton>
          )}
        </VStack>
      </PagesBackground>
    </Wrapper>
  );
}

export { SuccessPage };
