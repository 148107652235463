import styled from 'styled-components';

export const TextGradient = styled.b`
  font-weight: 800;
  font-family: 'Plus Jakarta Sans';
  background-image: ${props => (props.bg ? props.bg : 'linear-gradient(90.55deg, #64FFCB 1.26%, #20BDFF 50.57%, #5433FF 99.88%)')};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
`;
