import { Flex, Text, VStack } from 'native-base';
import SkButton from './sk_button';
import { PagesBackground } from '../../../common/layout/main_layout.styled';
import React, { useContext } from 'react';
import { ModelLoader } from '../../../common/components/model_loader';
import { StakingContext } from '../../../common/context/app_context';
import { JOIN_DISCORD_URL } from '../../../common/lib/constants';

export function TransactionFailed({ onRetry, title, skStyle }) {
  const { clearError } = useContext(StakingContext);
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  const handleRetry = () => {
    clearError();
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    onRetry();
  };
  const handleContactSupport = () => {
    window.open(JOIN_DISCORD_URL);
  };
  return (
    <PagesBackground skStyle={skStyle}>
      <VStack space={'40px'}>
        <ModelLoader model={`/3d/${skStyle ? 'step-1' : 'step-2'}/fail.glb`} scale={0.5}></ModelLoader>
        <Text fontSize={{ base: '18px', md: '48px' }} fontWeight={700} lineHeight={{ base: '22px', md: '60px' }} letterSpacing={'0em'} textAlign={'center'}>
          {title}
        </Text>
        <Text textAlign={'center'} color={'#FFEDD2'} mb={'23px'}>
          Please try again.
        </Text>
        <VStack alignItems={'center'} space={'64px'}>
          <SkButton eventName={`${title} - Retry`} fixedSize={false} onPress={handleRetry}>
            Retry
          </SkButton>
          <SkButton eventName={`${title} - Contact Support`} fixedSize={false} variant={'secondary'} onPress={handleContactSupport}>
            Contact Support
          </SkButton>
        </VStack>
      </VStack>
    </PagesBackground>
  );
}
