import { HStack, Image, Link, Stack } from 'native-base';
import twitterIcon from '../assets/icons/twitter.svg';
import { JOIN_DISCORD_URL } from '../lib/constants';
import discordIcon from '../assets/icons/discord.svg';
import redditIcon from '../assets/icons/reddit.svg';
import React from 'react';

export function WhitepaperAndSocial({ justifyContentDesktop = 'flex-end', ...props }) {
  const whitepaperLinkStyle = {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16px'
  };
  return (
    <Stack
      direction={{ base: 'column', md: 'row' }}
      space={{ base: '32px', md: '40px' }}
      mt={{ base: 0, md: '10px' }}
      alignItems={'center'}
      justifyContent={{ base: 'center', md: justifyContentDesktop }}
      {...props}
    >
      <Link href={'https://selfkey.org/whitepaper/'} isExternal _text={whitepaperLinkStyle} _web={whitepaperLinkStyle}>
        Whitepaper
      </Link>
      <HStack space={'24px'}>
        <Link href="https://twitter.com/selfkey" isExternal>
          <Image src={twitterIcon} alt="Alternate Text" w="20px" h="20px" />
        </Link>
        <Link href={JOIN_DISCORD_URL} isExternal>
          <Image src={discordIcon} alt="SelfKey Discord" w="20px" h="20px" />
        </Link>
        {/*<Link href="https://www.reddit.com/r/selfkey/" isExternal>
          <Image src={redditIcon} alt="SelfKey Reddit" w="20px" h="20px" />
        </Link>*/}
      </HStack>
    </Stack>
  );
}
