import React, { useContext } from 'react';
import DisconnectButton from '../../lockdao/pages/components/disconnect_button';
import { NftContext, RewardsContext, RoutingContext, Web3WagmiContext } from '../context/app_context';

function ConnectButton({ skStyle }) {
  const { disconnect } = useContext(Web3WagmiContext);
  const { setRewards } = useContext(RewardsContext);
  const { setUserRoute } = useContext(RoutingContext);
  const { clearMintError } = useContext(NftContext);

  const handleDisconnect = async () => {
    disconnect();
    setUserRoute('/');
    setRewards(null);
    clearMintError();
    // setMintTransaction();
    const pendingBurnCheckpointTx = localStorage.getItem('pendingBurnCheckpointTx');
    localStorage.clear();
    localStorage.setItem('pendingBurnCheckpointTx', pendingBurnCheckpointTx);
  };

  return <DisconnectButton isShortened handleClick={handleDisconnect} skStyle={skStyle} />;
}

export function ConnectWalletButton({ skStyle = false, ...props }) {
  return <ConnectButton {...props} skStyle={skStyle} />;
}
