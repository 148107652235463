import { Flex, Text, View, VStack } from 'native-base';
import SkButton from './components/sk_button';
import { PagesBackground } from '../../common/layout/main_layout.styled';
import React, { useContext } from 'react';
import { RegistryContext } from '../../common/context/app_context';
import { ModelLoader } from '../../common/components/model_loader';
import TransactionLink from '../../common/components/transaction_link';
import useDocumentTitle from '../../common/hooks/use_document_title';

export function PaymentCompleted({ hash }) {
  const { setDocumentTitle } = useDocumentTitle();

  React.useEffect(() => {
    setDocumentTitle('SelfKey DAO - Payment - Success');
  }, []);

  const { setPaymentCompleted } = useContext(RegistryContext);
  const handleContinue = () => {
    setPaymentCompleted(false);
  };
  return (
    <PagesBackground skStyle={true}>
      <VStack space={'40px'}>
        <View w={'100%'} h={{ base: '120px', md: '200px' }}>
          <ModelLoader model={'/3d/step-1/check.glb'} scale={0.5}></ModelLoader>
        </View>
        <Text fontSize={{ base: '18px', md: '48px' }} fontWeight={700} lineHeight={{ base: '22px', md: '60px' }} letterSpacing={'0em'} textAlign={'center'}>
          Thank you!
        </Text>
        <Text maxW={{ base: '280px', md: 'none' }} textAlign={'center'} color={'#FFEDD2'}>
          Your payment completed successfully!
        </Text>
        {!!hash && (
          <Text maxW={{ base: '280px', md: 'none' }} textAlign={'center'} color={'#FFEDD2'} mb={'23px'}>
            Transaction:
            <br />
            <TransactionLink hash={hash} />
          </Text>
        )}
        <Flex alignItems={'center'}>
          <SkButton eventName={'Continue - Payment Completed'} fixedSize={false} onPress={handleContinue}>
            Continue
          </SkButton>
        </Flex>
      </VStack>
    </PagesBackground>
  );
}
