import { Flex, Heading, Text, View, VStack } from 'native-base';
import SkButton from './components/sk_button';
import { PagesBackground } from '../../common/layout/main_layout.styled';
import React, { useContext } from 'react';
import { NftContext, RoutingContext } from '../../common/context/app_context';
import { ModelLoader } from '../../common/components/model_loader';
import { useHistory } from 'react-router-dom';
import TransactionLink from '../../common/components/transaction_link';

export function MintingCompleted() {
  const history = useHistory();
  const { mintingTransactionHash, refetchTokenId, refetchNft } = useContext(NftContext);
  const { setIsRouting } = useContext(RoutingContext);
  // const { refetchPayments } = useContext(RegistryContext);
  const handleContinue = () => {
    // refetchPayments();
    setIsRouting(true);
    refetchTokenId();
    refetchNft();
    history.push('/member');
  };
  return (
    <PagesBackground skStyle={true}>
      <VStack space={'40px'}>
        <View w={'100%'} h={{ base: '120px', md: '200px' }}>
          <ModelLoader model={'/3d/step-1/check.glb'} scale={0.5}></ModelLoader>
        </View>
        <Heading
          fontSize={{ base: '18px', md: '48px' }}
          fontWeight={700}
          lineHeight={{ base: '22px', md: '60px' }}
          letterSpacing={'0em'}
          textAlign={'center'}
          fontFamily={'Plus Jakarta Sans'}
        >
          Congratulations!
        </Heading>
        <Text
          maxW={{ base: '280px', md: 'none' }}
          fontWeight={400}
          fontSize={{ base: '14px', md: '16px' }}
          lineHeight={{ base: '20px', md: '24px' }}
          textAlign={'center'}
          color={'#BAEBFF'}
        >
          Your minting completed successfully!
        </Text>
        <Text
          maxW={{ base: '280px', md: 'none' }}
          fontWeight={400}
          fontSize={{ base: '14px', md: '16px' }}
          lineHeight={{ base: '20px', md: '24px' }}
          textAlign={'center'}
          color={'#BAEBFF'}
          mb={'23px'}
        >
          Transaction:
          <br />
          <TransactionLink hash={mintingTransactionHash} />
        </Text>
        <Flex alignItems={'center'}>
          <SkButton eventName={'Continue - Minting Completed'} fixedSize={false} onPress={handleContinue}>
            Continue
          </SkButton>
        </Flex>
      </VStack>
    </PagesBackground>
  );
}
