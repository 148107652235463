import { Text } from 'native-base';

export function DisclaimerText({ children, ...props }) {
  return (
    <Text
      textAlign={'left'}
      alignSelf={{ base: 'center', md: 'flex-start' }}
      fontWeight={400}
      fontSize={{ base: '10px', md: '12px' }}
      lineHeight={{ base: '14px', md: '16px' }}
      color={'#A9A9A9'}
      {...props}
    >
      {children}
    </Text>
  );
}
