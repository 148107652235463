import { Box, Flex, HStack, Image, Spinner, Stack, Text, useBreakpointValue, View, VStack } from 'native-base';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import axios from 'axios';
import SkButton from '../../lockdao/pages/components/sk_button';
import { PagesBackground } from '../../common/layout/main_layout.styled';
import { LoadingSpinner } from './components/loading_spinner';
import { RegistryContext, Web3WagmiContext } from '../../common/context/app_context';
import { handleError } from '../../common/lib/error_handler';
import poiTakeSelfieBgImg from '../../common/assets/images/selfie-background.svg';
import poiTakeSelfieOverlayImg from '../../common/assets/images/overlay-selfie.svg';
export const useVideoCapture = () => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [capturedImage, setCapturedImage] = useState(null);
  const [isCameraActive, setCameraActive] = useState(false);
  const [loadingCamera, setLoadingCamera] = useState(null);
  const [mediaStream, setMediaStream] = useState();

  // function checkIfMirrored(video) {
  //   const canvas = document.createElement('canvas');
  //   const ctx = canvas.getContext('2d');
  //   const track = video.srcObject.getVideoTracks()[0];
  //
  //   canvas.width = track.getSettings().width;
  //   canvas.height = track.getSettings().height;
  //
  //   // Draw the video frame onto the canvas
  //   ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
  //
  //   // Get the image data from the canvas
  //   const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
  //   const pixels = imageData.data;
  //
  //   // Check if the image is mirrored by comparing left and right halves
  //   const leftHalf = [];
  //   const rightHalf = [];
  //
  //   for (let i = 0; i < pixels.length; i += 4) {
  //     const pixelIndex = i / 4;
  //     const x = pixelIndex % canvas.width;
  //
  //     if (x < canvas.width / 2) {
  //       leftHalf.push(pixels[i]);
  //     } else {
  //       rightHalf.push(pixels[i]);
  //     }
  //   }
  //
  //   // Calculate the average pixel value for each half
  //   const averageLeft = leftHalf.reduce((sum, value) => sum + value, 0) / leftHalf.length;
  //   const averageRight = rightHalf.reduce((sum, value) => sum + value, 0) / rightHalf.length;
  //
  //   // Define a threshold to consider the video mirrored
  //   const mirrorThreshold = 5;
  //
  //   if (Math.abs(averageLeft - averageRight) > mirrorThreshold) {
  //     // Apply the scaleX transformation to mirror the video
  //     video.style.transform = 'scaleX(-1)';
  //   }
  // }

  const startCamera = async () => {
    const constraints = { video: { width: 400, height: 400 }, facingMode: { exact: 'environment' } };
    try {
      setLoadingCamera(true);
      setCameraActive(true);
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      setMediaStream(stream);
      videoRef.current.srcObject = stream;
      // videoRef.current.onloadedmetadata = () => {
      //   checkIfMirrored(videoRef.current);
      // };
      videoRef.current.style.transform = 'scaleX(-1)';
      setLoadingCamera(false);
      return stream;
    } catch (error) {
      handleError('Error accessing the camera:', error);
    }
  };

  const stopCamera = stream => {
    if (stream || mediaStream) {
      (stream || mediaStream).getTracks().forEach(track => track.stop());
      const videoRef = document.getElementById('videoElement');
      if (videoRef) {
        videoRef.srcObject = null;
      }
      setMediaStream(null);
    }
  };

  const captureImage = () => {
    const canvas = canvasRef.current;
    const video = videoRef.current;
    const context = canvas.getContext('2d');
    // Apply the mirror effect by flipping the image horizontally
    context.translate(video.videoWidth, 0);
    context.scale(-1, 1);
    // Draw the current frame from the video onto the canvas
    context.drawImage(video, 0, 0, canvas.width, canvas.height);
    // Reset the canvas transformation
    context.setTransform(1, 0, 0, 1, 0, 0);
    setCapturedImage(canvas.toDataURL('image/png'));
    stopCamera(mediaStream);
  };

  return {
    videoRef,
    canvasRef,
    startCamera,
    stopCamera,
    captureImage,
    capturedImage,
    setCapturedImage,
    isCameraActive,
    loadingCamera,
    onRetry: () => {
      setCapturedImage(null);
    }
  };
};

const isLocal = false;
const selfieCheckApiUrl = isLocal ? 'http://127.0.0.1:3030/selfie-check' : 'https://kycs-ai-service-staging-dsf3f7lktq-ew.a.run.app/selfie-check';

export const sendImage = async ({ capturedImage, walletAddress }) => {
  if (!capturedImage) {
    handleError('No image captured');
    return;
  }

  const requestBody = {
    walletAddress: walletAddress, // Replace with the actual wallet address
    newSelfieBase64: capturedImage.split(',')[1]
  };

  console.log(requestBody);

  try {
    const { data } = await axios.post(selfieCheckApiUrl, requestBody);
    // console.log('Server response:', data);
    return data;
  } catch (error) {
    handleError('Error sending the image:', error);
  }

  return null;
};

export function CaptureImage({ videoRef, onRetry, captureImage, isCameraActive, capturedImage, onSubmit, loadingCamera }) {
  const videoFrameDimension = useBreakpointValue({ base: '260px', md: '448px' });
  const videoDimension = useBreakpointValue({ base: '220px', md: '380px' });
  const videoButtonSize = useBreakpointValue({ base: 'small', md: 'normal' });
  const [cameraPermissionGranted, setCameraPermissionGranted] = useState(false);

  if (navigator.permissions && navigator.permissions.query) {
    navigator.permissions
      .query({ name: 'camera' })
      .then(permissionStatus => {
        if (permissionStatus.state === 'granted') {
          console.log('Camera access is granted.');
          setCameraPermissionGranted(true);
        } else if (permissionStatus.state === 'denied') {
          console.log('Camera access is denied.');
          setCameraPermissionGranted(false);
        } else if (permissionStatus.state === 'prompt') {
          console.log('Camera access is pending; the user will be prompted.');
          setCameraPermissionGranted(false);
        }
      })
      .catch(error => {
        console.error('Error querying camera permission:', error);
        // to avoid wrong message when permissions api isn't supported
        setCameraPermissionGranted(true);
      });
  } else {
    console.error('navigator.permissions API is not supported in this browser.');
  }

  if (!isCameraActive) {
    return null;
  }

  // if (capturedImage) {
  //   return (
  //     <>
  //       <img src={capturedImage} width={videoDimension} height={videoDimension} />
  //       <Stack mt={4} space={4} direction={{ base: 'column', md: 'row' }}>
  //         <SkButton eventName={'Back - Selfie Check Page'} variant={'secondary'} onPress={onRetry} size={videoButtonSize}>
  //           Retake
  //         </SkButton>
  //
  //         <SkButton eventName={'Submit Selfie - Selfie Check Page'} onPress={onSubmit} size={videoButtonSize}>
  //           Submit
  //         </SkButton>
  //       </Stack>
  //     </>
  //   );
  // }

  return (
    <>
      <Flex w={videoFrameDimension} h={videoFrameDimension} alignItems={'center'} justifyContent={'center'}>
        <Image alt="Selfie Background" source={{ uri: poiTakeSelfieBgImg }} w={videoFrameDimension} h={videoFrameDimension} position={'absolute'} />
        <Image alt="Selfie Focal" source={{ uri: poiTakeSelfieOverlayImg }} w={videoDimension} h={videoDimension} zIndex={10} position={'absolute'} />
        {loadingCamera && (
          <VStack position={'absolute'}>
            <Spinner size={'large'} />
            {!cameraPermissionGranted && (
              <Text maxW={{ base: '120px', md: '150px' }} textAlign={'center'}>
                Allow camera access
              </Text>
            )}
          </VStack>
        )}
        {capturedImage ? (
          <Image alt="Selfie" source={{ uri: capturedImage }} w={videoDimension} h={videoDimension} borderRadius={'10px'} position={'absolute'} />
        ) : (
          <video
            id={'videoElement'}
            ref={videoRef}
            width={videoDimension}
            height={videoDimension}
            style={{ zIndex: 5, borderRadius: '10px' }}
            autoPlay
            playsInline
          />
        )}
      </Flex>
      {/*<SkButton*/}
      {/*    eventName={'Take Selfie - Selfie Check Page'}*/}
      {/*    mt={4}*/}
      {/*    fixedSize={false}*/}
      {/*    onPress={() => {*/}
      {/*      captureImage();*/}
      {/*    }}*/}
      {/*>*/}
      {/*  Take Selfie*/}
      {/*</SkButton>*/}
    </>
  );
}

export function CheckResults({ results, capturedImage, onRetry }) {
  return (
    <PagesBackground>
      <VStack alignItems={'center'} justifyContent="center">
        <Text fontSize={{ base: '18px', md: '48px' }} fontWeight={700} lineHeight={{ base: '22px', md: '60px' }} letterSpacing={'0em'} textAlign={'center'}>
          Results
        </Text>
        <Box borderRadius={'10px'} p={'10px'} w={'100%'} maxW={'280px'} textAlign={'center'}>
          <Text fontSize={'18px'} fontWeight={700} lineHeight={'22px'} letterSpacing={'0em'} textAlign={'center'}>
            Similarity Score
          </Text>
          <Text fontSize={'18px'} fontWeight={700} lineHeight={'22px'} letterSpacing={'0em'} textAlign={'center'}>
            {results.similarityScore * 100}%
          </Text>
        </Box>
        <SkButton eventName={'Retry - Selfie Check Page'} fixedSize={false} variant={'secondary'} onPress={onRetry}>
          Retry
        </SkButton>
        <Box mt={4}>
          <Text fontSize={'18px'} fontWeight={700} lineHeight={'22px'} letterSpacing={'0em'}>
            New Selfie
          </Text>
          <img src={`${capturedImage}`} width={700} />
        </Box>
        <Flex flexDirection="column" mb={10}>
          {results.results.map((item, index) => {
            const imagePrefix = 'data:image/png;base64,';
            return (
              <Flex mt={4} flexDirection="row">
                <img src={`${imagePrefix}${item.data}`} width={300} />
                <Box ml={3}>
                  <Text fontSize={'14px'} fontWeight={600} lineHeight={'22px'} letterSpacing={'0em'}>
                    Index: {index}
                  </Text>
                  <Text fontSize={'14px'} fontWeight={700} lineHeight={'22px'} letterSpacing={'0em'}>
                    Score: {item.similarityScore * 100}%
                  </Text>
                  <Text fontSize={'14px'} fontWeight={600} lineHeight={'22px'} letterSpacing={'0em'}>
                    Image ID: {item.id}
                  </Text>
                  {item.createdAt && (
                    <Text fontSize={'14px'} fontWeight={600} lineHeight={'22px'} letterSpacing={'0em'}>
                      Uploaded at {new Date(item.createdAt).toLocaleString()}
                    </Text>
                  )}
                </Box>
              </Flex>
            );
          })}
        </Flex>
      </VStack>
    </PagesBackground>
  );
}
function WalletConnectedContainer({ activeAddress }) {
  const { videoRef, canvasRef, startCamera, captureImage, isCameraActive, capturedImage, onRetry, loadingCamera } = useVideoCapture();
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState(null);
  const [existingSelfieBase64, setExistingSelfieBase64] = useState(null);

  const onSubmit = async () => {
    setResults(null);
    setLoading(true);
    const result = await sendImage({
      capturedImage,
      walletAddress: activeAddress
    });

    setLoading(false);

    if (result) {
      setResults(result);
      setExistingSelfieBase64(result.existingSelfieBase64);
    }
    console.log(`send image result`, result);
  };

  if (results) {
    return (
      <>
        <CheckResults
          capturedImage={capturedImage}
          existingSelfieBase64={existingSelfieBase64}
          results={results}
          onRetry={() => {
            setResults(null);
            onRetry();
          }}
        />
      </>
    );
  }

  if (loading) {
    return (
      <PagesBackground>
        <LoadingSpinner />
      </PagesBackground>
    );
  }

  return (
    <PagesBackground skStyle={true}>
      <VStack space={'40px'}>
        <Text fontSize={{ base: '18px', md: '48px' }} fontWeight={700} lineHeight={{ base: '22px', md: '60px' }} letterSpacing={'0em'} textAlign={'center'}>
          Selfie Check
        </Text>
        <Text maxW={{ base: '280px', md: 'none' }} textAlign={'center'} color={'#FFEDD2'} mb={'23px'}>
          Please take a selfie to verify your identity
        </Text>

        <Flex alignItems={'center'}>
          <canvas ref={canvasRef} width="400" height="400" style={{ display: 'none' }} />
          {!isCameraActive ? (
            <SkButton
              eventName={'Start Capture - Selfie Check Page'}
              fixedSize={false}
              onPress={() => {
                startCamera();
              }}
            >
              Start Capture
            </SkButton>
          ) : (
            <CaptureImage
              videoRef={videoRef}
              canvasRef={canvasRef}
              captureImage={captureImage}
              isCameraActive={isCameraActive}
              capturedImage={capturedImage}
              onRetry={() => {
                setResults(null);
                onRetry();
              }}
              onSubmit={onSubmit}
            />
          )}
        </Flex>
      </VStack>
    </PagesBackground>
  );
}

export function SelfieCheckPage() {
  const { isConnected, activeAddress } = useContext(Web3WagmiContext);
  const { userRoute, setUserRoute } = useContext(RegistryContext);

  // useEffect(() => {
  //   if (userRoute !== '/selfie-check') {
  //     setUserRoute('/selfie-check');
  //   }
  // }, [userRoute, setUserRoute]);

  const container = useMemo(() => {
    if (!isConnected) {
      return (
        <PagesBackground>
          <VStack space={'40px'}>
            <Text fontSize={{ base: '18px', md: '48px' }} fontWeight={700} lineHeight={{ base: '22px', md: '60px' }} letterSpacing={'0em'} textAlign={'center'}>
              Selfie Check
            </Text>
            <Text maxW={{ base: '280px', md: 'none' }} textAlign={'center'} color={'#FFEDD2'} mb={'23px'}>
              Please connect your wallet to verify your identity
            </Text>
          </VStack>
        </PagesBackground>
      );
    }

    return <WalletConnectedContainer activeAddress={activeAddress} />;
  }, [activeAddress, isConnected]);

  return container;
}
