import React from 'react';
import top from '../../assets/imgs/frame-top.svg';
import topMobile from '../../assets/imgs/frame-top-mobile.svg';
import bottom from '../../assets/imgs/frame-bottom.svg';
import bottomMobile from '../../assets/imgs/frame-bottom-mobile.svg';

import { useBreakpointValue, View } from 'native-base';

const SkFrame = props => {
  const topBorder = useBreakpointValue({
    base: topMobile,
    md: top
  });
  const bottomBorder = useBreakpointValue({
    base: bottomMobile,
    md: bottom
  });
  const defaultWidth = useBreakpointValue({ base: '320px', md: '488px' });
  return (
    <View
      background={'rgba(0, 0, 0, 0.2)'}
      borderRadius={'16px'}
      w={props.w ? props.w : defaultWidth}
      h={props.h}
      px={props.px}
      justifyContent={'center'}
      mr={props.mr}
    >
      <img alt="top" src={topBorder} style={{ position: 'absolute', top: 0 }} />
      {props.children}
      <img alt="bottom" src={bottomBorder} style={{ position: 'absolute', bottom: 0 }} />
    </View>
  );
};

export default SkFrame;
