import { Flex, Heading, Text, View, VStack } from 'native-base';
import SkButton from './sk_button';
import { PagesBackground } from '../../../common/layout/main_layout.styled';
import React from 'react';
import { ModelLoader } from '../../../common/components/model_loader';
import TransactionLink from '../../../common/components/transaction_link';

export function TransactionCompleted({ tx: { status, transactionHash }, handleAction, skStyle }) {
  return (
    <PagesBackground skStyle={skStyle}>
      <VStack space={'40px'}>
        <View w={'100%'} h={{ base: '120px', md: '200px' }}>
          <ModelLoader model={`/3d/${skStyle ? 'step-1' : 'step-2'}/${status === 'success' ? 'check' : 'fail'}.glb`} scale={0.5}></ModelLoader>
        </View>
        <Heading
          fontSize={{ base: '18px', md: '48px' }}
          fontWeight={700}
          lineHeight={{ base: '22px', md: '60px' }}
          letterSpacing={'0em'}
          textAlign={'center'}
          fontFamily={'Plus Jakarta Sans'}
        >
          Transaction {`${status === 'success' ? 'Completed!' : 'Failed'}`}
        </Heading>
        <Text
          maxW={{ base: '280px', md: 'none' }}
          fontWeight={400}
          fontSize={{ base: '14px', md: '16px' }}
          lineHeight={{ base: '20px', md: '24px' }}
          textAlign={'center'}
          color={'#BAEBFF'}
          mb={'23px'}
        >
          Transaction:
          <br />
          <TransactionLink hash={transactionHash} />
        </Text>
        <Flex alignItems={'center'}>
          <SkButton eventName={'Continue - Lock POI Completed'} fixedSize={false} onPress={handleAction}>
            Continue
          </SkButton>
        </Flex>
      </VStack>
    </PagesBackground>
  );
}
