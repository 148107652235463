import SkButton from '../../lockdao/pages/components/sk_button';
import submitIcon from '../assets/icons/arrow-fwd.svg';
import checkIcon from '../assets/icons/check.svg';
import { Input, useBreakpointValue } from 'native-base';
import React from 'react';

export function SkInput({
  value,
  onChange,
  placeholder,
  onSubmit,
  isLoading,
  isDisabled,
  isChecked,
  eventName,
  width,
  height,
  showSubmitButton = true,
  ...props
}) {
  const handleSubmit = () => {
    if (!isDisabled) {
      onSubmit();
    }
  };
  const paddingRightElement = useBreakpointValue({
    base: '7px 15px 7px 15px',
    md: '11px 18px 11px 18px'
  });
  let extraProps = {};
  if (showSubmitButton) {
    extraProps = {
      rightElement: (
        <SkButton
          eventName={eventName}
          w={{ base: '70px', md: '90px' }}
          h={{ base: '46px', md: '66px' }}
          fixedSize={false}
          p={{ base: '2px', md: '8px' }}
          mr={{ base: '1px', md: '-1px' }}
          onPress={handleSubmit}
          rightIcon={isChecked ? checkIcon : submitIcon}
          rightIconSize={'28px'}
          isLoading={isLoading}
          showCheckIcon={isChecked}
          padding={paddingRightElement}
        />
      )
    };
  }
  return (
    <Input
      onSubmitEditing={handleSubmit}
      value={value}
      onChangeText={v => onChange(v)}
      placeholder={placeholder}
      variant="rounded"
      w={width ? width : { base: '320px', md: '376px' }}
      h={height ? height : { base: '48px', md: '56px' }}
      borderRadius={'40px'}
      alignSelf={'center'}
      px={showSubmitButton ? '40px' : '24px'}
      fontSize={'16px'}
      borderColor={'#939393'}
      overflowY={'hidden'}
      overflowX={'hidden'}
      isReadOnly={isDisabled}
      _focus={{
        borderWidth: '1px',
        borderColor: '#FFFFFF',
        background: 'rgba(0, 0, 0, 0.2)'
      }}
      _hover={{
        borderWidth: '1px',
        borderColor: '#FFFFFF',
        background: 'rgba(0, 0, 0, 0.2)'
      }}
      _stack={{
        borderWidth: '1px',
        borderColor: '#7D7D7D',
        background: 'rgba(0, 0, 0, 0.2)'
      }}
      focusOutlineColor={'#FFFFFF'}
      {...extraProps}
    />
  );
}
