import { Heading, VStack } from 'native-base';
import SkButton from './components/sk_button';
import { PagesBackground } from '../../common/layout/main_layout.styled';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ModelLoader } from '../../common/components/model_loader';
import { JOIN_DISCORD_URL } from '../../common/lib/constants';

export function CountryCheckFailed() {
  const history = useHistory();

  const handleRetry = () => {
    history.push('/eligibility-start');
  };
  const handleContactSupport = () => {
    window.open(JOIN_DISCORD_URL);
  };
  return (
    <PagesBackground skStyle={true}>
      <VStack space={'40px'}>
        <ModelLoader model={'/3d/step-1/fail.glb'} scale={0.5}></ModelLoader>
        <Heading
          fontSize={{ base: '16px', md: '18px' }}
          fontWeight={700}
          lineHeight={{ base: '20px', md: '24px' }}
          textAlign={'center'}
          maxW={{ base: '320px', md: '696px' }}
        >
          Something went wrong, please try again.
        </Heading>
        <SkButton eventName={'Retry - Country Check Failed'} fixedSize={false} onPress={handleRetry}>
          Retry
        </SkButton>
        <SkButton eventName={`Country check failed - Contact Support`} fixedSize={false} variant={'secondary'} onPress={handleContactSupport} mt={'24px'}>
          Contact Support
        </SkButton>
      </VStack>
    </PagesBackground>
  );
}
