import React, { useCallback, useContext, useEffect } from 'react';
import { PagesBackground } from '../../common/layout/main_layout.styled';
import { VoteComponent } from './components/generated/VoteComponent';
import { Flex, HStack, VStack } from 'native-base';
import { useHistory } from 'react-router-dom';
import { current as envConfigs } from 'env-configs/selfkey-org';
import { VoteContext, Web3WagmiContext } from '../../common/context/app_context';
import { getDID } from '../../common/lib/helpers';
import { SelfieModal } from '../../common/components/selfie_modal';
import LdButton from './components/sk_button';
import WarningModal from '../../common/components/warning_modal';
import { APP_NAME, PAGES } from '../../common/lib/constants';
import { RejectedPage } from './rejected_page';
import { TransactionFailed } from './components/transaction_failed';
import { TransactionCompleted } from './components/staking_completed';
import { POICheckFailed } from '../../common/components/poi_check_failed';
import useDocumentTitle from '../../common/hooks/use_document_title';
import { SkWaitingTransaction } from './components/sk_waiting_transaction';
import useSkrProcess from '../../common/hooks/use_skr_flow';
import { SigningConsentPage } from '../../common/components/signing-content-page';

function VotePageContainer() {
  const { setDocumentTitle } = useDocumentTitle();
  const {
    vote,
    votes,
    showVoteButton,
    voteTransaction,
    hasError,
    resetVoteState,
    waitingVoteTransaction,
    activeProposal,
    refetchActiveProposal,
    isLoadingActiveProposal
  } = useContext(VoteContext);

  const { activeAddress: address } = useContext(Web3WagmiContext);
  const history = useHistory();

  const {
    getSkr,
    skr,
    loadingSkr,
    getVka,
    loadingVka,
    showTakeSelfieModal,
    setShowTakeSelfieModal,
    showConsentModal,
    setShowConsentModal,
    consentView,
    loadingSignConsent,
    handleAgreeConsent,
    error,
    eligibleError,
    poiCheckFailedError,
    resetErrors
  } = useSkrProcess(
    address,
    data => {
      vote(data);
    },
    (success, error) => {
      if (success) {
        handleVote();
      }
    }
  );

  const handleBack = useCallback(() => {
    history.push('/member');
  }, []);

  const handleVote = useCallback(() => {
    if (address && activeProposal?.id) {
      getSkr(`${envConfigs.serverUrl}/governance/dao-vote-request/${getDID(address)}`, { proposalId: activeProposal?.id });
    }
  }, [address]);

  const handlePOIContinue = async base64Selfie => {
    setShowTakeSelfieModal(false);
    await getVka(skr.SKR.url, { SKR: skr.SKR, selfie: base64Selfie });
  };

  const refreshVotePage = () => {
    resetVoteState();
    resetErrors();
    refetchActiveProposal();
  };

  useEffect(() => {
    if (error || hasError) {
      setDocumentTitle(`${APP_NAME} - ${PAGES.VOTE} - Error`);
    } else if (voteTransaction) {
      setDocumentTitle(`${APP_NAME} - ${PAGES.VOTE} - Success`);
    } else {
      setDocumentTitle(`${APP_NAME} - ${PAGES.VOTE}`);
    }
  }, [error, hasError, voteTransaction]);

  if (hasError) {
    return (
      <RejectedPage
        includeLayout={false}
        title={'Oh, no!'}
        content={'Something went wrong, please try again'}
        actionLabel={'Retry'}
        handleAction={() => refreshVotePage()}
        eventPage={`${PAGES.VOTE} - ${error}`}
      />
    );
  }

  if (eligibleError) {
    setDocumentTitle(`${APP_NAME} - ${PAGES.VOTE} - Not Eligible`);
    return (
      <RejectedPage
        title={'Oh, no!'}
        content={'You are not eligible for this action.'}
        actionLabel={'OK'}
        handleAction={() => refreshVotePage()}
        skStyle={false}
        eventPage={`${PAGES.VOTE} - Not Eligible`}
      />
    );
  }

  if (error) {
    return <TransactionFailed title={'Vote failed'} onRetry={() => refreshVotePage()} skStyle={false} />;
  }

  if (waitingVoteTransaction) {
    setDocumentTitle(`${APP_NAME} - ${PAGES.VOTE} - Waiting for Transaction`);
    return <SkWaitingTransaction skStyle={false} />;
  } else if (voteTransaction) {
    return <TransactionCompleted tx={voteTransaction} handleAction={() => refreshVotePage()} skStyle={false} />;
  }

  if (poiCheckFailedError) {
    return <POICheckFailed handleAction={() => refreshVotePage()} page={PAGES.VOTE} />;
  }

  if (loadingSignConsent) {
    return <SigningConsentPage />;
  }

  return (
    <PagesBackground>
      <VStack justifyContent="flex-start" alignItems="center" w="100%" space={{ base: '20px', md: '32px' }}>
        <HStack width={'100%'} justifyContent="center" alignItems="flex-start" mb={{ base: '40px', md: '40px' }}>
          <Flex direction={{ base: 'column', md: 'row' }} w={'100%'} justifyContent={'space-between'} maxWidth={{ base: '320px', lg: '1216px' }}>
            <VoteComponent
              votes={votes}
              handleBack={handleBack}
              handleVote={handleVote}
              showButton={showVoteButton}
              loadingVoting={!activeProposal || loadingSkr || loadingVka || loadingSignConsent || waitingVoteTransaction || isLoadingActiveProposal}
            />
          </Flex>
        </HStack>
      </VStack>
      <WarningModal
        showModal={showConsentModal}
        title={consentView}
        leftButton={
          <LdButton variant={'secondary'} fixedSize={false} w={'134px'} onPress={() => setShowConsentModal(false)}>
            Cancel
          </LdButton>
        }
        rightButton={
          <LdButton fixedSize={false} w={'134px'} onPress={() => handleAgreeConsent()}>
            Agree
          </LdButton>
        }
        handleClose={() => setShowConsentModal(false)}
      />
      <SelfieModal isOpen={showTakeSelfieModal} handleClose={() => setShowTakeSelfieModal(false)} handlePOIContinue={handlePOIContinue} />
    </PagesBackground>
  );
}

export { VotePageContainer };
