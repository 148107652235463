import { Image, Pressable, Tooltip, useClipboard } from 'native-base';
import CopyIcon from '../../lockdao/assets/icons/copy-icon.svg';
import React, { useState } from 'react';
import { handleError } from '../lib/error_handler';

const COPIED_TOOLTIP_DELAY = 2000;

export function CopyButton({ value, ...props }) {
  const [isCopyTooltipOpen, setIsCopyTooltipOpen] = useState(false);
  const { onCopy } = useClipboard();
  const handleCopy = async () => {
    try {
      await onCopy(value);
      setIsCopyTooltipOpen(true);
    } catch (e) {
      handleError('Error handling copy action', e);
    } finally {
      setTimeout(() => {
        setIsCopyTooltipOpen(false);
      }, COPIED_TOOLTIP_DELAY + 100);
    }
  };
  return (
    <Tooltip
      isOpen={isCopyTooltipOpen}
      label={'Copied!'}
      placement={'top'}
      closeDelay={COPIED_TOOLTIP_DELAY}
      closeOnClick={false}
      hasArrow={true}
      offset={16}
      _dark={{ bg: '#0385E4', _text: { color: '#FFFFFF' } }}
    >
      <Pressable onPress={handleCopy} {...props}>
        <Image src={CopyIcon} w={'16px'} h={'16px'} alt={'Copy'} />
      </Pressable>
    </Tooltip>
  );
}
