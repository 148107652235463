import React, { useContext, useEffect, useState } from 'react';
import { Box, Heading, HStack, Image, Spinner, Stack, Text, View, VStack } from 'native-base';
import { PagesBackground } from '../../common/layout/main_layout.styled';
import { ModelLoader } from '../../common/components/model_loader';
import { JoinDiscordCard } from '../../common/components/join_discord_card';
import SkButton from './components/sk_button';
import { useHistory } from 'react-router-dom';
import { RewardsContext, SelfTokenContext, Web3WagmiContext } from '../../common/context/app_context';
import { TransactionFailed } from './components/transaction_failed';
import { TransactionCompleted } from './components/staking_completed';
import { convertNumber, isMetamaskAvailable } from '../../common/lib/helpers';
import SkFrame from './components/sk_frame';
import { RejectedPage } from './rejected_page';
import { SkBackButton } from './components/sk_back_button';
import { SkWaitingTransaction } from './components/sk_waiting_transaction';
import { current as envConfigs } from 'env-configs/selfkey-org';
import selfToken from '../../common/assets/icons/self-token.svg';
import useDocumentTitle from '../../common/hooks/use_document_title';
import { handleError } from '../../common/lib/error_handler';
import { APP_NAME, PAGES } from '../../common/lib/constants';
import { formatEther, hexToBigInt } from 'viem';
import useSkrProcess from '../../common/hooks/use_skr_flow';
import { SelfieModal } from '../../common/components/selfie_modal';
import WarningModal from '../../common/components/warning_modal';
import LdButton from './components/sk_button';
import { truncateAddress } from '../../common/lib/wallet_helpers';
import { CopyButton } from '../../common/components/copy_button';
import { useImportToken } from '../../common/hooks/use_import_token';
import { SigningConsentPage } from '../../common/components/signing-content-page';
import { MintingFailed } from '../../selfkey-id/pages/minting_failed';
import { POICheckFailed } from '../../common/components/poi_check_failed';
import { HoverTooltip } from '../../common/components/hover-tooltip';

function MintSelfPageContainer() {
  const { setDocumentTitle } = useDocumentTitle();
  const { activeAddress: address, contracts } = useContext(Web3WagmiContext);
  const { rewards, getRewards } = useContext(RewardsContext);
  const {
    selfBalance,
    isLoadingSelfBalance,
    refetchSelfBalance,
    selfMinting,
    isLoading: isLoadingSelfMinting,
    resetState: resetSelfMint,
    hasError,
    selfMintingTransaction,
    waitingTransaction
  } = useContext(SelfTokenContext);
  const history = useHistory();
  const earnedSelf = rewards?.total && formatEther(rewards.total);
  const [loadingImportToken, importToken] = useImportToken({
    tokenType: 'ERC20',
    tokenAddress: contracts.selfToken,
    tokenDecimals: 18,
    tokenSymbol: 'SELF',
    tokenImage: 'https://service.selfkey.org/self-token.svg'
  });

  const {
    getSkr,
    skr,
    loadingSkr,
    getVka,
    loadingVka,
    showTakeSelfieModal,
    setShowTakeSelfieModal,
    showConsentModal,
    setShowConsentModal,
    consentView,
    loadingSignConsent,
    handleAgreeConsent,
    error,
    eligibleError,
    poiCheckFailedError,
    consentError,
    errorMessage,
    resetErrors
  } = useSkrProcess(
    address,
    data => {
      // const amount = parseUnits(data.amount, 18);
      selfMinting(data);
    },
    (success, error) => {
      if (success) {
        handleMintAll();
      }
    }
  );

  const handlePOIContinue = async base64Selfie => {
    setShowTakeSelfieModal(false);
    await getVka(skr.SKR.url, { SKR: skr.SKR, selfie: base64Selfie });
  };

  const handleMintAll = async () => {
    try {
      const response = await getSkr(`${envConfigs.serverUrl}/rewards/self-minting-request/${address}`, {
        amount: formatEther(rewards?.total)
      });
      if (response.error) {
        throw new Error(response.error || response.status);
      }
    } catch (error) {
      handleError('Error requesting Mint All', error);
    }
  };

  const handleTransactionCompleted = () => {
    resetSelfMint();
  };

  const [formattedSelfBalance, setFormattedSelfBalance] = useState({ formattedValue: null, wasRounded: false });
  useEffect(() => {
    setFormattedSelfBalance(convertNumber(selfBalance, true));
  }, [selfBalance]);

  const [formattedEarnedSelf, setFormattedEarnedSelf] = useState({ formattedValue: null, wasRounded: false });
  useEffect(() => {
    setFormattedEarnedSelf(convertNumber(earnedSelf, true));
  }, [earnedSelf]);

  useEffect(() => {
    if (hasError) {
      setDocumentTitle(`${APP_NAME} - ${PAGES.TOKEN_MINTING} - Error`);
    } else if (selfMintingTransaction?.transactionHash) {
      refetchSelfBalance();
      getRewards();
      setDocumentTitle(`${APP_NAME} - ${PAGES.TOKEN_MINTING} - Success`);
    } else {
      setDocumentTitle(`${APP_NAME} - ${PAGES.TOKEN_MINTING}`);
    }

    if (error || eligibleError || poiCheckFailedError) {
      if (eligibleError) {
        setDocumentTitle(`${APP_NAME} - ${PAGES.TOKEN_MINTING} - Not Eligible`);
      } else {
        setDocumentTitle(`${APP_NAME} - ${PAGES.TOKEN_MINTING} - Error`);
      }
    }
  }, [hasError, selfMintingTransaction, error, eligibleError, poiCheckFailedError]);

  if (hasError) {
    return <TransactionFailed title={'Self Minting failed'} onRetry={handleTransactionCompleted} skStyle={false} />;
  } else if (selfMintingTransaction?.transactionHash) {
    return <TransactionCompleted tx={selfMintingTransaction} handleAction={handleTransactionCompleted} skStyle={false} />;
  }

  if (error || eligibleError || poiCheckFailedError) {
    if (consentError) {
      return <MintingFailed title={'Consent Request Failed'} onRetry={() => resetErrors()} errorMessage={errorMessage} />;
    } else if (poiCheckFailedError) {
      return <POICheckFailed handleAction={() => resetErrors()} page={PAGES.TOKEN_MINTING} />;
    } else {
      return (
        <RejectedPage
          title={'Oh, no!'}
          content={'Something went wrong, please try again.'}
          actionLabel={'OK'}
          handleAction={() => resetErrors()}
          skStyle={false}
          eventPage={`${PAGES.TOKEN_MINTING} - Request Error`}
        />
      );
    }
  }

  if (waitingTransaction) {
    return <SkWaitingTransaction skStyle={false} />;
  }

  if (loadingSignConsent) {
    return <SigningConsentPage />;
  }

  return (
    <PagesBackground>
      <Box flexDirection="column" alignItems="center" maxWidth={'1216px'} width={'100%'}>
        <View position={'absolute'} left={0}>
          <SkBackButton eventName={PAGES.TOKEN_MINTING + ' - Back Button'} onPress={() => history.goBack()} />
        </View>
        <VStack space={{ base: '40px', md: '64px' }}>
          <Stack direction={{ base: 'column', md: 'row' }} space={'32px'} alignItems={{ base: 'center', md: 'flex-start' }}>
            <Stack alignItems="center" textAlign="center" alignSelf={'center'} space="4" display={{ base: 'block', md: 'none' }}>
              <View w={'310px'} h={'310px'}>
                <ModelLoader model={'/3d/step-1/self.glb'}></ModelLoader>
              </View>
            </Stack>

            <VStack space={'40px'}>
              <SkFrame h={{ base: '226px', md: '300px' }}>
                <VStack alignItems={'center'} justifyContent={'center'} h={'100%'} space={{ base: '12px', md: '24px' }}>
                  {isLoadingSelfBalance ? (
                    <Spinner color={'indigo.500'} />
                  ) : (
                    <Text
                      fontWeight={700}
                      fontSize={{ base: '14px', md: '16px' }}
                      lineHeight={{ base: '20px', md: '24px' }}
                      display={'flex'}
                      alignItems={'center'}
                    >
                      SELF <Image alt="self token" source={{ uri: selfToken }} w={'16px'} h={'16px'} /> in your Wallet:{' '}
                      {
                        <HoverTooltip content={`${selfBalance}`} disabled={!formattedSelfBalance.wasRounded}>
                          <Text>
                            {formattedSelfBalance.formattedValue}
                            {formattedSelfBalance.wasRounded ? <Text fontSize={'12px'}>...</Text> : ''}
                          </Text>
                        </HoverTooltip>
                      }
                    </Text>
                  )}
                  <VStack alignItems={'center'}>
                    <Text fontWeight={400} fontSize={{ base: '16px', md: '20px' }} lineHeight={{ base: '24px', md: '32px' }} color={'#FFEDD2'}>
                      Unminted:
                    </Text>
                    <Heading
                      fontFamily={'Plus Jakarta Sans'}
                      fontWeight={700}
                      fontSize={{ base: '18px', md: '32px' }}
                      lineHeight={{ base: '22px', md: '40px' }}
                      display={'flex'}
                      alignItems={'center'}
                    >
                      {earnedSelf && (
                        <HoverTooltip content={`${earnedSelf}`} disabled={!formattedEarnedSelf.wasRounded}>
                          <Text>
                            {formattedEarnedSelf.formattedValue}
                            {formattedEarnedSelf.wasRounded ? <Text fontSize={'12px'}>...</Text> : ''} SELF{' '}
                          </Text>
                        </HoverTooltip>
                      )}
                      <Image alt="self token" source={{ uri: selfToken }} w={{ base: '18px', md: '32px' }} h={{ base: '18px', md: '32px' }} />
                    </Heading>
                  </VStack>
                  <VStack mt={{ base: '12px', md: 0 }} alignItems={'center'}>
                    <SkButton
                      eventName={'Mint All'}
                      w={{ base: '97px', md: '222px' }}
                      fixedSize={false}
                      onPress={handleMintAll}
                      isLoading={isLoadingSelfMinting || loadingSkr || loadingVka || loadingSignConsent}
                      isDisabled={parseFloat(earnedSelf) === 0}
                    >
                      Mint All
                    </SkButton>
                  </VStack>
                </VStack>
              </SkFrame>

              <SkFrame h={{ base: '226px', md: '300px' }}>
                <VStack alignItems={'center'} justifyContent={'center'} h={'100%'} space={{ base: '32px', md: '24px' }}>
                  <Heading
                    fontFamily={'Plus Jakarta Sans'}
                    fontWeight={700}
                    fontSize={{ base: '20px', md: '28px' }}
                    lineHeight={{ base: '24px', md: '36px' }}
                    display={'flex'}
                    alignItems={'center'}
                  >
                    Display SELF <Image alt="self token" source={{ uri: selfToken }} w={{ base: '18px', md: '32px' }} h={{ base: '18px', md: '32px' }} /> in
                    your wallet
                  </Heading>
                  <VStack alignItems={'center'}>
                    <Text fontWeight={400} fontSize={{ base: '14px', md: '16px' }} lineHeight={{ base: '20px', md: '24px' }} color={'#FFEDD2'}>
                      Token address:
                    </Text>
                    <HStack justifyContent={'space-between'} alignItems={'center'} space={'6px'}>
                      <Text fontWeight={400} fontSize={{ base: '14px', md: '16px' }} lineHeight={{ base: '20px', md: '24px' }} isTruncated>
                        {truncateAddress(contracts.selfToken, 23)}
                      </Text>
                      <CopyButton value={contracts.selfToken} />
                    </HStack>
                  </VStack>
                  {isMetamaskAvailable() && (
                    <SkButton
                      eventName={'Import SELF Token'}
                      w={{ base: '97px', md: '222px' }}
                      fixedSize={false}
                      isLoading={loadingImportToken}
                      onPress={importToken}
                    >
                      Import
                    </SkButton>
                  )}
                </VStack>
              </SkFrame>

              {false && (
                <SkFrame h={{ base: '296px', md: '324px' }} px={{ base: '20px', md: '40px' }}>
                  <VStack alignItems={'center'} justifyContent={'center'} h={'100%'} space={{ base: '32px', md: '24px' }}>
                    <Heading
                      fontFamily={'Plus Jakarta Sans'}
                      fontWeight={700}
                      fontSize={{ base: '20px', md: '28px' }}
                      lineHeight={{ base: '24px', md: '36px' }}
                      display={'flex'}
                      alignItems={'center'}
                    >
                      Bridge SELF <Image alt="self token" source={{ uri: selfToken }} w={{ base: '18px', md: '32px' }} h={{ base: '18px', md: '32px' }} />
                    </Heading>
                    <SkButton eventName={'Bridge SELF'} w={{ base: '97px', md: '222px' }} fixedSize={false} onPress={() => history.push('/member/bridge-self')}>
                      Bridge
                    </SkButton>
                    <Text fontWeight={400} fontSize={{ base: '10px', md: '12px' }} lineHeight={{ base: '14px', md: '16px' }} color={'#A9A9A9'}>
                      The bridging service is provided by a third-party platform, not by SelfKey DAO. By using this service, you acknowledge that the bridging
                      process and its security are solely the responsibility of the third-party service. SelfKey DAO does not have control over and is not
                      liable for, any issues that may arise during bridging. SelfKey DAO will not be able to help if something goes wrong while using the
                      Bridging services of this third party.
                    </Text>
                  </VStack>
                </SkFrame>
              )}
            </VStack>

            <Stack alignItems="center" textAlign="center" space="4" display={{ base: 'none', md: 'block' }} w={'440px'} h={'440px'}>
              <ModelLoader model={'/3d/step-1/self.glb'}></ModelLoader>
            </Stack>
          </Stack>
        </VStack>
        <SkBackButton eventName={'Token Minting Page'} onPress={() => history.goBack()} isMobile={true} my={'40px'} />
        <JoinDiscordCard my={{ base: '60px', md: '160px' }} skStyle={false} />
      </Box>
      <WarningModal
        showModal={showConsentModal}
        title={consentView}
        leftButton={
          <LdButton variant={'secondary'} fixedSize={false} w={'134px'} onPress={() => setShowConsentModal(false)}>
            Cancel
          </LdButton>
        }
        rightButton={
          <LdButton fixedSize={false} w={'134px'} onPress={() => handleAgreeConsent()}>
            Agree
          </LdButton>
        }
        handleClose={() => setShowConsentModal(false)}
      />
      <SelfieModal isOpen={showTakeSelfieModal} handleClose={() => setShowTakeSelfieModal(false)} handlePOIContinue={handlePOIContinue} />
    </PagesBackground>
  );
}

export { MintSelfPageContainer };
