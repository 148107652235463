import { current as envConfigs } from 'env-configs/selfkey-org';
export const subscribeToNewsletter = async function (email) {
  try {
    const result = await fetch(`${envConfigs.serverUrl}/newsletter`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email })
    });
    return result.ok;
  } catch (e) {
    return false;
  }
};
