import React, { useState } from 'react';
import { LandingPageComponent } from './landing_page_component';
import { LandingPageComponentMvp } from './landing_page_component_mvp';
import { current as envConfigs } from 'env-configs/selfkey-org';
import { handleError } from '../../common/lib/error_handler';

function LandingPageContainer({ mvp = true }) {
  const [subscribedToNewsletter, setSubscribedToNewsletter] = useState(false);

  const handleNewsletterSubscribe = async function (email) {
    await fetch(`${envConfigs.serverUrl}/newsletter`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email: email })
    }).catch(function (error) {
      handleError('Error subscribing to newsletter', error);
    });
    setSubscribedToNewsletter(true);
  };

  return mvp ? (
    <LandingPageComponentMvp />
  ) : (
    <LandingPageComponent subscribedToNewsletter={subscribedToNewsletter} onNewsletterClick={handleNewsletterSubscribe} />
  );
}

export { LandingPageContainer };
