import { RejectedPage } from '../../lockdao/pages/rejected_page';
import React from 'react';

export const POICheckFailed = ({ handleAction, page }) => {
  const errorContent =
    'The selfie provided did not match your selfie collected during the SelfKey iD Verification.\n' +
    '\n' +
    'Please make sure you are using a better quality camera.\n' +
    "For best results, using your phone's camera is recommended.";
  return (
    <RejectedPage
      includeLayout={false}
      title={'Verification Failed!'}
      content={errorContent}
      actionLabel={'Retry'}
      handleAction={handleAction}
      eventPage={`${page ? `${page} - ` : ''}POI Check Failed`}
    />
  );
};
