import React, { useState, useEffect } from 'react';
import usePost from './use_post';
import useGet from './use_get';
import { handleError } from '../lib/error_handler';
import { RP_CONFIG } from '../lib/constants';
import { ConsentAgreement } from '../components/consent_agreement';
import { useSignTypedData } from 'wagmi';

const useSkrProcess = (address, callback, consentCallback) => {
  const [getSkr, { data: skr, loading: loadingSkr, error: errorSkr, status: statusSkr, response: responseSkr }] = usePost();
  const [getVka, { data: vka, loading: loadingVka, error: errorVka, response: responseVka, status: statusVka }] = usePost();
  const [sendVka] = usePost();
  const [checkConsent, { data: consentData, status: consentStatus }] = useGet();

  const [errorMessage, setErrorMessage] = useState(false);
  const [error, setError] = useState(false);
  const [eligibleError, setEligibleError] = useState(false);
  const [poiCheckFailedError, setPoiCheckFailedError] = useState(false);
  const [consentError, setConsentError] = useState(false);

  const [consent, setConsent] = useState(null);
  const [consentView, setConsentView] = useState(null);
  const [consentApproved, setConsentApproved] = useState(false);
  const [showConsentModal, setShowConsentModal] = useState(false);
  const { signTypedDataAsync, error: errorSigningConsent, reset: resetErrorSigningRequest } = useSignTypedData({});
  const [sendConsentRequest, { data: dataConsentResponse, loading: isLoadingConsentRequest, error: errorConsentRequest }] = usePost();
  const [loadingSignConsent, setLoadingSignConsent] = useState(false);

  const [showTakeSelfieModal, setShowTakeSelfieModal] = useState(false);

  const handleAgreeConsent = async () => {
    setLoadingSignConsent(true);
    setShowConsentModal(false);
    try {
      const signature = await signTypedDataAsync({
        domain: {
          name: 'KYCS.Ai Consent',
          version: '1'
        },
        primaryType: 'KycsaiConsent',
        types: {
          KycsaiConsent: [{ name: 'content', type: 'string' }]
        },
        message: {
          content: consent.consent
        }
      });
      await sendConsentRequest(consent.consent_url, { signature });
      setConsentApproved(true);
      consentCallback(true);
    } catch (e) {
      consentCallback(false, e);
      setConsentError(true);
      setErrorMessage('Error signing consent');
      handleError('Error signing consent', e);
    } finally {
      setLoadingSignConsent(false);
    }
  };

  const resetErrors = () => {
    setErrorMessage(null);
    setError(false);
    setEligibleError(false);
    setPoiCheckFailedError(false);
    setConsentError(false);
  };

  // Handle SKR related logic
  useEffect(() => {
    if ((skr || errorSkr) && !loadingSkr) {
      if (statusSkr === 200 && responseSkr.data?.SKR?.url) {
        const SKR = responseSkr.data.SKR;
        if (SKR.requirements && Object.keys(SKR.requirements).length > 0) {
          const kycaiUrl = new URL(SKR.url).origin;
          checkConsent(`${kycaiUrl}/user/consent/${address}`);
        } else {
          getVka(SKR.url, { SKR });
        }
      } else {
        handleError('Error', errorSkr);
        setErrorMessage(errorSkr);
        setError(true);
      }
    }
  }, [skr, loadingSkr, statusSkr, errorSkr]);

  // Handle consent verification logic
  useEffect(() => {
    const SKR = skr?.SKR;
    if (SKR) {
      if (consentStatus === 200) {
        if (SKR.requirements[RP_CONFIG.SELFIE]) {
          setShowTakeSelfieModal(true);
        } else {
          getVka(SKR.url, { SKR });
        }
      } else if (consentStatus === 404) {
        getVka(SKR.url, { SKR });
      }
    }
  }, [consentStatus, consentData]);

  // Handle VKA related logic
  useEffect(() => {
    if ((vka || errorVka) && !loadingVka) {
      const status = responseVka?.status;
      if (status === 200) {
        if (vka.verifyUrl) {
          sendVka(vka.verifyUrl, vka);
        }
        const payload = vka.payload;
        callback(payload);
      } else if (status === 451 && !consentApproved) {
        // 451 - Consent missing - trigger consent flow
        const skr = responseVka?.data;
        setConsent(skr);
        setConsentView(() => <ConsentAgreement data={skr} />);
        setShowConsentModal(true);
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      } else if (status === 422) {
        if (responseVka.data.error === 'Scorecard failed') {
          handleError('Error', responseVka.data.error);
          setErrorMessage(responseVka.data.error);
          setEligibleError(true);
        } else if (responseVka.data.error === 'POI check failed') {
          handleError('Error', responseVka.data.error);
          setErrorMessage(responseVka.data.error);
          setPoiCheckFailedError(true);
        } else {
          handleError('Error', responseVka);
          setErrorMessage(responseVka);
          setError(true);
        }
      } else {
        handleError('Error', errorVka);
        setErrorMessage(errorVka);
        setError(true);
      }
    }
  }, [vka, loadingVka, responseVka, errorVka]);

  return {
    getSkr,
    skr,
    loadingSkr,
    getVka,
    vka,
    loadingVka,
    consentView,
    showConsentModal,
    setShowConsentModal,
    handleAgreeConsent,
    loadingSignConsent,
    showTakeSelfieModal,
    setShowTakeSelfieModal,
    errorMessage,
    error,
    eligibleError,
    poiCheckFailedError,
    consentError,
    resetErrors
  };
};

export default useSkrProcess;
