import { current as envConfigs } from 'env-configs/selfkey-org.js';

export async function isSelfKeyDaoServiceOnline() {
  try {
    const res = await fetch(`${envConfigs.serverUrl}/version`);
    if (res.status === 200) {
      const json = await res.json();
      return json.status === 'ok';
    }
  } catch (e) {
    console.log('SelfKey DAO Service not reachable', e);
  }
  return false;
}
export async function isSelfKeyDaoUnderMaintenance() {
  try {
    const res = await fetch(`${envConfigs.serverUrl}/maintenance`);
    if (res.status === 200) {
      const json = await res.json();
      return json.maintenance;
    }
  } catch (e) {
    console.log('SelfKey DAO Service not reachable', e);
  }
  return false;
}

export async function createUser({ address, email, publicKey }) {
  try {
    if (!navigator.onLine) {
      return false;
    }
    const response = await fetch(`${envConfigs.serverUrl}/user`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ address, email, publicKey })
    });
    const body = await response.json();
    return response.status === 200 && body.status === 'Invitation sent';
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function allowedForCrypto({ address, country }) {
  try {
    const response = await fetch(`${envConfigs.serverUrl}/credential/allowed-for-crypto/did:ethr:${address}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ country })
    });
    const body = await response.json();
    console.log('Allowed For Crypto Response', body);
    return response.status === 200;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function consumeInviteCode({ address, code }) {
  try {
    const response = await fetch(`${envConfigs.serverUrl}/user/invite/did:ethr:${address}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ code })
    });
    const body = await response.json();
    console.log('Consume Invite Code Response', body);
    return response.status === 200;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function getNftMetadata({ tokenId }) {
  try {
    const response = await fetch(`${envConfigs.serverUrl}/avatar/metadata/${tokenId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return await response.json();
  } catch (e) {
    console.error(e);
    return null;
  }
}

export const PAYMENT_MULTIPLIER = 1000000n;
