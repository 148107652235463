import React from 'react';
import { Heading, Text, View, VStack } from 'native-base';
import { PagesBackground } from '../../common/layout/main_layout.styled';
import { ModelLoader } from '../../common/components/model_loader';
import useDocumentTitle from '../../common/hooks/use_document_title';
import { APP_NAME, PAGES } from '../../common/lib/constants';

function VerificationPageContainer() {
  const { setDocumentTitle } = useDocumentTitle();

  React.useEffect(() => {
    setDocumentTitle(`${APP_NAME} - ${PAGES.VERIFYING}`);
  }, []);

  return (
    <PagesBackground skStyle={true}>
      <VStack space={{ base: '24px', md: '40px' }} alignItems={'center'}>
        <View w={{ base: '120px', md: '200px' }} h={{ base: '120px', md: '200px' }}>
          <ModelLoader model={'/3d/step-1/verification.glb'} scale={0.5}></ModelLoader>
        </View>
        <Heading
          fontSize={{ base: '18px', md: '48px' }}
          fontWeight={700}
          lineHeight={{ base: '22px', md: '60px' }}
          letterSpacing={'0em'}
          textAlign={'center'}
          fontFamily={'Plus Jakarta Sans'}
        >
          Verification in Progress
        </Heading>
        <Text
          maxW={{ base: '280px', md: 'none' }}
          textAlign={'center'}
          fontWeight={400}
          fontSize={{ base: '14px', md: '16px' }}
          lineHeight={{ base: '20px', md: '24px' }}
          color={'#BAEBFF'}
          mb={'56px'}
        >
          You will receive a notification via email when the verification is completed.
        </Text>
      </VStack>
    </PagesBackground>
  );
}

export { VerificationPageContainer };
