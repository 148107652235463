import { Image, useBreakpointValue, View } from 'native-base';
import React from 'react';
import backArrowBig from '../../assets/icons/back-icon-big.svg';
import SkButton from './sk_button';
import backArrow from '../../assets/icons/back-icon.svg';

export function SkBackButton({ onPress, eventName, isMobile = false, ...props }) {
  return isMobile ? (
    <View alignItems={'center'} display={{ base: 'block', lg: 'none' }} {...props}>
      <SkButton eventName={`Back - ${eventName}`} onPress={onPress} leftIcon={backArrow} leftIconSize={'16px'} variant={'secondary'} fixedSize={false}>
        Back
      </SkButton>
    </View>
  ) : (
    <SkButton
      eventName={`Back - ${eventName}`}
      alignSelf={'flex-start'}
      display={{ base: 'none', lg: 'block' }}
      variant={'secondary'}
      fixedSize={false}
      width={'52px'}
      onPress={onPress}
      mb={'8px'}
      {...props}
    >
      <Image
        source={{
          uri: backArrowBig
        }}
        alt="go back"
        w="28px"
        h="16px"
        resizeMode="contain"
      />
    </SkButton>
  );
}
