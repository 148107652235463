import { Text, useBreakpointValue, View } from 'native-base';
import React from 'react';
import { JOIN_DISCORD_URL } from '../lib/constants';
import SkButtonSK from '../../selfkey-id/pages/components/sk_button';
import SkButtonLD from '../../lockdao/pages/components/sk_button';

export function JoinDiscordCard({ skStyle = true, ...props }) {
  const SkButton = skStyle ? SkButtonSK : SkButtonLD;
  const handleJoinDiscord = () => {
    window.open(JOIN_DISCORD_URL);
  };
  const discordBackground = useBreakpointValue({
    base: "url('/join-discord-banner-mobile.png')",
    md: "url('/join-discord-banner.png')"
  });
  return (
    <View
      style={{
        backgroundImage: discordBackground,
        backgroundSize: 'cover'
      }}
      w={{ base: '300px', md: '863px' }}
      height={{ base: '314px', md: '230px' }}
      flexDir={{ base: 'column', md: 'row' }}
      alignItems={'center'}
      justifyContent={'flex-end'}
      pb={{ base: '55px', md: '30px' }}
      pr={{ base: '0', md: '50px' }}
      {...props}
    >
      <Text
        fontSize={{ base: '24px', md: '32px' }}
        fontWeight={700}
        lineHeight={{ base: '32px', md: '40px' }}
        maxW={'306px'}
        mr={{ base: '0', md: '96px' }}
        ml={{ base: '25px', md: '0' }}
        mb={{ base: '30px', md: '0' }}
        textAlign={{ base: 'center', md: 'left' }}
      >
        Join SelfKey DAO Discord community
      </Text>
      <SkButton eventName={'Join Discord Card'} size={'small'} ml={{ base: '25px', md: '0' }} onPress={handleJoinDiscord}>
        Join Discord
      </SkButton>
    </View>
  );
}
