import React from 'react';
import { Text, View, VStack } from 'native-base';
import { PagesBackground } from '../layout/main_layout.styled';
import SkButton from '../../selfkey-id/pages/components/sk_button';
import { ModelLoader } from './model_loader';
import { MainLayout } from '../../selfkey-id/pages/layout/main_layout';

function OfflinePageContainer({ retry, skStyle }) {
  return (
    <MainLayout>
      <PagesBackground skStyle={skStyle}>
        <VStack space={'40px'}>
          <View w={'100%'} h={{ base: '120px', md: '200px' }}>
            <ModelLoader model={`/3d/${skStyle ? 'step-1' : 'step-2'}/server.glb`} scale={0.5}></ModelLoader>
          </View>
          <Text fontSize={{ base: '18px', md: '48px' }} fontWeight={700} lineHeight={{ base: '22px', md: '60px' }} letterSpacing={'0em'} textAlign={'center'}>
            Server offline
          </Text>
          <VStack mb={'23px'}>
            <Text
              maxW={{ base: '280px', md: 'none' }}
              textAlign={'center'}
              color={'rgba(186, 235, 255, 1)'}
              fontSize={'16px'}
              fontWeight={400}
              lineHeight={'24px'}
            >
              The MetaProof server appears to be down.
            </Text>
            <Text
              maxW={{ base: '280px', md: 'none' }}
              textAlign={'center'}
              color={'rgba(186, 235, 255, 1)'}
              fontSize={'16px'}
              fontWeight={400}
              lineHeight={'24px'}
            >
              Wait a few minutes and Try again.
            </Text>
          </VStack>
          <SkButton eventName={'Retry - Offline Page'} fixedSize={false} onPress={retry} w={'105px'} alignSelf={'center'}>
            Retry
          </SkButton>
        </VStack>
      </PagesBackground>
    </MainLayout>
  );
}

export { OfflinePageContainer };
