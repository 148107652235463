import { Box, Heading, View } from 'native-base';
import React, { useEffect, useRef, useState } from 'react';
import Lottie from 'lottie-react';
import { TextGradient } from '../../selfkey-id/pages/components/text_gradient';
import { FeatureTitle } from './features_section';

export function LottieAnimationCard({ title, animation, loop = false, animationTopOffset = 0, ...props }) {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!isVisible && entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      {
        rootMargin: '0px',
        threshold: 0.1
      }
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <Box alignSelf={'center'} ref={ref} {...props}>
      {isVisible && (
        <View top={animationTopOffset}>
          <Lottie animationData={animation} loop={loop} />
        </View>
      )}
    </Box>
  );
}
