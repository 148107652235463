import { Flex, Text, VStack } from 'native-base';
import SkButton from './components/sk_button';
import { PagesBackground } from '../../common/layout/main_layout.styled';
import React, { useContext, useEffect } from 'react';
import { RegistryContext } from '../../common/context/app_context';
import { ModelLoader } from '../../common/components/model_loader';
import useDocumentTitle from '../../common/hooks/use_document_title';
import { JOIN_DISCORD_URL } from '../../common/lib/constants';

export function PaymentFailed() {
  const { hasError } = useContext(RegistryContext);
  const { setDocumentTitle } = useDocumentTitle();
  useEffect(() => {
    const userRejected = !!hasError && hasError.message?.indexOf('User rejected request') > -1;
    if (userRejected) {
      setDocumentTitle('SelfKey DAO - Payment - Rejected');
    } else {
      setDocumentTitle('SelfKey DAO - Payment - Error');
    }
  }, []);
  const { clearErrors } = useContext(RegistryContext);
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  const handleRetry = () => {
    clearErrors();
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };
  const handleContactSupport = () => {
    window.open(JOIN_DISCORD_URL);
  };
  return (
    <PagesBackground skStyle={true}>
      <VStack space={'40px'}>
        <ModelLoader model={'/3d/step-1/fail.glb'} scale={0.5}></ModelLoader>
        <Text fontSize={{ base: '18px', md: '48px' }} fontWeight={700} lineHeight={{ base: '22px', md: '60px' }} letterSpacing={'0em'} textAlign={'center'}>
          Payment failed
        </Text>
        <Text maxW={{ base: '280px', md: 'none' }} textAlign={'center'} color={'#FFEDD2'} mb={'23px'}>
          Please return to the purchase screen and try again.
        </Text>
        <VStack alignItems={'center'} space={'24px'}>
          <SkButton eventName={'Retry - Payment Failed'} fixedSize={false} onPress={handleRetry}>
            Retry
          </SkButton>
          <SkButton eventName={`Payment failed - Contact Support`} fixedSize={false} variant={'secondary'} onPress={handleContactSupport}>
            Contact Support
          </SkButton>
        </VStack>
      </VStack>
    </PagesBackground>
  );
}
