import { Input } from 'native-base';
import React from 'react';

export const SkNumericInput = ({ value, onChange, rightElement, maxValue, placeholder = '' }) => {
  const handleChange = text => {
    const currencyRegex = /^([0-9]*[.|,])?[0-9]*$/;
    try {
      if (!currencyRegex.test(text)) {
        return;
      }
      if (maxValue && parseFloat(text) > parseFloat(maxValue)) {
        return;
      }
      onChange(text);
    } catch (error) {
      console.log('ERROR', error);
    }
  };

  return (
    <Input
      value={value.toString()}
      placeholder={placeholder}
      onChangeText={handleChange}
      keyboardType="numeric"
      variant="rounded"
      w={{ base: '280px', md: '346px' }}
      h={{ base: '48px', md: '56px' }}
      borderRadius={'50px'}
      alignSelf={'center'}
      px={'40px'}
      fontSize={'16px'}
      borderColor={'#939393'}
      overflowY={'hidden'}
      _focus={{
        borderWidth: '1px',
        borderColor: '#FFFFFF',
        background: 'rgba(0, 0, 0, 0.2)'
      }}
      _hover={{
        borderWidth: '1px',
        borderColor: '#FFFFFF',
        background: 'rgba(0, 0, 0, 0.2)'
      }}
      _stack={{
        borderWidth: '1px',
        borderColor: '#7D7D7D',
        background: 'rgba(0, 0, 0, 0.2)'
      }}
      focusOutlineColor={'#FFFFFF'}
      rightElement={rightElement}
    />
  );
};
