import { SkButton } from '../../../../common/components/sk_button.jsx';
import styled from 'styled-components';

const Button1 = styled.b`
  position: relative;
  line-height: 16px;
`;
const ButtonRoot = styled(SkButton)`
  border-radius: 40px;
  background: linear-gradient(93.17deg, #2da1f8, #cd22c6);
  border: 1px solid #000;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px 40px;
  text-align: left;
  font-size: 16px;
  color: #fff;
  font-family: Inter;
`;

const VoteButton = ({ handleVote, loadingVoting }) => {
  return (
    <ButtonRoot id="VoteToUnlock" fixedSize={false} onPress={handleVote} isLoading={loadingVoting} eventName={'Vote'}>
      <Button1>Vote to Unlock</Button1>
    </ButtonRoot>
  );
};

export default VoteButton;
