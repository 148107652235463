import React from 'react';

const RegistryContext = React.createContext([null, () => {}]);

const Web3WagmiContext = React.createContext([null, () => {}]);

const NftContext = React.createContext([null, () => {}]);
const RestrictedCountriesContext = React.createContext([null, () => {}]);
const RewardsContext = React.createContext([null, () => {}]);
const StakingContext = React.createContext([null, () => {}]);
const MintLockContext = React.createContext([null, () => {}]);

const RoutingContext = React.createContext([null, () => {}]);

const LoadingContext = React.createContext([null, () => {}]);

const VoteContext = React.createContext([null, () => {}]);

const AirdropContext = React.createContext([null, () => {}]);

const SelfTokenContext = React.createContext([null, () => {}]);

export {
  NftContext,
  Web3WagmiContext,
  RestrictedCountriesContext,
  RewardsContext,
  StakingContext,
  MintLockContext,
  RegistryContext,
  RoutingContext,
  LoadingContext,
  VoteContext,
  AirdropContext,
  SelfTokenContext
};
