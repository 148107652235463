import { TextGradient } from '../../common/components/text_gradient';
import { Heading, Text } from 'native-base';
import React from 'react';
import { useLocation } from 'react-router-dom';

const HomeTitle = props => {
  return (
    <Heading
      fontWeight={800}
      fontSize={{ base: '32px', md: '72px' }}
      lineHeight={{ base: '40px', md: '80px' }}
      textAlign={{ base: 'center', lg: 'left' }}
      alignSelf={{ base: 'center', md: 'flex-start' }}
      maxW={{ base: '320px', md: '767px' }}
      fontFamily={'Plus Jakarta Sans'}
      pb={{ base: '12px', md: '16px' }}
    >
      {props.children}
    </Heading>
  );
};

const HomeSubTitle = props => {
  return (
    <Text
      color={'#FFEDD2'}
      fontWeight={400}
      fontSize={{ base: '16px', md: '20px' }}
      lineHeight={{ base: '24px', md: '32px' }}
      textAlign={{ base: 'center', lg: 'left' }}
      alignSelf={{ base: 'center', md: 'flex-start' }}
      maxW={{ base: '320px', md: '545px' }}
    >
      {props.children}
    </Text>
  );
};
export function HomeHero(props) {
  const { pathname } = useLocation();
  if (pathname === '/member') {
    return (
      <HomeTitle>
        Welcome to the
        <br />
        SelfKey <TextGradient>DAO</TextGradient>!
      </HomeTitle>
    );
  } else if (pathname === '/not-eligible') {
    return (
      <>
        <HomeTitle>Welcome!</HomeTitle>
        <HomeSubTitle>Unfortunately you are not eligible to fully access the SelfKey Network's features at this time.</HomeSubTitle>
      </>
    );
  } else if (pathname === '/public') {
    return (
      <>
        <HomeTitle>Hello, Curious Visitor!</HomeTitle>
        <HomeSubTitle>Since you are not verified, you will have limited access to the App's Features.</HomeSubTitle>
      </>
    );
  }
}
