import React, { useContext, useState } from 'react';
import { Checkbox, FormControl, Heading, HStack, Icon, Input, Stack, Text, View, VStack } from 'native-base';
import { PagesBackground } from '../../common/layout/main_layout.styled';
import { RegistryContext, Web3WagmiContext } from '../../common/context/app_context';
import SkButton from './components/sk_button';
import { validateEmail } from '../../common/lib/helpers';
import { JoinDiscordCard } from '../../common/components/join_discord_card';
import { ModelLoader } from '../../common/components/model_loader';
import { CachePolicies, useFetch } from 'use-http';
import { current as envConfigs } from 'env-configs/selfkey-org';
import WarningModal from '../../common/components/warning_modal';
import LdButton from '../../lockdao/pages/components/sk_button';
import { PaymentCompleted } from './payment_completed';
import useDocumentTitle from '../../common/hooks/use_document_title';
import useClickEvent from '../../common/hooks/use_click_event';
import { APP_NAME, PAGES } from '../../common/lib/constants';
import useSkrProcess from '../../common/hooks/use_skr_flow';
import { SigningConsentPage } from '../../common/components/signing-content-page';
import AutoRedirectModal from '../../common/components/auto-redirect-modal';
import { isDesktop } from 'react-device-detect';
import { SkLink } from '../../common/components/sk_link';
import Countdown from '../../common/components/countdown';

function SubHeading({ children }) {
  return (
    <Text
      fontSize={{ base: '14px', md: '16px' }}
      fontWeight={400}
      lineHeight={{ base: '20px', md: '24px' }}
      maxW={{ base: '320px', md: '510px' }}
      textAlign={'center'}
      color={'#BAEBFF'}
    >
      {children}
    </Text>
  );
}

const agreeTermsStyle = {
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '16px',
  paddingLeft: '2px',
  color: '#A9A9A9'
};
function VerifyPageContainer() {
  const { setDocumentTitle } = useDocumentTitle();
  const { activeAddress: address } = useContext(Web3WagmiContext);
  const [formData, setData] = React.useState({});
  const [errors, setErrors] = React.useState({});
  const [invitationSent, setInvitationSent] = React.useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const { paymentCompleted, paymentTransactionHash } = useContext(RegistryContext);
  const { setButtonClickEvent } = useClickEvent();

  const { getVka, loadingVka, showConsentModal, setShowConsentModal, consentView, loadingSignConsent, handleAgreeConsent } = useSkrProcess(
    address,
    data => {
      setInvitationSent(true);
    },
    (success, error) => {
      if (success) {
        onSubmit();
      }
    }
  );

  React.useEffect(() => {
    setDocumentTitle(`${APP_NAME} - ${PAGES.VERIFY}`);
  }, []);

  const { post: getInvitation, loading: loadingGetInvitation } = useFetch(`${envConfigs.serverUrl}/user/invitation`, {
    cachePolicy: CachePolicies.NETWORK_ONLY
  });

  const isSendingInvitation = () => loadingGetInvitation || loadingVka;
  const validate = () => {
    if (formData.email === undefined) {
      setErrors({ ...errors, email: 'Email is required.' });
      return false;
    } else if (!validateEmail(formData.email)) {
      setErrors({ ...errors, email: 'Invalid email.' });
      return false;
    } else if (!acceptedTerms) {
      setErrors({ ...errors, email: 'You have to agree with terms and conditions.' });
      return false;
    }
    setErrors({});
    return true;
  };

  const acceptTerms = checked => {
    setAcceptedTerms(checked);
    if (checked) {
      setButtonClickEvent('Accept Terms');
      setErrors({});
    }
  };

  const [kycaiLink, setKycaiLink] = useState(null);
  const [linkTtl, setLinkTtl] = useState(null);
  const [linkExpired, setLinkExpired] = useState(false);
  const [isAutoRedirectOpen, setIsAutoRedirectOpen] = useState(false);
  const onSubmit = async () => {
    setButtonClickEvent('Send Invitation');
    if (validate()) {
      const response = await getInvitation({ address });
      if (response?.SKR?.url) {
        const vka = await getVka(response.SKR.url, { SKR: response.SKR, email: formData.email });
        if (vka?.actionUrl) {
          setKycaiLink(vka.actionUrl);
          setLinkTtl(vka.ttl);
          setLinkExpired(false);
          setIsAutoRedirectOpen(true);
        }
      }
    }
  };

  const handleLinkExpired = () => {
    setLinkExpired(true);
    setLinkTtl(null);
  };

  if (paymentCompleted) {
    return <PaymentCompleted hash={paymentTransactionHash} />;
  }

  if (loadingSignConsent) {
    return <SigningConsentPage />;
  }

  return (
    <PagesBackground skStyle={true}>
      <VStack space={'40px'} mt={'18px'}>
        <View w={'100%'} h={{ base: '120px', md: '200px' }}>
          <ModelLoader model={'/3d/step-1/verification.glb'} scale={0.5}></ModelLoader>
        </View>
        <Heading
          fontSize={{ base: '18px', md: '48px' }}
          fontWeight={700}
          lineHeight={{ base: '22px', md: '60px' }}
          letterSpacing={'0em'}
          textAlign={'center'}
          fontFamily={'Plus Jakarta Sans'}
        >
          Initiate Verification
        </Heading>
        <VStack mb={{ base: '24px', md: '24px' }} alignSelf={'center'} space={{ base: '20px', md: '30px' }}>
          <SubHeading>Write your email in the field below and click Send Invitation.</SubHeading>
          <SubHeading>An invitation will be sent to your inbox.</SubHeading>
        </VStack>
        <FormControl alignItems={'center'} isRequired isInvalid={'email' in errors}>
          <Input
            onSubmitEditing={onSubmit}
            variant="rounded"
            placeholder="Email address"
            placeholderTextColor={'#7D7D7D'}
            w={{ base: '320px', md: '592px' }}
            h={{ base: '56px', md: '56px' }}
            alignSelf={'center'}
            py={'16px'}
            px={'40px'}
            overflowY={'hidden'}
            onChangeText={value => setData({ ...formData, email: value })}
            fontSize={'16px'}
            borderColor={'#7D7D7D'}
            _focus={{
              borderWidth: '1px',
              borderColor: '#FFFFFF',
              background: 'rgba(0, 0, 0, 0.2)'
            }}
            _hover={{
              borderWidth: '1px',
              borderColor: '#FFFFFF',
              background: 'rgba(0, 0, 0, 0.2)'
            }}
            _stack={{
              borderWidth: '1px',
              borderColor: '#7D7D7D',
              background: 'rgba(0, 0, 0, 0.2)'
            }}
            focusOutlineColor={'#FFFFFF'}
          />
          <Stack mb={'40px'}>
            {'email' in errors && <FormControl.ErrorMessage>{errors.email}</FormControl.ErrorMessage>}
            {invitationSent && !linkExpired && !isSendingInvitation() && !('email' in errors) && (
              <Text textAlign={'center'}>Invitation sent, please check your inbox!</Text>
            )}
            {kycaiLink && !isDesktop && !linkExpired && (
              <div style={{ marginTop: '20px' }}>
                <SkLink url={kycaiLink} text={'Click here to start'}></SkLink>
                <Countdown time={linkTtl} onComplete={() => handleLinkExpired()}></Countdown>
              </div>
            )}
            {kycaiLink && !isDesktop && linkExpired && (
              <Text maxW={{ base: '200px', md: '100%' }} textAlign={'center'}>
                Your application link expired, please resend your invitation.
              </Text>
            )}
          </Stack>
          <SkButton
            eventName={`${PAGES.VERIFY} - Send Invitation`}
            fixedSize={false}
            w={'176px'}
            h={'48px'}
            onPress={onSubmit}
            isLoading={isSendingInvitation()}
          >
            Send Invitation
          </SkButton>
          <Checkbox
            alignItems={'flex-start'}
            isInvalid={'terms' in errors}
            isChecked={acceptedTerms}
            onChange={e => acceptTerms(e)}
            alignSelf={{ base: 'center', md: 'left' }}
            letterSpacing={0}
            mt={'40px'}
            colorScheme={'green'}
            borderColor={'white'}
            borderWidth={'1px'}
            bg={'rgba(0, 0, 0, 0.2)'}
            _hover={{ borderColor: 'white' }}
            _icon={{ color: 'white' }}
            pb={0}
            pr={0}
            icon={
              <Icon>
                <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 5.5L4 8L8.5 1" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </Icon>
            }
          >
            <HStack maxW={{ base: '320px', md: '778px' }}>
              <Text {...agreeTermsStyle}>
                By continuing, you acknowledge and agree to the following: (1) Your wallet address and email address (stored on the local client on your
                computer without SelfKey DAO access to it) will be directly transferred by you to the third-party website, KYCS.AI, to initiate identity
                verification and other checks; (2) SelfKey DAO may further request specific information about you from KYCS.AI to ensure the security of
                transactions and to provide you with the Services. If you disagree, please do NOT continue. For more information, please review SelfKey DAO
                <a href={'/privacy-policy'} target="_blank" rel="noopener noreferrer" style={{ textDecorationColor: '#FFFFFF' }}>
                  <Text
                    color={'#FFFFFF'}
                    fontWeight={agreeTermsStyle.fontWeight}
                    fontSize={agreeTermsStyle.fontSize}
                    lineHeight={agreeTermsStyle.lineHeight}
                    paddingLeft={agreeTermsStyle.paddingLeft}
                  >
                    Privacy Policy
                  </Text>
                </a>
                .
              </Text>
            </HStack>
          </Checkbox>
        </FormControl>
        <JoinDiscordCard mb={{ base: '80px', md: '160px' }} mt={{ base: '23px', md: '119px' }} />
      </VStack>
      <WarningModal
        showModal={showConsentModal}
        title={consentView}
        leftButton={
          <LdButton variant={'secondary'} fixedSize={false} w={'134px'} onPress={() => setShowConsentModal(false)}>
            Cancel
          </LdButton>
        }
        rightButton={
          <LdButton fixedSize={false} w={'134px'} onPress={() => handleAgreeConsent()}>
            Agree
          </LdButton>
        }
        handleClose={() => setShowConsentModal(false)}
      />
      <AutoRedirectModal isOpen={isAutoRedirectOpen} onClose={() => setIsAutoRedirectOpen(false)} link={kycaiLink} ttl={linkTtl} />
    </PagesBackground>
  );
}

export { VerifyPageContainer };
