import { CachePolicies, useFetch } from 'use-http';
import { getDID } from '../lib/helpers';
import { current as envConfigs } from 'env-configs/selfkey-org';
import React, { useContext, useEffect, useState } from 'react';
import { NftContext, RoutingContext, Web3WagmiContext } from '../context/app_context';
import { UserStatus } from '../context/registry_provider';
import usePost from './use_post';
import { handleError } from '../lib/error_handler';
import { RP_CONFIG } from '../lib/constants';
import useGet from './use_get';

export function useMemberRoute() {
  const { setIsRouting, setUserRoute } = useContext(RoutingContext);
  const { activeAddress: address } = useContext(Web3WagmiContext);
  const { hasNft } = useContext(NftContext);
  const {
    post: requestAuth,
    error: errorRequestAuth,
    loading: isRequestAuthLoading
  } = useFetch(`${envConfigs.serverUrl}/credential/authorization`, { cachePolicy: CachePolicies.NO_CACHE });
  const [consentPayload, setConsentPayload] = useState(null);
  const [sendRequest, { data, loading, error, response, status }] = usePost();
  const [sendVKA] = usePost();
  const [showTakeSelfieModal, setShowTakeSelfieModal] = useState(false);
  const [poiCheckFailedError, setPoiCheckFailedError] = useState(false);
  const [skrPayload, setSkrPayload] = useState(null);
  const [checkConsent, { data: consentData, status: consentStatus }] = useGet();

  const { get } = useFetch(`${envConfigs.serverUrl}/user`, {
    retries: 3,
    retryDelay: 10000,
    retryOn({ response }) {
      return response && response.status !== 200 && response.url.endsWith(`/user/${address}`);
    },
    cachePolicy: CachePolicies.NETWORK_ONLY
  });
  async function checkAuthorized() {
    try {
      if (!isRequestAuthLoading) {
        setConsentPayload(null);
        const data = await requestAuth(getDID(address));
        const SKR = data?.SKR;
        setSkrPayload(SKR);
        if (SKR?.requirements && Object.keys(SKR.requirements).length > 0) {
          const kycaiUrl = new URL(SKR.url).origin;
          checkConsent(`${kycaiUrl}/user/consent/${address}`);
        } else {
          await sendRequest(SKR?.url, { SKR });
        }
      }
    } catch (e) {
      console.log('[DEBUG] checkAuthorized error', e);
    }
  }

  useEffect(() => {
    if (skrPayload) {
      if (consentStatus === 200) {
        if (skrPayload.requirements[RP_CONFIG.SELFIE]) {
          setShowTakeSelfieModal(true);
        } else {
          sendRequest(skrPayload.url, { SKR: skrPayload });
        }
      } else if (consentStatus === 404) {
        sendRequest(skrPayload.url, { SKR: skrPayload });
      }
    }
  }, [consentStatus, consentData]);

  useEffect(() => {
    if ((!loading && data) || error) {
      let route;
      if (error || (data && status !== 'ok')) {
        if (status === 451 || status === 422) {
          if (status === 451) {
            const skr = response?.data;
            setConsentPayload(skr);
          } else if (response?.data.error === 'POI check failed') {
            setPoiCheckFailedError(true);
          } else {
            setConsentPayload(null);
            setPoiCheckFailedError(false);
          }
          route = '/not-eligible';
        }
      } else {
        if (data.verifyUrl) {
          sendVKA(data.verifyUrl, data);
        }
        setConsentPayload(null);
        route = '/member';
      }
      setUserRoute(route);
      setIsRouting(false);
    }
  }, [data, error, loading, status, response]);

  useEffect(() => {
    if (hasNft) {
      routeMember();
    }
  }, [hasNft]);

  async function routeMember() {
    if (!address) return;
    let route;
    setIsRouting(true);
    if (hasNft === false) {
      try {
        const userStatus = await get(`/${address}`);
        switch (userStatus.status) {
          case UserStatus.approved:
          case UserStatus.member:
            route = '/member';
            break;
          case UserStatus.cancelled:
          case UserStatus.newUser:
            route = '/pay';
            break;
          case UserStatus.eligibleForMinting:
            route = '/mint-sbt';
            break;
          case UserStatus.eligibleForInvitation:
            route = '/initiate-verification';
            break;
          case UserStatus.pending:
            route = '/verifying';
            break;
          case UserStatus.rejected:
            route = '/rejected';
            break;
          default:
            break;
        }
      } catch (e) {
        handleError('Error routing user', e);
      }
      setUserRoute(route);
      setIsRouting(false);
    } else {
      try {
        await checkAuthorized();
        // const status = await isAuthorized();
        // if (status === AUTHENTICATION_STATUS.SUCCESS) {
        //   route = '/member';
        //   try {
        //     const rewardAmount = await getRewardAmount(address);
        //     console.log('[NFT] rewardAmount', rewardAmount);
        //   } catch (err) {
        //     console.log('[NFT] rewardAmount', err);
        //   }
        // } else if (status === AUTHENTICATION_STATUS.REJECTED) {
        //   route = '/rejected';
        // } else if (status === AUTHENTICATION_STATUS.NOT_ELIGIBLE || status === AUTHENTICATION_STATUS.UNKNOWN) {
        //   route = '/not-eligible';
        // }
      } catch (e) {
        handleError('Error checking authorized', e);
      }
    }
    // setIsRouting(false);
    // setUserRoute(route);
    // return route;
  }
  const handlePOIContinue = async base64Selfie => {
    setShowTakeSelfieModal(false);
    await sendRequest(skrPayload?.url, { SKR: skrPayload, selfie: base64Selfie });
  };
  const resetConsentPayload = () => {
    setConsentPayload(null);
  };
  return {
    routeMember,
    consentPayload,
    checkAuthorized,
    resetConsentPayload,
    showTakeSelfieModal,
    setShowTakeSelfieModal,
    poiCheckFailedError,
    setPoiCheckFailedError,
    handlePOIContinue
  };
}
